import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { Activity } from 'components/Pathway';
import { toLocalDateString } from '../../util/date.utils';
import {
    IPathwayMetadata,
    IPathwayInstanceData,
    IMilestone,
    ILesson,
} from '../../@types/pathway-types';
import { routerSelector } from 'redux/selectors';
import { HealthActivity } from './Activities';

export interface IPathwayActivityHolder {
    metaData: IPathwayMetadata;
    instanceData?: IPathwayInstanceData;
    milestone?: IMilestone;
    isHealthMode: boolean
}

const styles = {
    heading: {
        color: '#202124',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: '24px',
        mb: 1,
    },
    text: {
        color: '#202124',
        fontSize: '16px',
        letterSpacing: '0.15px',
        lineHeight: '24px',
        mb: 3,
    },
};
export function PathwayActivityHolder(props: IPathwayActivityHolder) {
    const { metaData, instanceData, milestone, isHealthMode } = props;
    const [skills, setSkills] = useState<string[]>([]);
    
    const currentCourseId = useSelector(
        routerSelector.selectCurrentPathwayLessonCourse
    ) || '';
    const currentLessonId = useSelector(
        routerSelector.selectCurrentMilestoneLessonId
    );
    const currentArticleId = useSelector(
        routerSelector.selectCurrentMilestoneArticleId
    ) || '';

    useEffect(() => {
        let filteredLesson:any[] = [];
        if(!isHealthMode) {
            const filteredMilestone =
                milestone?.data?.lessons.filter((lesson: ILesson) =>
                    lesson.metaData?.id === currentLessonId) || [];
            if(filteredMilestone.length) {
                filteredLesson =
                    filteredMilestone[0]?.data?.lessons.filter(
                        (lesson: ILesson) =>
                            lesson.metaData.id === currentLessonId
                    ) || [];
            }
            if(filteredLesson.length) {
                const skillData:any[] = filteredLesson[0]?.metaData?.skills || [];
                let skillArr:string[] = [];
                if(skillData.length > 0) {
                    skillData.map(item => {
                        skillArr.push(item.name);
                    });
                }
                setSkills(skillArr);
            }
        }
    }, [milestone, currentLessonId]);
    if (!currentCourseId && !currentArticleId) {
        return (
            <Box sx={{ ml: 3 }}>
                {instanceData?.dateStarted ? (
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            variant="body1"
                            sx={{ ...styles.heading, marginRight: 1 }}
                        >
                            Started
                        </Typography>
                        <Typography variant="body1" sx={styles.text}>
                            {toLocalDateString(instanceData?.dateStarted)}
                        </Typography>
                    </Box>
                ) : (
                    <></>
                )}
                <Box>
                    <Typography variant="body1" sx={styles.heading}>
                        Goal
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1" sx={styles.text}>
                        {metaData?.goal}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1" sx={styles.heading}>
                        Description
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1" sx={styles.text}>
                        {metaData?.description}
                    </Typography>
                </Box>
            </Box>
        );
    } else {
        return (
            <>
                {!isHealthMode ? <Activity
                    courseSisId={currentCourseId}
                    label={metaData?.title}
                    title={metaData?.title}
                    skills={skills}
                /> : <HealthActivity
                    currentArticleId={currentArticleId}
                />}
            </>
        );
    }
}