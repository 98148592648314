import { Button as MuiButton, Box } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';


export function Button(props: MuiButtonProps) {
    const { size: btnSize , color, variant, onClick, sx, ...other } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const btnStyles = {
        'contained': {
            ...selectedTheme?.button?.default,
            ...selectedTheme?.button?.size[btnSize || 'large'],
            ...selectedTheme?.button?.contained[color || 'primary']
        },
        'outlined': {
            ...selectedTheme?.button?.default,
            ...selectedTheme?.button?.size[btnSize || 'large'],
            ...selectedTheme?.button?.outlined[color || 'primary']
        },
        'text': {
            ...selectedTheme?.button?.default,
            ...selectedTheme?.button?.size[btnSize || 'large'],
            ...selectedTheme?.button?.text[color || 'primary']
        }
    }

    return (
        <Box>
            <MuiButton
                onClick={onClick}
                sx={!!selectedTheme && {...btnStyles[variant || 'outlined'], ...sx}}
                {...other}
            >
                {props.children}
            </MuiButton>
        </Box>
    );
}
