import {
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Modal
} from '@mui/material';
import { baseTheme, ThemeContext } from 'util/themes';
import { Close as CloseIcon } from '@mui/icons-material';
import { Children, useContext } from 'react';
import { Button } from 'lib/controls';

interface IConfirmButton {
    style: any;
    clickEvent: string;
    text: string;
    color: any;
    variant: any;
}

interface IConfirmModalComponent {
    isOpen: boolean;
    responseEvent: Function;
    headerText: string;
    subHeaderText: string;
    buttonList: IConfirmButton[];
}

const buttonBasics = {
    textTransform: 'none',
    mb: 2,
    fontWeight: '600',
    textDecoration: 'none',
    width: '-webkit-fill-available',
    fontSize: '14px',
};

const styles: any = {
    modalBackground: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 440,
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: 24,
        p: 2.5,
    },
    modalHeader: {
        color: baseTheme.palette.primary.main,
        fontSize: '20px',
        fontWeight: 'bold',
        mb: 3,
    },
    subHeader: {
        fontSize: '14px',
        color: '#5F6368',
        fontWeight: 500,
        pb: 3,
        alignSelf: 'flex-start',
    }
};

export function ConfirmModalComponent(props: IConfirmModalComponent) {
    const { isOpen, responseEvent, headerText, subHeaderText, buttonList } =
        props;
    const { selectedTheme } = useContext(ThemeContext);

    const modalClose = (event: string) => {
        responseEvent(event);
    };
    return (
        <Modal open={isOpen}>
            <Box sx={styles.modalBackground}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant={'h2'} sx={{
                        color: selectedTheme?.palette?.primary[700]
                    }}>
                        {headerText}
                    </Typography>
                    <CloseIcon
                        onClick={() => modalClose('CLOSE')}
                        sx={{ color: '#80848D' }}
                        className="cursor-pointer"
                    />
                </Box>
                <Typography sx={{
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body1,
                    ...selectedTheme?.typography?.body?.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale &&
                    selectedTheme?.palette?.secondary?.grayScale[700]
                }}>{subHeaderText}</Typography>
                <Box mt={4}>
                    {Children.toArray(
                        buttonList.map((button: IConfirmButton, index: number) => {
                            return (
                                <Button
                                    variant={button.variant}
                                    onClick={() => modalClose(button.clickEvent)}
                                    sx={{
                                        ...buttonBasics,
                                        ...button.style
                                    }}
                                    color={button.color}
                                >
                                    <Typography sx={{
                                        ...selectedTheme?.typography?.Components?.button?.default,
                                        ...selectedTheme?.typography?.Components?.button?.medium
                                    }}>
                                        {button.text}
                                    </Typography>
                                </Button>
                            );
                        })
                    )}
                </Box>
            </Box>
        </Modal>
    );
}
