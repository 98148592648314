import axios from 'axios';

import config from '../config';

const instance = (history = null) => {
    const axiosInstance = axios.create({ baseURL: config.apiBaseUrl });

    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, _) => {
                resolve(response);
            }),
        (error) => {
            console.log('ERROR', error.response);

            if (!error.response) {
                return new Promise((_, reject) => {
                    reject(error);
                });
            }

            if (
                error.response.status === 403 ||
                error.response.status === 401
            ) {
                localStorage.clear();

                if (history) {
                    console.log('ERROR: History', history);
                     (window as any).history.push('/login');
                } else {
                    console.log('ERROR: window.');
                    (window as any).location = '/login';
                }
                return new Promise((_, reject) => {
                    reject(error.response);
                });
            } else {
                return new Promise((_, reject) => {
                    reject(error.response);
                });
            }
        }
    );

    return axiosInstance;
};

const withCredentials = () => {
    const headers: any = {};
    const unparsedToken = window.localStorage.getItem('token') || '';
        

    const token = JSON.parse(unparsedToken);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    } else {
        console.error('JWT NOT FOUND');
    }

    return { headers: headers };
};

export { withCredentials, instance as axiosInstance };
export default instance;
