import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { licensureSelector, certificationSelector } from 'redux/selectors';
import {
    licensureActionType,
    certificationActionType,
} from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileLicensuresObject,
    IProfileCertificationsObject,
} from '../../../@types/profile-types';
import { LicenseForm } from './licenseForm';
import { CertificationForm } from './certificateForm';
import { ThemeContext } from 'util/themes';
import {
    CertificationKeyComponent,
    LicenceKeyComponent,
} from './licenceCertificationComponent';

interface ILicenseAndCertifications {
    title: string;
    canEdit: boolean;
    itemsToShow: string[];
    type: 'PAGE' | 'PREVIEW';
    selectedTheme?: any;
}

export const LicenseAndCertificationsCardComponent = (
    props: ILicenseAndCertifications
) => {
    const { title, canEdit, itemsToShow, type } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [licenseModalOpen, setLicenseModalOpen] = useState(false);
    const [certificationModalOpen, setCertificationModalOpen] = useState(false);
    const [licenseModalEditData, setLicenseModalEditData] = useState(null);
    const [certificationModalEditData, setCertificationModalEditData] =
        useState(null);
    const licenseKey = 'licensure_info';
    const certificationKey = 'certifications_info';

    const licensureLoading = useSelector(
        licensureSelector.selectLicensureLoading
    );
    const licensureList = useSelector(licensureSelector.selectLicensureData);
    const certificationsLoading = useSelector(
        certificationSelector.selectCertificationLoading
    );
    const certificationsList = useSelector(
        certificationSelector.selectCertificationData
    );

    const licensureConfig: IReduxDataConfig = {
        loadingAction: licensureActionType.PROFILE_LICENSURES_LOADING,
        successAction: licensureActionType.PROFILE_LICENSURES_SUCCESS,
        errorAction: licensureActionType.PROFILE_LICENSURES_ERROR,
        endpoint: '/v2/license',
    };

    const certificationsConfig: IReduxDataConfig = {
        loadingAction: certificationActionType.PROFILE_CERTIFICATIONS_LOADING,
        successAction: certificationActionType.PROFILE_CERTIFICATIONS_SUCCESS,
        errorAction: certificationActionType.PROFILE_CERTIFICATIONS_ERROR,
        endpoint: '/v2/certification',
    };

    useEffect(() => {
        if (itemsToShow.includes(licenseKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    licensureConfig
                )
            );
        }
        if (itemsToShow.includes(certificationKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    certificationsConfig
                )
            );
        }
    }, []);

    const handleLicenseModalOpen = (id: string) => {
        const find: any = licensureList.find(
            (x: IProfileLicensuresObject) => x.asset_id === id
        );
        const formObject = find ? find : null;
        setLicenseModalOpen(true);
        setLicenseModalEditData(formObject);
    };

    const handleCerticationsModalOpen = (id: string) => {
        const find: any = certificationsList.find(
            (x: IProfileCertificationsObject) => x.asset_id === id
        );
        const formObject = find ? find : null;
        setCertificationModalOpen(true);
        setCertificationModalEditData(formObject);
    };

    const handleModalClose = () => {
        setLicenseModalOpen(false);
        setCertificationModalOpen(false);
    };

    const addNewClicked = (modal?: string) => {
        if (modal === licenseKey) {
            setLicenseModalOpen(true);
            setLicenseModalEditData(null);
        } else if (modal === certificationKey) {
            setCertificationModalOpen(true);
            setCertificationModalEditData(null);
        }
    };

    let totalRecordsToDisplay = 0;
    if (itemsToShow.includes(licenseKey)) {
        totalRecordsToDisplay += licensureList.length;
    }
    if (itemsToShow.includes(certificationKey)) {
        totalRecordsToDisplay += certificationsList.length;
    }
    const addOptions = [
        { key: licenseKey, value: 'License' },
        { key: certificationKey, value: 'Certification' },
    ];
    return (
        <Grid item xs sx={selectedTheme.card?.gridWrapper}>
            <Paper sx={selectedTheme.card?.default}>
                <CardTitleComponent
                    title={title}
                    addOptionList={addOptions}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addNewClicked}
                />
                <Divider />
                {totalRecordsToDisplay === 0 ? (
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        <NoRecordsComponent />
                    </Grid>
                ) : (
                    <></>
                )}
                {itemsToShow.includes(licenseKey) ? (
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        {Children.toArray(
                            licensureList.map(
                                (
                                    x: IProfileLicensuresObject,
                                    index: number
                                ) => <LicenceKeyComponent
                                        data={{ ...x }}
                                        cardTitle={title}
                                        index={index}
                                        selectedTheme={selectedTheme}
                                        licensureLoading={licensureLoading}
                                        canEdit={canEdit}
                                        handleLicenseModalOpen={handleLicenseModalOpen}
                                    />
                            )
                        )}
                    </Grid>
                ) : (
                    <></>
                )}
                {itemsToShow.includes(certificationKey) ? (
                    <>
                        {certificationsList.length !== 0 ? <Divider /> : <></>}
                        <Grid container sx={selectedTheme.card?.cardPadding}>
                            {Children.toArray(
                                certificationsList.map(
                                    (
                                        x: IProfileCertificationsObject,
                                        index: number
                                    ) => <CertificationKeyComponent
                                            data={{ ...x }}
                                            cardTitle={title}
                                            index={index}
                                            selectedTheme={selectedTheme}
                                            certificationsLoading={certificationsLoading}
                                            canEdit={canEdit}
                                            handleCerticationsModalOpen={handleCerticationsModalOpen}
                                        />
                                )
                            )}
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                {licenseModalOpen ? (
                    <LicenseForm
                        modalOpen={licenseModalOpen}
                        licenseReduxConfig={licensureConfig}
                        handleModalClose={handleModalClose}
                        modalEditData={licenseModalEditData}
                    />
                ) : (
                    <></>
                )}
                {certificationModalOpen ? (
                    <CertificationForm
                        modalOpen={certificationModalOpen}
                        certificationsReduxConfig={certificationsConfig}
                        handleModalClose={handleModalClose}
                        modalEditData={certificationModalEditData}
                    />
                ) : (
                    <></>
                )}
            </Paper>
        </Grid>
    );
};

LicenseAndCertificationsCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: ['licensure_info', 'certifications_info'],
    type: 'PAGE',
};
