import React, { useEffect, useState } from 'react';
import { Grid, Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { IMilestone, IPathway } from '../../@types/pathway-types';
import { PathwaySummary, PathwayMilestoneHolder, PathwayActivityHolder } from '.';
import { PathwaySkeletonComponent } from './PathwaySkeleton';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';

interface IProps {
    loading: boolean,
    pathway: IPathway,
    selectedAssetId: any,
    completedMilestones: string,
    currentModule: string,
    isHealthMode: boolean,
}

export function PathwayDetailComponent(props: IProps) {
    const {pathway, selectedAssetId, loading, completedMilestones, currentModule, isHealthMode} = props;
    const theme = useTheme();

    const { selectedTheme } = useContext(ThemeContext);
    const wholePathWayComponentRef = React.useRef<HTMLInputElement>();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [
        marginTopForPathwayDetailComponent,
        setMarginTopForPathwayDetailComponent,
    ] = useState<number>(0);

    const pieTextColor = pathway?.instanceData ? selectedTheme?.palette?.purple?.dark : selectedTheme?.palette?.purple?.light;

    useEffect(() => {
        if (!selectedAssetId) {
            setMarginTopForPathwayDetailComponent(0);
        }
    }, [selectedAssetId]);

    const sectionSelected = (childMarginData: any) => {
        const parentMarginTop = wholePathWayComponentRef.current?.getBoundingClientRect().top;
        const childMarginTop = childMarginData.childTopMargin;
        const calculatedTop = childMarginTop - (parentMarginTop || 0);
        setMarginTopForPathwayDetailComponent(calculatedTop);
    };

    if (selectedAssetId && isMobile) {
        return (
            <PathwayActivityHolder
                metaData={pathway?.metaData}
                instanceData={pathway?.instanceData}
                milestone={pathway?.data?.milestones}
                isHealthMode={isHealthMode}
            />
        );
    }

    if (loading) {
        return <PathwaySkeletonComponent />
    }

    return (
        <Box>
            <PathwaySummary
                assetId={pathway?.assetId}
                metadata={pathway?.metaData}
                instancedata={pathway?.instanceData}
                mode="heading"
                completedMilestones={completedMilestones}
                pieTextColor={pieTextColor}
                applicationModule={currentModule}
            />
            <Container maxWidth={false} sx={{ px: '6.75rem !important', pb: 3}}>
                <Box ref={wholePathWayComponentRef}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item xs={12} md={4}>
                            {React.Children.toArray(
                                pathway?.data?.milestones?.map(
                                    (milestone: IMilestone) => {
                                        return (
                                            <PathwayMilestoneHolder
                                                milestone={milestone}
                                                sectionSelected={sectionSelected}
                                                isHealthMode={isHealthMode}
                                            />
                                        );
                                    }
                                )
                            )}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={8}
                            marginTop={{
                                xs: '0px',
                                md: `${marginTopForPathwayDetailComponent}px`,
                            }}
                        >
                            {isMobile ? (
                                <></>
                            ) : (
                                <PathwayActivityHolder
                                    metaData={pathway?.metaData}
                                    instanceData={pathway?.instanceData}
                                    milestone={pathway?.data?.milestones}
                                    isHealthMode={isHealthMode}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}