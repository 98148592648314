import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
    Grid,
    Typography,
    ButtonGroup,
    Button,
    TableSortLabel,
    Box,
    Skeleton,
} from '@mui/material';

import { consentLogActionCreators } from '../../redux/actionCreators';
import { PageHeader } from '../../components';
import ConsentLogSummary from '../../components/ConsentLogSummary';
import SearchBox from '../../components/SearchBox';
import { IConsentLogObject } from '../../redux/reducers/consentLog/getConsentLogs';

const styles = {
    root: { mx: 4 },
    buttonGrid: {
        height: '95px',
        py: 2,
    },
    searchGrid: {
        height: '95px',
        py: 2,
        justifyContent: {
            xs: 'flex-end', // theme.breakpoints.up('xs')
            lg: 'none', // theme.breakpoints.up('lg')
        },
        pl: {
            xs: 2, // theme.breakpoints.up('xs')
            lg: '0px', // theme.breakpoints.up('lg')
        },
    },
    mainHeader: {
        fontWeight: 'bold',
        fontSize: '18.72px',
        color: 'rgba(0,0,0,.74)',
    },
    noRecord: {
        pt: 2,
        width: '100%',
        textAlign: 'center' as const,
    },
    skeleton: {
        width: '100%',
        height: '100px',
    },
};

type Order = 'asc' | 'desc';

const comparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    if (orderBy === 'expirationDate') {
        if (b[orderBy]?.$date < a[orderBy]?.$date) return -1;
        if (b[orderBy]?.$date > a[orderBy]?.$date) return 1;
    }
    return 0;
};

const compare = (order: Order, orderBy: string | number | any) => {
    return order === 'desc'
        ? (a: any, b: any) => comparator(a, b, orderBy)
        : (a: any, b: any) => -comparator(a, b, orderBy);
};

export const ConsentLog = () => {
    const dispatch = useDispatch();
    const isLoadingConsentLogs = useSelector(
        (state: RootState) => state.getConsentLogs.loading
    );
    const consentLogs = useSelector(
        (state: RootState) => state.getConsentLogs.data
    );
    const [status, setStatus] = useState('active');
    const [visibleConsentLogs, setVisibleConsentLogs] = useState(consentLogs);
    const [searchData, setSearchData] = useState('');
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IConsentLogObject>();
    const consentLogHeaders = [
        { id: 'sharedWith', label: 'Shared With' },
        { id: 'expirationDate', label: 'Expiration' },
        { id: 'context', label: 'Context' },
        { id: 'source', label: 'Source' },
    ];

    const handleRequestSort =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        };

    const handleSearch = (event: { target: { value: any } }) => {
        setSearchData(event.target.value);
    };

    const getVisibleConsentLogs = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        const newlyVisible = consentLogs.filter((consentLog: any) =>
            consentLog.sharedWith
                .toLowerCase()
                .includes(searchData.toLowerCase())
        );

        switch (status) {
            case 'active':
                setVisibleConsentLogs(
                    newlyVisible.filter((consentLog: IConsentLogObject) => {
                        return !consentLog.expirationDate
                            ? true
                            : new Date(consentLog.expirationDate?.$date) >=
                                  today;
                    })
                );
                return;
            case 'expired':
                setVisibleConsentLogs(
                    newlyVisible.filter((consentLog: IConsentLogObject) => {
                        return !consentLog.expirationDate
                            ? false
                            : new Date(consentLog.expirationDate?.$date) <
                                  today;
                    })
                );
                return;
            default:
                setVisibleConsentLogs(newlyVisible);
                return;
        }
    };

    useEffect(() => {
        dispatch(consentLogActionCreators.getConsentLogs());
    }, []);

    useEffect(() => {
        getVisibleConsentLogs();
    }, [status, searchData, consentLogs, consentLogs]);

    return (
        <Box sx={styles.root}>
            <PageHeader title="Consent Log" />
            <Grid container direction="column">
                <Grid container>
                    <Grid container item md={6} sx={styles.buttonGrid}>
                        <Grid item xs>
                            <ButtonGroup size="large">
                                <Button
                                    variant={
                                        status === 'all'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    onClick={() => setStatus('all')}
                                >
                                    ALL
                                </Button>
                                <Button
                                    variant={
                                        status === 'active'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    onClick={() => setStatus('active')}
                                >
                                    ACTIVE
                                </Button>
                                <Button
                                    variant={
                                        status === 'expired'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    onClick={() => setStatus('expired')}
                                >
                                    EXPIRED
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs
                        md={6}
                        container
                        justifyContent="flex-end"
                        sx={styles.searchGrid}
                    >
                        <SearchBox
                            label="Search"
                            size="medium"
                            values={searchData}
                            onChange={handleSearch}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs>
                    {React.Children.toArray(
                        consentLogHeaders.map((consentLogHeader) => (
                            <Grid item xs={3} key={consentLogHeader.id}>
                                <TableSortLabel
                                    onClick={handleRequestSort(
                                        consentLogHeader.id
                                    )}
                                    active={orderBy === consentLogHeader.id}
                                    direction={
                                        orderBy === consentLogHeader.id
                                            ? order
                                            : 'asc'
                                    }
                                >
                                    <Typography
                                        sx={styles.mainHeader}
                                        color="primary"
                                    >
                                        {consentLogHeader.label}
                                    </Typography>
                                </TableSortLabel>
                            </Grid>
                        ))
                    )}
                    {isLoadingConsentLogs ? (
                        <Skeleton sx={styles.skeleton} />
                    ) : (
                        [
                            React.Children.toArray(
                                visibleConsentLogs.length > 0 ? (
                                    visibleConsentLogs
                                        .slice()
                                        .sort(compare(order, orderBy))
                                        .map((consentLog: any) => (
                                            <ConsentLogSummary
                                                key={consentLog.groupId}
                                                consentLog={consentLog}
                                            />
                                        ))
                                ) : (
                                    <Box sx={styles.noRecord}>
                                        <Typography sx={styles.mainHeader}>
                                            No Records Found
                                        </Typography>
                                    </Box>
                                )
                            ),
                        ]
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
