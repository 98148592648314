import { IRoute } from 'lib/types';

import { EVerify } from 'pages/e-verify/EVerify';

import { appTitle, authErrorPaths } from './route-constants'


export const verifyRoutes: IRoute[] = [
    {
        path: '/verify/dashboard',
        roles: ['VERIFIER'],
        component: EVerify,
        title: `${appTitle}: Verifier Pro Dashboard`,
        authErrorPaths: authErrorPaths,
    },
];

