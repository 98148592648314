import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Box, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import routes from 'util/routes';
import { ThemeContext } from 'util/themes';


const RouterBreadcrumbs = withBreadcrumbs(routes)(({ breadcrumbs }) => {
    
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Box sx={selectedTheme?.typography?.Components?.breadcrumb?.default}>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                {breadcrumbs.map(({
                    match,
                    breadcrumb
                }: any, i) => (
                    <Typography 
                        key={match.url} 
                        sx={{
                            ...selectedTheme?.typography?.captions?.default,
                            ...selectedTheme?.typography?.captions?.regular,
                            ...( breadcrumbs.length - 1 !== i ? selectedTheme?.typography?.Components?.breadcrumb?.link : selectedTheme?.typography?.Components?.breadcrumb?.linkFade) ,
                            ...( breadcrumbs.length - 1 !== i ? selectedTheme?.typography?.Components?.breadcrumb?.linkText  : '' )
                        }}>
                        <NavLink to={match.url}>{breadcrumb}</NavLink>
                    </Typography>
                ))}
            </Breadcrumbs>
        </Box>
    );
});


export default RouterBreadcrumbs;