import React from 'react';
import { Box, Stack } from '@mui/material';

import { styles } from '../verifyGrid.helper';
import { VerifyGridDetailRow } from './verifyGridDetailRow';

function verifyGridPhone(props: any) {
    const { data } = props;

    return (
        <>
            {!!data ? (
                <Stack direction="row" spacing={2}>
                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="Is Primary"
                            value={data?.primary?.toString() || 'n/a'}
                        />

                        <VerifyGridDetailRow
                            label="Phone Type"
                            value={data?.type || 'n/a'}
                        />

                        <VerifyGridDetailRow
                            label=" Phone Number"
                            value={data?.number || 'n/a'}
                        />
                    </Stack>

                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="Country Code"
                            value={data?.countryCode || 'n/a'}
                        />

                        <VerifyGridDetailRow
                            label="Extension"
                            value={data?.extension || 'n/a'}
                        />
                        <Box sx={styles.row}></Box>
                    </Stack>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
}

export default verifyGridPhone;
