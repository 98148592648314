import axios, { withCredentials } from 'util/axiosInstance';
import { 
    healthDashboardAllergyActionTypes,
    healthDashboardMedicationActionTypes,
    healthDashboardVaccinationActionTypes
 } from 'redux/actionsTypes';


 export const getHealthDashboardAllergyList = () => async (dispatch: Function) => {
    dispatch({ type: healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_LOADING });
    try {
        const resp = await axios().get('v2/allergy', withCredentials());
        dispatch({
            type: healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_ERROR });
    }
};

export const getHealthDashboardMedicationList = () => async (dispatch: Function) => {
    dispatch({ type: healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_LOADING });
    try {
        const resp = await axios().get('v2/medication', withCredentials());
        dispatch({
            type: healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_ERROR });
    }
};

export const getHealthDashboardVaccinationList = () => async (dispatch: Function) => {
    dispatch({ type: healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_LOADING });
    try {
        const resp = await axios().get('v2/vaccination', withCredentials());
        dispatch({
            type: healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_ERROR });
    }
};