import * as Types from '../lib/types';
import * as Components from '../lib/components';

import { Login } from '../pages/login';
import { TermsAndConditions } from '../pages/terms-and-conditions/TermsAndConditions';
import { Dashboard } from '../pages/dashboard';
import { ConsentLog } from '../pages/consent-log';
import { communityRoutes } from 'pages/commnuity/community.routes';
import { EVerify } from '../pages/e-verify/EVerify';
import { ProfileComponent } from '../pages/profile/profile';
import { ResetPassword } from '../pages/reset-password/ResetPassword';
import { HealthDashboardComponent } from 'pages/health-dashboard/HealthDashboard';
import {
    HomePathwayDetail,
    HomePathwayList,
    HomePathwayOnboarding,
} from 'pages/home-pathway';
import { LearnerDashboardComponent } from 'pages/learner-dashboard/LearnerDashboard';
import {
    DataManagerDashboard,
    DataManagerExported,
    DataManagerConsented,
    DataManagerOnboarding,
    DataManagerPrivacy,
} from '../pages/data-manager';

import {
    ShareMyDataWhoComponent,
    ShareMyDataWhatComponent,
    ShareMyDataHowComponent,
    ShareMyScreenReviewComponent,
    ShareMyDataWhenComponent,
} from 'pages/share-my-data';

import { UnexpectedError } from '../pages/errors';
import { FormDemo } from '../pages/form-demo';
import { VersionInfo } from '../pages/version';

import {
    OnboardingScreen1Component,
    OnboardingScreen2Component,
    OnboardingScreen3Component,
    OnboardingScreen4Component,
} from 'pages/onboarding';
import { skillRoutes } from 'pages/skills/skills.routes';
import { healthRoutes } from './routes/health.routes';
import { verifyRoutes } from './routes/verify.routes';
import ResetDemo from 'pages/reset-demo/ResetDemo';
import { appTitle, authErrorPaths } from './routes/route-constants';
import { marketPlaceRoutes } from './routes/marketPlace.routes';

const routes: Types.IRoute[] = [
    {
        path: '/form-demo',
        component: FormDemo,
        title: `${appTitle}: Form Demo`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/unexpected-error',
        component: UnexpectedError,
        title: `${appTitle}: Unexpected Error`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        title: 'Terms & Conditions',
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/profile',
        roles: ['PROFILE'],
        component: ProfileComponent,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/dashboard',
        roles: ['VIEWER'],
        component: Dashboard,
        title: `${appTitle}: Lifegraph Dashboard`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learner/dashboard',
        roles: ['LEARNER'],
        component: LearnerDashboardComponent,
        title: `${appTitle}: Learner Dashboard`,
        authErrorPaths: authErrorPaths,
    }, 
    {
        path: '/learning/pathway/onboarding',
        roles: ['LEARNER'],
        component: HomePathwayOnboarding,
        title: `${appTitle}: Let's Go on a Journey`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning/pathway/:pathwayId',
        roles: ['LEARNER'],
        component: HomePathwayDetail,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning/pathway',
        roles: ['LEARNER'],
        component: HomePathwayList,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/verify/dashboard',
        roles: ['VERIFIER'],
        component: EVerify,
        title: `${appTitle}: Verifier Pro Dashboard`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/consent',
        roles: ['VIEWER'],
        component: ConsentLog,
        title: `${appTitle}: Consent Log`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/login',
        component: Login,
        title: `${appTitle}: Login`,
        roles: ['public'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/logout',
        roles: ['VIEWER'],
        component: Components.Logout,
        title: `${appTitle}: Logout`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/reset',
        roles: ['VIEWER'],
        component: ResetDemo,
        title: `${appTitle}: Logout`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/set-password/',
        component: ResetPassword,
        title: `${appTitle}: Set Password`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/version-info',
        roles: ['VIEWER', 'public'],
        component: VersionInfo,
        title: `${appTitle}`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/1',
        component: OnboardingScreen1Component,
        title: `${appTitle}: Welcome`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/2',
        component: OnboardingScreen2Component,
        title: `${appTitle}: OK, let's get started`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/3',
        component: OnboardingScreen3Component,
        title: `${appTitle}: You're doing great!`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/4',
        component: OnboardingScreen4Component,
        title: `${appTitle}: Sweet! Your're all set.`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/data-manager/onboarding',
        roles: ['LEARNER'],
        component: DataManagerOnboarding,
        title: `${appTitle}: Take control of your data`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/data-manager/exported',
        roles: ['VIEWER'],
        component: DataManagerExported,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
    },

    {
        path: '/data-manager/consented',
        roles: ['VIEWER'],
        component: DataManagerConsented,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/data-manager/privacy',
        roles: ['VIEWER'],
        component: DataManagerPrivacy,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/who',
        roles: ['VIEWER'],
        component: ShareMyDataWhoComponent,
        title: `${appTitle}: Share My Data 1`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/what',
        roles: ['VIEWER'],
        component: ShareMyDataWhatComponent,
        title: `${appTitle}: Share My Data 2`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/how',
        roles: ['VIEWER'],
        component: ShareMyDataHowComponent,
        title: `${appTitle}: Share My Data 3`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/review',
        roles: ['VIEWER'],
        component: ShareMyScreenReviewComponent,
        title: `${appTitle}: Share My Data: Review My Data`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/when',
        roles: ['VIEWER'],
        component: ShareMyDataWhenComponent,
        title: `${appTitle}: Share My Data: When To Expire`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/data-manager',
        roles: ['VIEWER'],
        component: DataManagerDashboard,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
    },
    ...skillRoutes,
    ...communityRoutes,
    ...healthRoutes,
    ...verifyRoutes,
    ...marketPlaceRoutes,
    {
        path: '/health',
        roles: ['LEARNER'],
        component: HealthDashboardComponent,
        title: `${appTitle}: Health Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learner',
        roles: ['LEARNER'],
        component: LearnerDashboardComponent,
        title: `${appTitle}: Learner Dashboard`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning',
        roles: ['LEARNER'],
        component: HomePathwayList,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/',
        roles: ['PROFILE'],
        component: ProfileComponent,
        title: `${appTitle}`,
        authErrorPaths: authErrorPaths,
        breadcrumb : 'Home'
    },
];

export default routes;
