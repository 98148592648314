import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IMilestone } from '../../../@types/pathway-types';
import { routerSelector } from 'redux/selectors';

const pathwayState = (state: RootState) => state.pathway;

const selectPathwayState = createSelector([pathwayState], (state) => {
    return state;
});

export const selectPathwayLoading = createSelector(selectPathwayState, (state) => {
    return state.loading;
});

export const selectPathwayCompleteData = createSelector(selectPathwayState, (state) => {
    return state.data;
});

export const selectPathwayError = createSelector(selectPathwayState, (state) => {
    return state.error;
});

export const selectUserPathways = createSelector(selectPathwayCompleteData, (pathwayList) => {
    return pathwayList.filter((x) => x.instanceData);
});

export const selectOtherPathways = createSelector(selectPathwayCompleteData, (pathwayList) => {
    return pathwayList.filter((x) => !x.instanceData);
});

export const selectPathwayExpandedStep = createSelector(selectPathwayState, (state) => {
    return state.expandedActivity;
});

export const selectedPathwayLoading = createSelector(selectPathwayState, (state) => {
    return state.expandedActivityLoading
});

export const selectTotalCompletedMilestones = createSelector(
    selectedPathwayLoading, selectPathwayExpandedStep, (loading, selectedPathway) => {
        if (loading) {
            return '';
        }
        if (!selectedPathway?.data?.milestones?.length) {
            return '';
        }
        const total = selectedPathway?.data?.milestones.length;
        const complete = selectedPathway?.data?.milestones.filter((m: IMilestone) => m?.instanceData?.status === 'completed').length;
        return `${complete} of ${total} milestones`;
});

export const selectCurrentLessonCoursesLoading = createSelector(selectPathwayState, (state) => {
    return state.currentLessonCoursesLoading;
});

export const selectCurrentLessonCourses = createSelector(selectPathwayState, (state) => {
    return state.currentLessonCourses;
});

export const selectNextActivity = createSelector(selectCurrentLessonCourses, (courses) => {
    return courses.find((act: any) => act?.completion_requirement?.completed === false);
});

export const selectAllActivitiesCompleted = createSelector(selectCurrentLessonCourses, (courses) => {
    for (const activity of courses) {
        if (!activity.completion_requirement?.completed) {
            return false;
        }
    }
    return true;
});

export const selectLessonTitle = createSelector(
    routerSelector.selectCurrentMilestoneLessonId, selectPathwayExpandedStep, (lesson, pathway) => {
        if (pathway) {
            const milestones = pathway.data?.milestones || [];
            for (const milestone of milestones) {
                const search = milestone.data.lessons.find((x: any) => x.metaData.id === lesson);
                if (search) {
                    return search.metaData.title;
                }
            }
        }
        return '';
});