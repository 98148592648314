import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar';
import { ThemeContext } from 'util/themes';


const styles = {
	whiteFont: {
		color: '#5D6A71',
	},
	blueButton: {
		backgroundColor: '#0e2263',
	},
	barLabel: {
		fontFamily: 'Red Hat Text',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '10px',
		lineHeight: '13px',
		letterSpacing: '0.4px',
		color: '#47515A'
	}
};

const fitnessMockData = [
	{ day: "SUN", "steps": 1362 },
	{ day: "MON", "steps": 350 },
	{ day: "TUE", "steps": 547 },
	{ day: "WED", "steps": 732 },
	{ day: "THU", "steps": 748 },
	{ day: "FRI", "steps": 210 },
	{ day: "SAT", "steps": 1329 }
];

export function FitnessBarGraph() {
	const { selectedTheme } = useContext(ThemeContext);
	const theme = {
		axis: {
			domain: {
				line: {
					stroke: "#202020",
					strokeWidth: 0.3
				}
			},
			ticks: {
				text: {
					fill: selectedTheme?.palette?.secondary?.grayScale &&
						selectedTheme?.palette?.secondary?.grayScale[700],
					fontSize: '12px',
					fontWeight: 500,
					letterSpacing: '0.4px'

				}
			}
		}
	};
	return (
		<>
			<Grid
				xs={12}
				sx={{ height: '250px', pb: 4 }}
				container
				item
			>
				<Grid item container justifyContent='space-between'>
					<Grid item>
						<Typography sx={{
							...selectedTheme?.typography?.overlines?.overline1.default,
							...selectedTheme?.typography?.overlines?.overline1?.bold,
							color: selectedTheme?.palette.secondary?.grayScale &&
								selectedTheme?.palette.secondary?.grayScale[600],
							mt: 2.5, ml: 3.3, mb: 1
						}}>
							STEPS/DAY
						</Typography>
					</Grid>
					<Grid item>
						<Grid container justifyContent='center' alignItems='center' flexDirection='column'>
							<Typography variant={'h3'} sx={{
								color: selectedTheme?.palette?.primary[600],
								mt: 1.5, mr: 3.3
							}}>
								754
							</Typography>
							<Typography sx={{
								...selectedTheme?.typography?.overlines?.overline2.default,
								...selectedTheme?.typography?.overlines?.overline2?.medium,
								color: selectedTheme?.palette.secondary?.grayScale &&
									selectedTheme?.palette.secondary?.grayScale[600],
								mt: .5, mr: 3.3
							}}>
								AVG/DAY
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<ResponsiveBar
					data={fitnessMockData}
					keys={[
						'steps'
					]}
					theme={theme}
					indexBy="day"
					margin={{
						top: 0,
						right: 20,
						bottom: 60,
						left: 60,
					}}
					layers={[
						'grid',
						'axes',
						'bars',
						'markers',
						'legends',
						'annotations',
						({ bars }) => {
							return (
								<g>
									{React.Children.toArray(
										bars.map(
											({ x, y, data }) => {
												return (
													<text
														transform={`translate(${x + 3}, ${y - 10})`}
														textAnchor="left"
														dominantBaseline="central" style={styles.barLabel}>
														{`${data.value?.toLocaleString() ?? ''}`}
													</text>
												)
											})
									)}
								</g>
							);
						}
					]}
					enableLabel={false}
					padding={0.3}
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={selectedTheme?.palette?.primary[400]}
					borderColor={{
						from: 'color',
						modifiers: [
							[
								'darker',
								1.6
							]
						]
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 0,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 25,
					}}
					enableGridY={false}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						tickValues: [0, 2000, 4000, 6000, 8000, 10000],
						legend: '',
						legendPosition: 'middle',
						legendOffset: -45,
						format: (value) => {
							return value.toLocaleString() || value
						}
					}}
					maxValue={12000}
					labelSkipWidth={12}
					labelSkipHeight={12}
					role="application"
					ariaLabel="Nivo bar chart demo"
					barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in day: " + e.indexValue }}
				/>
			</Grid>
		</>
	)
}