import { IReduxDataConfig } from '../../@types/profile-types';

import {
    phoneNoActionType,
    ProfileLookupDataActions,
    emailAddressActionType,
    addressActionType,
    continuingEducationActionType,
    formalEducationActionType,
    licensureActionType,
    certificationActionType,
    trainingActionType,
    workActionType,
    volunteerActionType,
    citizenshipActionType,
    taxActionType,
    personalnfoActionType,
} from 'redux/actionsTypes';

// Profile Configs

export const personConfig: IReduxDataConfig = {
    loadingAction: personalnfoActionType.PROFILE_PERSONALINFO_LOADING,
    successAction: personalnfoActionType.PROFILE_PERSONALINFO_SUCCESS,
    errorAction: personalnfoActionType.PROFILE_PERSONALINFO_ERROR,
    endpoint: '/v2/person',
};

export const citizenshipConfig: IReduxDataConfig = {
    loadingAction: citizenshipActionType.PROFILE_CITIZENSHIP_LOADING,
    successAction: citizenshipActionType.PROFILE_CITIZENSHIP_SUCCESS,
    errorAction: citizenshipActionType.PROFILE_CITIZENSHIP_ERROR,
    endpoint: '/v2/citizenship',
};

export const taxConfig: IReduxDataConfig = {
    loadingAction: taxActionType.PROFILE_TAX_LOADING,
    successAction: taxActionType.PROFILE_TAX_SUCCESS,
    errorAction: taxActionType.PROFILE_TAX_ERROR,
    endpoint: '/v2/taxinfo',
};

// Phone
export const phoneConfig: IReduxDataConfig = {
    loadingAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_LOADING,
    successAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_SUCCESS,
    errorAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_ERROR,
    endpoint: '/v2/phone',
};

export const addressConfig: IReduxDataConfig = {
    loadingAction: addressActionType.PROFILE_ADDRESSES_LOADING,
    successAction: addressActionType.PROFILE_ADDRESSES_SUCCESS,
    errorAction: addressActionType.PROFILE_ADDRESSES_ERROR,
    endpoint: '/v2/address',
};

export const emailConfig: IReduxDataConfig = {
    loadingAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_LOADING,
    successAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_SUCCESS,
    errorAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_ERROR,
    endpoint: '/v2/email',
};

const continuingEducationConfig: IReduxDataConfig = {
    loadingAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_LOADING,
    successAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_SUCCESS,
    errorAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_ERROR,
    endpoint: '/v2/continuingeducation',
};

const educationConfig: IReduxDataConfig = {
    loadingAction: formalEducationActionType.PROFILE_EDUCATION_LOADING,
    successAction: formalEducationActionType.PROFILE_EDUCATION_SUCCESS,
    errorAction: formalEducationActionType.PROFILE_EDUCATION_ERROR,
    endpoint: '/v2/education',
};

const licensureConfig: IReduxDataConfig = {
    loadingAction: licensureActionType.PROFILE_LICENSURES_LOADING,
    successAction: licensureActionType.PROFILE_LICENSURES_SUCCESS,
    errorAction: licensureActionType.PROFILE_LICENSURES_ERROR,
    endpoint: '/v2/license',
};

const certificationsConfig: IReduxDataConfig = {
    loadingAction: certificationActionType.PROFILE_CERTIFICATIONS_LOADING,
    successAction: certificationActionType.PROFILE_CERTIFICATIONS_SUCCESS,
    errorAction: certificationActionType.PROFILE_CERTIFICATIONS_ERROR,
    endpoint: '/v2/certification',
};

const trainingConfig: IReduxDataConfig = {
    loadingAction: trainingActionType.PROFILE_TRAINING_LOADING,
    successAction: trainingActionType.PROFILE_TRAINING_SUCCESS,
    errorAction: trainingActionType.PROFILE_TRAINING_ERROR,
    endpoint: '/v2/training',
};

const volunteerConfig: IReduxDataConfig = {
    loadingAction: volunteerActionType.PROFILE_VOLUNTEER_LOADING,
    successAction: volunteerActionType.PROFILE_VOLUNTEER_SUCCESS,
    errorAction: volunteerActionType.PROFILE_VOLUNTEER_ERROR,
    endpoint: '/v2/volunteer',
};

const employmentPositionConfig: IReduxDataConfig = {
    loadingAction: workActionType.PROFILE_EMPLOYMENT_LOADING,
    successAction: workActionType.PROFILE_EMPLOYMENT_SUCCESS,
    errorAction: workActionType.PROFILE_EMPLOYMENT_ERROR,
    endpoint: '/v2/employmentposition',
};

// Lookup Configs
export const phoneLookupConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_ERROR,
    endpoint: '/v2/phone-external',
};

export const emailLookupConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_ERROR,
    endpoint: '/v2/phone-external',
};

const educationLookupConfig: IReduxDataConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_ERROR,
    endpoint: '/v2/education-external',
};

export const getConfig = (assetType: string) => {
    let x;
    switch (assetType) {
        case 'Person':
            return personConfig;
        case 'Citizenship':
            return citizenshipConfig;
        case 'Tax':
            return taxConfig;
        case 'Address':
            return addressConfig;
        case 'Email':
            return emailConfig;
        case 'Phone':
            return phoneConfig;
        case 'EmploymentPosition':
            return employmentPositionConfig;
        case 'Volunteer':
            return volunteerConfig;
        case 'ContinuingEducation':
            return continuingEducationConfig;
        case 'Education':
            return educationConfig;
        case 'License':
            return licensureConfig;
        case 'Certification':
            return certificationsConfig;
        case 'Training':
            return trainingConfig;
    }
};
