import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';

export interface IMedicationFormInputs {
    medicine: string;
    prescribedBy: string;
}

const schema = yupObject({
    medicine: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    prescribedBy: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
});

export const formSchemaResolver = yupResolver(schema);