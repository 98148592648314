import { pathwayActionType, notificationActionTypes } from 'redux/actionsTypes';
import { IStep } from '../../../@types/pathway-types';
import axios, { withCredentials } from 'util/axiosInstance';
import { history } from 'App';

// PATHWAY ActionCreators
export const getPathwayData = () => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_PATHWAY_LOADING });
    try {
        const resp = await axios().get('/v2/pathway', withCredentials());
        dispatch({
            type: pathwayActionType.GET_PATHWAY_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: pathwayActionType.GET_PATHWAY_ERROR });
    }
};

export const getSelectedPathwayData = (selectedPathwayId: string) => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_SELECTED_PATHWAY_LOADING });
    try {
        const resp = await axios().get(`/v2/pathway/${selectedPathwayId}`, withCredentials());
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: resp.data
        });
    } catch (err) {
        dispatch({ type: pathwayActionType.GET_SELECTED_PATHWAY_ERROR });
    }
}

export const addOtherPathwayToUserPathway = (pathwayId: string) => async (dispatch: Function) => {
    try {
        await axios().post(
            `/v2/pathway`,
            { pathwayId: pathwayId },
            withCredentials()
        );
        dispatch({ type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION, message: 'Success' });
        history.push('/learning/pathway');
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to add Pathway' });
    }
}

export const getSelectedPathwayMilestoneCourses = (courseId: string) => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_COURSE_ITEMS_LOADING_ACTION });
    try {
        const response = await axios().get(`/v2/canvas/course/${courseId}/items`, withCredentials());
        dispatch({ type: pathwayActionType.GET_COURSE_ITEMS_SUCCESS_ACTION, payload: response.data });
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load course items' });
    }
}

export const setPathwayExpandedStep =
    (step: IStep) => async (dispatch: Function) => {
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: step,
        });
    };


export const clearPathwayExpandedStep =
    () => async (dispatch: Function) => {
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: {},
        });
    };