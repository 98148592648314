import { dataManagerSummaryActionType, notificationActionTypes } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';

export const getSharedDataContracts = () => async (dispatch: Function) => {
    dispatch({ type: dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY });
    try {
        const resp = await axios().get('/contract', withCredentials());
        dispatch({
            type: dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY_SUCCESS,
            payload: resp.data.data,
        });
    } catch (error) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load shared data, please contact support' });
        dispatch({ type: dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY_ERROR });
    }
};

export const getExportedDataContracts = () => async (dispatch: Function) => {
    dispatch({ type: dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY });
    try {
        const resp = await axios().get('/export-data/vc', withCredentials());
        dispatch({
            type: dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY_SUCCESS,
            payload: resp.data,
        });
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load exported data, please contact support' });
        dispatch({ type: dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY_ERROR });
    }
}