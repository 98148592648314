import { addressActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileAddressesObject } from '../../../@types/profile-types';
import mockAddressData from 'assets/data/profile/address.json';

const initialData: IProfileAddressesObject[] = mockAddressData.data as IProfileAddressesObject[];

export interface IProfileAddressesInfo {
    error: boolean;
    data: IProfileAddressesObject[];
    loading: boolean;
}

interface IprofileAddressesAction {
    type: string;
    payload: IProfileAddressesObject[];
}

const initialStateData = {
    error: false,
    data: initialData,
    loading: true,
};

const reducer = (
    state: IProfileAddressesInfo = initialStateData,
    action: IprofileAddressesAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case addressActionType.PROFILE_ADDRESSES_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case addressActionType.PROFILE_ADDRESSES_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case addressActionType.PROFILE_ADDRESSES_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
