import React, { useEffect } from 'react';
import { Typography, Box, Paper, IconButton, SvgIcon } from '@mui/material';
import { IArticle } from '../../../@types/pathway-types';
import { baseTheme } from 'util/themes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { routerSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import {
    toHoursAndMinutes, toLocalDateString,
} from 'util/date.utils';
import { Icon } from '@iconify/react';
import { accountCheck, lock } from '../pathwayIcons';

const styles = {
    root: {
        boxSizing: 'border-box',
        border: '2px solid #BDC1C5',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 2px 0 #E3E8EE',
        my: baseTheme.spacing(2),
        p: baseTheme.spacing(1),
    },
    activeArticle: {
        border: '2px solid #69D7D2',
        borderTop: '10px solid #69D7D2',
    },
    title: {
        color: '#47515A',
        fontSize: baseTheme.spacing(2),
        fontWeight: '600',
        letterSpacing: 0,
        lineHeight: baseTheme.spacing(3),
    },
    completeTitleColor: {
        color: '#5D6A71',
    },
    completeCard: {
      background: '#F7F9FA',
    },
    dateComplete: {
        color: '#47515A',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '18px',
    },
    projectCompletionTime: {
        color: '#5D6A71',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0',
        lineHeight: '19px',
    },
};

export function Articles(props: IArticle) {
    const { assetId, metaData, sectionSelected, instanceData = {} } = props;
    const {status = '', dateComplete = '', dateStart = ''} = instanceData
    const articleBoxReference = React.useRef<HTMLInputElement>();
    const history = useHistory();
    const selectedArticleAssetId = useSelector(
        routerSelector.selectCurrentMilestoneArticleId
    );

    const showActivities = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('article_id', assetId);
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
        // setOpen(true);
        if (articleBoxReference.current) {
            sectionSelected({
                childTopMargin:
                articleBoxReference.current.getBoundingClientRect().top,
            });
        }
    };

    useEffect(() => {
        if (
            assetId === selectedArticleAssetId &&
            articleBoxReference.current
        ) {
            sectionSelected({
                childTopMargin:
                articleBoxReference.current.getBoundingClientRect().top,
            });
        }
    }, []);

    const getArticleDetail = () => {
        if(status === "completed") {
            return (
                <Box sx={styles.projectCompletionTime}>
                    {`Completed: ${toLocalDateString(dateComplete)}`}
                </Box>
            )
        } else if(dateStart) {
            return (
                <Box sx={styles.projectCompletionTime}>
                    {`Started: ${toLocalDateString(dateStart)}`}
                </Box>
            )
        } else {
            return (
                <Box sx={styles.projectCompletionTime}>
                    {`~${toHoursAndMinutes(metaData?.projectCompletionTime)}`}
                </Box>
            );
        }
    };

    const getIcon = (articleStatus: string) => {
        if (!articleStatus) {
            return <Box />;
        } else if (articleStatus === 'completed') {
            return <Icon icon={accountCheck} style={{ color: '#3FB3B0' }} width={24} height={24} />;
        } else if (articleStatus === 'locked' || status === 'blocked') {
            return <Icon icon={lock} style={{color: "#8499A0"}} />;
        }
    };

    const selectedStyleOverride: any = {};
    if ((metaData as any).id === selectedArticleAssetId) {
        selectedStyleOverride.boxShadow = '';
    }

    let rootStyle = {...styles.root, ...selectedStyleOverride};
    if(assetId === selectedArticleAssetId) rootStyle = {...rootStyle, ...styles.activeArticle}
    let titleStyle = styles.title;
    if(status === 'completed') {
        titleStyle = { ...titleStyle, ...styles.completeTitleColor };
        rootStyle = {...rootStyle, ...styles.completeCard}
    }
    return (
        <>
            <Box ref={articleBoxReference}>
                <Paper
                    sx={rootStyle}
                    elevation={9}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ ml: 1.5 }}>
                            <Typography variant="h3" sx={titleStyle}>
                                {metaData.title}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1.5 }}>
                            {!!instanceData?.status ? (
                                <SvgIcon fontSize="large" viewBox="36 36">
                                    {getIcon(instanceData?.status)}
                                </SvgIcon>
                            ) : (
                                <Box component={'span'} />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ p: 1 }}>{getArticleDetail()}</Box>
                        <IconButton onClick={showActivities}>
                            <ChevronRightIcon sx={{ color: '#3FB3B0' }} />
                        </IconButton>
                    </Box>
                </Paper>
            </Box>
        </>
    );
}