import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { healthPathwayActionCreators } from 'redux/actionCreators';
import { healthPathwaySelector, routerSelector } from 'redux/selectors';
import { PathwayListComponent } from 'components/Pathway/PathwayList';

 export function HealthPathwayListComponent() {
    const dispatch = useDispatch();
    const myPathways = useSelector(healthPathwaySelector.selectUserHealthPathways);
    const otherPathways = useSelector(healthPathwaySelector.selectOtherHealthPathways);
    const loading = useSelector(healthPathwaySelector.selectHealthPathwayLoading);
    const currentModule = useSelector(routerSelector.selectCurrentModule);

    useEffect(() => {
        // if (!checkOnboarding('pathway')) {
        //     history.push('/pathway/onboarding');
        // }
        dispatch(healthPathwayActionCreators.getHealthPathwayList());
    }, []);

    return (
        <PathwayListComponent
            myPathways={myPathways}
            loading={loading}
            currentModule={currentModule} 
            otherPathways={otherPathways}
        />
    );
 }