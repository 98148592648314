import {
    Typography,
    Box,
    useMediaQuery,
    useTheme,
    Skeleton,
    List,
    ListItem,
    ListItemText,
    Dialog,
} from '@mui/material';
import { BorderLinearProgress } from 'components';
import { useState, useEffect, Children, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios, { withCredentials } from '../../util/axiosInstance';
import { commonStyles } from './share-my-data.helper';
import { shareMyDataSelector } from 'redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import {
    IShareData,
    IShareableItem,
    IShareMethod,
    IShareDataRequest,
} from '../../@types/share-my-data.types';
import { toLocalDateString } from 'util/date.utils';
import {
    notificationActionTypes,
    shareMyDataActionTypes,
} from 'redux/actionsTypes';
import { ProfilePreviewComponent } from 'pages/profile/profile-preview';
import { ProfileConstants } from 'app-constants';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import { downloadDataMangerData } from '../../util/helper';

const styles = {
    primaryText: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        textTransform: 'uppercase',
    },
    secondaryText: {
        color: '#202124',
        fontSize: '16px',
        lineHeight: '24px',
    },
};

export function ShareMyScreenReviewComponent() {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);

    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const selectedData = useSelector(shareMyDataSelector.selectData);
    const [selectedItemList, setSelectedItemList] = useState<string[]>([]);
    const [seletedMethodName, setSelectedMethodName] = useState<string>();
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [shareDataLoading, setShareDataLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    const assetTypesToShare = shareObject.assetTypes;

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        } else {
            const type = shareObject.type;
            const find: IShareData | undefined = selectedData.find(
                (x: IShareData) => x.type === type
            );
            const valueToTitleArray: string[] = [];
            if (find && shareObject.assetTypes) {
                for (const shareAsset of find.shareableAssetList) {
                    shareAsset.values
                        .filter((x: IShareableItem) =>
                            shareObject.assetTypes.includes(x.value)
                        )
                        .forEach((x: IShareableItem) =>
                            valueToTitleArray.push(x.title)
                        );
                }
            }
            setSelectedItemList(valueToTitleArray);
            if (find && shareObject.method) {
                const shareMethod = find.shareMethodList.find(
                    (x: IShareMethod) => x.value === shareObject.method
                );
                if (shareMethod) {
                    setSelectedMethodName(shareMethod.header);
                }
            }
        }
    }, [loading]);

    const width = isMobile ? '92%' : '500px';

    const previewClicked = () => {
        setShowPreview(true);
    };

    const handlePreviewClose = () => {
        setShowPreview(false);
    };

    const nextClicked = async () => {
        setShareDataLoading(true)
        // Omit values contain pathway values that we don't want to send for now including internal user_id
        const OMIT_VALUE = [
            'user_id',
            ProfileConstants.EMPLOYEE_PATHWAY_INFO,
            ProfileConstants.OTHER_PATHWAY_INFO,
            ProfileConstants.NON_ACTIVE_INFO,
        ];
        let requestData: IShareDataRequest = {
            for:
                shareObject.assetTypes?.filter(
                    (assetType: string) => !OMIT_VALUE.includes(assetType)
                ) || [],
            to: shareObject.type,
            type: shareObject.method,
            until: shareObject.until,
        };
        try {
            let url = '/share-data';
            if (shareObject?.method === 'VC') {
                url = '/export-data';
                requestData.method = 'VC';
                requestData.type = 'export';
                delete requestData.to;
                delete requestData.until;

                let response = await axios().post(url, requestData, {...withCredentials(), responseType: 'arraybuffer'});
                downloadDataMangerData(response);
                
            } else if(shareObject?.method === 'consent') {
                await axios().post(url, requestData, withCredentials());
            }
            dispatch({
                type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION,
                message: 'Congratulations, you shared your data successfully',
            });
            dispatch({
                type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
                payload: {},
            });
            history.push(shareObject.shareOrigin);
            setShareDataLoading(false)
        } catch (err) {
            dispatch({
                type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION,
                message: 'An error occurred. Please try later!',
            });
            setShareDataLoading(false)
        }
        // validate all type, asset values, date before api call for employer type and external type
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    const data: any[] = [];
    if (shareObject.type === 'employer') {
        data.push({
            title: 'Recipient',
            value: 'August Brett - august.brett@gmail.com',
        });
        data.push({ title: 'Data Shared', value: selectedItemList.join(', ') });
        data.push({
            title: 'Expiration Date',
            value: shareObject.until
                ? toLocalDateString(shareObject.until)
                : 'No Expiration',
        });
    } else {
        data.push({ title: 'Method', value: seletedMethodName });
        data.push({ title: 'Data Shared', value: selectedItemList.join(', ') });
    }

    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width, height: '200px' }}>
                <Typography
                    sx={{
                        ...commonStyles.header,
                        color: selectedTheme.palette.primary[700],
                    }}
                    variant="h1"
                >
                    Share My Data
                </Typography>
                <BorderLinearProgress
                    variant="determinate"
                    value={95}
                    bgcolor={selectedTheme.palette.primary[500]}
                />
                <Typography
                    sx={{
                        ...commonStyles.subHeader,
                        mb: 2,
                        color:
                            selectedTheme?.palette?.secondary?.grayScale &&
                            selectedTheme?.palette?.secondary?.grayScale[800],
                    }}
                    variant="h2"
                >
                    Review and Send
                </Typography>
                <Button
                    variant={'outlined'}
                    size={'large'}
                    onClick={previewClicked}
                    color="primary"
                    sx={{ fontWeight: 'bold', borderRadius: '4px' }}
                >
                    <Typography
                        sx={{
                            ...selectedTheme.typography?.Components?.button
                                ?.default,
                            ...selectedTheme.typography?.Components?.button
                                ?.large,
                            color: selectedTheme.palette.primary[700],
                        }}
                    >
                        Preview Shared Data
                    </Typography>
                </Button>
                <List dense={true}>
                    {Children.toArray(
                        data.map((row: any, index: number) => {
                            return (
                                <ListItem disableGutters>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            loading ? (
                                                <Skeleton width={'20%'} />
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        ...selectedTheme
                                                            ?.typography
                                                            ?.overlines
                                                            ?.overline2.default,
                                                        ...selectedTheme
                                                            ?.typography
                                                            ?.overlines
                                                            ?.overline2
                                                            ?.regular,
                                                        color:
                                                            selectedTheme
                                                                ?.palette
                                                                ?.secondary
                                                                ?.grayScale &&
                                                            selectedTheme
                                                                ?.palette
                                                                ?.secondary
                                                                ?.grayScale[600],
                                                    }}
                                                >
                                                    {row.title}
                                                </Typography>
                                            )
                                        }
                                        secondary={
                                            loading ? (
                                                <Skeleton width={'70%'} />
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        ...styles.secondaryText,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.default,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.body1,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.regular,
                                                        color:
                                                            selectedTheme
                                                                ?.palette
                                                                ?.secondary
                                                                ?.grayScale &&
                                                            selectedTheme
                                                                ?.palette
                                                                ?.secondary
                                                                ?.grayScale[800],
                                                    }}
                                                >
                                                    {row.value}
                                                </Typography>
                                            )
                                        }
                                    />
                                </ListItem>
                            );
                        })
                    )}
                </List>
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color="secondary"
                        size="large"
                        disabled={loading}
                        onClick={cancelClicked}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        size="large"
                        disabled={loading}
                        onClick={previousClicked}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                            }}
                        >
                            Back
                        </Typography>
                    </Button>
                    <Button
                        size={'large'}
                        color="primary"
                        variant={'contained'}
                        onClick={!shareDataLoading ? nextClicked : () => {}}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Finish
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                fullScreen={isMobile}
                open={showPreview}
                onClose={handlePreviewClose}
            >
                <ProfilePreviewComponent
                    assetListToShow={assetTypesToShare}
                    closeEvent={handlePreviewClose}
                />
            </Dialog>
        </Box>
    );
}
