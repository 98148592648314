import { produce } from 'immer';
import { consentLogActionTypes } from 'redux/actionsTypes';

export interface IConsentLog {
    error: boolean;
    data: Array<IConsentLogObject>;
    loading: boolean;
}

export interface IConsentInfoObject {
    assetType: string;
    attributes: Array<string>;
    asset_id: string;
}

export interface IConsentLogObject {
    sharedWith: string;
    expirationDate: null | { $date: string };
    source: string;
    context: string;
    groupId: string;
    consentInfo: Array<IConsentInfoObject>;
}

interface IConsentLogAction {
    type: string;
    payload: Array<IConsentLogObject>;
}

const initialData = [
    {
        sharedWith: '',
        expirationDate: null,
        source: '',
        context: '',
        groupId: '',
        consentInfo: [],
    },
];

const initialState = {
    error: false,
    data: initialData,
    loading: false,
};

const reducer = (
    state: IConsentLog = initialState,
    action: IConsentLogAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case consentLogActionTypes.GET_CONSENT_LOGS_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = false;
                return draft;
            case consentLogActionTypes.GET_CONSENT_LOGS_LOADING:
                draft.error = true;
                draft.data = initialData;
                draft.loading = true;
                return draft;
            case consentLogActionTypes.GET_CONSENT_LOGS_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
