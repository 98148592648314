import { Grid, useTheme, useMediaQuery, Container, Divider } from '@mui/material';
import { ImageSummaryComponent } from 'pages/data-manager/components';
import { IImageSummaryComponent } from '../components/ImageSummary';
import { PlatformPrivacyComponent, ThirdPartyIntegrationsComponent } from 'pages/data-manager/components';
import mockIntegrationsData from 'assets/data/data-manager/data-integrations.json';
import { useEffect, useContext } from 'react';
import { ThemeContext } from 'util/themes';

export function DataManagerPrivacy() {
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const exportedDataProps: IImageSummaryComponent = {
        mode: 'PAGE',
        header: `Privacy of Your Data`,
        subHeader: 'Who has access to my data and activity in LifeGraph?',
        imageName: 'privacy-of-data-screen.svg',
        bgColor: selectedTheme?.palette?.background?.default || '#FFFFFF',
        summaryType: 'PRIVACY'
    };

    const flexDirection = isMobile ? 'row' : 'row-reverse';
    const mainGridMarginTop = isMobile ? 2 : 3

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    },[]);

    return (
        <>
            <ImageSummaryComponent { ...exportedDataProps } headerVariant={'h1'}/>
            <Divider variant='fullWidth' sx={{
                borderBottomWidth: 'thick',
                width: 'calc(100% + 4.5rem)',
                position: 'relative',
                left: '-3rem'}}
            />
            <Container maxWidth={'lg'}>
                <Grid container sx={{ display: 'flex', flexDirection: flexDirection, mt: mainGridMarginTop }}>
                    <Grid item xs={12} md={4}>
                        <PlatformPrivacyComponent 
                            selectedTheme={selectedTheme}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ThirdPartyIntegrationsComponent 
                            selectedTheme={selectedTheme}
                            datalist={mockIntegrationsData.data} 
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}