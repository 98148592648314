import React, { Children } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Box } from '@mui/material';

import { getDate } from '../../util/getDate';
import { ConfirmDialog } from '../../lib/components';
import { consentLogActionCreators } from 'redux/actionCreators';

const styles = {
    title: {
        pb: 2,
    },
    consentInfoHeader: { fontWeight: 'bold', color: '#9AA0A6' },
    titleHeader: { fontWeight: 'bold' },
    rightGrid: {
        width: 0.5,
        pt: 3,
        pr: 3,
        pb: 3,
    },
    leftGrid: {
        width: 0.5,
        border: 'solid',
        borderColor: 'divider',
        borderWidth: 1,
        backgroundColor: '#f7f8fa',
        p: 3,
        borderRadius: 1.1,
    },
};

const RevokeConsentLogModal = (props: {
    consentLog: any;
    revoke: boolean;
    setRevoke: any;
}) => {
    const dispatch = useDispatch();
    const { consentLog: result, revoke, setRevoke } = props;

    const revokePayload = (consentInfos: any) =>
        consentInfos.reduce(
            (obj: any, item: any) =>
                Object.assign(obj, { [item.assetType]: item.asset_id }),
            {}
        );

    return (
        <ConfirmDialog
            title="Revoke Access"
            open={revoke}
            setOpen={setRevoke}
            onConfirm={() => {
                dispatch(
                    consentLogActionCreators.revokeConsentLogs(
                        revokePayload(result.consentInfo)
                    )
                );
                dispatch(consentLogActionCreators.getConsentLogs());
            }}
            confirmText="Revoke"
            maxWidth="lg"
        >
            <Grid container>
                <Grid item xs={12} sx={styles.title}>
                    <Typography sx={styles.titleHeader}>
                        Are you sure you want to revoke this consent?
                    </Typography>
                </Grid>
                <Grid item sx={styles.rightGrid}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="overline"
                                sx={styles.consentInfoHeader}
                            >
                                RECIPIENT
                            </Typography>
                            <Typography variant="body2">
                                {result.sharedWith}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="overline"
                                sx={styles.consentInfoHeader}
                            >
                                EXPIRATION
                            </Typography>
                            <Typography variant="body2">
                                {getDate(result.expirationDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={styles.leftGrid}>
                    {Children.toArray(
                        result.consentInfo.map((constent: any) => (
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography
                                        variant="overline"
                                        sx={styles.consentInfoHeader}
                                    >
                                        {constent.assetType}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant="body2">
                                        {constent.attributes.join(', ')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        </ConfirmDialog>
    );
};

export default RevokeConsentLogModal;
