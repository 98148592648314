import { useState, useEffect, memo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { medicationSelector } from 'redux/selectors';
import { HealthDashboardCard } from "../health-dashboard-card/HealthDashboardCard";
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { MedicationForm } from './MedicationModal';

interface IMedicationCardComponent {
    cardData: {
        title: string;
        itemsToShow: any;
    }
}

const MedicationComponent = (props: IMedicationCardComponent) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [selectedData, setSelectedData] = useState({
        medicine: '',
        prescribedBy: '', 
        assetId: '',
        userId: ''
    });
    const { cardData } = props;
    let itemToShowArray = [...cardData.itemsToShow];
    const initialCardObj = {
        label: 'Prescribed By',
        value: '',
        subHeading: ''
    };
        
    useEffect(() => {
        dispatch(healthDashboardActionCreators.getHealthDashboardMedicationList());
    }, []);

    const data = useSelector(medicationSelector.selectMedicationData);
    const medLoading = useSelector(medicationSelector.selectMedicationLoading);

    if(data.length){
        data.forEach((item: any) => {
            itemToShowArray.push(item);
        })
    }

    const handleModalOpen = (id: string) => {
        const filteredData = data.filter((item: any) => {
            return item.assetId === id
        }) || [];
        if(filteredData.length){
            const { value, subHeading, assetId, userId } = filteredData[0];
            setSelectedData({
                'medicine': value,
                'prescribedBy': subHeading,
                assetId,
                userId
            });
        }
        setEdit(true);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const onAddIconClicked = () => {
        setSelectedData({
            medicine: '', 
            prescribedBy: '',
            assetId: '',
            userId: ''
        });
        setEdit(false);
        setModalOpen(true);   
    }
    
    return (
        <>
            <HealthDashboardCard 
                title={cardData.title}
                itemsToShow={itemToShowArray}
                isLoading={medLoading}
                initialCardObj={initialCardObj}
                canEdit={true}
                handleModalOpen={handleModalOpen}
                onAddIconClicked={onAddIconClicked}  
            />
            {modalOpen ? (
                <MedicationForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    selectedData={selectedData}
                    isEdit={isEdit}
                />
                ) : (
            <></>
            )}
        </>
        
    )
}

export const MedicationCardComponent = memo(MedicationComponent);
