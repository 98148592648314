import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IProfileEmailAddressesObject } from '../../../@types/profile-types';
import { sortArrayOfObjectByStringAttribute } from 'util/array.utils';
import { selectEmailLookupData, selectEmailLookupLoading } from 'redux/selectors/profileLookup/emailLookup.selector';

const emailAddressState = (state: RootState) => state.profile.profileEmailAddresses;

const selectEmailAddressState = createSelector([emailAddressState], (state) => {
    return state;
});

export const selectEmailAddressLoading = createSelector(selectEmailAddressState, selectEmailLookupLoading, (state, emailLoading) => {
    return (state.loading || emailLoading);
});

export const selectEmailAddressDataRaw = createSelector(selectEmailAddressState, (state) => {
    const emaiList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByStringAttribute(emaiList, 'type');
    for (const email of emaiList) {
        email.type = email.type.toLowerCase();
    }
    return emaiList;
});

export const selectEmailAddressData = createSelector(selectEmailAddressDataRaw, selectEmailLookupData, (emailListRaw, lookupData) => {
    const rawList = JSON.parse(JSON.stringify(emailListRaw));
    for (const email of rawList) {
        const findEmailType = lookupData.find((x) => x.value === email.type);
        email.type = findEmailType ? findEmailType.display : email.type;
    }
    return rawList as IProfileEmailAddressesObject[];
});

export const selectEmailAddressError = createSelector(selectEmailAddressState, (state) => {
    return state.error;
});
