import { OnboardingComponent, IOnboardingComponent } from 'components/Onboarding/Onboarding';

export function HomePathwayOnboarding() {
       const data: IOnboardingComponent = {
           imageName: 'pathway_onboarding_1.png',
           redirectLink: '/learning/pathway',
           header: `Let's Go on a Journey`,
           headerStyle: {
               lineHeight: '3.125rem',
               pt: '9.88rem'
            },
           subHeader: `Gain new skills, get a new certification, and more!`,
           subHeaderStyle: {
               lineHeight: '2.25rem',
               pb: '7.875rem'
            },
           bottomTextList: [
               `Pathways are a way to help you reach your goals and grow. They contain courses and activities you can complete on your own time to get you to the next level in your career, wherever that may be.`,
               `Currently there are a number of growth pathways which have been provided by your employer. These are designed to help match you to new opportunities in your organization.`,
           ],
           bottomTextStyle: { lineHeight: '28px', fontSize: '18px' },
           buttonText: `Explore Pathways`,
           onboardingScreen: 'pathway',
           background:
               'linear-gradient(85.68deg, #FBFED2 -11.56%, #FFC4DF 48.86%, #B1ADFF 100.97%), #D2E4E5',
           backgroundStyle: { width: '30rem', height: '32.31rem', top: '8rem' },
           headerContainerStyle: { px: '4.75rem'},
           bottomBoxContainer: { px: '4.75rem', mt: '3.5rem' }
       };
    return <OnboardingComponent { ...data } />;
}