export const items = [{
    'title': 'Licenses & Certifications',
    'itemsToShow': [
        {
        'label': 'Licensed Practical Nurse',
        'value': 'Iowa Board of Nursing',
        'status': 'Active',
        "verificationData": {
            "verificationStatus": "verified"
        }
    }, {
        'label': 'CPR Certification',
        'value': 'Local Training Center',
        "status" : 'Expires soon',
        'subHeading': 'Philedelphia, PA',
        "verificationData": {
            "verificationStatus": "verified"
        }
    }],
}];

const styles = {
    descStyle : {
        width: '302px',
        mt: 2,
        fontSize: '20px',
        lineHeight: '26px',
        color: '#47515A'
    },
    btnStyle: {
        mt: 4,
        mb: 0
    }
};

export const apps : any[] = [{
    heading: 'CONNECTED APP ',
    subHeading: 'Lifetrek',
    description : 'Let’s go on a journey. Create the career of your dreams.',
    descriptionStyle: styles.descStyle,
    imageName: 'lifetrek.svg',
    buttonText: 'Continue',
    isLinkButton : true,
    goToLink : '/learning/pathway',
    buttonStyle: styles.btnStyle
},{
    heading: 'Lifegraph App',
    subHeading: 'LearnIQ',
    description : 'Learn Business, Creative & Tech Skills With Online Video Tutorials.',
    descriptionStyle: styles.descStyle,
    imageName: 'learnIQ.svg',
    buttonText: 'Continue',
    buttonStyle: styles.btnStyle
}]
