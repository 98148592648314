import React from 'react';
import { Typography } from '@mui/material';

export const CommonDateElement = (props: any) => {
    const { selectedTheme, startDate, endDate } = props
    return (
        <Typography
            sx={{
                fontSize: '12px',
                ...selectedTheme?.typography?.captions?.default,
                ...selectedTheme?.typography?.captions?.bold,
                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
            }}
            key={'start_end_date'}
        >
            {`${startDate} - ${endDate}`}
        </Typography>
    )
};
