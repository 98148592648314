import { healthPathwayActionType } from 'redux/actionsTypes';
import { produce } from 'immer';
import { IPathway, IPathwayArticle } from '../../../@types/pathway-types';

export interface IPathwayState {
    error: boolean;
    data: IPathway[];
    loading: boolean;
    expandedActivity: IPathway;
    expandedActivityLoading: boolean;
    articleDetail: IPathwayArticle;
    articleDetailLoading: boolean;
}

interface IPathwayAction {
    type: string;
    payload: IPathway[];
}

const initialData: IPathwayState = {
    error: false,
    loading: true,
    data: [
        {
            assetId: '',
            data: [],
            metaData: {
                id: '',
                goal: '',
                imageName: '',
                theme: '',
                title: '',
            },
        },
    ],
    expandedActivity: {
        assetId: '',
        data: [],
        metaData: {
            id: '',
            goal: '',
            imageName: '',
            theme: '',
            title: '',
        },
    },
    expandedActivityLoading: false,
    articleDetail: {
        id: '',
        title: '',
        label: '',
        type: '',
        projectCompletionTime: 0,
        dependencies: '',
        course_sis_id: '',
        skill: 0,
        skills: '',
        articleContent: '',
        completed: false
    },
    articleDetailLoading: false,
};

const reducer = (
    state: IPathwayState = initialData,
    action: IPathwayAction
) => {
    return produce(state, () => {
        switch (action.type) {
            case healthPathwayActionType.GET_HEALTH_PATHWAY_ERROR:
                return { ...state, error: true, loading: false };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_LOADING:
                return { ...state, loading: true };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_SUCCESS:
                return { ...state, data: action.payload, loading: false };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_ERROR:
                return { ...state, error: true, expandedActivityLoading: false };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_LOADING:
                return { ...state, expandedActivityLoading: true };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_SUCCESS:
                return { ...state, expandedActivity: action.payload, expandedActivityLoading: false };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR:
                return { ...state, error: true, articleDetailLoading: false };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING:
                return { ...state, articleDetailLoading: true };
            case healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS:
                return { ...state, articleDetail: action.payload, articleDetailLoading: false };
            case healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR:
                return { ...state, error: true, articleDetailLoading: false };
            case healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING:
                return { ...state, articleDetailLoading: true };
            case healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS:
                return { ...state, articleDetail: action.payload, articleDetailLoading: false };
        }
        return state;
    });
};

export default reducer;