import { useEffect, useContext } from 'react';
import { OnboardingLayoutComponent } from 'pages/onboarding';
import { Typography, Box, Grid, useMediaQuery } from '@mui/material';
import { OnboardingScreen2Helper } from './onboardingScreen2.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { citizenshipConfig, taxConfig } from 'pages/profile/about/about.helper';
import {
    citizenshipSelector,
    taxSelector,
    personalInfoSelector,
} from 'redux/selectors';
import { AboutForm } from 'pages/profile/about/aboutForm';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from 'util/themes';

const styles = {
    gradientMaxHeight: {
        xs: '40vh',
        md: '100%',
    },
    sectionHolder: {
        m: 'auto',
        width: {
            xs: '95%',
            md: '70%',
        },
    },
    header: {
        mt: '30%',
        fontSize: '40px',
    },
    subHeader: {
        fontSize: '20px',
    },
};

export const OnboardingScreen2Component = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);

    const nextClicked = () => {
        history.push('/onboarding/3');
    };

    const typographyList: string[] = [OnboardingScreen2Helper.subHeader];

    const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
    if (isDesktopView) {
        typographyList.unshift(OnboardingScreen2Helper.header);
    }

    useEffect(() => {
        setCurrentTheme('lifeGraph');
        dispatch(profileActionCreators.getProfilePersonalInfo());
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(citizenshipConfig)
        );
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(taxConfig));
    }, []);

    const isLoading = useSelector(
        personalInfoSelector.selectPersonalInfoLoading
    );
    const citizenshipLoading = useSelector(
        citizenshipSelector.selectCitizenshipLoading
    );
    const taxLoading = useSelector(taxSelector.selectTaxLoading);
    const combinedLoading = isLoading || citizenshipLoading || taxLoading;

    return (
        <Grid container>
            <Grid item xs={12} md={6} maxHeight={styles.gradientMaxHeight}>
                <OnboardingLayoutComponent
                    imageFileName={'onboarding-no-top-hexes'}
                    typographyList={typographyList}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={styles.sectionHolder}>
                    <Typography
                        variant='h1'
                        marginBottom={{ xs: '20px', md: '30px' }}
                        color={selectedTheme.palette.primary[700]}
                        marginTop={{ xs: '60px', md: '100px' }}
                    >
                        {OnboardingScreen2Helper.title}
                    </Typography>

                    {!combinedLoading ? (
                        <>
                            <AboutForm
                                handleModalClose={nextClicked}
                                modalOpen={true}
                                type={'ONBOARDING'}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
