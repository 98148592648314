import { Children, useEffect, useState } from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    ProfileInfoRowVaraint2Component,
    CardTitleComponent,
    NoRecordsComponent,
    ChipsComponent,
} from 'components';
import {
    IReduxDataConfig,
    IProfilePathway,
} from '../../../@types/profile-types';
import { pathwayProfileSelector } from 'redux/selectors';
import { pathwayProfileActionType } from 'redux/actionsTypes';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { getColorByStatus } from 'util/color.utils';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
    subTitle: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 'bold',
        mb: 1,
    },
};

interface IPathwayProfileComponent {
    title: string;
    canEdit: boolean;
    type: 'PAGE' | 'PREVIEW';
}

const convertObjectToProfileRowVariant = (object: IProfilePathway) => {
    const header = object.title;
    const subHeader = '';
    const bodyList: (string | JSX.Element)[] = [];
    const color = getColorByStatus(object.status);
    bodyList.push(
        <Typography
            fontSize={14}
            color={color}
            fontWeight={'bold'}
            key={'prof_path_status'}
        >
            {object.status}
        </Typography>
    );
    return { header, subHeader, bodyList };
};

export const PathwayProfileComponent = (props: IPathwayProfileComponent) => {
    const { title, canEdit } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const loading = useSelector(pathwayProfileSelector.selectPathwayLoading);
    const pathwayProfileData = useSelector(
        pathwayProfileSelector.selectPathwayData
    );

    const pathwayConfig: IReduxDataConfig = {
        loadingAction: pathwayProfileActionType.PATHWAY_PROFILE_LOADING,
        successAction: pathwayProfileActionType.PATHWAY_PROFILE_SUCCESS,
        errorAction: pathwayProfileActionType.PATHWAY_PROFILE_ERROR,
        endpoint: '/v2/pathway',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getProfilePathwayCardsInfo(pathwayConfig)
        );
    }, []);

    return (
        <Box>
            <Grid item xs sx={selectedTheme?.card?.gridWrapper}>
                <Paper sx={selectedTheme?.card?.default}>
                    <CardTitleComponent
                        title={title}
                        canAdd={'HIDDEN'}
                        onAddIconClicked={() => ''}
                    />
                    <Divider />
                    <Grid container sx={selectedTheme?.card?.cardPadding}>
                        {pathwayProfileData.length === 0 ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            pathwayProfileData.map(
                                (x: IProfilePathway, index: number) => {
                                    const row =
                                        convertObjectToProfileRowVariant(x);
                                    return (
                                        <Box className="flex-column full-width">
                                            <ProfileInfoRowVaraint2Component
                                                isLoading={loading}
                                                header={row.header}
                                                subHeader={row.subHeader}
                                                bodyDataList={row.bodyList}
                                                objectId={x.asset_id}
                                                onEditClicked={() => ''}
                                                canEdit={'HIDDEN'}
                                                verificationData={convertProfileObjectToMaryVilleVerification(
                                                    x
                                                )}
                                            />
                                            {loading ? (
                                                <></>
                                            ) : (
                                                <Grid container>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            sx={styles.subTitle}
                                                        >
                                                            Skills :&nbsp;
                                                        </Typography>
                                                        <ChipsComponent
                                                            values={x.skills}
                                                            sx={{
                                                                color: selectedTheme?.palette?.purple?.light,
                                                                borderColor: selectedTheme?.palette?.purple?.light
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {index !==
                                            pathwayProfileData.length - 1 ? (
                                                <Divider sx={{ my: 3 }} />
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                    );
                                }
                            )
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Box>
    );
};

PathwayProfileComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
