const fontColor = '#202124';

export const styles = {
    header: {
        fontSize: { xs: '32px', md: '40px' },
        color: fontColor,
    },
    subHeader: {
        fontSize: { xs: '20px', md: '24px' },
        color: fontColor,
        pt: 4
    },
    topBox: {
        maxHeight: { xs: '33%', md: '50%' },
    },
    bottomBox: {
        mt: { xs: 12.5, sm: 25, md: 4 },
    },
    bottomText: {
        fontSize: '20px',
        color: fontColor,
    },
    sectionContainer: {
        px: '0 !important'
    },
    sectionStyle: {
        position: 'relative',
        height: '2px',
    },
    extraSpaceAtBottom: {
        mb: { xs: 6.25, md: 0 },
    },
};
