import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Loader } from 'lib/components/Loader/BarSkeleton';
import { VerificationStatusComponent } from 'components';
import { ILifeGraphVerification } from '../../../@types/profile-types';
import { Edit as EditIcon } from '@mui/icons-material';

const styles = {
    label: {
        textTransform: 'uppercase' as const,
        letterSpacing: '1px',
        fontWeight: 'bold',
        fontSize: '10px',
        color: '#9AA0A6',
    },
    value: {
        color: '#202124',
        fontSize: '1rem',
        lineHeight: 1.5,
    },
    container: {
        mt:0.25,
        mb: 1,
    },
};

interface IHealthDashboardCardInfoRowComponent {
    label: string;
    value: string | ReactElement;
    Icon?:any;
    subHeading? : string;
    isLoading: boolean;
    classNames: string;
    verificationData: ILifeGraphVerification;
    verificationHolderSize: number;
    assetId: string;
    assetType : string;
    canEdit: 'ACTIVE' | 'DISABLED' | 'HIDDEN' | false;
    onEditClicked: (id: string) => void;
    selectedTheme?: any;
    containerStyle?:any;
    secondRow?:any;
    verificationDetail?: any;
    hideVerification?: any;
    hasLabelTopPadding: boolean;
}

interface IEditIconProps {
    canEdit: string | boolean;
    isLoading: boolean;
    onClick: () => void;
    label: string;
    selectedTheme: any;
}

const EditIconComponent = ({canEdit, isLoading, onClick, label, selectedTheme}: IEditIconProps) => {
    if(canEdit === 'HIDDEN' || !canEdit){
        return <></>
    }

    if(isLoading) {
        return <Loader width={30} />
    } else {
        return (<EditIcon
            onClick={onClick}
            data-testid={`${label}_editIcon`}
            className="cursor-pointer"
            style={{ color: selectedTheme?.palette?.primary[500] }}
        />)
    }
};

export const HealthDashboardCardInfoRowComponent = (props: IHealthDashboardCardInfoRowComponent) => {
    const {
        label,
        value,
        Icon,
        isLoading,
        assetId,
        assetType,
        canEdit = "ACTIVE",
        hasLabelTopPadding = true,
        onEditClicked,
        verificationData,
        selectedTheme,
        subHeading,
        containerStyle={},
        secondRow,
        verificationDetail,
        hideVerification=false
    } = props;

    const onClick = () => {
        if (canEdit === 'ACTIVE') {
            onEditClicked(assetId);
        }
    };

    return (
        <Grid container direction="row" sx={{...containerStyle}}>
            <Grid direction={'row'} container>
                <Grid item maxWidth={24} width={24} height={24} mr={2} alignItems='center'>
                    {verificationData && !hideVerification  ? (
                        <VerificationStatusComponent
                            data={verificationData}
                            loading={isLoading}
                            selectedTheme={selectedTheme}
                            verificationDetail= {{key : assetType, data : {...props, cardTitle : verificationDetail?.cardTitle}}}
                        />
                    ) : ('')}
                    {Icon ? <Icon  sx={{ color: selectedTheme?.palette?.primary[500]}}/> : ''}
                </Grid>
                <Grid item container sx={{width: 'calc(100% - 40px)'}}>
                    <Grid item xs={10} sx={styles.container}>
                        {isLoading ? <Loader/> : <Typography sx={{
                            ...selectedTheme?.typography?.subtitles?.default,
                            ...selectedTheme?.typography?.subtitles?.subtitles1,
                            ...selectedTheme?.typography?.subtitles?.semibold,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                        }}>
                            {value}
                        </Typography>}
                        <Typography sx={{
                            ...selectedTheme?.typography?.overlines?.overline2.default,
                            ...selectedTheme?.typography?.overlines?.overline2.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600],
                            pt: hasLabelTopPadding && 1.5
                        }}>
                            {label}
                        </Typography>
                        {subHeading ? (<Typography sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.tpyography?.body?.body1,
                            ...selectedTheme?.tpyography?.body?.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}>
                            {subHeading}
                        </Typography>) : ''}
                    </Grid>
                    <Grid item xs={2} display={'flex'} justifyContent='flex-end'>
                        <EditIconComponent
                            canEdit={canEdit}
                            isLoading={isLoading}
                            label={label}
                            onClick={onClick}
                            selectedTheme={selectedTheme}
                        />
                    </Grid>
                </Grid>
            </Grid>
            { secondRow && 
                <Grid container direction={'row'}>
                    <Grid item xs={11} sx={{...styles.container}} ml={5.05}>
                        <Typography sx={{
                            ...selectedTheme?.typography?.overlines?.overline2.default,
                            ...selectedTheme?.typography?.overlines?.overline2.regular,
                            textTransform: 'uppercase',
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600],
                        }}>
                            {secondRow.label}
                        </Typography>
                        {isLoading ? <Loader/> : <Typography sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.tpyography?.body?.body1,
                            ...selectedTheme?.tpyography?.body?.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}>
                            {secondRow.value}
                        </Typography>}
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default HealthDashboardCardInfoRowComponent;

HealthDashboardCardInfoRowComponent.defaultProps = {
    assetId: '',
    label: '',
    value: '',
    isLoading: false,
    verificationHolderSize: 1,
    classNames: '',
    canEdit: 'HIDDEN',
    verificationData: null,
    onEditClicked: () => {},
};
