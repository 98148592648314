import {
    Paper,
    Grid,
    Typography,
    Box,
    Container,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Button } from 'lib/controls';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { PathwayPie } from '..';
import {
    IPathwayMetadata,
    IPathwayInstanceData,
} from '../../../@types/pathway-types';
import { styles } from './pathwaySummary.helper';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { PathwayActivityHolder } from 'components/Pathway';
import { useDispatch } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';


interface IPathwaySummary {
    assetId: string;
    metadata: IPathwayMetadata;
    instancedata?: IPathwayInstanceData;
    mode: string;
    completedMilestones?: string;
    pieTextColor?: string;
    applicationModule: string;
}

const getTitle = (metaData: IPathwayMetadata, selectedTheme: any) => {
    return (
        <Typography sx={{...styles.title, ...selectedTheme?.typography?.h1, color: selectedTheme?.palette?.secondary?.grayScale?.main}}>
            {metaData?.title}
        </Typography>
    );
};

const getPathwayPie = (
    metaData: IPathwayMetadata,
    instanceData: IPathwayInstanceData | undefined,
    selectedTheme: any,
    pieTextColor: string | undefined,
    isHealthMode: boolean
) => {
    return (
        <PathwayPie
            isHealthMode={isHealthMode}
            percentComplete={instanceData?.percentComplete || 0}
            projectCompletionTime={metaData?.projectCompletionTime}
            sx={{
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body3,
                ...selectedTheme?.typography?.body?.bold,
                color: pieTextColor || selectedTheme?.palette?.secondary?.grayScale?.main
            }}
        />
    );
};

const getViewDetailsButton = (mode: string, viewDetailsClicked: Function, selectedTheme: any) => {
    if (mode === 'summary') {
        return (
            <Button
                variant="contained"
                color='primary'
                size="medium"
                onClick={() => viewDetailsClicked()}
                sx={{ borderRadius: '4px', py: '.25rem' }}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.medium,
                    textTransform: 'none'
                }}>
                    View Details
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const getAllPathwaysLink = (mode: string, goBackClick: Function, selectedTheme: any) => {
    if (mode === 'heading') {
        return (
            <Button
                variant='text'
                size='small'
                onClick={() => goBackClick()}
                sx={styles.button}
                color='secondary'
                startIcon={<ArrowBackIcon  sx={{color: selectedTheme?.palette.purple?.light}}/>}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body2,
                    ...selectedTheme?.typography?.body?.bold,
                    letterSpacing: '0.25px',
                    color: selectedTheme?.palette.purple?.light
                }}>
                    All Pathways
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const getImageBox = (metaData: IPathwayMetadata, isHealthMode: boolean) => {
    return (
        <Box
            component="img"
            alt="Pathway Image"
            src={`/images/graphics/${metaData?.imageName}`}
            sx={!isHealthMode ? styles.pathwayImage : styles.healthPathwayImage}
        />
    );
};

const getStartNowButton = (
    mode: string,
    instanceData: IPathwayInstanceData | undefined,
    startNowClicked: Function,
    selectedTheme: any
) => {
    if (!instanceData && mode === 'heading') {
        return (
            <Button
                variant="contained"
                size="medium"
                sx={{ 
                    ...styles.cta, 
                    mb: 1,
                    backgroundColor: selectedTheme?.palette.purple?.light,
                    py: '0.25rem',
                    borderRadius: '4px'
                }}
                onClick={() => startNowClicked()}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.medium,
                    textTransform: 'none'
                }}>
                    Start This Pathway
                </Typography>
            </Button>
        );
    }
    return <></>;
};

export function PathwaySummary(props: IPathwaySummary) {
    const { assetId, metadata, instancedata, mode, completedMilestones, pieTextColor, applicationModule } = props;
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const startNowClicked = async () => {
        const pathwayId = metadata.id;
        dispatch(pathwayActionCreators.addOtherPathwayToUserPathway(pathwayId));
    };

    const goBackClicked = () => {
        if (applicationModule === 'health') {
            history.push(`/health/pathway`);
        } else {
            history.push(`/learning/pathway`);
        }
    };

    const viewDetailsClicked = () => {
        if (applicationModule === 'health') {
            history.push(`/health/pathway/${metadata?.id}`);
        } else {
            history.push(`/learning/pathway/${metadata?.id}`);
        }
    };

    const viewPathwayDetail = () => {
        setShowDetails(!showDetails);
    };

    const getBgStyle = () => {
        let cardBase = selectedTheme?.card?.base;
        let modeStyle = {borderRadius: '0px'};
        let bgStyle = {};

        if (!instancedata || !instancedata?.status) {
            bgStyle = styles.gray;
        } else if (instancedata?.status === 'completed') {
            bgStyle = styles.green;
        } else if(applicationModule === 'health') {
            bgStyle = styles.white;
        } else {
            bgStyle = styles.blue;
        }

        return { ...cardBase, ...modeStyle, ...bgStyle };
    };

    const getHeaderStyle = () => {
        if (applicationModule === 'health') {
            return { paddingLeft: 3, paddingRight: '0 !important' }
        } else if(mode !== 'summary') {
            return { px: '6.75rem !important' }
        } else {
            return {
                px: 5
            }
        }

    }

    if (isMobile) {
        const pieWidth = mode === 'summary' ? 6 : 5;
        const imageHolderWidth = mode === 'summary' ? 8 : 7;
        const imageMaxWidth = mode === 'summary' ? '312px' : '230px';
        const viewDetailsButtonWidth = mode === 'summary' ? 6 : 0;
        const bottomCardDisplay = mode === 'summary' ? 'none' : 'flex';
        return (
            <>
                <Paper sx={{ ...getBgStyle() }}>
                    <Box sx={{ px: 2 }}>
                        {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                        {getTitle(metadata, selectedTheme)}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        {getStartNowButton(mode, instancedata, startNowClicked, selectedTheme)}
                    </Box>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            textAlign: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <Grid item xs={pieWidth}>
                            {getPathwayPie(metadata, instancedata, selectedTheme, pieTextColor, applicationModule === 'health')}
                        </Grid>
                        <Grid item xs={viewDetailsButtonWidth}>
                            {getViewDetailsButton(mode, viewDetailsClicked, selectedTheme)}
                        </Grid>
                        <Grid
                            item
                            xs={imageHolderWidth}
                            width={imageMaxWidth}
                            margin={'auto'}
                            mb={-1}
                        >
                            {getImageBox(metadata, applicationModule === 'health')}
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            border: '1px solid #DADCE0',
                            height: '44px',
                            display: bottomCardDisplay,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            sx={styles.button}
                            variant={'text'}
                            size={'small'}
                            color='secondary'
                            onClick={() => viewPathwayDetail()}
                        >
                            {showDetails ? 'Hide ' : 'View '} Pathway Details
                        </Button>
                    </Box>
                </Paper>
                {!showDetails ? (
                    <></>
                ) : (
                    <PathwayActivityHolder
                        instanceData={instancedata}
                        metaData={metadata}
                        isHealthMode={false}
                    />
                )}
            </>
        );
    }
    return (
        <Paper sx={{ ...getBgStyle(), marginBottom: 3 }}>
            <Container maxWidth={false} sx={getHeaderStyle()}>
                {applicationModule !== 'health' ?
                    <>
                        {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                        <Grid container sx={{ position: 'relative', py: 3 }}>
                            <Grid item md={2} sx={{ ...styles.pie }}>
                                {getPathwayPie(metadata, instancedata, selectedTheme, pieTextColor, applicationModule === 'health')}
                            </Grid>
                            <Grid item md={5} sx={{ pl: 4 }}>
                                {getTitle(metadata, selectedTheme)}
                                <Typography sx={{
                                    ...styles.goal,
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body1,
                                    letterSpacing: '0.15px',
                                    color: selectedTheme?.palette?.secondary?.grayScale?.main
                                }}>
                                    Goal: {metadata?.goal}
                                </Typography>
                                {getViewDetailsButton(mode, viewDetailsClicked, selectedTheme)}
                                {getStartNowButton(mode, instancedata, startNowClicked, selectedTheme)}
                            </Grid>
                            <br />
                            <Grid
                                item
                                md={4}
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                {getImageBox(metadata, false)}
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid sx={{ display: 'flex' }}>
                        <Grid sx={{
                            paddingTop: 3,
                            width: {
                                md: '60%',
                                lg: '70%',
                                xl: '70%'
                            }, }}>
                            {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                            <Grid container sx={{ position: 'relative', py: 3 }}>
                                <Grid item md={3} sx={{ ...styles.pie }}>
                                    {getPathwayPie(metadata, instancedata, selectedTheme, pieTextColor, applicationModule === 'health')}
                                </Grid>
                                <Grid item md={9} sx={{ pl: { xl: 0, lg: 0, md: 4 } }}>
                                    {getTitle(metadata, selectedTheme)}
                                    <Typography sx={{
                                        ...styles.goal,
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        letterSpacing: '0.15px',
                                        color: selectedTheme?.palette?.secondary?.grayScale?.main
                                    }}>
                                        Goal: {metadata?.goal}
                                    </Typography>
                                    {getViewDetailsButton(mode, viewDetailsClicked, selectedTheme)}
                                    {getStartNowButton(mode, instancedata, startNowClicked, selectedTheme)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: {
                                md: '40%',
                                lg: '30%',
                                xl: '30%'
                            },
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url("/images/graphics/${metadata?.imageName}")`,
                            backgroundSize: 'cover'
                        }} />
                    </Grid>
                }

            </Container>
        </Paper>
    );
}
