import moment from 'moment';
import { IverificationStatus } from '../../../../../@types/profile-types';
import DynamicCardTemplate from '../index';
import { vaccinationSelector } from 'redux/selectors';
import { vaccinationActionType } from 'redux/actionsTypes';
import { useState } from 'react';

import AddEditVaccinationsForm, {
    formDefaults,
} from './addEditFormVaccinations';

export interface IProfileVaccinationObject {
    assetType: string;
    asset_id: string;
    dateReceived?: { $date?: string };
    status: string;
    userId: string;
    vaccine: string;
    vaccineId: string;
    verificationCompany: string;
    verificationType: string;
    verificationStatus: IverificationStatus;
}

export interface IProfileVaccinationDetail {
    label: string;
    getter: Function;
}

const ProfileVaccinationDetails: IProfileVaccinationDetail[] = [
    {
        label: 'Date',
        getter: (data: IProfileVaccinationObject) =>
            moment(data.dateReceived?.$date).format('YYYY-MM-DD'),
    },
];

export const initialProfileVaccination: IProfileVaccinationObject[] = [];

const PROFILE_PHONE_NO_ENDPOINT = '/profile/vaccination_info';

const constructTitle = (data: { vaccine: string }) => data.vaccine;

const reduxConfig = {
    loadingSelector: vaccinationSelector.selectVaccinationLoading,
    dataSelector: vaccinationSelector.selectVaccinationData,
    errorSelector: vaccinationSelector.selectVaccinationError,
    loadingAction: vaccinationActionType.PROFILE_VACCINATIONS_LOADING,
    successAction: vaccinationActionType.PROFILE_VACCINATIONS_SUCCESS,
    errorAction: vaccinationActionType.PROFILE_VACCINATIONS_ERROR,
};

const Vaccination = (props: { canEdit?: boolean; canAdd?: boolean }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(formDefaults);
    const { canEdit, canAdd } = props;
    const handleModalClose = () => {
        setModalEditData(formDefaults);
        setModalOpen(false);
    };

    const handleModalOpen = () => {
        setModalEditData(formDefaults);
        setModalOpen(true);
    };
    return (
        <>
            <DynamicCardTemplate
                title="Vaccinations"
                constructTitle={constructTitle}
                endpoint={PROFILE_PHONE_NO_ENDPOINT}
                detailsSectionData={ProfileVaccinationDetails}
                reduxConfig={reduxConfig}
                handleModalOpen={handleModalOpen}
                setModalEditData={setModalEditData}
                canEdit={canEdit}
                canAdd={canAdd}
            />
            {canEdit && (
                <AddEditVaccinationsForm
                    modalOpen={modalOpen}
                    reduxConfig={reduxConfig}
                    endpoint={PROFILE_PHONE_NO_ENDPOINT}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            )}
        </>
    );
};

Vaccination.defaultProps = {
    canEdit: true,
    canAdd: true,
};
export default Vaccination;
