import {
    OnboardingComponent,
    IOnboardingComponent,
} from 'components/Onboarding/Onboarding';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';

export function DataManagerOnboarding() {
    const { selectedTheme } = useContext(ThemeContext);

    const data: IOnboardingComponent = {
        imageName: 'data-manager-intro-banner.svg',
        redirectLink: '/data-manager',
        overline: 'Data Manager',
        overlineStyle: {
            ...selectedTheme?.typography?.overlines?.overline1?.default,
            ...selectedTheme?.typography?.overlines?.overline1?.semibold,
            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
        },
        header: `Take control of your data`,
        headerStyle: {
            ...selectedTheme?.typography?.banner?.banner1,
            color: selectedTheme?.palette?.primary[700]
        },
        subHeader: `Because you should be in the driver’s seat`,
        subHeaderStyle: {
            pb: 12,
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body3,
            ...selectedTheme?.typography?.body?.regular,
            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
        },
        bottomTextList: [
            `LifeGraph enables data dignity by giving you the power to choose what data to share, who to share it with, and for how long. This control gives you the transparency and trust you need to put your data to work.`,
            `If you’d like to learn more about the genius technology, check out the full details here.`,
        ],
        bottomTextStyle: {
            pb: 0,
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body3,
            ...selectedTheme?.typography?.body?.regular,
            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
        },
        buttonText: `Manage Your Data`,
        onboardingScreen: 'dataManager',
        background: '#FFFFFF',
        backgroundStyle: {width: '100%', top: '0', height: '100%'},
        isSectionVisible: true,
        imageWrapperClass: 'data-manager-img-wrapper',
        generalOnboardingContainerWrapper: 'data-manager-general-onboarding-container-wrapper',
        headerContainerStyle: { pl: '3rem' },
        bottomBoxContainer: { px: '3rem', mt: '3.5rem' }
    };
    return <OnboardingComponent {...data} />;
}
