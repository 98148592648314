import { useMediaQuery } from '@mui/material';
import { IImageSummaryComponent } from './ImageSummary';
import { ITableComponent } from 'components/Table/Table';
import { IExportedData } from '../../../@types/data-manager-types';
import { DataSummary } from './DataSummary';

interface IExportedDataSummary {
    dataList: IExportedData[];
    loading: boolean;
    selectedTheme?: any;
}

export function ExportedDataSummary(props:IExportedDataSummary) {
    const { selectedTheme } = props;
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const { loading, dataList } = props;

    const exportedDataProps: IImageSummaryComponent = {
        mode: 'SUMMARY',
        header: `Data I've Exported`,
        subHeader: 'This is data you have exported from LifeGraph in a sharable format. To see the exact data that you shared, download the export file.',
        imageName: 'data-manager-dashboard-data-ive-exported.svg',
        bgColor: selectedTheme?.palette?.background?.default || '#FFFFFF',
        summaryType: 'EXPORTED',
        subHeaderWidth: 'inherit'
    };

    const exportedDataTable: ITableComponent = {
        loading: loading,
        data : dataList,
        columns: [{
            id: 'recipient',
            dataType: 'string',
            label: 'Recipient',
            hasSorting: true,
            valueStyleOverride: { fontWeight: 500 },
            onClick: () => {},
            width: '60%',
        }, {
            id: 'created',
            dataType: 'date',
            label: 'Created',
            hasSorting: true,
            valueStyleOverride: { },
            onClick: () => {},
            width: '40%',
        }]
    };

    return (
        <DataSummary
            selectedTheme={selectedTheme}
            dataList={dataList}
            loading={loading}
            dataProps={exportedDataProps}
            dataTable={exportedDataTable}
            url="/data-manager/exported"
            isMobile={isMobile}
            isShared={false}
        />
    );
}