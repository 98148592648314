import { IReduxDataConfig } from '../../../@types/profile-types';
import { citizenshipActionType, taxActionType } from 'redux/actionsTypes';

export const citizenshipConfig: IReduxDataConfig = {
    loadingAction: citizenshipActionType.PROFILE_CITIZENSHIP_LOADING,
    successAction: citizenshipActionType.PROFILE_CITIZENSHIP_SUCCESS,
    errorAction: citizenshipActionType.PROFILE_CITIZENSHIP_ERROR,
    endpoint: '/v2/citizenship'
};

export const taxConfig: IReduxDataConfig = {
    loadingAction: taxActionType.PROFILE_TAX_LOADING,
    successAction: taxActionType.PROFILE_TAX_SUCCESS,
    errorAction: taxActionType.PROFILE_TAX_ERROR,
    endpoint: '/v2/taxinfo'
};