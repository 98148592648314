import { Typography, Box, Grid, Container, useMediaQuery, useTheme} from '@mui/material';

export interface ISkillsDashboardHeaderComponent {
    imagePath: string;
    header: string;
    subHeader: string;
}

const styles = {
    mainBox: {
        boxSizing: 'border-box',
        background: 'linear-gradient(85.68deg, #FBFED2 -11.56%, #FFC4DF 48.86%, #B1ADFF 100.97%), #D2E4E5',
        boxShadow: '0 1px 2px 0 #E3E8EE',
    },
    header: {
        color: '#202124',
        fontSize: '32px',
        lineHeight: '40px'
    },
    subHeader: {
        color: '#202124',
        fontSize: { xs: '20px', md: '16px' },
        fontWeight: '500',
        lineHeight: '30px',
        mt: 2
    }
}

export function SkillsDashboardHeaderComponent (props: ISkillsDashboardHeaderComponent) {
    const { imagePath, header, subHeader } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const height = isMobile ? '228px' : '240px';

    return (
        <Box sx={{ ...styles.mainBox, height: height }}>
            <Container maxWidth={false} disableGutters={false}>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12} md={8} sx={{ mt: { xs: 4, md: 6 }}}>
                        <Typography sx={{...styles.header, pl: 10.5}}>{ header }</Typography>
                        <Typography sx={{...styles.subHeader, fontStyle: 'italic', pb: 14, px: 10.5}}>{ subHeader }</Typography>
                    </Grid>
                    <Grid item md={4} sx={{ display: { xs : 'none', md: 'contents'}}}>
                        <img src={imagePath} height={'275px'} width={'275px'} style={{ marginTop: '-35px' }}></img>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )    
}