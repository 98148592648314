import React, { Children } from 'react';
import {
    ListItem,
    ListItemText,
    Grid,
    Toolbar,
    IconButton,
    AppBar,
    Divider,
    Drawer,
    List,
    Box,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { getNavLinks, getOtherNavLinks } from '../../util/navLinks';
import { ProfileAvatarComponent } from 'components';
import { NotificationsComponent } from './Notifications';

const drawerWidth = 240;
const appBarHeight = '48px';

const styles = {
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: 'white',
        zIndex: 2,
        height: appBarHeight,
    },
    appBarGridItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    appBarRightGridItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
    },
    drawer: {
        width: {
            sm: drawerWidth, // theme.breakpoints.up('sm')
        },
        flexShrink: {
            sm: 0, // theme.breakpoints.up('sm')
        },
    },
    link: {
        textDecoration: 'none' as const,
    },
    linkText: {
        textDecoration: 'none' as const,
        color: 'black',
    },
};

interface IMobileDrawerProps {
    appName: string;
}

function MobileDrawer(props: IMobileDrawerProps) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box sx={{ marginTop: appBarHeight }}>
            <List>
                {Children.toArray(
                    getNavLinks().map((navLink) => (
                        <NavLink to={navLink.path} style={styles.link}>
                            <ListItem button>
                                <ListItemText
                                    onClick={handleDrawerToggle}
                                    primary={navLink.title}
                                    sx={styles.linkText}
                                />
                            </ListItem>
                        </NavLink>
                    ))
                )}
            </List>
            <Divider />
            <List>
                {Children.toArray(
                    getOtherNavLinks().map((navLink) => (
                        <NavLink to={navLink.path} style={styles.link}>
                            <ListItem button>
                                <ListItemText
                                    primary={navLink.title}
                                    sx={styles.linkText}
                                />
                            </ListItem>
                        </NavLink>
                    ))
                )}
            </List>
        </Box>
    );

    return (
        <Box sx={styles.root}>
            <AppBar position="fixed" sx={styles.appBar}>
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item sx={styles.appBarGridItem}>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon style={{ color: '#9AA0A6' }} />
                            </IconButton>
                        </Grid>
                        <Box sx={styles.appBarRightGridItem}>
                            <NotificationsComponent />
                            <ProfileAvatarComponent size="default" />
                        </Box>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={styles.drawer}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    anchor={'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default MobileDrawer;
