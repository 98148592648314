import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IMilestone } from '../../../@types/pathway-types';

const healthPathwayState = (state: RootState) => state.healthPathway;

const selectHealthPathwayState = createSelector([healthPathwayState], (state) => {
    return state;
});

export const selectHealthPathwayLoading = createSelector(selectHealthPathwayState, (state) => {
    return state.loading;
});

export const selectHealthPathwayCompleteData = createSelector(selectHealthPathwayState, (state) => {
    return state.data;
});

export const selectHealthPathwayError = createSelector(selectHealthPathwayState, (state) => {
    return state.error;
});

export const selectUserHealthPathways = createSelector(selectHealthPathwayCompleteData, (pathwayList) => {
    return pathwayList.filter((x) => x.instanceData);
});

export const selectOtherHealthPathways = createSelector(selectHealthPathwayCompleteData, (pathwayList) => {
    return pathwayList.filter((x) => !x.instanceData);
});

export const selectHealthPathwayExpandedStep = createSelector(selectHealthPathwayState, (state) => {
    return state.expandedActivity;
});

export const selectedHealthPathwayLoading = createSelector(selectHealthPathwayState, (state) => {
    return state.expandedActivityLoading
});

export const selectTotalCompletedHealthMilestones = createSelector(
    selectedHealthPathwayLoading, selectHealthPathwayExpandedStep, (loading, selectedHealthPathway) => {
        if (loading) {
            return '';
        }
        if (!selectedHealthPathway?.data?.milestones?.length) {
            return '';
        }
        const total = selectedHealthPathway?.data?.milestones.length;
        const complete = selectedHealthPathway?.data?.milestones.filter((m: IMilestone) => m?.instanceData?.status === 'completed').length;
        return `${complete} of ${total} milestones`;
    });

export const selectedHealthPathwayArticleStep = createSelector(selectHealthPathwayState, (state) => {
    return state.articleDetail;
});

export const selectedHealthPathwayArticleLoading = createSelector(selectHealthPathwayState, (state) => {
    return state.articleDetailLoading
});