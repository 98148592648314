import { Paper, Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ImageSummaryComponent } from 'pages/data-manager/components';
import { IImageSummaryComponent } from '../components/ImageSummary';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Children } from 'react';
import { Button } from 'lib/controls';

const styles = {
    buttonHolder: {
        height: '80px',
        borderRadius: '0px',
        border: '1px solid #DADCE0',
        display: 'flex',
        pl: 2,
        alignItems: 'center',
        py: 1,
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
};

interface IDataSummary {
    selectedTheme?: any;
}

export function IntegrationSummary(props:IDataSummary) {
    const { selectedTheme } = props;
    const history = useHistory();
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const privacyDataProps: IImageSummaryComponent = {
        mode: 'SUMMARY',
        header: `Privacy of Your Data`,
        subHeader: 'Who has access to my data and activity in LifeGraph?',
        imageName: 'data-manager-dashboard-privacy-of-data.svg',
        bgColor: selectedTheme?.palette?.background?.default || '#FFFFFF',
        summaryType: 'PRIVACY',
    };

    const logoNameList = ['canvas.png', 'slack.png'];

    const traveseToDetail = (force: boolean) => {
        if (isMobile || force) {
            history.push('/data-manager/privacy');
        }
    };

    return (
        <Paper
            sx={selectedTheme.card?.default}
            onClick={() => traveseToDetail(false)}
        >
            <ImageSummaryComponent {...privacyDataProps} />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Box sx={styles.buttonHolder}>
                    {Children.toArray(
                        logoNameList.map((x: string, index: number) => {
                            const imageLink = `/images/data-manager/${x}`;
                            return (
                                <img
                                    src={imageLink}
                                    height={48}
                                    width={'auto'}
                                    style={{ marginRight: '16px' }}
                                />
                            );
                        })
                    )}
                    <Button
                        variant="text"
                        size='large'
                        color='primary'
                        endIcon={<ArrowForwardIcon sx={{color: selectedTheme?.palette?.primary[500]}}/>}
                        onClick={() => traveseToDetail(true)}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            View All
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}
