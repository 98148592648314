import React from 'react';
import { Route } from 'react-router-dom';
import { hasRequiredRoles } from '../../util/getRoles';
import * as Components from '../../lib/components';
import * as Types from '../../lib/types';
import RouterBreadcrumbs from '../Breadcrumb/Breadcrumb';
import { getUserRouteStatus } from 'util/getUserData';

export const RenderRoute = (route: Types.IRoute) => {

    const status = getUserRouteStatus();
    document.title = `${route.title}` || '';

    if (route.roles?.length && !hasRequiredRoles(route.roles)) {
        return <Components.Logout />;
    } else {
        return (
            <>
                <Route
                    path={route.path}
                    exact={true}
                    render={(props) => <React.Fragment>
                        {status == 'AUTHENTICATED' ? <RouterBreadcrumbs /> : ''}
                        <route.component {...props} />
                    </React.Fragment>}
                />
            </>
        );
    }
};
