import moment, { utc } from 'moment';

const monthNameList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const thirtyDaysInMillis = 30 * 86400000;

const defaultDateTimeFormat = 'MM/DD/YYYY  hh:mm a';
const defaultDateFormat = 'MM/DD/YYYY';

export const toLocalDateString = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).utc().format(defaultDateFormat);
    } else if (date) {
        return moment(date).utc().format(defaultDateFormat);
    }
    return '';
};

export const toLocalDateTimeString = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).format(defaultDateTimeFormat);
    } else if (date) {
        return moment(date).format(defaultDateTimeFormat);
    }
    return '';
};

export const isDateExpiredFromCurrentDate = (date: any): boolean => {
    return moment().isAfter(date);
}

export const convertDateToMonthYearDate = (date: any) => {
    if (!date) {
        return null;
    }
    try {
        const convertedDateCheck = new Date(date);
        if (isNaN(convertedDateCheck.getMonth())) {
            return null;
        }
        const convertedDate = moment(date).utc();
        if (convertedDate.format('MMMM') === 'Invalid date') {
            return null;
        }
        return `${convertedDate.format('MMMM')} ${convertedDate.format(
            'YYYY'
        )}`;
    } catch (error) {
        return null;
    }
};

export const getDateStatusOf2Dates = (date1: any, date2: any) => {
    if (!date2) {
        return 'Active';
    }
    try {
        // const milliOrigin = new Date(date1).getTime();
        const milliEnd = new Date(date2).getTime();
        const milliCurrent = new Date().getTime();
        const timeRemaining = milliEnd - milliCurrent;
        if (timeRemaining <= 0) {
            return 'Expired';
        }
        if (timeRemaining >= thirtyDaysInMillis) {
            return 'Active';
        }
        if (timeRemaining > 0 && timeRemaining <= thirtyDaysInMillis) {
            return 'Expires Soon';
        }
        return 'Invalid';
    } catch (err) {
        return 'Inactive';
    }
};

export const convertDateToMonthDayYear = (date: any) => {
    if (!date) {
        return '';
    }
    try {
        const d = moment(date).utc();
        return `${d.format('MMMM')} ${d.format('D')} ${d.format('YYYY')}`;
    } catch (err) {
        return '';
    }
};

export const defaultDate = (): string => {
    return moment().format('YYYY-MM-DD');
};

export const stringToLocalDate = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).utc().format('YYYY-MM-DD');
    } else if (date) {
        return moment(date).utc().format('YYYY-MM-DD');
    }
    return '';
};


export const commaSeparatedLocalDate = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).utc().format('ll');
    } else if (date) {
        return moment(date).utc().format('ll');
    }
    return '';
}

export const stringToDateTime = (date: any) => {
    if (date) {
        return moment(date).utc().format('MM-DD-YYYY HH:MM');
    }
    return '';
};

export const stringToUTCDate = (date: string): string | null => {
    if (date) {
        let newDate = moment(date).format('YYYY-MM-DD');
        return new Date(newDate).toISOString();
    }
    return null;
};

export function toHoursAndMinutes(totalMinutes?: number) {
    if (!totalMinutes) return '';
    const hours = totalMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    let hoursString = '';
    let minutesString = '';

    if (rhours === 1) {
        hoursString = `${rhours.toString()} hr`;
    } else if (rhours > 1) {
        hoursString = `${rhours.toString()} hrs`;
    }

    if (rhours && rminutes) {
        minutesString += ' and ';
    }

    if (rminutes === 1) {
        minutesString += `${rminutes.toString()} min`;
    } else if (rminutes > 1) {
        minutesString += `${rminutes.toString()} mins `;
    }

    return hoursString + minutesString;
}

export function minBirthDate(date?:string){
    return moment().subtract('120', 'years').utc().format('DD-MM-YYYY');
}
