import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { healthPathwayActionCreators } from 'redux/actionCreators';
import { healthPathwaySelector, routerSelector } from 'redux/selectors';
import { PathwayDetailComponent } from 'components/Pathway/PathwayDetail';
import { ThemeContext } from 'util/themes';

export function HealthPathwayDetail() {
    const pathway = useSelector(healthPathwaySelector.selectHealthPathwayExpandedStep);
    const loading = useSelector(healthPathwaySelector.selectedHealthPathwayLoading);
    const selectedAssetId = useSelector(routerSelector.selectCurrentMilestoneArticleId);
    const completedMilestones = useSelector(healthPathwaySelector.selectTotalCompletedHealthMilestones);
    const currentModule = useSelector(routerSelector.selectCurrentModule);
    const dispatch = useDispatch();

    const { setCurrentTheme } = useContext(ThemeContext);

    const { pathwayId } = useParams<any>();

    useEffect(() => {
        setCurrentTheme('lifeGraph');
        dispatch(healthPathwayActionCreators.getHealthPathwayById(pathwayId));
    }, []);

    return (
        <PathwayDetailComponent isHealthMode={true} loading={loading} pathway={pathway} selectedAssetId={selectedAssetId} completedMilestones={completedMilestones} currentModule={currentModule} />
    );
}