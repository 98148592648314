import React from 'react';
import { Stack } from '@mui/material';

import { styles } from '../verifyGrid.helper';
import { VerifyGridDetailRow } from './verifyGridDetailRow';
import { stringToLocalDate } from 'util/date.utils';

export default function verifyGridAllergy(props: any) {
    const { data } = props;

    return (
        <>
            {!!data ? (
                <Stack direction="row" spacing={2}>
                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="Allergy"
                            value={data?.allergy?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label="Last Reaction"
                            value={
                                stringToLocalDate(data?.lastReaction?.$date) ||
                                'n/a'
                            }
                        />
                    </Stack>

                    <Stack sx={styles.column}></Stack>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
}
