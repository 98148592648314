import {
    DataGridPro,
    GridColDef,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import { dataGridStyleOverrides, rowOverrideStyles } from './sharingGrid.helper';
import { useCallback, useContext } from 'react';
import { SharingGridExpandedRow } from './SharingGridExpandedRow';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import { ThemeContext } from 'util/themes';

interface IDataGrid {
    rows: any[];
    columns: GridColDef[];
    isMobile: boolean;
    loading: boolean;
}

export function SharingGrid(props: IDataGrid) {
    const dataGridStyles = dataGridStyleOverrides();
    const { rows, columns, isMobile, loading } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const rowHeight = 70;
    const headerHeight = 40;
    const loadingRows = Array.from(Array(5), (_, i) => {
        return { id: i };
    });

    const find = columns.find(
        (x: GridColDef) => x.field === '__detail_panel_toggle__'
    );
    const getDetailPanelHeight = useCallback((rowDetail: any) => {
        const totalChild = rowDetail.row.expandedRowValue.length;
        return 64 + 24 * totalChild + 16 * (totalChild - 1);
    }, []);

    const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] =
        useCallback(({ row }) => {
            const lastRowId = rows[rows.length - 1]?.id;
            return (
                <SharingGridExpandedRow
                    isExpanded={true}
                    rows={row.expandedRowValue}
                    isLastRow={row.id === lastRowId}
                />
            );
        }, []);

    const expandColumnConfig =
        find && !loading
            ? {
                  getDetailPanelHeight: getDetailPanelHeight,
                  getDetailPanelContent: getDetailPanelContent,
              }
            : {};

    if (loading) {
        for (const column of columns) {
            column.renderCell = () => (
                <Skeleton width={'80%'} animation={'wave'} />
            );
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', ...rowOverrideStyles }}>
                <DataGridPro
                    sx={selectedTheme.table?.default}
                    autoHeight={true}
                    headerHeight={headerHeight}
                    hideFooter={true}
                    rowHeight={rowHeight}
                    className={
                        isMobile
                            ? dataGridStyles.mobileDataGrid
                            : dataGridStyles.desktopDataGrid
                    }
                    columns={columns}
                    rows={loading ? loadingRows : rows}
                    {...expandColumnConfig}
                    components={{
                        DetailPanelExpandIcon: ArrowRightIcon,
                        DetailPanelCollapseIcon: ArrowDownIcon,
                    }}
                />
            </Box>
        </Box>
    );
}
