import { makeStyles } from '@mui/styles';

const commonRowStyles = {
    '&:nth-last-of-type(1)': {
        borderRadius: '0 0 12px 12px',
        border: 'none',
    },
};

export const dataGridStyleOverrides = makeStyles((theme: any) => ({
    tableRow : theme.table?.rows,
    expandedRow : {
        ...theme.table?.rowCell,
        ...theme.table?.rowExpanded,
        ...theme.table?.rowExpandedCell
    },
    headerVariant: {
        ...theme.table?.headerCell,
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
        },
    },
    cellValueBold: {
        fontWeight: 600,
    },
    desktopDataGrid: {
        m: 'auto',
        ...theme.table?.default,
        '& .MuiDataGrid-columnHeaders': theme.table?.header,
        '& .MuiDataGrid-columnHeaderTitle': theme.table?.headerCellTitle,
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                ...theme.table?.rows, ...commonRowStyles,
            },
        },
        '& .MuiDataGrid-cell' : theme.table?.rowCell
    },
    mobileDataGrid: {
        m: 'auto',
        borderBottom: '1px solid #BDC1C5',
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#E8EAED',
            borderTop: '1px solid #BDC1C5',
            borderBottom: '1px solid #BDC1C5',
            borderRadius: '0px',
        },
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                ...theme.table?.rows, ...commonRowStyles,
            },
        },
    },
}));

export const rowOverrideStyles = {
    '& .disabledRowStyle': {
        opacity: 0.4,
    },
};
