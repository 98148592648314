import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import { axiosInstance, withCredentials } from 'util/axiosInstance';

import * as verifyDeatils from './verify-grid-detail';
import {
    styles,
    IItem,
    ITableExpandedRow,
    dataGridStyleOverrides,
} from './verifyGrid.helper';

export function VerifyGridExpandedRow(props: ITableExpandedRow) {
    const dataGridStyles = dataGridStyleOverrides();
    const { isExpanded, row, isLastRow, onReloadVerifications } = props;
    const [dataToVerify, setDataToVerify] = useState<IItem[]>();
    const { selectedTheme } = useContext(ThemeContext);

    useEffect(() => {
        axiosInstance()
            .get(
                `/v2/${row.assetType.toLowerCase()}/verification/consented-data/${
                    row.asset_id
                }`,
                withCredentials()
            )
            .then((resp) => {
                setDataToVerify(resp?.data?.data?.asset);
            });
    }, [row]);

    if (!isExpanded) {
        return <></>;
    }
    const lastRowOverrides: any = {};
    if (isLastRow) {
        lastRowOverrides.borderRadius = '0 0 12px 12px';
        lastRowOverrides.borderBottom = 'none';
    }

    // Selects the correct layout component for the specific data type
    const renderDetail = (currentType: string) => {
        const result = new Map(
            Object.entries(verifyDeatils).map((x) => [x[0], x[1]])
        );

        const Component: any = result.get(currentType);
        return Component ? <Component data={dataToVerify} /> : <></>;
    };

    const handleVerifyClick = () => {
        const url = `/v2/${row.assetType.toLowerCase()}/verification/${
            row.asset_id
        }`;

        axiosInstance()
            .put(url, {}, withCredentials())
            .then(() => {
                onReloadVerifications();
            });
    };

    return (
        <Box
            className={dataGridStyles.expandedRow}
            sx={{ ...styles.parent, ...lastRowOverrides }}
        >
            {renderDetail(row.assetType)}

            {row.verificationStatus === 'pending' ? (
                <Box sx={{ ...styles.submitButton }}>
                    <Button
                        size="large"
                        onClick={handleVerifyClick}
                        variant="contained"
                        color="secondary"
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,

                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                            }}
                        >
                            Start Verification
                        </Typography>
                    </Button>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
}
