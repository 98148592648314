import { useState, useEffect, useContext } from 'react';

import {
    Box,
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { ThemeContext } from 'util/themes';

import { dataGridStyleOverrides } from './components/verify-grid/verifyGrid.helper';
import { VerifyGrid } from './components';
import { EVerifyHeaderCards } from './components/everify-header-cards';
import {
    styles,
    IFilterState,
    getColumns,
    IVerificationRecord,
    getSearchString,
    loadVerifications,
    filterDefaults,
} from './e-verify.helper';

export function EVerify() {
    const { selectedTheme } = useContext(ThemeContext);
    const dataGridStyles = dataGridStyleOverrides();
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<IFilterState>(filterDefaults);
    const [allVerifications, setAllVerifications] = useState<
        IVerificationRecord[]
    >([]);

    const handleLoadVerifications = () => {
        loadVerifications(setAllVerifications, setLoading);
    };

    useEffect(() => {
        handleLoadVerifications();
    }, []);

    const filterData = (data: IVerificationRecord[]) => {
        let filteredRows = data;

        if (filters.pendingRecordsOnly) {
            filteredRows = filteredRows.filter(
                (verification: IVerificationRecord) => {
                    return (
                        verification.verificationStatus.toLowerCase() ===
                        'pending'
                    );
                }
            );
        }

        if (filters.searchString) {
            filteredRows = filteredRows.filter(
                (verification: IVerificationRecord) => {
                    const searchValue = getSearchString(verification);
                    return searchValue.includes(
                        filters.searchString.toLowerCase()
                    );
                }
            );
        }

        return filteredRows;
    };

    const togglePendingOnly = () => {
        setFilters({
            ...filters,
            pendingRecordsOnly: !filters.pendingRecordsOnly,
        });
    };

    const updateSearchString = (params: any) => {
        setFilters({
            ...filters,
            searchString: params.target.value.toLowerCase(),
        });
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ ...styles.banner }}>
                    <Box width="100%">
                        <Typography
                            variant="h1"
                            color={selectedTheme.palette.primary[700]}
                            sx={{ ...styles.heading }}
                        >
                            Verifier Pro
                        </Typography>

                        {/* Verification Header Stats Cards */}
                        <EVerifyHeaderCards />
                    </Box>
                </Grid>

                <Grid item xs={12} sx={{ px: 3 }}>
                    <Typography
                        variant="h2"
                        sx={{ ...styles.heading }}
                        color={selectedTheme.palette.secondary.grayScale[700]}
                    >
                        Tasks
                    </Typography>

                    {/* Verification Filters */}
                    <Box sx={styles.searchBar}>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                my: 2,
                            }}
                        >
                            <Grid item xs={12} md={5}>
                                <TextField
                                    fullWidth
                                    sx={{
                                        ...selectedTheme?.input,
                                        ...styles.searchControl,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <SearchIcon
                                                sx={{
                                                    color: selectedTheme.palette
                                                        .primary[500],
                                                }}
                                            />
                                        ),
                                    }}
                                    placeholder="Search"
                                    InputLabelProps={{
                                        style: { marginTop: '-2px' },
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onChange={updateSearchString}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={togglePendingOnly}
                                                sx={{
                                                    ml: 2,
                                                    color: selectedTheme
                                                        ?.palette.primary[400],
                                                }}
                                            />
                                        }
                                        label="Pending Only"
                                        disableTypography
                                        sx={{
                                            ...styles.activeOnlySwitch,
                                            ...selectedTheme?.typography?.body
                                                ?.default,
                                            ...selectedTheme?.typography?.body
                                                ?.body1,
                                            ...selectedTheme?.typography?.body
                                                ?.regular,
                                            color:
                                                selectedTheme?.palette.secondary
                                                    ?.grayScale &&
                                                selectedTheme?.palette.secondary
                                                    ?.grayScale[700],
                                        }}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Verification Grid */}
                    <VerifyGrid
                        rows={filterData(allVerifications)}
                        columns={getColumns(dataGridStyles)}
                        loading={loading}
                        onReloadVerifications={handleLoadVerifications}
                    />
                    <br />
                </Grid>
            </Grid>
        </>
    );
}
