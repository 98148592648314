import { dataManagerSummaryActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { ISharedData } from '../../../@types/data-manager-types';

export interface IDataManagerSharedState {
    error: boolean;
    data: ISharedData[];
    loading: boolean;
}

interface IDataManagerSharedAction {
    type: string;
    payload: ISharedData[];
}

const initialStateData: IDataManagerSharedState = {
    error: false,
    data: [],
    loading: true,
};

const reducer = (
    state: IDataManagerSharedState = initialStateData,
    action: IDataManagerSharedAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case dataManagerSummaryActionType.GET_DATA_MANAGER_SHARED_SUMMARY_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
