import React from 'react';
import { Box } from '@mui/material';
import { ProfileInfoRowComponent } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { ProfileConstants } from 'app-constants';

export const AddressComponent = (props: any) => {
    const {
        loading,
        data,
        canEdit,
        handleModalOpen,
        hideVerification = false,
        selectedTheme,
    } = props

    return (
        <Box className="flex-column full-width">
            <ProfileInfoRowComponent
                isLoading={loading}
                label={data.type}
                selectedTheme={selectedTheme}
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                value={getSingleLineAddressFromObject(
                    data
                )}
                assetId={data.asset_id}
                canEdit={
                    canEdit ? 'ACTIVE' : 'HIDDEN'
                }
                onEditClicked={handleModalOpen}
                verificationDetail={{key: ProfileConstants.ADDRESS_INFO, data: props}}
                hideVerification={hideVerification}
            />
        </Box>
    )
}
