import { Grid, Typography } from '@mui/material';
import { Children, ReactElement } from 'react';
import { Loader } from '../../lib/components/Loader/BarSkeleton';
import { VerificationStatusComponent } from 'components';
import { ILifeGraphVerification } from '../../@types/profile-types';
import { Edit as EditIcon } from '@mui/icons-material';

const styles = {
    label: {
        textTransform: 'uppercase' as const,
        letterSpacing: '1px',
        fontWeight: 'bold',
        fontSize: '10px',
        color: '#9AA0A6',
    },
    value: {
        color: '#202124',
        fontSize: '1rem',
        lineHeight: 1.5,
    },
    container: {
        mt:0.25,
        mb: 1,
    },
};

interface IProfileInfoRowComponent {
    label: string;
    value: string | ReactElement;
    Icon?:any;
    subHeading? : string;
    isLoading: boolean;
    classNames: string;
    verificationData: ILifeGraphVerification;
    verificationHolderSize: number;
    assetId: string;
    canEdit: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    onEditClicked: (id: string) => void;
    selectedTheme?: any;
    containerStyle?:any;
    secondRow?:any;
    verificationDetail?: any;
    hideVerification?: any;
}

export const ProfileInfoRowComponent = (props: IProfileInfoRowComponent) => {
    const {
        label,
        value,
        Icon,
        isLoading,
        assetId,
        canEdit,
        onEditClicked,
        verificationData,
        verificationHolderSize,
        selectedTheme,
        subHeading,
        containerStyle={},
        secondRow = {},
        verificationDetail,
        hideVerification=false
    } = props;

    const onClick = () => {
        if (canEdit === 'ACTIVE') {
            onEditClicked(assetId);
        }
    };

    return (
        <Grid container direction="row" sx={{...containerStyle}}>
            <Grid item maxWidth={24} width={24} height={24} mr={2}>
                {verificationData && !hideVerification  ? (
                    <VerificationStatusComponent
                        data={verificationData}
                        loading={isLoading}
                        selectedTheme={selectedTheme}
                        verificationDetail={verificationDetail}
                    />
                ) : ('')}
                {Icon ? <Icon  sx={{ color: selectedTheme?.palette?.primary[500] }}/> : ''}
            </Grid>
            <Grid item xs={8} sx={styles.container}>
                <Typography sx={{
                    ...selectedTheme?.typography?.overlines?.overline2.default,
                    ...selectedTheme?.typography?.overlines?.overline2.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                }}>
                    {label}
                </Typography>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {typeof value === 'string'
                            ? Children.toArray(
                                  value
                                      .split('\n')
                                      .map((addressElement: string) => {
                                          return (
                                              <Typography
                                                  sx={{
                                                      ...selectedTheme?.typography?.body?.default,
                                                      ...selectedTheme?.tpyography?.body?.body1,
                                                      ...selectedTheme?.tpyography?.body?.regular,
                                                      color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                                                  }}
                                                  data-testid={`${label}_value`}
                                              >
                                                  {addressElement}
                                              </Typography>
                                          );
                                      })
                              )
                            : value}
                    </>
                )}
                {subHeading ? (<Typography sx={{
                    ...selectedTheme?.typography?.overlines?.overline2.default,
                    ...selectedTheme?.typography?.overlines?.overline2.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                }}>
                    {subHeading}
                </Typography>) : ''}
            </Grid>
            <Grid item xs={1}>
                {canEdit === 'HIDDEN' ? (
                    <></>
                ) : isLoading ? (
                    <Loader width={30} />
                ) : (
                    <EditIcon
                        onClick={onClick}
                        data-testid={`${label}_editIcon`}
                        className="cursor-pointer"
                        style={{ color: selectedTheme?.palette?.primary[500] }}
                    />
                )}
            </Grid>
           { secondRow ? <>
            <Grid item xs={verificationHolderSize}>{''}</Grid>
            <Grid item xs={10} sx={{...styles.container, ml: 5}}>
                <Typography sx={{
                    ...selectedTheme?.typography?.overlines?.overline2.default,
                    ...selectedTheme?.typography?.overlines?.overline2.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                }}>
                    {secondRow.label}
                </Typography>
                { secondRow.value }
                <Typography sx={{
                    ...selectedTheme?.typography?.overlines?.overline2.default,
                    ...selectedTheme?.typography?.overlines?.overline2.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                }}>
                    {secondRow.subHeading}
                </Typography>
            </Grid> </> : ''}
        </Grid>
    );
};

export default ProfileInfoRowComponent;

ProfileInfoRowComponent.defaultProps = {
    assetId: '',
    label: '',
    value: '',
    isLoading: false,
    verificationHolderSize: 1,
    classNames: '',
    canEdit: 'HIDDEN',
    verificationData: null,
    onEditClicked: () => {},
};
