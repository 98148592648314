import {useEffect, useState, memo} from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { healthDashboardVaccinationSelector } from 'redux/selectors';
import { HealthDashboardCard } from "../health-dashboard-card/HealthDashboardCard";
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { VaccinationForm } from './VaccinationModal';

interface IVaccinationCardComponent {
    cardData: {
        title: string;
        itemsToShow: any;
    }
}

const VaccinationComponent = (props: IVaccinationCardComponent) => {
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({
        vaccine: '',
        administerDate: '',
        administeredBy: '', 
        assetId: '',
        userId: ''
    });

    const { cardData } = props;
    let itemToShowArray = [...cardData.itemsToShow];
    const initialCardObj = {
        'label': 'Administered By',
        'value': '',
        'subHeading': '',
        'secondRow': {
            'label': 'Date Administered',
            'value': '',
        }
    };
        
    useEffect(() => {
        dispatch(healthDashboardActionCreators.getHealthDashboardVaccinationList());
    }, []);

    const data = useSelector(healthDashboardVaccinationSelector.selectVaccinationData);
    const vacLoading = useSelector(healthDashboardVaccinationSelector.selectVaccinationLoading);

    if(data.length){
        data.forEach((item: any) => {
            itemToShowArray.push(item);
        })
    }

    const handleModalOpen = (id: string) => {
        const filteredData = data.filter((item: any) => {
            return item.assetId === id
        }) || [];
        if(filteredData.length){
            const { value, subHeading, assetId, userId } = filteredData[0];
            const dataObj = {
                'vaccine': value,
                'administerDate': '',
                'administeredBy': '',
                assetId,
                userId
            }
            if(filteredData[0]?.secondRow){
                const { value: administerDate } = filteredData[0].secondRow;
                dataObj.administerDate = administerDate !== '' ?
                     moment(administerDate).format('YYYY-MM-DD') :
                    '';
                dataObj.administeredBy = subHeading;
            } else {
                dataObj.administerDate = subHeading !== '' ? moment(subHeading).format('YYYY-MM-DD') : '';
                dataObj.administeredBy = ''
            }
            setSelectedData(dataObj);
        }
        setEdit(true);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const onAddIconClicked = () => {
        setSelectedData({
            vaccine: '',
            administerDate: '',
            administeredBy: '', 
            assetId: '',
            userId: ''
        });
        setEdit(false);
        setModalOpen(true);   
    }
    
    return (
        <>
            <HealthDashboardCard 
                title={cardData.title}
                itemsToShow={itemToShowArray}
                isLoading={vacLoading}
                initialCardObj={initialCardObj}
                canEdit={true}
                handleModalOpen={handleModalOpen}
                onAddIconClicked={onAddIconClicked}    
            />
            {modalOpen ? (
                <VaccinationForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    selectedData={selectedData}
                    isEdit={isEdit}
                />
                ) : (
                <></>
            )}
        </>
    )
}

export const VaccinationCardComponent = memo(VaccinationComponent);
