import { Box, Grid } from '@mui/material';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes';
import DashboardCardComponent, { ICardProps } from 'pages/dashboard/components/DashboardCard';

export const DashboardApps = (props: any) => {

    const { apps } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const styles = {
        label: {
            ...selectedTheme?.typography?.overlines?.overline1?.default,
            ...selectedTheme?.typography?.overlines?.overline1?.semibold,
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            color: selectedTheme?.palette?.secondary?.grayScale
                && selectedTheme?.palette?.secondary?.grayScale[700]
        },
        description: {
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body3,
            mt: 2,
            mb: 2
        }
    };

    const appCardProps: ICardProps = {
        heading: '',
        headingStyle: styles.label,
        isHeadingIcon: false,
        subHeading: '',
        subHeadingStyle: {...selectedTheme?.typography?.h2, color: selectedTheme?.palette?.primary[700]},
        description: '',
        descriptionStyle: styles.description,
        imageName: '',
        buttonText: 'Get Started',
        rightIcon: true,
        isLinkButton: true,
        goToLink: '',
        descriptionOrder: {
            order: 1
        },
        buttonOrder: {
            order: 2
        },
        buttonStyle: {
            m: 0
        },
        containerStyle: {
            mr: 2,
            mb: 2.5,
            ...selectedTheme?.card?.default
        }
    };

    return (
        <Box>
            <Grid xs={12} display='flex' container item>
                {Children.toArray(apps.map((app: any) => <DashboardCardComponent {...appCardProps} {...app} />))}
            </Grid>
        </Box>
    );
};