import { personalnfoActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IverificationStatus, IProfilePersonal } from '../../../@types/profile-types';
import data from '@iconify/icons-mdi/check-circle-outline';

export interface IProfilePersonalInfo {
    error: boolean;
    data: IProfilePersonal;
    loading: boolean;
    isShareMyDataSidebarOpen: boolean;
    profileEditMode: boolean;
}

interface IprofilePersonalInfoAction {
    type: string;
    payload: IProfilePersonal;
}

export const initialData: IProfilePersonal = {
    assetType: '',
    asset_id: '',
    birthdate: {},
    familyName: '',
    gender: '',
    givenName: '',
    preferredName: '',
    suffix: '',
    userId: '',
    formattedName: '',
    middleName: '',
    verifier: '',
    verificationCompany: '',
    verificationStatus: 'unverified',
    verificationType: '',
    verificationAssetId: '',
    verificationSignature: '',
    verificationTimestamp: {},
    verificationHash: ''
};

const initialProfilePersonalInfo = {
    error: false,
    data: initialData,
    loading: false,
    isShareMyDataSidebarOpen: false,
    profileEditMode: false
};

const reducer = (
    state: IProfilePersonalInfo = initialProfilePersonalInfo,
    action: IprofilePersonalInfoAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case personalnfoActionType.PROFILE_PERSONALINFO_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = false;
                return draft;
            case personalnfoActionType.PROFILE_PERSONALINFO_LOADING:
                draft.error = false;
                draft.data = initialData;
                draft.loading = true;
                return draft;
            case personalnfoActionType.PROFILE_PERSONALINFO_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
            case personalnfoActionType.PROFILE_SHARE_MY_DATA_SIDEBAR_OPEN:
                draft.isShareMyDataSidebarOpen = true;
                return draft;
            case personalnfoActionType.PROFILE_SHARE_MY_DATA_SIDEBAR_CLOSE:
                draft.isShareMyDataSidebarOpen = false;
                return draft;
            case personalnfoActionType.TOGGLE_PROFILE_EDIT_MODE:
                draft.profileEditMode = !draft.profileEditMode;
                return draft;
            case personalnfoActionType.CLOSE_PROFILE_EDIT_MODE:
                draft.profileEditMode = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
