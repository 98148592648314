import { 
    object as yupObject, 
    string as yupString, 
    number as yupNumber
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOgranizationAddress } from '../../../@types/profile-types';
import { OrganizationAddressForm } from 'components';
import validationMessage from 'util/validations';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';

export interface IContinuingEducationFormInputs extends IOgranizationAddress {
    name: string;
    startDate: string;
    endDate: string;
    credits: number;
    asset_id?: string;
}

export const formDefaults: IContinuingEducationFormInputs = {
    name: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    credits: 0,
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    credits: yupNumber().required(validationMessage.required),
    name: yupString().max(255, validationMessage.max(255)).required(validationMessage.required)
}).concat(OrganizationAddressForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);