import React from 'react';
import {
    Box,
    SvgIcon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Grid,
    Typography,
    IconButton,
} from '@mui/material';
import { baseTheme, ThemeContext } from 'util/themes';

import CloseIcon from '@mui/icons-material/Close';

import { Icon } from '@iconify/react';
import { accountCheck, circleOutline } from '../../../components/Pathway/pathwayIcons';
import { Button } from 'lib/controls';
import { useContext } from 'react';

interface IProps {
    setOpenPopup: (val: boolean) => void;
    activities?: any[];
    title?: string;
    label?: string;
}

// interface IActivity {
//     title: string;
//     pageId: string;
//     pageUrl: string;
//     status: string;
// }

const styles = {
    title: {
        color: '#202124',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '36px',
    },
    label: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: baseTheme.spacing(3),
        display: 'block',
    },
};

export function ActivityPopup(props: IProps) {
    const { setOpenPopup, activities, label, title } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const onClose = () => {
        setOpenPopup(false);
    };

    const getIcon = (status: string) => {
        if (status === 'completed') {
            return <Icon icon={accountCheck} style={{ color: 'green' }} />;
        } else {
            return <Icon icon={circleOutline} />;
        }
    };

    return (
        <Grid container>
            <Box
                sx={{
                    width: '100%',
                    m: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography variant="body1" sx={styles.label}>
                        {label}
                    </Typography>
                    <Typography variant="h6" sx={styles.title}>
                        {title}
                    </Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    mx: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography variant="body1" sx={styles.label}>
                        Skills
                    </Typography>
                </Box>
                <Button variant="contained" size="medium" sx={{
                    backgroundColor: selectedTheme?.palette.purple?.light,
                    py: '0.25rem',
                    borderRadius: '4px'
                }}>
                    <Typography sx={{
                        textTransform: 'none',
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.medium,
                    }}>
                        Resume
                    </Typography>
                </Button>
            </Box>

            <Grid item xs={12} style={{ width: '500px' }}>
                <List>
                    {React.Children.toArray(
                        activities?.map((activity: any) => {
                            return (
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <SvgIcon
                                                fontSize="large"
                                                viewBox="36 36"
                                            >
                                                {getIcon(activity.status)}
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={activity.title}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    )}
                </List>
            </Grid>
        </Grid>
    );
}
