import React, { Children, useContext } from 'react';
import {
    Grid,
    Typography,
    Box,
    Popper,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemText,
    Button,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { ThemeContext } from '../../util/themes/themeContext';

interface IAddOption {
    key: string;
    value: string;
}

interface ICardTitle {
    title: string;
    canAdd: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    addOptionList: IAddOption[];
    onAddIconClicked: (id?: string) => void;
}

const styles = {
    titleContainer: {
        py: 2,
        px: 3,
    },
    cardHeading: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    popperBox: {
        border: 0,
        p: 0,
        backgroundColor: 'white',
    },
    listItemPadding: {
        p: 0,
    },
    listItemButton: {
        py: 0.75,
        px: 2,
        border: '1px solid',
    },
};

const getOptionsDropdown = (
    optionList: IAddOption[],
    onAddIconClicked: (id?: string) => void,
    iconColor: string | undefined
) => {
    const { selectedTheme } = useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const onAddClick = (event: string) => {
        onAddIconClicked(event);
    };

    const isButtonDisabled = iconColor === 'disabled' ? true : false;

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    disabled={isButtonDisabled}
                    className="popper-button"
                    sx={{ padding: 0, minWidth: 0, color: selectedTheme.palette.primary[500]}}
                >
                    <AddIcon/>
                </Button>
            </ClickAwayListener>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
            >
                <Box className="popper-dropdown-box">
                    <List component="nav" sx={styles.listItemPadding}>
                        {Children.toArray(
                            optionList.map((x: IAddOption) => {
                                return (
                                    <ListItemButton
                                        onClick={(_e) => onAddClick(x.key)}
                                        selected={false}
                                        sx={styles.listItemButton}
                                    >
                                        <ListItemText primary={x.value} />
                                    </ListItemButton>
                                );
                            })
                        )}
                    </List>
                </Box>
            </Popper>
        </>
    );
};

export const CardTitleComponent = (props: ICardTitle) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { title, canAdd, onAddIconClicked, addOptionList } = props;
    const iconColor = selectedTheme.palette.primary[500];

    const onAddClicked = (id?: string) => {
        if (canAdd === 'ACTIVE') {
            onAddIconClicked(id);
        }
    };
    return (
        <Grid
            container
            sx={styles.titleContainer}
            alignContent="center"
            justifyContent="space-between"
            wrap="nowrap"
        >
            <Grid item>
                <Typography
                    variant="h4"
                    sx={{
                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                    }}
                    data-testid="card-heading-holder"
                >
                    {title}
                </Typography>
            </Grid>
            {canAdd === 'HIDDEN' ? (
                <> </>
            ) : addOptionList.length === 0 ? (
                <AddIcon
                    style={{ color: iconColor }}
                    className="cursor-pointer"
                    onClick={(_e) => onAddClicked()}
                    data-testid={`${title}_add_icon`}
                />
            ) : (
                getOptionsDropdown(addOptionList, onAddClicked, iconColor)
            )}
        </Grid>
    );
};

CardTitleComponent.defaultProps = {
    title: '',
    canAdd: 'HIDDEN',
    onAddIconClicked: () => {},
    addOptionList: [],
};
