import { useEffect, Children, useContext } from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { baseTheme, ThemeContext } from 'util/themes';
import { ResponsiveCirclePacking, CircleProps, useNodeMouseHandlers } from '@nivo/circle-packing';
import { skillsActionCreators } from 'redux/actionCreators';
import { skillsSelector } from 'redux/selectors';
import { animated } from '@react-spring/web';
import { ISkillMap } from '../../../@types/skills.types';
import { useHistory } from 'react-router-dom';

const styles = {
    title: {
        color: baseTheme.palette.primary.main,
        fontSize: '24px',
        fontWeight: '600'
    },
    subHeader: {
        fontSize: '16px',
        color: '#202124',
        pt: 2,
    },
    link: {
        color: baseTheme.palette.secondary.main,
    },
    circleSkillLevel: {
        color: 'rgba(255,255,255,0.8)',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '1px',
    },
    circleSkillName: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 600,
    },
    circleSkillPoints: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'bold',
    }
}

export function SkillsMapComponent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);

    const loading = useSelector(skillsSelector.selectLoading);
    const data = useSelector(skillsSelector.selectData);

    useEffect(() => {
        if (loading) {
            dispatch(skillsActionCreators.getUserSkills());
        }
    }, [loading]);

    const labelOverride = (params: any) => {
        const skill: ISkillMap = params.node.data;
        const getLableColor = () => {
            if(skill.isVerified){
                return '#FFFFFF'
            } else {
                return selectedTheme?.palette?.purple?.darker || skill.labelFontColor
            }
        }
        const commonStyle = { x: params.node.x, y: params.node.y, fill: getLableColor(), textAnchor: 'middle', dominantBaseline: 'central' };
        const splitNameList: string[] = skill.name.split(' ');
        const length = splitNameList.length - 1;
        const levelYPosition = params.node.y - 30 - (length * 10);
        const pointsYPosition = params.node.y + 30 + (length * 10) - (skill.isVerified ? 0 : 10);
        return (
            <>
                <text { ...commonStyle } y={levelYPosition} style={styles.circleSkillLevel} >
                    { skill.level }
                </text>
                {
                    Children.toArray(splitNameList.map((value: string, index: number) => {
                        let yPosition = params.node.y;
                        if (!skill.isVerified) {
                            yPosition = yPosition - 10;
                        }
                        if (length === 1) {
                            yPosition = index === 0 ? yPosition - 10 : yPosition;
                        } else if (length === 2) {
                            yPosition = index === 0 ? yPosition  - 15 : (index === 1 ? yPosition - 5 : yPosition + 5); 
                        }
                        return (
                            <text { ...commonStyle } y={yPosition + (index * 10)} style={styles.circleSkillName}>
                                { value }
                            </text>
                        )
                    }))
                }
                <text { ...commonStyle } y={pointsYPosition} style={styles.circleSkillPoints} >
                    { skill.totalPoints }
                </text>
            </>
        )
    }

    const clickOverride=(params: any) => {
        const skill: ISkillMap = params.data;
        if (skill.isVerified) {
            history.push(`/learning/skills/${skill.id}`);
        }
    }

    const circleOverride = <RawDatum,>({
        node,
        style,
        onMouseEnter,
        onMouseMove,
        onMouseLeave,
        onClick,
    }: CircleProps<RawDatum>) => {
        const handlers = useNodeMouseHandlers<RawDatum>(node, {
            onMouseEnter,
            onMouseMove,
            onMouseLeave,
            onClick,
        })

        const getNodeColor = () => {
            if((node.data as any).isVerified) {
                return selectedTheme?.palette?.purple?.light
            } else {
                return selectedTheme?.palette?.purple?.lighter
            }
        }
    
        return (
            <animated.circle
                key={node.id}
                cx={node.x}
                cy={node.y}
                r={node.radius - 10}
                fill={getNodeColor() || (node.data as any).color}
                strokeWidth={style.borderWidth}
                opacity={1}
                onMouseEnter={handlers.onMouseEnter}
                onMouseMove={handlers.onMouseMove}
                onMouseLeave={handlers.onMouseLeave}
                onClick={handlers.onClick}
                className={'cursor-pointer'}
            />
        )
    }

    return (
        <Box sx={{ py: 3 }}>
            <Typography sx={{
                ...styles.title,
                color: selectedTheme?.palette?.purple?.light
            }}>
                Skill Map
            </Typography>
            <Typography sx={styles.subHeader}>Earn points when you stay on your learning path! Collect points automatically when you complete your lessons, milestones, and pathways. Over time, we unlock learning opportunities or rewards that you can purchase with your points. Learn and earn! </Typography>
            <Link sx={{
                ...styles.link,
                color: selectedTheme?.palette?.purple?.light,
                textDecorationColor: selectedTheme?.palette?.purple?.light
            }} className="cursor-pointer">Learn about skill levels</Link>
            {
                loading ? <> </> : (
                    <Grid item xs={12} height={700}>
                        <ResponsiveCirclePacking
                            data={data}
                            colorBy={'id'}
                            inheritColorFromParent={true}
                            id="name"
                            leavesOnly={true}
                            enableLabels
                            margin={{ right: 0, top: 0 }}
                            onClick={clickOverride}
                            circleComponent={circleOverride}
                            labelComponent={labelOverride}
                        />
                    </Grid>
                )
            }
        </Box>
    )
}