const getRoles = () => {
    const assignedRoles = JSON.parse(localStorage.getItem('userData'))?.roles;

    return assignedRoles ? assignedRoles : ['public'];
};

const hasRequiredRoles = (requiredRoles) => {
    if (!requiredRoles.length) {
        return true;
    }

    const roles = getRoles();
    const hasNeededRoles = requiredRoles.filter((element) =>
        roles.includes(element)
    )?.length;
    return !!hasNeededRoles;
};

export { getRoles, hasRequiredRoles };
