export const downloadDataMangerData = (response: { data: BlobPart; headers: { [x: string]: string; }; }) => {
    let blob = new Blob([response.data], {type: 'mime' || 'application/octet-stream'});
    let bloburl = window.URL.createObjectURL(blob);
    let fileName = response?.headers['content-disposition'].split('filename=')[1].split(';')[0];
    if(fileName){
        fileName = fileName.replaceAll('"','');
    }
    //create link and download
    let link = document.createElement('a');
    link.href = bloburl;
    link.download = fileName || 'export.zip';
    link.click();
}