import {
    Typography,
    Box,
    Grid,
    useMediaQuery,
    useTheme,
    Paper,
    Skeleton,
    Link,
} from '@mui/material';
import { commonStyles } from './share-my-data.helper';
import { useState, Fragment, useEffect, Children, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BorderLinearProgress } from 'components';
import {
    CircleOutlined,
    CheckCircleOutlineOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataSelector } from 'redux/selectors';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { IShareData, IShareMethod } from '../../@types/share-my-data.types';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

const styles = {
    paper: {
        mb: 3,
        borderRadius: '5px',
        pl: 4,
        pr: 2,
        py: 2,
        border: '2px solid #457CCC',
        textAlign: 'left',
    },
    paperHeader: {
        fontWeight: '600',
        fontSize: '16px',
        color: '#457CCC',
        pb: 0.5,
    },
    paperSubHeader: {
        fontSize: '14px',
        color: '#457CCC',
    },
    paperBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#202124',
        mb: 4,
    },
    link: (selectedTheme: any) => ({
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Red Hat Text',
        display: { xs: 'block', md: 'inline' },
        textDecorationColor: selectedTheme?.palette.primary.main,
        '&:hover': {
            textDecorationColor: selectedTheme?.palette.primary.main
        }
    }),
};

export function ShareMyDataHowComponent() {
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const data = useSelector(shareMyDataSelector.selectData);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const width = isMobile ? '92%' : '500px';
    const loadingData = Array.from(Array(2), (_, i) => {
        return {};
    });
    const [rows, setRows] = useState<any[]>(loadingData);
    const [methodSelected, setMethodSelected] = useState<string>('');

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        } else {
            const type = shareObject.type;
            const find = data.find((x: IShareData) => x.type === type);
            if (find && find.shareMethodList) {
                setRows(find.shareMethodList);
            }
            if (shareObject.method) {
                setMethodSelected(shareObject.method);
            }
        }
    }, [loading]);

    const nextClicked = () => {
        shareObject.method = methodSelected;
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        history.push('/share-my-data/review');
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    const disableButton = loading || !methodSelected ? true : false;

    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width }}>
                <Typography sx={{...commonStyles.header, color: selectedTheme.palette.primary[700]}} variant={'h1'}>Share My Data</Typography>
                <BorderLinearProgress variant="determinate" value={60} bgcolor={selectedTheme.palette.primary[500]}/>
                <Typography sx={{ 
                    ...commonStyles.subHeader,
                     mb: 1,
                     ...selectedTheme?.typography?.body?.default,
                     ...selectedTheme?.typography?.body?.body3,
                     ...selectedTheme?.typography?.body?.regular,
                     color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                }}
                >
                    How would you like to share your data?
                </Typography>
                <Typography sx={{
                    ...styles.text,
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body1,
                    ...selectedTheme?.typography?.body?.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                }}>
                    Need help deciding how to share? &nbsp;&nbsp;
                    <Link sx={{
                        ...styles.link(selectedTheme),
                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                    }} href="#">
                        Learn more about your options
                    </Link>
                </Typography>
                {Children.toArray(
                    rows.map((x: IShareMethod, index: number) => {
                        const isSelected = methodSelected === x.value;
                        let paperColor = isSelected
                            ? selectedTheme?.paper?.card?.active?.background
                            : selectedTheme?.paper?.card?.default?.background;
                        paperColor = x.disabled 
                            ? selectedTheme?.paper?.card?.disabled?.background
                            : paperColor;
                        return (
                            <Paper
                                elevation={isSelected ? 6 : 0}
                                sx={{
                                    ...styles.paper,
                                    backgroundColor: paperColor,
                                    borderColor: selectedTheme?.paper?.card?.default?.borderColor,
                                    "&:hover": {
                                        ...(x.disabled 
                                                ? {...selectedTheme?.paper?.card?.disabled} 
                                                : {...selectedTheme?.paper?.card?.active}
                                            )
                                    }
                                }}
                                className={!x.disabled ? "cursor-pointer" : ""}
                                onClick={() => !x.disabled && setMethodSelected(x.value)}
                            >
                                <Box sx={styles.paperBox}>
                                    <Box sx={{ width: '100%' }}>
                                        {loading ? (
                                            <Fragment>
                                                <Skeleton
                                                    width={'80%'}
                                                    sx={styles.paperHeader}
                                                ></Skeleton>
                                                <Skeleton
                                                    width={'80%'}
                                                    sx={styles.paperSubHeader}
                                                ></Skeleton>
                                                <Skeleton
                                                    width={'80%'}
                                                    sx={styles.paperSubHeader}
                                                ></Skeleton>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Typography
                                                    sx={{
                                                        ...styles.paperHeader,
                                                        ...selectedTheme?.typography?.body?.default,
                                                        ...selectedTheme?.typography?.body?.body1,
                                                        ...selectedTheme?.typography?.body?.semibold,
                                                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800] 
                                                    }}
                                                >
                                                    {x.header}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        ...styles.paperSubHeader,
                                                        ...selectedTheme?.typography?.body?.default,
                                                        ...selectedTheme?.typography?.body?.body1,
                                                        ...selectedTheme?.typography?.body?.regular,
                                                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700] 
                                                    }}
                                                >
                                                    {x.subHeader}
                                                </Typography>
                                            </Fragment>
                                        )}
                                    </Box>
                                    {isSelected ? (
                                        <CheckCircleOutlineOutlined
                                            style={{ color: selectedTheme?.palette?.primary[400] }}
                                        />
                                    ) : (
                                        <CircleOutlined
                                            style={{ color:  selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600] }}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        );
                    })
                )}
                <Box sx={commonStyles.footer}>
                    <Button
                        color="secondary"
                        size='large'
                        variant={'text'}
                        disabled={false}
                        onClick={cancelClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={disableButton ? "inherit" : "primary"}
                        size='large'
                        disabled={disableButton}
                        onClick={previousClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Back
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color={disableButton ? "inherit" : "primary"}
                        size={'large'}
                        disabled={disableButton}
                        onClick={nextClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
