export const GET_HEALTH_PATHWAY_LOADING = 'GET_HEALTH_PATHWAY_LOADING';
export const GET_HEALTH_PATHWAY_SUCCESS = 'GET_HEALTH_PATHWAY_SUCCESS';
export const GET_HEALTH_PATHWAY_ERROR = 'GET_HEALTH_PATHWAY_ERROR';

export const GET_HEALTH_PATHWAY_BY_ID_LOADING = 'GET_HEALTH_PATHWAY_BY_ID_LOADING';
export const GET_HEALTH_PATHWAY_BY_ID_SUCCESS = 'GET_HEALTH_PATHWAY_BY_ID_SUCCESS';
export const GET_HEALTH_PATHWAY_BY_ID_ERROR = 'GET_HEALTH_PATHWAY_BY_ID_ERROR';

export const GET_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING = 'GET_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING';
export const GET_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS = 'GET_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS';
export const GET_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR = 'GET_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR';

export const UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING = 'UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING';
export const UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS = 'UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS';
export const UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR = 'UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR';