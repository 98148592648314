import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { ResponsivePie } from '@nivo/pie';
import { toHoursAndMinutes } from 'util/date.utils';
import { ThemeContext } from 'util/themes';

import starIcon from 'assets/images/icons/star.svg';

interface IPathwayPie {
    percentComplete: number;
    projectCompletionTime?: number;
    sx?: any;
    isHealthMode?: boolean;
}

interface IPathwayPieFilling {
    percentComplete: number;
    projectCompletionTime?: number;
    sx?: any;
    isHealthMode?: boolean;
}

const margin = { top: 0, right: 0, bottom: 0, left: 0 };

const styles = {
    root: {
        backgroundColor: 'unset',
        boxShadow: 'none',
        textAlign: 'left',
        position: 'relative',
        p: 1,
        height: {
            xs: 130,
            sm: 130,
            md: 130,
            lg: 150,
            xl: 200
        },
    } as const,
    overlay: {
        p: 4,
        position: 'absolute',
        color: '#34918E',
        top: 0,
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '30px',
        right: margin.right,
        bottom: 0,
        left: margin.left,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        // This is important to preserve the chart interactivity
        pointerEvents: 'none',
    },
    totalLabel: {
        fontSize: { xs: '14px', md: '24px' },
        fontWeight: 'bold',
        color: '#202124',
    },
};

function getMdiIcon () {
    return (<Icon icon="mdi:diabetes" style={{width: '80px', height: '80px'}} color="#285c6c" />)
}

function getCompleteIncompleteText(percentComplete: number, sx: any, innerText: any) {
    if (percentComplete !== 100) {
        return (
            <>
                <Typography sx={{...styles.totalLabel, ...sx}}>{innerText?.topText}</Typography>
                <Typography sx={{...styles.totalLabel, ...sx}}>{innerText?.bottomText}</Typography>
            </>
        )
    } else {
        return (<img src={starIcon} style={{width: '53.33px', height: '50.67px'}}/>)
    }
}

function PathwayPieFilling(props: IPathwayPieFilling) {
    const { percentComplete, projectCompletionTime, sx } = props;
    const [innerText, setInnerText] = useState<any>({
        topText: '',
        bottomText: '',
    });

    useEffect(() => {
        if (percentComplete === 100) {
            setInnerText({ topText: 'Goal', bottomText: 'Achieved!' });
        } else if (percentComplete >= 75 && percentComplete <= 99) {
            setInnerText({ topText: 'Almost', bottomText: 'There!' });
        } else if (percentComplete >= 50 && percentComplete < 76) {
            setInnerText({ topText: 'Awesome', bottomText: 'Progress' });
        } else if (percentComplete >= 25 && percentComplete < 50) {
            setInnerText({ topText: 'Keep', bottomText: 'Going!' });
        } else if (percentComplete >= 1 && percentComplete < 25) {
            setInnerText({ topText: 'Great', bottomText: 'Start!' });
        } else {
            setInnerText({ topText: toHoursAndMinutes(projectCompletionTime) });
        }
    }, [percentComplete, projectCompletionTime]);

    return (
        <Box sx={styles.overlay}>
            {getCompleteIncompleteText(percentComplete, sx, innerText)}
        </Box>
    );
}

export function PathwayPie(props: IPathwayPie) {
    const { percentComplete, projectCompletionTime = 0, sx, isHealthMode = false } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const getData = () => {
        if (!percentComplete) {
            return [{ id: 'x', value: 100 }];
        } else {
            return [
                { id: 'y', value: 100 - percentComplete },
                { id: 'xAxis', value: percentComplete },
            ];
        }
    };
    return (
        <Box sx={styles.root}>
            <ResponsivePie
                startAngle={360}
                endAngle={-360}
                margin={margin}
                data={getData()}
                innerRadius={0.85}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                defs={[
                    {
                        id: 'gray',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: '#F0F0F0' },
                            { offset: 100, color: '#E8E8E8' },
                        ],
                    },
                    {
                        id: 'blueGreen',
                        type: 'linearGradient',
                        gradientTransform: 'rotate(180 0.5 0.5)',
                        colors: [
                            { offset: 0, color: selectedTheme?.palette?.pieColor?.primary || '#C2F5E0', opacity: 100 },
                            { offset: 100, color: selectedTheme?.palette?.pieColor?.secondary || '#69D7D2', opacity: 100 },
                        ],
                    },
                ]}
                fill={[
                    { match: { id: 'xAxis' }, id: 'blueGreen' },
                    { match: '*', id: 'gray' },
                ]}
            />
            <PathwayPieFilling
                percentComplete={percentComplete}
                projectCompletionTime={projectCompletionTime}
                sx={sx}
                isHealthMode={isHealthMode}
            />
        </Box>
    );
}
