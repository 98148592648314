import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const vaccinationState = (state: RootState) => state.profile.profileVaccinations;

const selectVaccinationState = createSelector([vaccinationState], (state) => {
    return state;
});

export const selectVaccinationLoading = createSelector(selectVaccinationState, (state) => {
    return state.loading;
});

export const selectVaccinationData = createSelector(selectVaccinationState, (state) => {
    return state.data;
});

export const selectVaccinationError = createSelector(selectVaccinationState, (state) => {
    return state.error;
});
