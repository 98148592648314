import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { commaSeparatedLocalDate } from 'util/date.utils';

const allergyState = (state: RootState) => state.healthDashboard.allergy;

const selectAllergyState = createSelector([allergyState], (state) => {
    return state;
});

export const selectAllergyLoading = createSelector(selectAllergyState, (state) => {
    return state.loading;
});

export const selectAllergyData = createSelector(selectAllergyState, (state) => {

    let formattedData = state?.data?.map((item: any) => {
        return {
            'assetId': item.asset_id,
            'userId': item.userId,
            'label': 'Last Major Reaction',
            'value': item.allergy,
            'assetType' : item.assetType,
            'subHeading': (item?.lastReaction?.$date &&
                commaSeparatedLocalDate(item?.lastReaction)) || '',
            "verificationStatus": item.verificationStatus,
            "verificationCompany" : item?.verificationCompany || '',
            "verifier" : item?.verifier || '',
            "verificationTimestamp" : item?.verificationTimestamp?.$date || '',
            "verificationAssetId" : item?.verificationAssetId || '',
            "verificationHash" : item?.verificationHash || '',
            "verificationSignature" : item?.verificationSignature || '',
        }
    }) || [];

    return formattedData;
});

export const selectAllergyError = createSelector(selectAllergyState, (state) => {
    return state.error;
});