import { Children, useState, useEffect, useContext } from 'react';
import {
    Box,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    Typography,
    Tooltip,
    Grid
} from '@mui/material';
import { Icon as ReactIcons } from '@iconify/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
    ILifeGraphVerification,
    IReduxDataConfig,
} from '../../@types/profile-types';
import { Loader } from 'lib/components/Loader/BarSkeleton';
import { useTheme } from '@mui/material/styles';
import poweredByLogo from 'assets/images/logos/lifeGraph_poweredby.png';
import {
    styleByStatus,
    styles,
    getListitemByStatus,
} from './VerificationStatus.helper';
import { capitalizeFirstLetter } from 'util/string.util';
import { stringToDateTime } from 'util/date.utils';

import { Button } from '../../lib/controls';
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from 'util/themes/themeContext';
import { getConfig } from 'redux/helpers/profile.helpers';
import { useDispatch } from 'react-redux';
import { HealthConstants } from 'app-constants';
import { profileActionCreators, healthDashboardActionCreators } from 'redux/actionCreators';
import { getVerificationComponent } from './VerificationComponent';

interface IVerificationStatusComponent {
    data: ILifeGraphVerification;
    loading?: boolean;
    selectedTheme?: any;
    verificationDetail?: any;
}

interface IVerificationStatusContent {
    data: any;
    selectedTheme: any;
    onRequestVerificationClick: Function;
}

interface IRequestVerification {
    data: any;
    close: Function;
    verificationDetail?: any;
}

interface IContractStub {
    assetType: string;
    attributes: string[];
    expirationDate?: string;
    sharedWith: string;
}

const RequestVerification = (props: IRequestVerification) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { data, close, verificationDetail } = props;
    const [contractStub, setContractStub] = useState<IContractStub>();
    const [assetType, setAssetType] = useState<string[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        axios()
            .get(
                `/v2/${data.assetType.toLowerCase()}/verification/contract-stub`,
                withCredentials()
            )
            .then((resp) => {
                setContractStub(resp?.data?.data);
            });
    }, []);

    const submitVerificationRequest = async () => {
        const body = {
            source: 'verification request',
            asset_id: data.asset_id || data.assetId,
        };

        await axios()
            .post(
                `/v2/${data.assetType.toLowerCase()}/verification/verification-request`,
                body,
                withCredentials()
            )
            .then(() => {
                const config = getConfig(
                    `${data.assetType}`
                ) as IReduxDataConfig;

                switch(data.assetType){
                    case HealthConstants.ALLERGY_INFO : 
                        dispatch(healthDashboardActionCreators.getHealthDashboardAllergyList());
                    break;
                    case HealthConstants.MEDICATION_INFO : 
                        dispatch(healthDashboardActionCreators.getHealthDashboardMedicationList());
                    break;
                    case HealthConstants.VACCINATION_INFO : 
                        dispatch(healthDashboardActionCreators.getHealthDashboardVaccinationList());
                    break;
                    default : 
                        dispatch( profileActionCreators.getPersonalDynamicCardsInfo(config));
                    
                }

                close();
            });
    };

    const handleSubmitClick = () => {
        submitVerificationRequest();
    };

    const handleCancelClick = () => {
        close();
    };

    return (
        <Box>
            <DialogTitle sx={{ p: 0 }}>
                <Box
                    sx={{
                        px: 3,
                        paddingTop: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h4" sx={{ letterSpacing: 0 }}>
                        Review Verification Request
                    </Typography>
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={handleCancelClick}
                    />
                </Box>
                <hr></hr>
            </DialogTitle>

            <Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant="body1">
                        Please review the data below. This data will be shared
                        with a third-party verification company for
                        authentication. To make changes, click "cancel".
                    </Typography>
                </Box>

                {
                    getVerificationComponent(
                        verificationDetail?.key || '',
                        { ...verificationDetail?.data, hideVerification: true, canEdit: false },
                        contractStub?.sharedWith || '',
                        contractStub?.attributes || []
                    )
                }

                <Box
                    sx={{
                        px: 2,
                        my: 2,
                        display: 'flex',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        size="medium"
                        onClick={handleCancelClick}
                        variant={'text'}
                        color="secondary"
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        size="medium"
                        onClick={handleSubmitClick}
                        variant="contained"
                        color="primary"
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                            }}
                        >
                            Submit Request
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const VerificationStatusContent = (props: IVerificationStatusContent) => {
    const { data, selectedTheme, onRequestVerificationClick } = props;
    const listItemToShown = getListitemByStatus(data.verificationStatus);
    const [isProofOpen, setIsProofOpen] = useState(false);
    const qrCode =  listItemToShown.filter(row => row.valueAttribute === 'qrCode');

    const onClick = (a: any) => {
        if (a.target?.textContent === 'Show Proof') {
            setIsProofOpen(!isProofOpen);
            const find = listItemToShown.find((x) => x.label === 'Show Proof');
            if (find) {
                find.label = 'Hide Proof';
            }
        }
        if (a.target?.textContent === 'Hide Proof') {
            setIsProofOpen(!isProofOpen);
            const find = listItemToShown.find((x) => x.label === 'Hide Proof');
            if (find) {
                find.label = 'Show Proof';
            }
        }
    };

    const body2Style = {
        ...selectedTheme?.typography?.body?.default,
        ...selectedTheme?.typography?.body?.body2,
        ...selectedTheme?.typography?.body?.regular,
        color:
            selectedTheme?.palette?.secondary?.grayScale &&
            selectedTheme?.palette?.secondary?.grayScale[800],
    };

    const showListItem = (items : any) => {

        return Children.toArray(
            listItemToShown.filter(row => items.includes(row.valueAttribute)).map((row) => {
                return row.type === 'proof' && !isProofOpen ? (
                    <Box component={'span'} />
                ) : (
                        <>
                        <ListItem>
                            <ListItemText
                                onClick={onClick}
                                disableTypography
                                primary={
                                    <Typography
                                        sx={row.labelStyle(
                                            selectedTheme
                                        )}
                                    >
                                        {row.label}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={row.valueStyle(
                                            selectedTheme
                                        )}
                                    >
                                        {row.label === 'When'
                                            ? stringToDateTime(
                                                data[
                                                row
                                                    .valueAttribute
                                                ]
                                            )
                                            : data[
                                            row.valueAttribute
                                            ]}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            {data.verificationStatus ===
                                'unverified' ? (
                                <Box
                                    sx={{
                                        px: 2,
                                        mx: 'auto',
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            onRequestVerificationClick()
                                        }
                                        color="primary"
                                        size="medium"
                                        sx={{
                                            borderRadius: '4px',
                                            py: '0.25rem',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ...selectedTheme
                                                    ?.typography
                                                    ?.Components
                                                    ?.button
                                                    ?.default,
                                                ...selectedTheme
                                                    ?.typography
                                                    ?.Components
                                                    ?.button
                                                    ?.medium,
                                                textTransform:
                                                    'none',
                                            }}
                                        >
                                            REQUEST VERIFICATION
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </ListItem>
                    </>
                );
            })
        )
    }

    return (
        <>
            <DialogContent sx={{ p: 0 }}>
                <List dense={true}>
                    {showListItem(['verificationMessage', 'unverified', 'pending'])}
                    {
                        qrCode.length > 0 ?
                            <ListItem sx={{p : 0, m: 0}}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {showListItem(['verificationCompany', 'verifier', 'verificationTimestamp'])}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img src={data[qrCode[0].valueAttribute]} alt="" />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        : showListItem(['verificationCompany', 'verifier', 'verificationTimestamp'])
                    }
                    {showListItem(['whyTrust', 'showProof', 'verification', 'verificationAssetId', 'verificationHash', 'verificationSignature'])}
                    {isProofOpen ? (
                        <ListItem
                            key={`aaaaa`}
                            sx={{ px: 3, flexDirection: 'column', gap: 1 }}
                        >
                            <ListItemText
                                sx={body2Style}
                                disableTypography={true}
                            >
                                A <b>Proof</b> is all the information you need
                                to verify that your information is accurate and
                                secure.
                            </ListItemText>
                            <ListItemText
                                sx={body2Style}
                                disableTypography={true}
                            >
                                An <b>Asset ID</b> is a string of characters
                                that represents a piece of data. This ID should
                                never change even as your data evolves.
                            </ListItemText>
                            <ListItemText
                                sx={body2Style}
                                disableTypography={true}
                            >
                                A <b>Hash</b> is another string of characters
                                that is generated to represent a piece of data.
                                Unlike the Asset ID, a hash will change when
                                data is altered.
                            </ListItemText>
                            <ListItemText
                                sx={body2Style}
                                disableTypography={true}
                            >
                                A <b>Signature</b> is verification that a hash
                                is legitimate, and will also change when that
                                data is altered. Together, the hash and
                                signature are relied upon to prove that the data
                                has, or hasn't been, altered.
                            </ListItemText>
                        </ListItem>
                    ) : (
                        <></>
                    )}
                </List>
            </DialogContent>
        </>
    );
};

const VerificationStatus = (props: IVerificationStatusComponent) => {
    const { loading, data, selectedTheme, verificationDetail } = props;

    const status = data.verificationStatus;

    if (!status) {
        return <></>;
    }
    if (loading) {
        return <Loader width={20} />;
    }

    const { icon, color, toolTip } = styleByStatus[status];
    const themeStatusColor = {
        pending: {
            color: selectedTheme?.palette?.secondary[200],
        },
        verified: {
            color:
                selectedTheme?.palette?.tertiary &&
                selectedTheme?.palette?.tertiary?.green[400],
        },
        unverified: {
            color:
                selectedTheme?.palette?.secondary?.grayScale &&
                selectedTheme?.palette?.secondary?.grayScale[700],
        },
    };
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showRequestVerification, setShowRequestVerification] =
        useState(false);

    const showVerificationPopUp = () => {
        setOpen(true);
    };

    const closeVerificationPopUp = () => {
        setOpen(false);
        setShowRequestVerification(false);
    };

    const onRequestVerificationClick = () => {
        setShowRequestVerification(true);
    };

    return (
        <Box>
            <Tooltip title={toolTip} arrow>
                <ReactIcons
                    icon={icon}
                    color={themeStatusColor[status].color || color}
                    fontSize="24"
                    data-testid="status-icon-holder"
                    className="cursor-pointer"
                    onClick={(e) => showVerificationPopUp()}
                />
            </Tooltip>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                fullScreen={fullScreen}
                open={open}
                onClose={closeVerificationPopUp}
                aria-labelledby="responsive-dialog-title"
            >
                {!showRequestVerification ? (
                    <>
                        <DialogTitle sx={{ p: 0 }}>
                            <Box
                                sx={{
                                    px: 3,
                                    paddingTop: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{ letterSpacing: 0 }}
                                >
                                    Verification Details
                                </Typography>
                                <CloseIcon
                                    className="cursor-pointer"
                                    onClick={closeVerificationPopUp}
                                />
                            </Box>
                            <hr></hr>
                        </DialogTitle>
                        <Box
                            sx={{
                                pt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <ReactIcons
                                icon={icon}
                                color={themeStatusColor[status].color || color}
                                fontSize="28"
                                key={'inside_icon'}
                            />
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.overlines
                                        ?.overline1?.default,
                                    ...selectedTheme?.typography?.overlines
                                        ?.overline1?.medium,
                                    color:
                                        selectedTheme?.palette?.secondary &&
                                        selectedTheme?.palette?.secondary
                                            ?.grayScale[600],
                                }}
                            >
                                {capitalizeFirstLetter(
                                    data.verificationStatus || ''
                                )}
                            </Typography>
                        </Box>
                        <VerificationStatusContent
                            data={data}
                            selectedTheme={selectedTheme}
                            onRequestVerificationClick={
                                onRequestVerificationClick
                            }
                        />

                        <DialogActions sx={{ pt: 1.25, pr: 2, pb: 2, pl: 0 }}>
                            <img
                                src={poweredByLogo}
                                alt="Powered By LifeGraph Logo"
                                style={styles.poweredByLogo}
                            />
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <RequestVerification
                            data={data}
                            close={closeVerificationPopUp}
                            verificationDetail={verificationDetail}
                        />
                    </>
                )}
            </Dialog>
        </Box>
    );
};

export default VerificationStatus;
