import {
    Typography,
    Box,
    Grid,
    useMediaQuery,
    useTheme,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { commonStyles } from './share-my-data.helper';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BorderLinearProgress } from 'components';
import { useForm } from 'react-hook-form';
import { Controls } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataSelector } from 'redux/selectors';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { shareMyDataActionTypes } from 'redux/actionsTypes';

import { defaultDate as todayDate, stringToUTCDate} from '../../util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

interface IFormInputs {
    expirationDate: string;
}

const styles = {
    formControl: {
        width: '300px',
        display: 'block',
        mb: 3,
    },
    formLabel: {
        fontSize: '14px',
    },
    formInput: {
        style: {
            p: '8px 12px',
        },
    },
    switchGroup: {
        mb: 4,
    },
};

export function ShareMyDataWhenComponent() {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);
    
    const inputStyleProp = {
        ...styles.formInput,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };
    const labelStyleProp = {
        ...styles.formLabel,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [expirationDate, setExpirationDate] = useState<string>();
    const width = isMobile ? '92%' : '500px';

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        }
    }, [loading]);

    const defaultDate = shareObject.until ? shareObject.until : '';

    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm<IFormInputs>({
        defaultValues: { expirationDate: defaultDate },
    });

    const watchExpiration = watch('expirationDate');
    if (watchExpiration && watchExpiration !== expirationDate) {
        setExpirationDate(watchExpiration);
    }

    const switchToggle = (event: any) => {
        if (event.target.checked) {
            setExpirationDate('');
            setValue('expirationDate', '');
        }
    };

    const nextClicked = (e: any) => {
        shareObject.until = expirationDate ? stringToUTCDate(expirationDate) : null;
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        history.push('/share-my-data/review');
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };
    
    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width }}>
                <Typography sx={{...commonStyles.header, color: selectedTheme.palette.primary[700]}} variant='h1'>Share My Data</Typography>
                <BorderLinearProgress variant="determinate" value={80} bgcolor={selectedTheme.palette.primary[500]}/>
                <Typography sx={{
                        ...commonStyles.subHeader,
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body3,
                        ...selectedTheme?.typography?.body?.regular,
                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                    }}
                >
                    How long do you want to share your data?
                </Typography>
                <form id="expiration_form">
                    <Controls.Input
                        name="expirationDate"
                        label="Exact Date"
                        defaultValue={''}
                        type="date"
                        control={control}
                        error={errors?.expirationDate || ''}
                        labelStyles={labelStyleProp}
                        inputStyleProps={inputStyleProp}
                        inputProps={{min: todayDate()}}
                        sx={{...selectedTheme?.input, ...styles.formControl}}
                    />
                </form>
                <FormGroup sx={styles.switchGroup}>
                    <FormControlLabel
                        control={
                            <Switch
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: selectedTheme?.palette?.primary[400]
                                      },
                                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: selectedTheme?.palette?.primary[400],
                                      },
                                }}
                                onChange={switchToggle}
                                checked={expirationDate ? false : true}
                            />
                        }
                        label="No Expiration"
                        disableTypography
                        sx={{ 
                            width: 'max-content',
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                        }}
                    />
                </FormGroup>
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color='secondary'
                        size='large'
                        onClick={cancelClicked}>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large
                            }}>
                                Cancel
                            </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color='primary'
                        size='large'
                        onClick={previousClicked}>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Back
                            </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color={"primary"}
                        size={'large'}
                        onClick={nextClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
