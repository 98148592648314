import { useContext } from 'react';
import { Grid } from '@mui/material';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes';
import { formSchemaResolver } from './allergy.helper';
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { modalStyles } from '../health-dashboard.helper';
import { ModalFormWrapper } from '../HealthDashboardModalWrapper';

interface IAllergyFormInputs {
    allergy: string;
    lastReaction: string;
}

const styles = {
    controlPadding: { },
    inputWidth: {
        width: '100%'
    },
    labelStyles: { }
};

export const AllergyForm = (props:any) => {
    const { modalOpen, selectedData = {}, isEdit = false} = props;
    const { selectedTheme } = useContext(ThemeContext);
    const copyData = {...selectedData};
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IAllergyFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: copyData
    });

    const submitForm = async (formValues: any) => {
        try {
            const requestData = {
                allergy: formValues.allergy,
                lastReaction: stringToUTCDate(formValues.lastReaction),
                userId: selectedData.userId
            };
        
            if(selectedData.assetId) {
                await axios().put(`/v2/allergy/${selectedData.assetId}`, requestData, withCredentials());
            } else {
                delete requestData.userId;
                await axios().post(`/v2/allergy`, requestData, withCredentials())
            }
            dispatch(healthDashboardActionCreators.getHealthDashboardAllergyList());
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ModalFormWrapper
            formId='allergy'
            modalOpen={modalOpen}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            title='Allergy'
            handleModalClose={props.handleModalClose}
            onSubmitForm={submitForm}
        >
            <>
                <Grid item xs={12}>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item sx={styles.inputWidth}>
                            <Controls.Input
                                name="allergy"
                                label="Allergy"
                                defaultValue={copyData.allergy}
                                control={control}
                                error={errors?.allergy || ''}
                                inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                                sx={{...selectedTheme?.input}}
                                labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item sx={styles.inputWidth}>
                        <Controls.Input
                            name="lastReaction"
                            label="Date of Last Reaction"
                            type="date"
                            defaultValue={copyData.lastReaction}
                            control={control}
                            error={errors?.lastReaction || ''}
                            inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                            sx={{...selectedTheme?.input}}
                            labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                            required
                        />
                    </Grid>
                </Grid>
            </>
        </ModalFormWrapper>
    )
}