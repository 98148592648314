import React from 'react';
import { Box } from '@mui/material';
import { ProfileInfoRowComponent, ProfileInfoRowVaraint2Component } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { ProfileConstants } from 'app-constants';
import { IProfileEmploymentObject } from '../../../@types/profile-types';
import { convertDateToMonthYearDate } from 'util/date.utils';
import {CommonDateElement} from '../helper';

const convertObjectToProfileRowVariant = (object: IProfileEmploymentObject, selectedTheme: any) => {
    const header = object.title;
    const subHeader = object.organizationName || undefined;
    const endDate =
        convertDateToMonthYearDate(object.endDate?.$date) ?? 'Present';
    const startDate = convertDateToMonthYearDate(object.startDate?.$date);
    const workBodyList = [];
    if (startDate) {
        workBodyList.push(
            <CommonDateElement selectedTheme={selectedTheme} startDate={startDate} endDate={endDate} />
        );
    }
    return { header, subHeader, workBodyList };
};

export const WorkComponent = (props: any) => {
    const {
        employmentLoading,
        data,
        canEdit,
        handleModalOpen,
        hideVerification = false,
        selectedTheme
    } = props

    const row =
        convertObjectToProfileRowVariant(data, selectedTheme);
    return (
        <Box className="flex-column full-width">
            <ProfileInfoRowVaraint2Component
                isLoading={employmentLoading}
                header={row.header}
                subHeader={row.subHeader}
                bodyDataList={row.workBodyList}
                selectedTheme={selectedTheme}
                objectId={data.asset_id}
                onEditClicked={handleModalOpen}
                canEdit={
                    canEdit
                        ? 'ACTIVE'
                        : 'HIDDEN'
                }
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                verificationDetail={{key: ProfileConstants.WORK_INFO, data: props}}
                hideVerification={hideVerification}
            />
            {!employmentLoading &&
            data.department ? (
                <ProfileInfoRowComponent
                    isLoading={
                        employmentLoading
                    }
                    selectedTheme={selectedTheme}
                    label="Department"
                    value={data.department}
                    canEdit={'HIDDEN'}
                />
            ) : (
                <></>
            )}
            <ProfileInfoRowComponent
                isLoading={employmentLoading}
                label="Employer Address"
                selectedTheme={selectedTheme}
                value={getSingleLineAddressFromObject(
                    data
                )}
                canEdit={'HIDDEN'}
            />
        </Box>
    );
}
