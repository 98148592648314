import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { ILookup } from '../../../@types/profile-types';

const phoneLookupState = (state: RootState) => state.profileLookups.phoneLookup;

const selectPhoneLookupState = createSelector([phoneLookupState], (state) => {
    return state;
});

export const selectPhoneLookupLoading = createSelector(selectPhoneLookupState, (state) => {
    return state.loading;
});

export const selectPhoneLookupData = createSelector(selectPhoneLookupState, (state) => {
    const copy: ILookup[] = JSON.parse(JSON.stringify(state.data));
    copy.push({ value: '__other__', display: 'Other' });
    return copy;
});

export const selectPhoneLookupError = createSelector(selectPhoneLookupState, (state) => {
    return state.error;
});
