import { useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Skeleton,
} from '@mui/material';
import { baseTheme } from 'util/themes';
import { Icon } from '@iconify/react';
import { Button } from 'lib/controls';
import {
    accountCheck,
} from 'components/Pathway/pathwayIcons';
import {
    Close as CloseIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { healthPathwayActionCreators } from 'redux/actionCreators';
import { healthPathwaySelector } from 'redux/selectors';
import { toHoursAndMinutes } from '../../../util/date.utils';

interface IProps {
    currentArticleId: string;
}

const styles = {
    paper: {
        borderRadius: '12px',
        ml: { xs: 0, md: 3 },
        borderTop: '12px solid #69D7D2'
    },
    title: {
        color: '#293541',
        fontSize: { xs: '20px', md: '24px' },
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '36px',
    },
    label: {
        color: '#47515A',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: baseTheme.spacing(3),
        display: 'block',
        textTransform: 'uppercase',
    },
    subTitle: {
        color: '#47515A',
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '0.1px',
        lineHeight: '19px',
        marginTop: '5px',
    },
    listItem: {
        fontSize: '16px',
        color: '#202124',
        fontWeight: 600,
    },
    disableListItem: {
        borderTop: '1px solid #BDC1C5',
        backgroundColor: '#f5f5f5',
        opacity: '0.6',
        PointerEvent: 'none',
    },
    enableListItem: {
        borderTop: '1px solid #BDC1C5',
        opacity: '1',
    },
    button: {
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '26px',
        textTransform: 'uppercase',
    },
    itemType: {
        ml: 1,
        fontSize: 'small',
        fontWeight: 100,
    },
    paragraph: {
        whiteSpace: 'break-spaces',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 2
    },
    completed: {
        display: 'flex',
        justifyContent: 'center',
    },
    completedText: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        color: '#47515A',
        marginLeft: 1,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    }
};

export function HealthActivity(props: IProps) {
    const { currentArticleId } = props;
    const history = useHistory();
    const articleLoading = useSelector(healthPathwaySelector.selectedHealthPathwayArticleLoading);
    const article = useSelector(healthPathwaySelector.selectedHealthPathwayArticleStep);
    const dispatch = useDispatch();
    const noData = !articleLoading && !article;

    useEffect(() => {
        dispatch(
            healthPathwayActionCreators.getHealthPathwayArticleById(
                currentArticleId
            )
        );
    }, [currentArticleId]);

    const removeSelectedLesson = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('article_id');
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
    };

    const handleComplete = () => {
        dispatch(healthPathwayActionCreators.updateHealthPathwayArticleById(currentArticleId))
    }

    const getArticleStatusHolder = (currentArticle: any) => {
        if (!currentArticle?.completed) {
            return <Button variant='contained' sx={styles.button} onClick={handleComplete}>Complete</Button>
        } else {
            return (
                <Box sx={styles.completed}>
                    <Icon icon={accountCheck} color="#2FC89A" height={32} width={32}/>
                    <Typography sx={styles.completedText}>completed</Typography>
                </Box>
            )
        }
    }

    return (
        <>
            <Paper elevation={2} sx={styles.paper}>
                <Box sx={{ p: 2 }}>
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={removeSelectedLesson}
                        sx={{ float: 'right' }}
                    />
                    {noData ?
                        <>
                            <Typography
                                // variant="overline"
                                component="div"
                                sx={styles.title}
                            >
                                No Data Available for selected article
                            </Typography>
                        </> :
                        <>
                            <Typography
                                variant="overline"
                                component="div"
                                sx={styles.label}
                            >
                                {articleLoading ? <Skeleton width={150} height={40}/> : article?.type || ''}
                            </Typography>
                            <Typography
                                // variant="overline"
                                component="div"
                                sx={styles.title}
                            >
                                {articleLoading ? <Skeleton width={500} height={40}/> : article?.title || ''}
                            </Typography>
                            <Typography
                                component="div"
                                sx={styles.subTitle}
                            >
                                {articleLoading ?
                                    <Skeleton width={150} height={40}/> :
                                    `${toHoursAndMinutes(article?.projectCompletionTime)} ${article?.projectCompletionTime ? 'read' : ''}`
                                }
                            </Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: {xs: 'column', md: 'row'},
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="body1" sx={styles.paragraph}>
                                        {articleLoading ? <Skeleton variant="rectangular" width={700}
                                                                    height={200}/> : article?.articleContent || ''}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={styles.footer}>
                                {articleLoading ?
                                    (<Skeleton width={150} height={70}/>) : 
                                        getArticleStatusHolder(article)
                                }
                            </Box>
                        </>
                    }
                </Box>
            </Paper>
        </>
    );
}