import React from 'react';
import { Box } from '@mui/material';
import { ProfileInfoRowComponent, ProfileInfoRowVaraint2Component } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { ProfileConstants } from 'app-constants';
import { IProfileVolunteer } from '../../../@types/profile-types';
import { convertDateToMonthYearDate } from 'util/date.utils';
import {CommonDateElement} from '../helper';

const convertObjectToProfileRowVariant = (object: IProfileVolunteer, selectedTheme: any) => {
    const header = object.title;
    const subHeader = object.organizationName || undefined;
    const startDate = convertDateToMonthYearDate(object.startDate?.$date);
    const endDate =
        convertDateToMonthYearDate(object.endDate?.$date) ?? 'Present';
    const bodyList = [];
    if (startDate) {
        bodyList.push(
            <CommonDateElement selectedTheme={selectedTheme} startDate={startDate} endDate={endDate} />
        );
    }
    return { header, subHeader, bodyList };
};

export const VolunteerComponent = (props: any) => {
    const {
        volunteerLoading,
        data,
        canEdit,
        handleModalOpen,
        hideVerification = false,
        selectedTheme
    } = props

    const row = convertObjectToProfileRowVariant(data, selectedTheme);
    return (
        <Box className="flex-column full-width">
            <ProfileInfoRowVaraint2Component
                isLoading={volunteerLoading}
                header={row.header}
                subHeader={row.subHeader}
                selectedTheme={selectedTheme}
                bodyDataList={row.bodyList}
                objectId={data.asset_id}
                onEditClicked={handleModalOpen}
                canEdit={
                    canEdit ? 'ACTIVE' : 'HIDDEN'
                }
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                verificationDetail={{key: ProfileConstants.VOLUNTEER_INFO, data: props}}
                hideVerification={hideVerification}
            />
            {!volunteerLoading && data.department ? (
                <ProfileInfoRowComponent
                    isLoading={volunteerLoading}
                    label="Department"
                    selectedTheme={selectedTheme}
                    value={data.department}
                    canEdit={'HIDDEN'}
                />
            ) : (
                <></>
            )}
            {!volunteerLoading && data.hours ? (
                <ProfileInfoRowComponent
                    isLoading={volunteerLoading}
                    label="Hours"
                    selectedTheme={selectedTheme}
                    value={data.hours.toString()}
                    canEdit={'HIDDEN'}
                />
            ) : (
                <></>
            )}
            <ProfileInfoRowComponent
                isLoading={volunteerLoading}
                label="Organization Address"
                selectedTheme={selectedTheme}
                value={getSingleLineAddressFromObject(
                    data
                )}
                canEdit={'HIDDEN'}
            />
        </Box>
    );
}
