import {
    OnboardingComponent,
    IOnboardingComponent,
} from 'components/Onboarding/Onboarding';

export function SkillsOnboardingComponent() {
    const data: IOnboardingComponent = {
        imageName: 'skills_onboarding.svg',
        redirectLink: '/learning/skills/map',
        backgroundStyle: { width: '30rem', height: '30rem', top: '5rem' },
        headerContainerStyle: { px: '4.75rem' },
        bottomBoxContainer: { px: '4.75rem', mt: '3.5rem' },
        header: `LearnIQ Skills Center`,
        headerStyle: {
            lineHeight: '3.125rem',
            pt: '9.38rem'
        },
        subHeader: `Browse the skill center and assemble your learning toolkit.`,
        subHeaderStyle: {
            lineHeight: '2.25rem',
            pb: '4.75rem'
        },
        bottomTextStyle: { lineHeight: '28px', fontSize: '18px' },
        bottomTextList: [
            `A vital part in reaching your goals is maintaining your progress. LearnIQ enables you to commit to professional growth without compromising your current career and lets you check in on your progress in one simple view. With easy access to enriching pathways, you can prove you're devoted to evolving your skillset.`,
        ],
        buttonText: `View Your Skills`,
        onboardingScreen: 'skills',
        background: 'linear-gradient(85.68deg, #FBFED2 -11.56%, #FFC4DF 48.86%, #B1ADFF 100.97%), #D2E4E5'
    };
    return <OnboardingComponent {...data} />;
}