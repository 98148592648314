import { useState, useContext } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import {
    Close as CloseIcon,
} from '@mui/icons-material';
import { INotify } from 'lib/types';
import { UseFormHandleSubmit } from 'react-hook-form';
import { ThemeContext } from 'util/themes';
import { modalStyles } from './health-dashboard.helper';

interface IModalFormWrapperProps {
    modalOpen: boolean;
    isEdit?: boolean;
    title: string;
    formId: string;
    children: React.ReactElement;
    handleModalClose: () => void;
    onSubmitForm: (formValues: any) => void;
    handleSubmit: UseFormHandleSubmit<any>;
}


export const ModalFormWrapper = (props:IModalFormWrapperProps) => {
    const { 
        modalOpen, 
        isEdit = false, 
        children,
        formId,
        title,
        onSubmitForm,
        handleModalClose,
        handleSubmit,
    } = props;
    
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const { selectedTheme } = useContext(ThemeContext);
    
    const submitForm = async (formValues: any) => {
        onSubmitForm(formValues);
        handleModalClose();
    }

    return (
        <Box>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
                sx={modalStyles.dialog}
            >
                <DialogTitle data-testid="about_form_title">
                    <Typography component={'span'} sx={{
                        letterSpacing: 0,
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {isEdit ? 'Edit' : 'Add'} {title}
                    </Typography>
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={handleModalClose}
                        sx={{ float: 'right' }}/>
                </DialogTitle>
                <DialogContent dividers={true} sx={modalStyles.content}>
                    <Grid 
                        container
                        component="form"
                        id={formId}
                        onSubmit={handleSubmit(submitForm)}
                     >
                        {children}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{padding: '11px 24px 14px'}}>
                    <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Cancel
                        </Typography>
                    </Controls.Button>
                    <Controls.Button form={formId} type="submit" variant='contained' color='primary'>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Submit
                        </Typography>
                    </Controls.Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}