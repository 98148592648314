import { SimCardDownload } from '@mui/icons-material';

const descStyle = {
    width: '302px',
    mt: 2,
    fontSize: '20px',
    lineHeight: '26px',
    color: '#47515A'
};

export const apps : any[] = [{
    heading: 'NEW !',
    subHeading: 'Well U Diabetes Management',
    subHeadingStyle: {
        width: '218px',
        fontSize: '28px',
        lineHeight: '33px',
        color: '#285C6C'
    },
    description : 'Dr. Westerman has assigned you to the Diabetes Management health pathway.',
    descriptionStyle: descStyle,
    imageName: 'diabetes-management-card.svg',
    goToLink: '/health/pathway/onboarding',
},{
    heading: 'Connected App',
    subHeading: 'Lotus Meditations',
    description : 'You are currently prescribed four meditations. View information about them and manage them here.',
    descriptionStyle: descStyle,
    buttonText: 'Continue',
    imageName: 'lotus-meditations-card.svg',
    goToLink: '/health/dashboard',
        
}]

export const itemsFirstColumn: any[] = [{
    'title': 'Allergies',
    'itemsToShow': [],
},
{
    'title': 'Medical Documents',
    'itemsToShow': [{
        'label': 'Advanced Care Directive',
        'Icon': SimCardDownload,
        'link': '/docs/AdvancedHealthCareDirectiveForm-fillable.3522860b.pdf',
    }, {
        'label': 'Medical Power of Attorney',
        "Icon": SimCardDownload,
        'link': '/docs/MedicalPowerOfAttorney.20908ff0.pdf'
    }],
}];

export const items: any[] = [
    {
        'title': 'Medications',
        'itemsToShow': [],
    },
    {
        'title': 'Vaccinations',
        'itemsToShow': []
    }
];

export const modalStyles = {
    dialog: {
        '.MuiBackdrop-root': {
            backgroundColor: 'rgba(210, 219, 222, 0.65)',
            mixBlendMode: 'normal',
            backdropFilter: 'blur(10px)'
        },
        '.MuiDialog-paper': {
            borderRadius: '10px',
            boxShadow:
                `0px 5px 5px -3px rgb(0 0 0 / 20%),
                0px 8px 10px 1px rgb(0 0 0 / 14%),
                0px 3px 14px 2px rgb(0 0 0 / 12%)`
        }
    },
    content: {
        borderTop: '1.58px solid #AABABF',
        borderBottom: '1.58px solid #AABABF'
    },
    inputStyleProp:(styles: any, selectedTheme: any) => ({
        ...styles.controlPadding,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    }),
    labelStyleProp:(styles: any, selectedTheme: any) => ({
        ...styles.labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    })
};
