import { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { CardTitleComponent } from 'components';
import { AboutForm } from './aboutForm';
import {
    citizenshipSelector,
    taxSelector,
    personalInfoSelector,
} from 'redux/selectors';
import { toLocalDateString } from 'util/date.utils';
import { citizenshipConfig, taxConfig } from './about.helper';
import { ThemeContext } from 'util/themes';
import { AboutWrapper, PassportWrapper, SocialSecurityWrapper } from './aboutComponent';

interface IAboutCardComponent {
    title: string;
    canEdit: boolean;
    itemsToShow: string[];
    type: 'PAGE' | 'PREVIEW';
    selectedTheme?: any;
}

export const AboutCardComponent = (props: IAboutCardComponent) => {
    const { title, canEdit, itemsToShow } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const personalInfoKey = 'personal_info';
    const passportInfoKey = 'passport_info';
    const taxInfoKey = 'ssn_info';

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        
        if (itemsToShow.includes(personalInfoKey)) {
            dispatch(profileActionCreators.getProfilePersonalInfo());
        }
        if (itemsToShow.includes(passportInfoKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    citizenshipConfig
                )
            );
        }
        if (itemsToShow.includes(taxInfoKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(taxConfig)
            );
        }
    }, []);

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const isLoading = useSelector(
        personalInfoSelector.selectPersonalInfoLoading
    );

    const citizenshipData = useSelector(
        citizenshipSelector.selectCitizenshipData
    );
    const citizenshipLoading = useSelector(
        citizenshipSelector.selectCitizenshipLoading
    );

    const taxData = useSelector(taxSelector.selectTaxData);
    const taxLoading = useSelector(taxSelector.selectTaxLoading);

    const passportInfo: any = {};
    if (citizenshipData.length > 0) {
        passportInfo.passportNumber = citizenshipData[0].passportNumber;
        passportInfo.passportStatus = citizenshipData[0].verificationStatus;
    } else {
        passportInfo.passportNumber = '';
        passportInfo.passportStatus = '';
    }

    const taxInfo: any = {};
    if (taxData.length > 0) {
        taxInfo.taxNumber = taxData[0].number;
        taxInfo.status = taxData[0].verificationStatus;
    } else {
        taxInfo.taxNumber = '';
        taxInfo.status = '';
    }

    let combinedLoading = false;
    if (itemsToShow.includes(personalInfoKey)) {
        combinedLoading = combinedLoading || isLoading;
    }
    if (itemsToShow.includes(passportInfoKey)) {
        combinedLoading = combinedLoading || citizenshipLoading;
    }
    if (itemsToShow.includes(taxInfoKey)) {
        combinedLoading = combinedLoading || taxLoading;
    }

    const birthDateLabel = toLocalDateString(personalInfo.birthdate);

    let fullName = personalInfo?.givenName;
    if (personalInfo?.middleName) {
        fullName += ` ${personalInfo?.middleName}`;
    }
    fullName += ` ${personalInfo?.familyName}`;
    if (personalInfo?.suffix) {
        fullName += `, ${personalInfo.suffix}`;
    }

    return (
        <Box>
            <Grid item xs sx={selectedTheme?.card?.gridWrapper}>
                <Paper sx={selectedTheme?.card?.default}>
                    <CardTitleComponent title={title} />
                    <Divider />
                    <Grid container sx={selectedTheme?.card?.cardPadding}>
                        <AboutWrapper
                            cardTitle={title}
                            selectedTheme={selectedTheme}
                            combinedLoading={combinedLoading}
                            handleModalOpen={handleModalOpen}
                            fullName={fullName}
                            personalInfo={personalInfo}
                            canEdit={canEdit}
                            birthDateLabel={birthDateLabel}
                        />
                        {itemsToShow.includes(taxInfoKey) ? (
                            <SocialSecurityWrapper
                                selectedTheme={selectedTheme}
                                combinedLoading={combinedLoading}
                                handleModalOpen={handleModalOpen}
                                canEdit={canEdit}
                                taxInfo={taxInfo}
                                taxData={taxData}
                            />
                        ) : (
                            <></>
                        )}
                        {itemsToShow.includes(passportInfoKey) ? (
                            <PassportWrapper
                                selectedTheme={selectedTheme}
                                combinedLoading={combinedLoading}
                                handleModalOpen={handleModalOpen}
                                canEdit={canEdit}
                                passportInfo={passportInfo}
                                citizenshipData={citizenshipData}
                            />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <AboutForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

AboutCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: ['personal_info', 'passport_info', 'ssn_info'],
    type: 'PAGE',
};
