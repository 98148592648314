import { TextField, FormHelperText, Box } from '@mui/material';
import { ErrorSharp } from '@mui/icons-material';
import Autocomplete from '@mui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { SxProps, Theme } from '@mui/system';

interface IInputProps {
    // All other props
    [other: string]: any;
}

const ComboBoxWrapper: SxProps<Theme> = {
    m: 2,
};

export function ComboBox(props: IInputProps) {
   
    const {
        name,
        label,
        value,
        onChange,
        error,
        control,
        options,
        required,
        defaultValue,
        getOptionLabel,
        getOptionValue,
        ...other
    } = props;

    // ! TODO: Does this need to be included to save a certain value from the selected option
    // const getValue = (_e: any, val: any) => {
    // 	const data = { name, value: getOptionValue(val) };
    // 	const event = { target: data };
    // 	onChange(event);
    // };

    return (
        <Box sx={ComboBoxWrapper}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                // eslint-disable-next-line
                render={({ field: { onChange } }) => (
                    <Autocomplete
                        options={options}
                        getOptionLabel={getOptionLabel}
                        onChange={(_, data) => onChange(data)}
                        // eslint-disable-next-line
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label={label}
                                variant="outlined"
                                {...(error && {
                                    error: true,
                                    helperText: error.message,
                                })}
                            />
                        )}
                        {...other}
                    />
                )}
            />
        </Box>
    );
}
