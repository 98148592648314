import { makeStyles } from '@mui/styles';



export interface IItem {
    key: string;
    value: string;
}

export interface ITableExpandedRow {
    isExpanded: boolean;
    row: any;
    isLastRow: boolean;
    onReloadVerifications: Function;
}

export interface IExpandedRowValue {
    items: IItem[];
}
export const styles = {
    parent: {
        backgroundColor: '#F9FAFB',
        borderColor: '#BDC1C5',
        borderTop: 0,
        borderBottom: '1px solid #BDC1C5',
        boxShadow: '0 1px #e3e8ee inset',
        p: 4,
    },
    container: {
        border: '1px solid red',
        justifyContent: 'center',
        display: 'flex',

        gap: 2,
    },
    submitButton: {
        m: 3,
        display: 'flex',
        justifyContent: 'center',
    },
    column: {
        flexBasis: '50%',
    },
    row: {
        display: 'flex',
        flexBasis: '100%',
        p: 1,
    },
    cell: {
        flexBasis: '50%',
        lineHeight: '21px',
        fontSize: '16px',
    },
    label: {
        textAlign: 'right',
        fontFamily: 'Red Hat Text',
        fontWeight: 700,
        mr: 1,
    },
    value: {
        textAlign: 'left',
        fontFamily: 'Red Hat Text',
        fontWeight: 400,
    },
};


//  Are these needed? I copied them from the data manager work
const commonRowStyles = {
    '&:nth-last-of-type(1)': {
        borderRadius: '0 0 12px 12px',
        border: 'none',
    },
};

export const dataGridStyleOverrides = makeStyles((theme: any) => ({
    tableRow: theme.table?.rows,
    expandedRow: {
        ...theme.table?.rowCell,
        ...theme.table?.rowExpanded,
        ...theme.table?.rowExpandedCell,
    },
    headerVariant: {
        ...theme.table?.headerCell,
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
        },
    },
    cellValueBold: {
        fontWeight: 600,
    },
    desktopDataGrid: {
        m: 'auto',
        ...theme.table?.default,
        '& .MuiDataGrid-columnHeaders': theme.table?.header,
        '& .MuiDataGrid-columnHeaderTitle': theme.table?.headerCellTitle,
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                ...theme.table?.rows,
                ...commonRowStyles,
            },
        },
        '& .MuiDataGrid-cell': theme.table?.rowCell,
    },
}));

export const rowOverrideStyles = {
    '& .disabledRowStyle': {
        opacity: 0.4,
    },
};
