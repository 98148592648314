import { Box, Grid, Typography, Divider } from '@mui/material';
import { AboutCardComponent } from './about/about';
import { ContactCardCompoment } from './contact/contact';
import { WorkCardComponent } from './work/work';
import { LicenseAndCertificationsCardComponent } from './license-certificates/licenseCertifications';
import { FormalEducationCardComponent } from './formal-education/formalEducation';
import { AddressCardComponent } from './address/address';
import { ContinuingEducationCardComponent } from './continuing-education/continuingEducation';
import { VolunteerCardComponent } from './volunteer/volunteer';
import { TrainingCardComponent } from './training/training';
import { PathwayProfileComponent } from './pathway/pathwayProfile';
import { Close as CloseIcon } from '@mui/icons-material';
import { arrayDiff } from 'util/array.utils';
import { ProfileConstants } from 'app-constants';

interface IProfilePreviewComponent {
    assetListToShow: string[];
    closeEvent: Function;
}

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        px: 3,
        py: 2,
    },
    dialogHeader: {
        color: '#202124',
        fontSize: '20px',
        fontWeight: 600,
    },
    contentContainer: {
        p: {
            xs: '16px 0 0 0',
            md: '24px 24px 0 0',
        },
    },
    cardWidth: {
        xs: 1,
        md: 0.47,
        lg: 0.31,
    },
    cardMargin: {
        ml: {
            xs: 0,
            md: 3,
        },
    },
    columnheader: {
        color: '#80848D',
        fontSize: '16px',
        fontWeight: 'bold',
        mb: 1.5,
        ml: {
            xs: 2,
            md: 1,
        },
    },
};

export function ProfilePreviewComponent(props: IProfilePreviewComponent) {
    const { assetListToShow, closeEvent } = props;
    const isInEditMode = false;
    const personalSectionItems =
        ProfileConstants.PERSONAL_SECTION_PROFILE_ITEMS;
    const hasPersonalSection =
        arrayDiff(personalSectionItems, assetListToShow).length !==
        personalSectionItems.length;

    const experienceSectionItems =
        ProfileConstants.EXPERIENCE_SECTION_PROFILE_ITEMS;
    const hasExperienceSection =
        arrayDiff(experienceSectionItems, assetListToShow).length !==
        experienceSectionItems.length;

    const educationSectionItems =
        ProfileConstants.EDUCATION_SECTION_PROFILE_ITEMS;
    const hasEducationSection =
        arrayDiff(educationSectionItems, assetListToShow).length !==
        educationSectionItems.length;

    return (
        <Box>
            <Box sx={styles.headerContainer}>
                <Typography sx={styles.dialogHeader}>
                    Preview Shared Data
                </Typography>
                <CloseIcon
                    style={{ color: '#80848D' }}
                    onClick={() => closeEvent()}
                    className="cursor-pointer"
                />
            </Box>
            <Divider />
            <Grid container sx={styles.contentContainer}>
                {hasPersonalSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={styles.columnheader}>
                            {'PERSONAL'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.PERSONAL_INFO
                        ) ? (
                            <Grid item>
                                <AboutCardComponent
                                    title={'About'}
                                    canEdit={isInEditMode}
                                    itemsToShow={assetListToShow}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.EMAIL_INFO
                        ) ||
                        assetListToShow.includes(
                            ProfileConstants.PHONE_INFO
                        ) ? (
                            <Grid item>
                                <ContactCardCompoment
                                    title={'Contact'}
                                    canEdit={isInEditMode}
                                    itemsToShow={assetListToShow}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.ADDRESS_INFO
                        ) ? (
                            <Grid item>
                                <AddressCardComponent
                                    title={'Address'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <> </>
                )}
                {hasExperienceSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={styles.columnheader}>
                            {'EXPERIENCE'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.LICENSE_INFO
                        ) ||
                        assetListToShow.includes(
                            ProfileConstants.CERTIFICATION_INFO
                        ) ? (
                            <Grid item>
                                <LicenseAndCertificationsCardComponent
                                    title={'Licenses & Certifications'}
                                    canEdit={isInEditMode}
                                    itemsToShow={assetListToShow}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.WORK_INFO
                        ) ? (
                            <Grid item>
                                <WorkCardComponent
                                    title={'Work'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.VOLUNTEER_INFO
                        ) ? (
                            <Grid item>
                                <VolunteerCardComponent
                                    title={'Volunteer'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <> </>
                )}
                {hasEducationSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={styles.columnheader}>
                            {'EDUCATION'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.FORMAL_EDUCATION_INFO
                        ) ? (
                            <Grid item>
                                <FormalEducationCardComponent
                                    title={'Formal Education'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.CONTINUING_EDUCATION_INFO
                        ) ? (
                            <Grid item>
                                <ContinuingEducationCardComponent
                                    title={'Continuing Education'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.TRAINING_INFO
                        ) ? (
                            <Grid item>
                                <TrainingCardComponent
                                    title={'Training'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.EMPLOYEE_PATHWAY_INFO
                        ) ? (
                            <Grid item>
                                <PathwayProfileComponent
                                    title={'Pathways'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <> </>
                )}
            </Grid>
        </Box>
    );
}
