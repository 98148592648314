import { baseTheme } from 'util/themes';
// import { object as yupObject, string as yupString } from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
// import validationMessage from 'util/validations';

export const commonStyles = {
    container: {
        pt: 4,
        minHeight: '80vh',
    },
    holder: {
        maxWidth: '500px',
        m: 'auto',
    },
    header: {
        pb: 5,
    },
    subHeader: {
        mt: 5,
        fontSize: '24px',
        color: '#5D6A71',
        mb: 4,
    },
    text: {
        fontSize: '16px',
        color: '#202124',
    },
    footer: {
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        justifyContent: 'flex-end',
        mb: 3,
        gap: 2,
    },
    nextButton: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    cancelButton: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    previousButton: {
        textTransform: 'none',
        fontWeight: 'bold'
    }
}

// export interface IExternalInput {
//     name: string;
//     emailAddress: string;
// }

// const schema = yupObject({
//     emailAddress: yupString().email(validationMessage.email).max(255, validationMessage.max(255)).required(validationMessage.required),
//     name: yupString().max(255, validationMessage.max(255)).required(validationMessage.required)
// });

// export const formSchemaResolver = yupResolver(schema);
