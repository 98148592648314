import React from 'react';
import { baseTheme, lifeGraph, lifetrek, burstIQ } from 'util/themes';

const themes : {[key : string] : any} = {
    'lifeGraph' : lifeGraph,
    'lifetrek' : lifetrek,
    'baseTheme' : baseTheme,
    'burstIQ': burstIQ
};

export const ThemeContext = React.createContext({
    selectedTheme: themes[window.localStorage.getItem('selectedItem') || 'baseTheme'],
    setCurrentTheme: (themeName : string) => {
        return themeName ? window.localStorage.setItem('selectedTheme', themeName) : '';
    }
} as {
    selectedTheme:any,
    setCurrentTheme: (themeName : string) => void
});