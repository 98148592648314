import { Children, useContext } from 'react';
import {
    Grid,
    Typography,
    useMediaQuery,
    Box,
    Container,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import { styles } from './onboarding.helper';
import { updateOnboarding } from '../../util/getUserData';
import { Button } from 'lib/controls';
import { ThemeContext } from 'util/themes';

export interface IOnboardingComponent {
    imageName: string;
    redirectLink: string;
    header: string;
    headerStyle?: any;
    overline?: string;
    overlineStyle?: any;
    subHeader: string;
    subHeaderStyle?: any;
    bottomTextList: string[];
    buttonText: string;
    onboardingScreen: string;
    background?: string;
    backgroundStyle?: any;
    bottomTextStyle?: any;
    isSectionVisible?: boolean;
    imageWrapperClass?: string;
    generalOnboardingContainerWrapper?: string;
    buttonTextStyle?: any;
    headerContainerStyle?: any;
    bottomBoxContainer?: any;
}

export interface IOnboarding {
    pathway?: string;
    dataManager?: string;
    profile?: string;
}

export const OnboardingComponent = (props: IOnboardingComponent) => {
    const {
        imageName,
        redirectLink,
        header,
        subHeader,
        bottomTextList,
        buttonText,
        onboardingScreen,
        background,
        backgroundStyle = {},
        overline,
        overlineStyle,
        subHeaderStyle,
        headerStyle,
        bottomTextStyle,
        isSectionVisible,
        imageWrapperClass = '',
        generalOnboardingContainerWrapper = '',
        buttonTextStyle,
        headerContainerStyle = {},
        bottomBoxContainer = {}
    } = props;
    const imageLink = `/images/onboarding/${imageName}`;

    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const imageClassname = isMobile
        ? 'general-onboarding-mobile'
        : 'general-onboarding-desktop';

    const onButtonClick = () => {
        updateOnboarding(onboardingScreen).then(() => {
            history.push(redirectLink);
        });
    };

    return (
        <>
            <Box className="general-onboarding-bg" sx={{background: background}}>
                <Container maxWidth={false} className={generalOnboardingContainerWrapper}>
                    <Grid container direction={'row'} sx={{...styles.topBox, ...headerContainerStyle}}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            alignSelf={'center'}
                            mt={0}
                            pt={4}
                        >
                            {overline && 
                                <>
                                    <Typography sx={{...overlineStyle}}>{overline}</Typography>
                                    <br/>
                                </>
                            }
                            <Typography sx={{...styles.header, ...headerStyle}}>{header}</Typography>
                            <Typography sx={{...styles.subHeader, ...subHeaderStyle}}>
                                {subHeader}
                            </Typography>
                        </Grid>
                        <Grid className={imageWrapperClass} item xs={12} md={6} textAlign={'center'}>
                            <img
                                src={imageLink}
                                style={backgroundStyle}
                                height={'auto'}
                                className={imageClassname}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {!!isSectionVisible && <Container maxWidth={false} sx={styles.sectionContainer}>
                <Grid sx={{...styles.sectionStyle, backgroundColor: selectedTheme?.palette?.primary[600]}}/>
            </Container>}
            <Container maxWidth={false}>
                <Grid
                    container
                    direction={'row'}
                    sx={styles.extraSpaceAtBottom}
                >
                    <Grid item xs={12} md={6} sx={{...styles.bottomBox, ...bottomBoxContainer}}>
                        {Children.toArray(
                            bottomTextList.map((x: string) => {
                                return (
                                    <Box>
                                        <Typography sx={{...styles.bottomText, ...bottomTextStyle}}>
                                            {x}
                                        </Typography>
                                        <br></br>
                                    </Box>
                                );
                            })
                        )}
                        {!!buttonText && <Button
                            onClick={onButtonClick}
                            variant="contained"
                            size="medium"
                            sx={{ borderRadius: '4px', py: '0.25rem', mb: '11.6rem' }}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.medium,
                                textTransform: 'none',
                                ...buttonTextStyle
                            }}>
                                {buttonText}
                            </Typography>
                        </Button>}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
