import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, Theme } from '@mui/material';
import logo from 'assets/images/logos/Lifegraph Badge.svg';
import { updateUserDataLoggedInStatus } from 'util/getUserData';
import { updateOnboarding } from '../../../util/getUserData';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';

const bannerWidth = 436;

const styles = {
    desktopLogo: (theme: Theme) => ({
        height: 'auto',
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(15),
        width: '68px',
    }),
    desktop: {
        height: '100%',
        position: 'relative' as const,
        display: {
            xs: 'none',
            md: 'block',
        },
    },
    mobileLogo: (theme: Theme) => ({
        height: 'auto',
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
        width: '40px',
    }),
    button: {
        textTransform: 'none',
    },
};

export const OnboardingScreen4Component = () => {
    const theme = useTheme();
    const { selectedTheme, setCurrentTheme }  = useContext(ThemeContext);

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    }, [])

    updateOnboarding('profile');
    const profileClicked = () => {
        // dispatch the action here for api
        updateUserDataLoggedInStatus(true);
        window.location.href = '/profile';
    };

    const onClickDashboardBtn = () => {
        updateUserDataLoggedInStatus(true);
        window.location.href = '/dashboard';
    };

    const imageLink = `/images/onboarding/Success-page-img.png`;

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box className="onboarding-bg-rectangle" minHeight={{ xs: '90%', md: '90vh' }}>
                    <Box display={{ xs: 'block', md: 'none' }}>
                        <Box>
                            <img
                                src={logo}
                                alt="Lifegraph"
                                style={styles.mobileLogo(theme)}
                            />
                        </Box>         
                        <Box sx={{ width: '100%', top: '0', position: 'absolute'}}>
                            <img
                                src={imageLink}
                                height={'100%'}
                                width={'100%'}
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'none', md: 'block' }} sx={styles.desktop}>
                        <Box>
                            <img
                                src={logo}
                                alt="Lifegraph"
                                style={styles.desktopLogo(theme)}
                            />
                        </Box>
                        <Box sx={{ width: '100%', top: '0', position: 'absolute'}}>
                            <img
                                style={{...selectedTheme.imageBackground, maxHeight :'90vh'}}
                                src={imageLink}
                            />
                        </Box>
                    </Box>   
                </Box>
            </Grid>
            <Grid item container xs={12} md={6} display='flex' justifyContent='center' alignItems='center'>
                <Grid
                    container
                    display="flex"
                    direction={'row'}
                    gap={'20px'}
                    ml={16}
                    textAlign={'left'}
                >
                    <Grid container item xs={12}>
                        <Grid width={bannerWidth} sx={{borderBottom:  `6px solid ${selectedTheme.palette.primary[400]}`}}>
                            <Typography sx={{...selectedTheme?.typography?.banner?.banner2, color: selectedTheme.palette.primary[700]}}>
                                Sweet!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{...selectedTheme?.typography?.banner?.bannerSubtitle, color: selectedTheme.palette.text.secondary}}>
                            You're all set.
                        </Typography>
                    </Grid>
                    {/* This will come back later
                     <Grid item xs={12}>
                        <Button
                            variant="contained"
                            sx={styles.button}
                            size="small"
                            onClick={profileClicked}
                            color={'secondary'}
                        >
                            Go to my profile
                        </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            sx={{py: 1.8, px: 3.75}}
                            size="large"
                            onClick={onClickDashboardBtn}
                            color={'primary'}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large
                            }}>
                                Go to my dashboard
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
