export const sortArrayOfObjectByStringAttribute = (arraylist: any[], attribute: string) => {
    arraylist.sort((a: any, b: any) => {
        if (a[attribute] < b[attribute]) {
            return 1;
        } else if (a[attribute] > b[attribute]) {
            return -1;
        }
        return 0;
    })
};

export const sortArrayOfObjectByDateAttribute = (arraylist: any[], attribute: string) => {
    arraylist.sort((a: any, b: any) => {
        if (a[attribute]?.$date < b[attribute]?.$date) {
            return 1;
        } else if (a[attribute]?.$date > b[attribute]?.$date) {
            return -1;
        }
        return 0;
    })
};

export const arrayDiff = (parentArray: any[], childArray: any[]) => {
    return parentArray.filter((x) => !childArray.includes(x));
}

export const convertArrayRowToArrayColumn = (listRaw: any[], numberOfSubArray: number) => {
    const list = [...listRaw];
    let finalListofList: any[] = [];
    for (let i = 0; i <= list.length; i += numberOfSubArray) {
        for (let j = 0; j < numberOfSubArray; j++) {
            if (list[i + j]) {
                if (finalListofList[j]) {
                    finalListofList[j].push(list[i + j])
                } else {
                    finalListofList[j] = [list[i + j]];
                }
            }
        }
    }
    return finalListofList;
}