import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { commaSeparatedLocalDate } from 'util/date.utils';

const vaccinationState = (state: RootState) => state.healthDashboard.vaccination;

const selectVaccinationState = createSelector([vaccinationState], (state) => {
    return state;
});

export const selectVaccinationLoading = createSelector(selectVaccinationState, (state) => {
    return state.loading;
});

export const selectVaccinationData = createSelector(selectVaccinationState, (state) => {
    const formattedData = state?.data?.map((item) => {
        let dataObj: any = {
            'label': 'Date Administered',
            'assetId': item?.asset_id,
            'assetType' : item?.assetType,
            'value': item?.vaccine || '',
            'userId': item?.userId,
            'subHeading': (item?.administerDate?.$date &&
                commaSeparatedLocalDate(item?.administerDate)) || '',
            "verificationMessage" :"This data has gone through a verification process which includes it being formally validated by a trusted 3rd party. Scan the QR code below to view Joan Robinson’s Vaccine verification.",
            'verificationStatus': item?.verificationStatus || '',
            "verificationCompany" : item?.verificationCompany || '',
            "verifier" : item?.verifier || '',
            "verificationTimestamp" : item?.verificationTimestamp?.$date || '',
            "verificationAssetId" : item?.verificationAssetId || '',
            "verificationHash" : item?.verificationHash || '',
            "verificationSignature" : item?.verificationSignature || '',
            "qrCode" : "/images/dashboard/QR-code-burst-website.svg"
        };
        if(item?.administeredBy && item?.administeredBy !== '') {
            dataObj.label = 'Administered By';
            dataObj.subHeading = item?.administeredBy || '';
            dataObj.secondRow = {
                'label': 'Date Administered',
                'value': (item?.administerDate?.$date &&
                    commaSeparatedLocalDate(item?.administerDate)) || '',
            }
        }

        return dataObj;
    }) || [];

    return formattedData;
});

export const selectVaccinationError = createSelector(selectVaccinationState, (state) => {
    return state.error;
});