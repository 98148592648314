import { pathwayActionType } from 'redux/actionsTypes';
import { produce } from 'immer';
import { IPathway } from '../../../@types/pathway-types';

export interface IPathwayState {
    error: boolean;
    data: IPathway[];
    loading: boolean;
    expandedActivity: IPathway;
    expandedActivityLoading: boolean;
    currentLessonCourses: any[];
    currentLessonCoursesLoading: boolean;
}

interface IPathwayAction {
    type: string;
    payload: IPathway[];
}

const initialData: IPathwayState = {
    error: false,
    loading: true,
    expandedActivityLoading: true,
    expandedActivity: {} as IPathway,
    data: [
        {
            assetId: '',
            data: [],
            metaData: {
                id: '',
                goal: '',
                imageName: '',
                theme: '',
                title: '',
            },
        },
    ],
    currentLessonCourses: [],
    currentLessonCoursesLoading: true
};

const reducer = (
    state: IPathwayState = initialData,
    action: IPathwayAction
) => {
    return produce(state, () => {
        switch (action.type) {
            case pathwayActionType.GET_PATHWAY_ERROR:
                return { ...state, error: true, loading: false };
            case pathwayActionType.GET_PATHWAY_LOADING:
                return { ...state, loading: true };
            case pathwayActionType.GET_PATHWAY_SUCCESS:
                return { ...state, data: action.payload, loading: false };
            case pathwayActionType.SET_PATHWAY_EXPANDED_STEP:
                return { ...state, expandedActivity: action.payload, expandedActivityLoading: false };
            case pathwayActionType.GET_SELECTED_PATHWAY_LOADING:
                return { ...state, expandedActivityLoading: true }
            case pathwayActionType.GET_COURSE_ITEMS_LOADING_ACTION:
                return { ...state, currentLessonCoursesLoading: true };
            case pathwayActionType.GET_COURSE_ITEMS_SUCCESS_ACTION:
                return { ...state, currentLessonCourses: action.payload, currentLessonCoursesLoading: false }
        }
        return state;
    });
};

export default reducer;
