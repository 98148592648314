import { useEffect, useState } from 'react';
import AvatarComp from '../../lib/components/Avatar';
import { getUserData } from '../../util/getUserData';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';

interface IProfileAvatar {
    size?: 'large' | 'default' | 'medium' | 'xlarge';
    shape?: string;
    sx?: any;
}

const styles = {
    hexagonShape: {
        clipPath: 'polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%)',
    },
    roundShape: {
        borderRadius: '50%'
    }
}

const ProfileAvatar = (props: IProfileAvatar) => {
    const [profileAvatar, setProfileAvatar] = useState('');
    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    const firstName = personalInfo.givenName || '';
    const lastName = personalInfo.familyName || '';

    const fetchProfileAvatar = async (publicId: string) => {
        try {
            const image = await import(
                `../../assets/images/avatars/${publicId}.png`
            );
            setProfileAvatar(image.default);
        } catch (error) {
            setProfileAvatar('');
        }
    };

    useEffect(() => {
        const publicId = getUserData('publicId');
        fetchProfileAvatar(publicId);
    }, []);

    return (
        <>
            <AvatarComp 
                firstName={firstName}
                lastName={lastName}
                avatarImg={profileAvatar}
                size={props.size}
                sx={{...props.sx, ...styles[props.shape === 'hexagon' ? 'hexagonShape' : 'roundShape']}}
            />
        </>
    );
};

ProfileAvatar.defaultProps = {
    size: 'default'
}

export default ProfileAvatar;
