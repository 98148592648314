import { ProfileAvatarComponent } from 'components';
import {
    Box,
    Popper,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemText,
    Button,
    Grid
} from '@mui/material';
import { Children, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOtherNavLinks } from 'util/navLinks';

const styles = {
    listItemPadding: {
        p: 0,
    },
    listItemButton: {
        py: 0.75,
        px: 2
    },
    imageHolder: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 0,
    },
    listItemText: {
        '& span': {
            fontFamily: 'Red Hat Text',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '26px',
            color: '#293541'
        }
    },
    listItemDividerStyle: {
        position: 'relative' as const,
        width: 'calc(100% + 1px)',
        left: '-1px',
        borderTop: '1.5px solid #5D6A71',
    },
    popperDropdownBox: {
        border: '1.5px solid #5D6A71',
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
        filter: 'drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))'
    }
};

export const HeaderAvatarDropdownComponent = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onLinkClick = (path: string) => {
        history.push(path);
    };

    const open = Boolean(anchorEl);
    const popperId = 'header_avatar_dropwdown';
    const navLinksLength = getOtherNavLinks().length || 0;

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button
                    aria-describedby={popperId}
                    onClick={handleClick}
                    sx={{ minWidth: 0, padding: '1px 6px' }}
                >
                    <ProfileAvatarComponent size="medium" shape="hexagon"/>
                </Button>
            </ClickAwayListener>
            <Popper
                id={popperId}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
                style={{ zIndex: 2 }}
            >
                <Box className="popper-dropdown-box" sx={styles.popperDropdownBox}>
                    <List component="nav" sx={styles.listItemPadding}>
                        {Children.toArray(
                            getOtherNavLinks().map((element, index) => {
                                return (
                                    <>
                                        <ListItemButton
                                            onClick={(_e) =>
                                                onLinkClick(element.path)
                                            }
                                            selected={false}
                                            sx={styles.listItemButton}
                                        >
                                            <ListItemText primary={element.title} sx={styles.listItemText}/>
                                        </ListItemButton>
                                        {(navLinksLength !== 1 && navLinksLength !== (index + 1)) && <Grid style={styles.listItemDividerStyle}/>}
                                    </>     
                                );
                            })
                        )}
                    </List>
                </Box>
            </Popper>
        </>
    );
};
