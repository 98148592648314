import { Grid, Typography, Box } from '@mui/material';
import { VerificationStatusComponent } from 'components';
import { Loader } from '../../lib/components/Loader/BarSkeleton';
import { ILifeGraphVerification } from '../../@types/profile-types';
import { Edit as EditIcon } from '@mui/icons-material';
import { Children } from 'react';

const styles = {
    header: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
    subHeader: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    bodyData: {
        fontSize: '12px',
        fontWeight: 600,
    },
    container: {
        marginBottom: 2,
    },
};

interface IProfileInfoRowVaraint2 {
    objectId: string;
    header: string;
    subHeader: string;
    bodyDataList: (string | JSX.Element)[];
    isLoading: boolean;
    classNames: string;
    canEdit: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    verificationData: ILifeGraphVerification;
    onEditClicked: (id: string) => void;
    selectedTheme?: any;
    verificationDetail?: any;
    hideVerification?: any;
}

export const ProfileInfoRowVaraint2Component = (
    props: IProfileInfoRowVaraint2
) => {
    const {
        header,
        subHeader,
        bodyDataList,
        isLoading,
        canEdit,
        objectId,
        onEditClicked,
        verificationData,
        verificationDetail,
        selectedTheme,
        hideVerification = false
    } = props;

    const onClick = () => {
        if (canEdit === 'ACTIVE') {
            onEditClicked(objectId);
        }
    };

    return (
        <Grid container direction="row" sx={styles.container}>
            <Grid item width={24} height={24} mr={2} xs={1} sx={{maxWidth: '1.5rem !important'}}>
                {verificationData && !hideVerification ? (
                    <VerificationStatusComponent
                        data={verificationData}
                        loading={isLoading}
                        selectedTheme={selectedTheme}
                        verificationDetail={verificationDetail}
                    />
                ) : (
                    ''
                )}
            </Grid>
            <Grid item xs={10}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.subtitles?.default,
                            ...selectedTheme?.typography?.subtitles?.subtitle1,
                            ...selectedTheme?.typography?.subtitles?.semibold,
                            color:
                                selectedTheme?.palette?.secondary?.grayScale &&
                                selectedTheme?.palette?.secondary
                                    ?.grayScale[700],
                        }}
                        data-testid={`${objectId}_header`}
                    >
                        {header}
                    </Typography>
                )}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.tpyography?.body?.body1,
                            ...selectedTheme?.tpyography?.body?.regular,
                            color:
                                selectedTheme?.palette?.secondary?.grayScale &&
                                selectedTheme?.palette?.secondary
                                    ?.grayScale[700],
                        }}
                        data-testid={`${objectId}_subheader`}
                    >
                        {subHeader}
                    </Typography>
                )}
                {isLoading ? (
                    <Loader />
                ) : (
                    bodyDataList.map(
                        (body: string | JSX.Element, index: number) => {
                            if (typeof body === 'string') {
                                if (body.includes('\n')) {
                                    return Children.toArray(
                                        body
                                            .split('\n')
                                            .map((addressElement: string) => {
                                                return (
                                                    <Typography
                                                        sx={{
                                                            ...selectedTheme
                                                                ?.typography
                                                                ?.body?.default,
                                                            ...selectedTheme
                                                                ?.tpyography
                                                                ?.body?.body1,
                                                            ...selectedTheme
                                                                ?.tpyography
                                                                ?.body?.regular,
                                                            color:
                                                                selectedTheme
                                                                    ?.palette
                                                                    ?.secondary
                                                                    ?.grayScale &&
                                                                selectedTheme
                                                                    ?.palette
                                                                    ?.secondary
                                                                    ?.grayScale[800],
                                                        }}
                                                        data-testid={`${objectId}_body_${index}`}
                                                    >
                                                        {addressElement}
                                                    </Typography>
                                                );
                                            })
                                    );
                                } else {
                                    return (
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography
                                                    ?.body?.default,
                                                ...selectedTheme?.tpyography
                                                    ?.body?.body1,
                                                ...selectedTheme?.tpyography
                                                    ?.body?.regular,
                                                color:
                                                    selectedTheme?.palette
                                                        ?.secondary
                                                        ?.grayScale &&
                                                    selectedTheme?.palette
                                                        ?.secondary
                                                        ?.grayScale[800],
                                            }}
                                            key={`${index}_body`}
                                            data-testid={`${objectId}_body_${index}`}
                                        >
                                            {body}
                                        </Typography>
                                    );
                                }
                            }
                        }
                    )
                )}
            </Grid>
            <Grid item xs={1}>
                {canEdit === 'HIDDEN' ? (
                    <></>
                ) : isLoading ? (
                    <Loader width={30} />
                ) : (
                    <EditIcon
                        onClick={onClick}
                        className="cursor-pointer"
                        data-testid={`${objectId}_editIcon`}
                        style={{ color: selectedTheme?.palette?.primary[500] }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

ProfileInfoRowVaraint2Component.defaultProps = {
    objectId: '',
    header: '',
    subHeader: '',
    bodyDataList: [],
    isLoading: false,
    classNames: '',
    canEdit: 'ACTIVE',
    verificationData: null,
    onEditClicked: () => {},
};
