export const GET_PERSONAL_INFO_LOADING = 'GET_PERSONAL_INFO_LOADING';
export const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS';
export const GET_PERSONAL_INFO_ERROR = 'GET_PERSONAL_INFO_ERROR';

export const GET_PHONE_INFO_LOADING = 'GET_PHONE_INFO_LOADING';
export const GET_PHONE_INFO_SUCCESS = 'GET_PHONE_INFO_SUCCESS';
export const GET_PHONE_INFO_ERROR = 'GET_PHONE_INFO_ERROR';

export const GET_EMAIL_INFO_LOADING = 'GET_EMAIL_INFO_LOADING';
export const GET_EMAIL_INFO_SUCCESS = 'GET_EMAIL_INFO_SUCCESS';
export const GET_EMAIL_INFO_ERROR = 'GET_EMAIL_INFO_ERROR';

export const GET_EDUCATION_INFO_LOADING = 'GET_EDUCATION_INFO_LOADING';
export const GET_EDUCATION_INFO_SUCCESS = 'GET_EDUCATION_INFO_SUCCESS';
export const GET_EDUCATION_INFO_ERROR = 'GET_EDUCATION_INFO_ERROR';

export const GET_ADDRESS_INFO_LOADING = 'GET_ADDRESS_INFO_LOADING';
export const GET_ADDRESS_INFO_SUCCESS = 'GET_ADDRESS_INFO_SUCCESS';
export const GET_ADDRESS_INFO_ERROR = 'GET_ADDRESS_INFO_ERROR';

export const GET_OCCUPATION_INFO_LOADING = 'GET_OCCUPATION_INFO_LOADING';
export const GET_OCCUPATION_INFO_SUCCESS = 'GET_OCCUPATION_INFO_SUCCESS';
export const GET_OCCUPATION_INFO_ERROR = 'GET_OCCUPATION_INFO_ERROR';

export const GET_COURSE_INFO_LOADING = 'GET_COURSE_INFO_LOADING';
export const GET_COURSE_INFO_SUCCESS = 'GET_COURSE_INFO_SUCCESS';
export const GET_COURSE_INFO_ERROR = 'GET_COURSE_INFO_ERROR';

export const GET_LICENSURE_INFO_LOADING = 'GET_LICENSURE_INFO_LOADING';
export const GET_LICENSURE_INFO_SUCCESS = 'GET_LICENSURE_INFO_SUCCESS';
export const GET_LICENSURE_INFO_ERROR = 'GET_LICENSURE_INFO_ERROR';

export const GET_TRAINING_INFO_LOADING = 'GET_TRAINING_INFO_LOADING';
export const GET_TRAINING_INFO_SUCCESS = 'GET_TRAINING_INFO_SUCCESS';
export const GET_TRAINING_INFO_ERROR = 'GET_TRAINING_INFO_ERROR';

export const GET_CERTIFICATION_INFO_LOADING = 'GET_CERTIFICATION_INFO_LOADING';
export const GET_CERTIFICATION_INFO_SUCCESS = 'GET_CERTIFICATION_INFO_SUCCESS';
export const GET_CERTIFICATION_INFO_ERROR = 'GET_CERTIFICATION_INFO_ERROR';

export const GET_VACCINATION_INFO_LOADING = 'GET_VACCINATION_INFO_LOADING';
export const GET_VACCINATION_INFO_SUCCESS = 'GET_VACCINATION_INFO_SUCCESS';
export const GET_VACCINATION_INFO_ERROR = 'GET_VACCINATION_INFO_ERROR';

