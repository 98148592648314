import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';

export interface IAllergyFormInputs {
    allergy: string;
    lastReaction: any;
}

const schema = yupObject({
    allergy: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    lastReaction: yupString().max(255, validationMessage.max(255)).required(validationMessage.required)
});

export const formSchemaResolver = yupResolver(schema);