import * as yup from 'yup';

export const schema = yup.object({
    signature: yup
        .string()
        .max(40)
        .required('Please sign the terms and conditions'),
    accepted: yup
        .boolean()
        .oneOf([true], 'Please accept the terms and conditions'),
});

export type FormValues = yup.InferType<typeof schema>;

export type TermsAndConditionsResponse = {
    username: string;
    password: string;
    hash: string;
    signature: string;
};

export interface LocationState {
    from: {
        pathname: string;
        search: string;
    };
    terms: {
        username: string;
        password: string;
        terms: {
            client: string;
            hash: string; //Unique hash for each user and terms
            level: string;
            name: string;
            term: string; //The Markdown Terms and Conditions
            term_group: string;
        }[];
    };
}
