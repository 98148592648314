import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

const styles = {
    root: {
        '& > *': {
            m: 1,
            width: '35ch',
        },
    },
};

const SearchBox = (props: { label: string } | any) => {
    const { label, size, onChange } = props;

    return (
        <Box component="form" sx={styles.root} noValidate autoComplete="off">
            <TextField
                id="outlined-basic"
                label={label}
                variant="outlined"
                size={size}
                onChange={onChange}
                color="secondary"
            />
        </Box>
    );
};

export default SearchBox;
