import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { INotify } from 'lib/types';

export const Notify = (props: {
    notify: INotify;
    setNotify: any;
    position?: 'top' | 'bottom';
}) => {
    const { notify, setNotify, position = 'top' } = props;

    const handleClose = (
        _event: Event | React.SyntheticEvent ,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        if (notify.onClosed instanceof Function) {
            notify.onClosed();
        }

        setNotify({ ...notify, isOpen: false });
    };

    return (
        <Snackbar
            open={notify.isOpen}
            onClose={handleClose}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: position, horizontal: 'center' }}
            sx={{zIndex: 3}}
        >
            <Alert severity={notify.type} onClose={handleClose}>
                {notify.message}
            </Alert>
        </Snackbar>
    );
};
