import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { addressLookupSelector, personalInfoSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { IProfileAddressesObject } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, IAddressFormInputs, styles } from './addressForm.helper';
import { addressConfig } from './address.helper';
import { areObjectDifferent } from 'util/object.util';
import { ThemeContext } from 'util/themes';

type AddressPageType = 'PROFILE' | 'ONBOARDING';

interface IProps {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileAddressesObject | null;
    type: AddressPageType;
    autoSubmit?: boolean;
}

export const getAddressForm = (modalEditData: any, submitFormValues: Function, typeOptions: Partial<HTMLInputElement>[], pageType: AddressPageType, autoSubmit: boolean | undefined, selectedTheme: any) => {
    const [defaultFormData, setDefaultFormData] = useState<IAddressFormInputs>(formDefaults);
    const inputStyleProp = {
        ...styles[pageType].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']  
    };
    const labelStyleProp = {
        ...styles[pageType].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const submitForm = async (formValues: any) => {
        submitFormValues(formValues, defaultFormData);
    }

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
        setValue,
    } = useForm<IAddressFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit(submitForm)();
        }
    }, [autoSubmit]);

    useEffect(() => {
        const formData: IAddressFormInputs = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.type = modalEditData.type || '';
            formData.city = modalEditData.city || '';
            formData.country = modalEditData.country || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.state = modalEditData.state || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.primary = modalEditData.primary || false;
            formData.asset_id = modalEditData.asset_id || '';
            formData.streetAddressLine2 = modalEditData.streetAddressLine2 || '';
            formData.country = modalEditData.country || '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <Grid container display={'flex'} direction={'row'} component="form" id={'address_form'} onSubmit={handleSubmit(submitForm)}>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Checkbox
                    name="primary"
                    label="Is Primary"
                    checkedColor={selectedTheme?.palette?.primary[500]}
                    unCheckedColor={selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]}
                    value={defaultFormData.primary}
                    color="primary"
                    control={control}
                    inputStyleProps={inputStyleProp}
                    error={errors?.primary}
                />
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.TextSelectWithOtherDropdown
                    name="type"
                    label="Address Type"
                    defaultValue={defaultFormData.type}
                    labelStyles={labelStyleProp}
                    options={typeOptions}
                    control={control}
                    inputStyleProps={inputStyleProp}
                    inputInlineStyle={{...selectedTheme?.input}}
                    errors={errors}
                    setValue={setValue}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container display={'flex'} direction={'row'} justifyContent={'space-between'}>
                    <Box sx={styles[pageType].streetAddressWidth}>
                        <Controls.Input
                            name="streetAddress"
                            label="Street Address"
                            defaultValue={defaultFormData.streetAddress}
                            control={control}
                            error={errors?.streetAddress || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box display={pageType === 'ONBOARDING' ? 'block' : 'none'} sx={styles[pageType].typeWidth}>
                        <Controls.TextSelect
                            name="type"
                            label="Address Type"
                            defaultValue={defaultFormData.type}
                            options={typeOptions}
                            control={control}
                            error={errors?.type || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    name="streetAddressLine2"
                    label="Street Address Line 2"
                    defaultValue={defaultFormData.streetAddressLine2}
                    control={control}
                    error={errors?.streetAddressLine2 || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{...selectedTheme?.input}}
                    labelStyles={labelStyleProp}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container display={'flex'} direction={'row'} justifyContent={'space-between'}>
                    <Box sx={styles[pageType].cityWidth}>
                        <Controls.Input
                            name="city"
                            label="City"
                            defaultValue={defaultFormData.city}
                            control={control}
                            error={errors?.city || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box sx={styles[pageType].stateWidth}>
                        <Controls.Input
                            name="state"
                            label="State"
                            defaultValue={defaultFormData.state}
                            control={control}
                            error={errors?.state || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box sx={styles[pageType].zipWidth}>
                        <Controls.Input
                            name="postalCode"
                            label={`${pageType === 'ONBOARDING' ? 'Zip Code' : 'Postal Code'}`}
                            defaultValue={defaultFormData.postalCode}
                            control={control}
                            error={errors?.postalCode || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Input
                    name="country"
                    label="Country"
                    defaultValue={defaultFormData.country}
                    control={control}
                    error={errors?.country || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{...selectedTheme?.input}}
                    labelStyles={labelStyleProp}
                    required
                />
            </Grid>
        </Grid>
    )
}


export const AddressForm = (props: IProps) => {
    const { modalOpen, modalEditData, type, autoSubmit } = props;
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    let addressLookupData = useSelector(addressLookupSelector.selectAddressLookupData);

    if (type === 'ONBOARDING') {
        addressLookupData = addressLookupData.filter((x) => x.value !== '__other__');
    }

    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const addressType of addressLookupData) {
        typeOptions.push({ id: addressType.value, title: addressType.display });
    }

    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    useEffect(() => {
        setDidMount(true);
        return () => {
            setDidMount(false);
        }
    }, []);

    const submitForm = async (formValues: any, defaultFormData: IAddressFormInputs) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }

        const sendApiRequest = areObjectDifferent(formValues, defaultFormData, ['type', 'streetAddress', 'streetAddressLine2', 'city', 'state', 'postalCode', 'country', 'primary']);
        if (!sendApiRequest) {
            handleModalClose();
            return;
        }
        delete requestValues.otherType;
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(addressConfig.endpoint, requestValues, withCredentials());
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(`${addressConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    if (type === 'PROFILE') {
        return (
            <>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle data-testid="address_form_title">
                        <Typography component={'span'} sx={{
                            letterSpacing: 0,
                            ...selectedTheme?.typography?.h4,
                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                        }}>
                            {modalEditData ? 'Edit' : 'Add'} Address Info
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            getAddressForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <Controls.Button form="address_form" type="submit" variant='contained' color='primary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Submit
                            </Typography>
                        </Controls.Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    } else {
        return (
            <>
                {
                    getAddressForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                }
            </>
        )
    }
};
