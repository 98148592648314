import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controls, Components } from '../../../../../lib';

import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios, { withCredentials } from '../../../../../util/axiosInstance';
import { profileActionCreators } from '../../../../../redux/actionCreators';
import { INotify } from 'lib/types';
import moment from 'moment';
import { IReduxConfig } from '../index';
import { personalInfoSelector } from 'redux/selectors/profile';

export type IFormInputs = {
    vaccinationReceivedDate: string;
    vaccine: string;
    asset_id?: string;
    dateReceived?: { $date?: string };
};

export const formDefaults = {
    vaccinationReceivedDate: '',
    vaccine: '',
    asset_id: '',
};

export const schema = yup.object({
    vaccinationReceivedDate: yup.string().required('Required'),
    vaccine: yup.string().required('Required'),
});

interface IProps {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IFormInputs;
    reduxConfig: IReduxConfig;
    endpoint: string;
}

const AddEditForm = (props: IProps) => {
    const { modalOpen, modalEditData, reduxConfig, endpoint } = props;
    const dispatch = useDispatch();

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [defaultFormData, setDefaultFormData] =
        useState<IFormInputs>(formDefaults);

    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const submitForm = async (formValues: any) => {
        try {
            let reqValues = {
                vaccinationReceivedDate: formValues.certificationBoard,
            };
            if (!defaultFormData.asset_id) {
                await axios().post(endpoint, reqValues, withCredentials());
            } else {
                let editReqValues = {
                    ...reqValues,
                    asset_id: defaultFormData.asset_id,
                    userId: personalInfo.userId,
                };
                await axios().put(
                    `${endpoint}/${defaultFormData.asset_id}`,
                    editReqValues,
                    withCredentials()
                );
            }
            const { loadingAction, successAction, errorAction } = reduxConfig;
            const config = {
                successAction,
                loadingAction,
                errorAction,
                endpoint,
            };
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(config));
            setNotify({
                message: 'Success.',
                type: 'success',
                isOpen: true,
            });
            handleModalClose();
        } catch (error) {
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    useEffect(() => {
        formReset();
        if (!!modalEditData.asset_id) {
            let newData = {
                vaccine: modalEditData.vaccine,
                vaccinationReceivedDate: moment(
                    modalEditData.dateReceived?.$date
                ).format('YYYY-MM-DD'),
            };

            setDefaultFormData(newData);
        } else {
            setDefaultFormData(modalEditData);
        }
    }, [modalEditData, modalOpen]);
    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="certificates-info-modal"
            >
                <DialogTitle id="certificates-info-modal">
                    {!!defaultFormData.asset_id ? 'Edit' : 'Add'} VAccination Info
                </DialogTitle>
                <DialogContent>
                    <form id="personal" onSubmit={handleSubmit(submitForm)}>
                        <Controls.Input
                            name="vaccine"
                            label="Vaccination"
                            defaultValue={defaultFormData.vaccine}
                            control={control}
                            error={errors?.vaccine || ''}
                        />
                        <Controls.Input
                            name="vaccinationReceivedDate"
                            label="Vaccination Issue Date"
                            defaultValue={
                                defaultFormData.vaccinationReceivedDate
                            }
                            type="date"
                            control={control}
                            error={errors?.vaccinationReceivedDate || ''}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Controls.Button onClick={handleModalClose}>
                        Cancel
                    </Controls.Button>
                    <Controls.Button form="personal" type="submit">
                        Submit
                    </Controls.Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default AddEditForm;
