import { Box, Grid, Typography, Container } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';
import { getUserData } from 'util/getUserData';
import { AssignmentTurnedInOutlined, AutoStoriesOutlined, FavoriteBorderOutlined, AccountBalanceOutlined } from '@mui/icons-material';
import DashboardCardComponent, { ICardProps } from './components/DashboardCard';
import { recentAppCardData } from './components/dashboardCard.helper';
import welcomeToLifegraph from '../../assets/images/welcome-to-lifegraph.svg';


const styles = {
	containerImage: {
		backgroundImage: 'url(/images/dashboard/hex-bg.svg), url(/images/dashboard/dashboard-home.svg)',
		backgroundRepeat : 'no-repeat',
		backgroundSize: 'contain',
		height: '40vh',
		backgroundPosition: 'left, right'
	},
	imageLogoStyle: {
		marginTop: '5.375rem',
		marginLeft: '6.3125rem',
		width: '26.81rem',
		height: '9.06rem'
	}
};

export const Dashboard = () => {
	const { selectedTheme } = useContext(ThemeContext);
	const roles = getUserData('roles');
	const isVerifier = roles.includes('VERIFIER');
	
	const dataManagerCardProps: ICardProps = {
		heading: 'DATA MANAGER',
		headingStyle: {
			mt: 4
		},
		isHeadingIcon: false,
		subHeading: 'Share & Manage your Data',
		subHeadingStyle: {
			mt: 4,
			color: selectedTheme?.palette?.primary[700],
			width: '17rem'
		},
		imageName: 'share-data-card.svg',
		buttonText: 'Let’s Do it',
		isLinkButton: true,
		goToLink: '/data-manager',
		containerStyle: {
			mr: 1
		}
	};

	const profileCardProps: ICardProps = {
		heading: 'PROFILE',
		headingStyle: {
			mt: 4
		},
		isHeadingIcon: false,
		subHeading: 'View & Edit your Profile',
		subHeadingStyle: {
			mt: 4,
			color: selectedTheme?.palette?.primary[700],
			width: '17rem'
		},
		imageName: 'view-profile-card.svg',
		buttonText: 'Let’s Do it',
		isLinkButton: true,
		goToLink: '/profile',
		containerStyle: {
			ml: 1
		}
	};

	const recentCardProps = {
		isHeadingIcon: true,
		headingStyle: {
			position: 'relative' as const,
			top: '-.5rem',
			left: '.5rem'
		},
		subHeadingStyle: {
			mt: 2.5,
			color: selectedTheme?.palette?.secondary?.grayScale
				&& selectedTheme?.palette?.secondary?.grayScale[800],
			fontSize: '35px',
			lineHeight: '41px'
		},
		isLinkButton: false,
		goToLink: '',
		descriptionStyle: {
			mt: 2,
			color: selectedTheme?.palette?.secondary?.grayScale
				&& selectedTheme?.palette?.secondary?.grayScale[700],
		}
	};

	return (
		<>
			<Box sx={styles.containerImage}>
				<img src={welcomeToLifegraph} style={styles.imageLogoStyle}/>
			</Box>
			<Container maxWidth={false} sx={{mb: 3, mt : 8}}>
				<Box>
					<Grid xs={12} display='flex' container item>
						<DashboardCardComponent {...dataManagerCardProps} />
						<DashboardCardComponent {...profileCardProps} />
					</Grid>
				</Box>
				<Box marginTop={4}>
					<Grid xs={12} container item>
						<Typography variant='h2' sx={{
							color: selectedTheme?.palette?.secondary?.grayScale
								&& selectedTheme?.palette?.secondary?.grayScale[900],
						}}>
							Recent Applications
						</Typography>
					</Grid>
					<Grid xs={12} display='flex' container marginTop={2} item>
						{!isVerifier ? <DashboardCardComponent
							{...recentCardProps}
							heading={recentAppCardData.learning.heading}
							subHeading={recentAppCardData.learning.subHeading}
							icon={<AutoStoriesOutlined sx={{
								color: selectedTheme?.palette?.primary[500]
							}} />}
							goToLink={recentAppCardData.learning.goToLink}
							description={recentAppCardData.learning.description}
							containerStyle={{
								mr: 1
							}}
						/> : ''}
						<DashboardCardComponent
							{...recentCardProps}
							heading={recentAppCardData.health.heading}
							subHeading={recentAppCardData.health.subHeading}
							icon={<FavoriteBorderOutlined sx={{
								color: selectedTheme?.palette?.tertiary?.pink &&
									selectedTheme?.palette?.tertiary?.pink[400]
							}} />}
							description={recentAppCardData.health.description}
							containerStyle={{
								mr: 1,
								ml: 1
							}}
						/>
						{isVerifier ? <DashboardCardComponent
							{...recentCardProps}
							heading={recentAppCardData.verifier.heading}
							subHeading={recentAppCardData.verifier.subHeading}
							icon={<AssignmentTurnedInOutlined sx={{
								color: selectedTheme?.palette?.primary[500]
							}} />}
							goToLink={recentAppCardData.verifier.goToLink}
							description={recentAppCardData.verifier.description}
							containerStyle={{
								mr: 1
							}}
						/> : ''}
						<DashboardCardComponent
							{...recentCardProps}
							heading={recentAppCardData.banking.heading}
							subHeading={recentAppCardData.banking.subHeading}
							icon={<AccountBalanceOutlined sx={{
								color: selectedTheme?.palette?.tertiary?.cyan &&
									selectedTheme?.palette?.tertiary?.cyan[500]
							}} />}
							description={recentAppCardData?.banking.description}
							containerStyle={{
								ml: 1
							}}
						/>
					</Grid>
				</Box>
			</Container>

		</>
	);
};
