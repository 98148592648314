import React, { useContext } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { ThemeContext } from 'util/themes';
import { FitnessProfileComponent } from './Fitness/FitnessProfileCard';
import { DashboardApps } from '../dashboard/components/DashboardApps';
import { HealthDashboardCard } from './health-dashboard-card/HealthDashboardCard';
import { itemsFirstColumn, items, apps } from './health-dashboard.helper';
import { AllergyCardComponent } from './Allergy/Allergy';
import { MedicationCardComponent } from './Medication/Medication';
import { VaccinationCardComponent } from './Vaccination/Vaccination';

const styles = {
    headerImage: {
		background: 'url(/images/dashboard/dashboard-bg.svg) no-repeat',
		backgroundSize: 'contain',
		height: '20rem',
        position: "relative",
        top: "0px",
        width: "100%"
	}
};

export function HealthDashboardComponent() {
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <>
        <Box sx={styles.headerImage}>
            <Typography variant={'h1'} sx={{...selectedTheme?.typography?.h1, p : 5}}>Health Dashboard</Typography>
        </Box>
        <Container maxWidth={false} sx={{position: "relative", top: "-12rem"}}>
            <Box sx={{backgroundColor: 'white', ml:2, mr:2}}>
                <DashboardApps apps={apps}/>
                <FitnessProfileComponent />
                <Grid display='flex' container spacing={2}>
                    <Grid item xs={4}>
                        <AllergyCardComponent cardData={itemsFirstColumn[0]}/>
                        <HealthDashboardCard {...itemsFirstColumn[1]} canEdit={false} hasLabelTopPadding={false}/>
                    </Grid>
                    <MedicationCardComponent cardData={items[0]}/>
                    <VaccinationCardComponent cardData={items[1]}/>
                </Grid>
            </Box>
        </Container>
        </>
    )
}