import { Grid, Typography, Tab, Tabs, Hidden, Container } from '@mui/material';
import { AboutCardComponent } from './about/about';
import { ContactCardCompoment } from './contact/contact';
import { WorkCardComponent } from './work/work';
import { LicenseAndCertificationsCardComponent } from './license-certificates/licenseCertifications';
import { FormalEducationCardComponent } from './formal-education/formalEducation';
import { AddressCardComponent } from './address/address';
import { ContinuingEducationCardComponent } from './continuing-education/continuingEducation';
import { VolunteerCardComponent } from './volunteer/volunteer';
import { TrainingCardComponent } from './training/training';
import { ProfilePageHeaderComponent } from './profileHeader';
import { useState, SyntheticEvent, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { ThemeContext } from 'util/themes';
const styles = {
    columnheader: {
        color: '#80848D',
        fontSize: '16px',
        fontWeight: 'bold',
        mb: 1.5,
        ml: {
            xs: '16px',
            md: '8px',
        },
    },
    tabsRow: {
        height: '48px',
        m: 1,
    },
    tab: {
        minWidth: '80px',
        p: '12px 10px',
    },
    cardWidth: {
        xs: 1,
        md: 0.48,
        lg: 0.32,
    },
    container: {
        mt: 3,
    },
};

const tabAllKey = 'ALL';
const tabPersonalKey = 'PERSONAL';
const tabExperienceKey = 'EXPERIENCE';
const tabEducationKey = 'EDUCATION';

export const ProfileComponent = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabAllKey);
    const {selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const onTabChange = (
        event: SyntheticEvent<Element, Event>,
        value: string
    ) => {
        setSelectedTabIndex(value);
    };

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    },[]);

    const isInEditMode = useSelector(personalInfoSelector.getProfileEditMode);

    return (
        <Grid container direction="row">
            <ProfilePageHeaderComponent />

            <Container maxWidth={false} sx={{ px: '0' }}>
                <Hidden mdUp>
                    <Grid
                        container
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={styles.tabsRow}
                    >
                        <Grid item xs={12}>
                            <Tabs
                                value={selectedTabIndex}
                                onChange={onTabChange}
                            >
                                <Tab
                                    label="All"
                                    value={tabAllKey}
                                    sx={styles.tab}
                                />
                                <Tab
                                    label="Personal"
                                    value={tabPersonalKey}
                                    sx={styles.tab}
                                />
                                <Tab
                                    label="Experience"
                                    value={tabExperienceKey}
                                    sx={styles.tab}
                                />
                                <Tab
                                    label="Education"
                                    value={tabEducationKey}
                                    sx={styles.tab}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container sx={styles.container}>
                    <Grid container justifyContent={'space-between'}>
                        {[tabAllKey, tabPersonalKey].includes(
                            selectedTabIndex
                        ) ? (
                            <Grid item width={styles.cardWidth}>
                                <Typography sx={{
                                    ...selectedTheme?.typography?.overlines?.overline1?.default,
                                    ...selectedTheme?.typography?.overlines?.overline1?.semibold,
                                    color: selectedTheme?.palette?.secondary.grayScale && selectedTheme?.palette?.secondary.grayScale[600],
                                    mb: 1,
                                    ml: 1
                                }}>
                                    {'PERSONAL'}
                                </Typography>
                                <Grid item>
                                    <AboutCardComponent
                                        title={'About'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <ContactCardCompoment
                                        title={'Contact'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <AddressCardComponent
                                        title={'Address'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {[tabAllKey, tabExperienceKey].includes(
                            selectedTabIndex
                        ) ? (
                            <Grid item width={styles.cardWidth} gap={'24px'}>
                                <Typography sx={{
                                    ...selectedTheme?.typography?.overlines?.overline1?.default,
                                    ...selectedTheme?.typography?.overlines?.overline1?.semibold,
                                    color: selectedTheme?.palette?.secondary.grayScale && selectedTheme?.palette?.secondary.grayScale[600],
                                    mb: 1,
                                    ml: 1
                                }}>
                                    {'EXPERIENCE'}
                                </Typography>
                                <Grid item>
                                    <LicenseAndCertificationsCardComponent
                                        title={'Licenses & Certifications'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <WorkCardComponent
                                        title={'Work'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <VolunteerCardComponent
                                        title={'Volunteer'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {[tabAllKey, tabEducationKey].includes(
                            selectedTabIndex
                        ) ? (
                            <Grid item width={styles.cardWidth}>
                                <Typography sx={{
                                    ...selectedTheme?.typography?.overlines?.overline1?.default,
                                    ...selectedTheme?.typography?.overlines?.overline1?.semibold,
                                    color: selectedTheme?.palette?.secondary.grayScale && selectedTheme?.palette?.secondary.grayScale[600],
                                    mb: 1,
                                    ml: 1
                                }}>
                                    {'EDUCATION'}
                                </Typography>
                                <Grid item>
                                    <FormalEducationCardComponent
                                        title={'Formal Education'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <ContinuingEducationCardComponent
                                        title={'Continuing Education'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                                <Grid item>
                                    <TrainingCardComponent
                                        title={'Training'}
                                        canEdit={isInEditMode}
                                        selectedTheme={selectedTheme}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};
