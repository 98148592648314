import React, { Children, useContext } from 'react';
import {
    Toolbar,
    Container,
    Tab,
    Tabs,
    CssBaseline,
    Box,
    Grid,
    AppBar,
} from '@mui/material';
import { getNavLinksBySelectedModule } from 'util/navLinks';
import { HeaderAvatarDropdownComponent } from './HeaderAvatarDropdown';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationsComponent } from './Notifications';
import { HelpComponent } from './Help';
import { ThemeContext } from 'util/themes';
import { useSelector } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import lifetrekLogo from 'assets/images/logos/lifetrek-logo.svg';

const drawerWidth = 240;
const closeDrawerWidth = 95;
const styles = {
    root: {
        display: 'flex',
    },
    appBar: (theme: any, open: boolean, width: number) => ({
        backgroundColor: 'white',
        borderBottom: '2px solid #E7ECEE',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: `calc(100% - ${width}px)`,
        marginLeft: `${width}px`,
        ...(open && {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        zIndex: 1,
    }),
    appBarGridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center'
    },
    leftLogo: {
        height: 'auto',
        width: '120px',
    },
    appBarRightGridItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'right',
        gap: '20px',
    },
};

interface IDesktopHeaderProps {
    appName: string;
    desktopWidth: number;
    open: boolean;
}

export default function DesktopHeader(props: IDesktopHeaderProps) {
    const { open } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const location = useLocation();
    const selectedModule = useSelector(routerSelector.selectCurrentModule);
    const navLinks = getNavLinksBySelectedModule(selectedModule);

    const headerShadow = selectedModule === 'learning' ? {boxShadow : '0px 1px 2px rgba(0, 0, 0, 0.15)'} : { boxShadow : 'none'};

    // TODO, when we change url's for home paths then we need to change all the home path url's at once and remove this condition
    const indexToCheckBasedOnModule = selectedModule === 'home' ? 1 : 2;
    let selectedTabIndex: string | boolean =
        location.pathname.split('/')[indexToCheckBasedOnModule];
    const find = navLinks.find(
        (x: any) =>
            selectedTabIndex === x.path.split('/')[indexToCheckBasedOnModule]
    );

    const isNavHasHover = navLinks.some(nav => nav?.isLinkHover === true);
    
    if (!find) {
        selectedTabIndex = false;
    } else {
        selectedTabIndex = find.path;
    }

    const onTabClick = (path: string) => {
        if (path !== location.pathname) {
            history.push(path);
        }
    };

    const getSelectedNavLogo = () => {
        if(selectedModule === 'learning') {
            return lifetrekLogo
        } else {
            return ''
        }
    };
        
    return (
        <Box sx={styles.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{...styles.appBar(
                    selectedTheme,
                    open,
                    open ? drawerWidth : closeDrawerWidth
                ), ...headerShadow}}
            >
                <Toolbar disableGutters>
                    <Container maxWidth={false}>
                        <Grid container pl={3}>
                            <Grid item container xs={10} sx={styles.appBarGridItem}>
                                {getSelectedNavLogo() && <Grid item alignItems={'center'} pl={5.25} pr={9.5} pt={1}>
                                    <img src={getSelectedNavLogo()}/>
                                </Grid>}
                                <Grid item>
                                    <Tabs
                                        value={selectedTabIndex}
                                        sx={{
                                            ...selectedTheme?.typography?.Components
                                                ?.tabBar?.tabs,
                                            ...(isNavHasHover &&
                                                {
                                                    '& .MuiTabs-indicator': {
                                                        display:  'none' 
                                                    }
                                                }
                                            ),
                                            ...(isNavHasHover && selectedTheme?.typography?.Components
                                                ?.tabBar?.tabHover)
                                        }}
                                    >
                                        {Children.toArray(
                                            navLinks.map((navLink) => {
                                                return (
                                                    <Tab
                                                        onClick={() =>
                                                            onTabClick(navLink.path)
                                                        }
                                                        value={navLink.path}
                                                        label={navLink.title}
                                                        sx={{
                                                            color: '#80848D',
                                                            textTransform: 'none',
                                                            fontWeight: '600',
                                                            fontSize: '16px',
                                                            ...selectedTheme
                                                                ?.typography
                                                                ?.Components?.tabBar
                                                                ?.tab,
                                                        }}
                                                    />
                                                );
                                            })
                                        )}
                                    </Tabs>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={2} sx={styles.appBarGridItem}>
                                <Box sx={styles.appBarRightGridItem}>
                                    <NotificationsComponent />
                                    <HelpComponent />
                                    <HeaderAvatarDropdownComponent />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
