import { addressActionType, ProfileLookupDataActions } from 'redux/actionsTypes';

export const addressConfig = {
    loadingAction: addressActionType.PROFILE_ADDRESSES_LOADING,
    successAction: addressActionType.PROFILE_ADDRESSES_SUCCESS,
    errorAction: addressActionType.PROFILE_ADDRESSES_ERROR,
    endpoint: '/v2/address'
};

export const addressLookupConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_ADDRESS_TYPES_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_ADDRESS_TYPES_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_ADDRESS_TYPES_ERROR,
    endpoint: '/v2/address-external',
}