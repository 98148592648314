import React, { useEffect, useContext } from 'react';
import {
    Box,
    Button,
    SvgIcon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Link,
    Typography,
    Paper,
    Skeleton,
} from '@mui/material';
import { baseTheme, ThemeContext } from 'util/themes';
import { Icon } from '@iconify/react';
import {
    accountCheck,
    circleOutline
} from 'components/Pathway/pathwayIcons';
import { useHistory } from 'react-router-dom';
import { ChipsComponent } from 'components';
import {
    Close as CloseIcon,
    OpenInNew as OpenInNewIcon, 
    ArrowBack as ArrowBackIcon,
    AssignmentOutlined as AssignmentIcon,
    QuestionAnswerOutlined as QuestionAnswerIcon,
    QuizOutlined as QuizIcon
 } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { pathwaySelector } from 'redux/selectors';
import * as Controls from 'lib/controls';

interface IProps {
    courseSisId: string;
    title?: string;
    label?: string;
    skills?: string[];
}

const styles = {
    paper: {
        borderRadius: '12px 12px 0 0',
        ml: { xs: 0, md: 3 },
        border: '1px solid #BDC1C5',
    },
    title: {
        color: '#202124',
        fontSize: { xs: '20px', md: '24px' },
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '36px',
    },
    label: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: baseTheme.spacing(3),
        display: 'block',
        textTransform: 'uppercase',
    },
    subTitle: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '20px',
    },
    listItem: {
        fontSize: '16px',
        color: '#202124',
        fontWeight: 600,
    },
    listTypeItem:{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#9AA0A6'
    },
    disableListItem: {
        borderTop: '1px solid #BDC1C5',
        backgroundColor: '#f5f5f5',
        opacity: '0.6',
        PointerEvent: 'none',
    },
    enableListItem: {
        borderTop: '1px solid #BDC1C5',
        opacity: '1',
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
    itemType: {
        fontWeight: 100,
        minWidth: '30px'
    }
};

export function LessonActivity(props: IProps) {
    const { courseSisId, skills } = props;
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const activities = useSelector(pathwaySelector.selectCurrentLessonCourses);
    const loadingActivies = useSelector(
        pathwaySelector.selectCurrentLessonCoursesLoading
    );
    const nextActivity = useSelector(pathwaySelector.selectNextActivity);
    const areAllActivitiesCompleted = useSelector(
        pathwaySelector.selectAllActivitiesCompleted
    );
    const activityTitle = useSelector(pathwaySelector.selectLessonTitle);
    const dispatch = useDispatch();

    const loadingRows = Array.from(Array(5), (_, i) => {
        return { id: i };
    });
    const activityItems = loadingActivies ? loadingRows : activities;

    useEffect(() => {
        dispatch(
            pathwayActionCreators.getSelectedPathwayMilestoneCourses(
                courseSisId
            )
        );
    }, [courseSisId]);

    const getIcon = (activity: any) => {
        const completed = activity?.completion_requirement?.completed;
        if (completed) {
            return <Icon icon={accountCheck} style={{ color: 'green' }} />;
        } else {
            return <Icon icon={circleOutline} />;
        }
    };

    const getListItemStyle = (activity: any) => {
        const completed = activity?.completion_requirement?.completed;
        return completed ? {...styles.disableListItem, ...selectedTheme?.card?.muted} : styles.enableListItem;
    };

    const removeSelectedLesson = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('course_sis_id');
        currentUrlParams.delete('lesson_id');
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
    };

    const getTypeIcon = (type: any) => {
        const iconFontSize = '22px';
        const greyColor = '#9AA0A6';
        switch (type) {
            case 'Assignment':
                return <AssignmentIcon style={{ color: greyColor, fontSize: iconFontSize }}/>;
            case 'Discussion':
                return <QuestionAnswerIcon style={{ color: greyColor, fontSize: iconFontSize }} />;
            case 'Quiz':
                return <QuizIcon style={{ color: greyColor, fontSize: iconFontSize }} />;
            default: 
                return null;
        }
    };

    return (
        <>
            <Box sx={{ p: 2, display: { xs: 'flex', md: 'none' } }}>
                <Controls.Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    sx={styles.button}
                    onClick={removeSelectedLesson}
                >
                    <Typography sx={{
                        color: selectedTheme?.palette?.purple?.light
                    }}>
                        My Pathway
                    </Typography>
                </Controls.Button>
            </Box>
            <Paper elevation={2} sx={{...styles.paper, ...selectedTheme?.card?.default}}>
                <Box sx={{ p: 2 }}>
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={removeSelectedLesson}
                        sx={{ float: 'right' }}
                    ></CloseIcon>

                    <Typography
                        variant="overline"
                        component="div"
                        sx={styles.label}
                    >
                        Lesson
                    </Typography>
                    <Typography variant="h3" sx={styles.title}>
                        {loadingActivies ? (
                            <Skeleton
                                sx={{ width: { xs: '80%', md: '40%' } }}
                            />
                        ) : (
                            activityTitle
                        )}
                    </Typography>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="body1" sx={styles.subTitle}>
                                Skills :&nbsp;
                            </Typography>
                            <ChipsComponent 
                                values={skills}
                                sx={{
                                    color: selectedTheme?.palette?.purple?.light,
                                    borderColor: selectedTheme?.palette?.purple?.light
                                }}
                            />
                        </Box>
                        {areAllActivitiesCompleted ? (
                            <></>
                        ) : (
                            <Button
                                disabled={loadingActivies}
                                variant="contained"
                                size="medium"
                                endIcon={<OpenInNewIcon />}
                                sx={{
                                    textTransform: 'none',
                                    mt: { xs: 2, md: 0 },
                                    py: '0.25rem',
                                    borderRadius: '4px',
                                    ...selectedTheme?.button?.contained?.primary
                                }}
                                href={nextActivity?.html_url}
                                target="_blank"
                            >
                                <Typography sx={{
                                    ...selectedTheme?.typography?.Components?.button?.default,
                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                }}>
                                    Resume
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
                <List sx={{ py: 0 }}>
                    {React.Children.toArray(
                        activityItems?.map((activity: any) => {
                            const listItemStyle = getListItemStyle(activity);
                            return (
                                <>
                                    <ListItem disablePadding sx={listItemStyle}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <SvgIcon
                                                    fontSize="large"
                                                    viewBox="36 36"
                                                >
                                                    {getIcon(activity)}
                                                </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    loadingActivies ? (
                                                        <Skeleton
                                                            sx={{
                                                                width: {
                                                                    xs: '80%',
                                                                    md: '50%',
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <Link
                                                            href={
                                                                activity?.html_url
                                                            }
                                                            target="_blank"
                                                            underline="none"
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={
                                                                        styles.listItem
                                                                    }
                                                                >
                                                                    {
                                                                        activity.title
                                                                    }
                                                                </Typography>

                                                                {activity.type !==
                                                                'Page' ? (
                                                                    <Typography
                                                                        sx={
                                                                            styles.listTypeItem
                                                                        }
                                                                    >
                                                                        <ListItemIcon sx={styles.itemType}>
                                                                            {getTypeIcon(activity.type)}
                                                                        </ListItemIcon>
                                                                        {
                                                                            activity.type
                                                                        }
                                                                    </Typography>
                                                                    
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Box>
                                                        </Link>
                                                    )
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            );
                        })
                    )}
                </List>
            </Paper>
        </>
    );
}
