import { produce } from 'immer';

import * as actionTypes from '../../actionTypes-old';

export interface IOccupationInfo {
    error: boolean;
    data: IOccupationInfoObject;
    loading: boolean;
}

export interface IClaimedObject {
    assetType: string;
    asset_id: string;
    verificationStatus: string;
}

export interface IOccupationInfoObject {
    claimed: Array<IClaimedObject>;
    unclaimed: Array<IClaimedObject>;
}

interface IOccupationInfoAction {
    type: string;
    payload: IOccupationInfoObject;
}

const initialData = {
    claimed: [],
    unclaimed: [],
};

const initialState = {
    error: false,
    data: initialData,
    loading: false,
};

const reducer = (
    state: IOccupationInfo = initialState,
    action: IOccupationInfoAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.GET_OCCUPATION_INFO_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = false;
                return draft;
            case actionTypes.GET_OCCUPATION_INFO_LOADING:
                draft.error = true;
                draft.data = initialData;
                draft.loading = true;
                return draft;
            case actionTypes.GET_OCCUPATION_INFO_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
