import { personalnfoActionType } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';
import { IReduxDataConfig } from '../../../@types/profile-types';

// TODO next function is only used in health profile, do we need it ?
export function updateLoggedInUserData() {
    return {
        type: personalnfoActionType.REFRESH_USER_DATA,
    };
}

export const getProfilePersonalInfo = () => async (dispatch: Function) => {
    dispatch({ type: personalnfoActionType.PROFILE_PERSONALINFO_LOADING });
    try {
        const resp = await axios().get('/v2/person', withCredentials());
        dispatch({
            type: personalnfoActionType.PROFILE_PERSONALINFO_SUCCESS,
            payload: resp.data.data[0],
        });
    } catch (error) {
        dispatch({ type: personalnfoActionType.PROFILE_PERSONALINFO_ERROR });
    }
};

export const getPersonalDynamicCardsInfo = (config: IReduxDataConfig) => async (dispatch: Function) => {
    dispatch({ type: config.loadingAction });
    try {
        const resp = await axios().get(config.endpoint, withCredentials());
        dispatch({
            type: config.successAction,
            payload: resp.data.data,
        });
    } catch (error) {
        dispatch({ type: config.errorAction });
    }
};

export const getProfilePathwayCardsInfo = (config: IReduxDataConfig) => async (dispatch: Function) => {
    dispatch({ type: config.loadingAction });
    try {
        const resp = await axios().get(config.endpoint, withCredentials());
        dispatch({
            type: config.successAction,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: config.errorAction });
    }
};