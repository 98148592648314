import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { addressSelector } from 'redux/selectors';
import { AddressForm } from './addressForm';
import {
    ILookup,
    IProfileAddressesObject,
} from '../../../@types/profile-types';
import mockAddressLookupData from 'assets/data/profile/address-types.json';
import { addressConfig, addressLookupConfig } from './address.helper';
import { ThemeContext } from 'util/themes';
import { AddressComponent } from './addressComponent';

interface IAddressCardComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const AddressCardComponent = (props: IAddressCardComponent) => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);

    const loading = useSelector(addressSelector.selectAddressLoading);
    const addressListRaw = useSelector(addressSelector.selectAddressData);
    const addressList = useSelector(addressSelector.selectAddressData);

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
        );
        // TODO remove this block of code when external data api is ready
        const mockData = mockAddressLookupData.data as ILookup[];
        dispatch({
            type: addressLookupConfig.successAction,
            payload: mockData,
        });

        // TODO uncomment next line when external data api is ready
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressLookupConfig));
    }, []);

    const { title, canEdit, type } = props;

    const handleModalOpen = (id: string) => {
        const find = addressListRaw.find(
            (x: IProfileAddressesObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addAddress = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Box>
            <Grid item xs sx={selectedTheme.card?.gridWrapper}>
                <Paper sx={selectedTheme.card?.default}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addAddress}
                    />
                    <Divider />
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        {addressList.length === 0 && !loading ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            addressList.map((x, index) => {
                                return (
                                    <AddressComponent
                                        cardTitle={title}
                                        data={{ ...x }}
                                        loading={loading}
                                        selectedTheme={selectedTheme}
                                        canEdit={canEdit}
                                        handleModalOpen={handleModalOpen}
                                    />
                                );
                            })
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <AddressForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                    type="PROFILE"
                />
            ) : (
                <> </>
            )}
        </Box>
    );
};

AddressCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
