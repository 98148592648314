import { healthDashboardMedicationActionTypes } from 'redux/actionsTypes';
import produce from 'immer';

interface IMedicationObj {
    assetType: string;
    asset_id: string;
    medicine: any;
    prescribedBy: string;
    userId: string;
    verificationAssetId: string;
    verificationCompany: string;
    verificationHash: string;
    verificationSignature: string;
    verificationStatus: string;
    verificationTimestamp: any;
    verificationType: string;
    verifier: string;
}

export interface IMedicationInfo {
    error: boolean;
    data: IMedicationObj[];
    loading: boolean;
}

interface IMedicationAction {
    type: string;
    payload: {
        data: IMedicationObj[]
    }
}

const initialStateData = {
    error: false,
    data: [],
    loading: false,
};

const reducer = (
    state: IMedicationInfo = initialStateData,
    action: IMedicationAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_LOADING:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case healthDashboardMedicationActionTypes.GET_HEALTH_DASHBOARD_MEDICATION_SUCCESS:
                draft.error = false;
                draft.data = action.payload.data;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;