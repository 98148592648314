import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Box, SxProps, Theme } from '@mui/system';

const inputWrapper: SxProps<Theme> = {
    my: 1,
};

interface IInputProps {
    name: string;
    label: string;
    datatestid?: string;
    type?: string;
    error: any;
    control: any;
    defaultValue?: string;
    required?: boolean;
    inputProps?: any;
    labelStyles?: any
    inputStyleProps?: any;
    // All other props
    [other: string]: any;
}

export function Input(props: IInputProps) {
    const {
        name,
        label,
        datatestid,
        error = null,
        control,
        type = 'text',
        defaultValue,
        required,
        inputProps,
        labelStyles,
        inputStyleProps,
        ...other
    } = props;

    let testId = datatestid;
    if (!testId) {
        testId = `${name}_input`;
    }

    const labelClassName = required ? 'required' : '';
    const labelStyle = labelStyles ? labelStyles : {};
    
    
    return (
        <Box sx={inputWrapper}>
            <label htmlFor={name} className={labelClassName} style={labelStyle}>{label}</label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <TextField
                        variant="outlined"
                        fullWidth
                        type={type}
                        InputProps={{
                            style: inputStyleProps
                        }}
                        inputProps={{
                            'data-testid': testId,
                            ...inputProps,
                        }}
                        {...other}
                        {...field}
                        {...(error && {
                            error: true,
                            helperText: error.message,
                        })}
                    />
                )}
            />
        </Box>
    );
}
