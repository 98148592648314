import { useContext } from 'react';
import { Typography } from '@mui/material';
import { history } from 'App';
import { useSelector, useDispatch } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';

export function ShareMyDataButton () {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const currentRoute = useSelector(routerSelector.selectCurrentLocation);
    const shareMyDataClicked = () => {
        history.push('/share-my-data/who', { from: currentRoute });
        const shareObject = {
            shareOrigin: currentRoute
        };
        dispatch({ type : shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT, payload: shareObject });
    }

    return (
        <Button
            onClick={() => shareMyDataClicked()}
            size='large'
            variant='contained'
            color='secondary'
            sx={{ borderRadius: '4px' }}
        >
            <Typography sx={{
                ...selectedTheme?.typography?.Components?.button?.default,
                ...selectedTheme?.typography?.Components?.button?.large,
            }}>
                Share Data
            </Typography>
        </Button>
    )
}