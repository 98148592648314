import { pathwayProfileActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IPathway } from '../../../@types/pathway-types';
import mockPathwayProfileData from 'assets/data/profile/profile-pathway.json';

// const initialData: IProfilePathway[] = mockPathwayProfileData.data as IProfilePathway[];

export interface IProfilePathwayInfo {
    error: boolean;
    data: IPathway[];
    loading: boolean;
}

interface IProfilePathwayAction {
    type: string;
    payload: IPathway[];
}

const initialStateData = {
    error: false,
    data: [],
    loading: true,
};

const reducer = (
    state: IProfilePathwayInfo = initialStateData,
    action: IProfilePathwayAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case pathwayProfileActionType.PATHWAY_PROFILE_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case pathwayProfileActionType.PATHWAY_PROFILE_LOADING:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case pathwayProfileActionType.PATHWAY_PROFILE_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
