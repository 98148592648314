import {
    Box, Divider, Grid, Paper,
    Typography,
} from '@mui/material';
import { ProfileConstants, HealthConstants } from 'app-constants';

import {
    AboutWrapper,
    AddressComponent,
    ContactComponent,
    CertificationKeyComponent,
    ContinuingEducationComponent,
    EmailComponent,
    FormalEducationComponent,
    LicenceKeyComponent,
    PassportWrapper,
    SocialSecurityWrapper,
    TrainingComponent,
    VolunteerComponent,
    WorkComponent
} from 'pages/profile/commonComponent';


import { HealthDashboardCardInfoRowComponent } from 'pages/health-dashboard/health-dashboard-card/HealthDashboardCardRow';

import { CardTitleComponent } from '..';
import React from 'react';

export const getVerificationComponent = (type: string, data: any, sharedWith: string, attributes: any) => {
  
    let element = null
    switch (type) {
        // PERSONAL
        case ProfileConstants.PERSONAL_INFO:
            element = <AboutWrapper {...data} />;
            break;
        case ProfileConstants.PASSPORT_INFO:
            element = <PassportWrapper {...data} />;
            break;
        case ProfileConstants.SOCIAL_SECURITY_INFO:
            element = <SocialSecurityWrapper {...data} />;
            break;
        case ProfileConstants.EMAIL_INFO:
            element = <EmailComponent {...data} />;
            break;
        case ProfileConstants.PHONE_INFO:
            element = <ContactComponent {...data} />;
            break;
        case ProfileConstants.ADDRESS_INFO:
            element = <AddressComponent {...data} />
            break;
        // EXPERIENCE
        case ProfileConstants.LICENSE_INFO:
            element = <LicenceKeyComponent {...data} />;
            break;
        case ProfileConstants.CERTIFICATION_INFO:
            element = <CertificationKeyComponent {...data} />;
            break;
        case ProfileConstants.WORK_INFO:
            element = <WorkComponent {...data} />;
            break;
        case ProfileConstants.VOLUNTEER_INFO:
            element = <VolunteerComponent {...data} />;
            break;
        // EDUCATION
        case ProfileConstants.FORMAL_EDUCATION_INFO:
            element = <FormalEducationComponent {...data} />
            break;
        case ProfileConstants.CONTINUING_EDUCATION_INFO:
            element = <ContinuingEducationComponent {...data} />
            break;
        case ProfileConstants.TRAINING_INFO:
            element = <TrainingComponent {...data} />;
            break;
        case [HealthConstants.ALLERGY_INFO, HealthConstants.MEDICATION_INFO, HealthConstants.VACCINATION_INFO].includes(type) ? type : false :
            element = <HealthDashboardCardInfoRowComponent {...data} />;
            break;
        default:
            return (
                <>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="h6">Shared With:</Typography>
                        <Typography variant="body1">
                            {sharedWith}
                        </Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="h6">Shared Information:</Typography>
                        <Typography variant="body1">
                            {attributes.join(', ')}
                        </Typography>
                    </Box>
                </>
            )
    }

    return (
        <Grid item xs sx={{ ...data.selectedTheme.card?.gridWrapper, p: 3 }}>
            <Paper sx={data.selectedTheme.card?.default}>
                <CardTitleComponent title={data?.cardTitle || type} />
                <Divider />
                <Box>
                    <Grid container sx={{ paddingTop: 2 }}>
                        {element}
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    )
}
