import { useContext } from 'react';
import { Grid, } from '@mui/material';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes';
import { formSchemaResolver } from './vaccination.helper';
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { ModalFormWrapper } from '../HealthDashboardModalWrapper';
import { modalStyles } from '../health-dashboard.helper';

interface IVaccinationFormInputs {
    vaccine: string;
    administerDate: string;
    administeredBy: string;
}

const styles = {
    controlPadding: { },
    inputWidth: {
        width: '100%'
    },
    labelStyles: { }
};

export const VaccinationForm = (props:any) => {
    const { modalOpen, selectedData = {}, isEdit = false } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const copyData = {...selectedData};
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IVaccinationFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: copyData
    });

    const submitForm = async (formValues: any) => {
        try {
            const requestData = {
                vaccine: formValues.vaccine,
                administerDate: stringToUTCDate(formValues.administerDate),
                administeredBy: formValues.administeredBy,
                userId: selectedData.userId
            };
        
            if(selectedData.assetId) {
                await axios().put(`/v2/vaccination/${selectedData.assetId}`, requestData, withCredentials());
            } else {
                delete requestData.userId;
                await axios().post(`/v2/vaccination`, requestData, withCredentials())
            }
            dispatch(healthDashboardActionCreators.getHealthDashboardVaccinationList());
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ModalFormWrapper 
            formId='vaccination'
            modalOpen={modalOpen}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            title='Vaccination'
            handleModalClose={props.handleModalClose}
            onSubmitForm={submitForm}
        >
            <>
                <Grid item xs={12}>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item sx={styles.inputWidth}>
                            <Controls.Input
                                name="vaccine"
                                label="Vaccination"
                                defaultValue={copyData.vaccine}
                                control={control}
                                error={errors?.vaccine || ''}
                                inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                                sx={{...selectedTheme?.input}}
                                labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item sx={styles.inputWidth}>
                            <Controls.Input
                                name="administerDate"
                                label="Date Administered"
                                type={'date'}
                                defaultValue={copyData.administerDate}
                                control={control}
                                error={errors?.administerDate || ''}
                                inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                                sx={{...selectedTheme?.input}}
                                labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item sx={styles.inputWidth}>
                            <Controls.Input
                                name="administeredBy"
                                label="Administered By"
                                defaultValue={copyData.administeredBy}
                                control={control}
                                error={errors?.administeredBy || ''}
                                inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                                sx={{...selectedTheme?.input}}
                                labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </ModalFormWrapper>
    )
}