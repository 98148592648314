import React from 'react';
import { Box, Stack } from '@mui/material';
import { styles } from '../verifyGrid.helper';

export interface IVerifyGridDetailRow {
    label: string;
    value: string;
}
export function VerifyGridDetailRow(props: IVerifyGridDetailRow) {
    const { label, value } = props;

    return (
        <Box sx={styles.row}>
            <Box sx={{ ...styles.cell, ...styles.label }}>{label}</Box>
            <Box sx={{ ...styles.cell, ...styles.value }}>{value}</Box>
        </Box>
    );
}
