import { useMediaQuery, useTheme } from '@mui/material';
import DesktopHeader from './DesktopHeader';
import MobileDrawer from './MobileDrawer';

interface IHeaderProps {
    appName: string;
    desktopWidth: number;
    open: boolean;
}

function Header(props: IHeaderProps) {
    const { appName, desktopWidth, open } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (isMobile) {
        return <MobileDrawer appName={appName} />;
    }
    return <DesktopHeader desktopWidth={desktopWidth} appName={appName} open={open}/>;
}

export default Header;
