import { produce } from 'immer';

import { consentLogActionTypes } from 'redux/actionsTypes';

export interface IContract {
    error: boolean;
    data: IContractObject;
    loading: boolean;
}

export interface IContractObject {
    address_info: string;
    phone_info: string;
    email_info: string;
    education_info: string;
    occupation_info: string;
    certification_info: string;
    licensure_info: string;
    training_info: string;
    vaccination_info: string;
}

interface IContractAction {
    type: string;
    payload: IContractObject;
}

const initialData = {
    address_info: '',
    phone_info: '',
    email_info: '',
    education_info: '',
    occupation_info: '',
    certification_info: '',
    licensure_info: '',
    training_info: '',
    vaccination_info: '',
};

const initialState = {
    error: false,
    data: initialData,
    loading: false,
};

const reducer = (state: IContract = initialState, action: IContractAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case consentLogActionTypes.REVOKE_CONSENT_LOGS_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = false;
                return draft;
            case consentLogActionTypes.REVOKE_CONSENT_LOGS_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = true;
                return draft;
            case consentLogActionTypes.REVOKE_CONSENT_LOGS_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
