import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';

export const styles = {
    PROFILE: {
        controlPadding: { },
        labelStyles: { }
    },
    ONBOARDING: {
        controlPadding: {
            style: {
                padding: '10px 12px 8px 12px'
            }
        },
        labelStyles: {
            fontSize: '14px'
        }
    }
}

export type IEmailFormInputs = {
    primary: boolean;
    emailAddress: string;
    type: string;
    asset_id?: string;
    isRequired? : boolean;
};

export const formDefaults = {
    primary: false,
    emailAddress: '',
    type: 'personal',
    asset_id: '',
};

const schema = yupObject({
    emailAddress: yupString().email(validationMessage.email).max(255, validationMessage.max(255))
    .when('emailRequired', {
        is : (val:any) => val === 'yes' ? true : false,
        then : yupString().required(validationMessage.required)
    }),
    type: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    otherType: yupString()
        .when('type', {
            is: '__other__',
            then: yupString().max(255, validationMessage.max(255)).required(validationMessage.required)
        })
});

export const formSchemaResolver = yupResolver(schema);