import { useEffect, useContext } from 'react';
import { OnboardingLayoutComponent } from 'pages/onboarding';
import { Typography, Box, Grid } from '@mui/material';
import { OnboardingScreen1Helper } from './onboardingScreen1.helper';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';
const styles = {
    gradientMaxHeight: {
        xs: '30vh',
        md: '100%',
    },
    sectionHolder: {
        m: 'auto',
        width: {
            xs: '95%',
            md: '48%',
        },
    },
    header: {
        mt: { xs: '20%', md: '30%' },
        fontSize: '40px',
        color: '#202124',
    },
    subHeader: {
        fontSize: '20px',
        color: '#202124',
    },
};

export const OnboardingScreen1Component = () => {
    const history = useHistory();
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    },[]);

    const getStarted = () => {
        history.push('/onboarding/2');
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6} maxHeight={styles.gradientMaxHeight}>
                <OnboardingLayoutComponent imageFileName={'onboarding-no-top-hexes'} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={styles.sectionHolder}>
                    <Typography sx={{...styles.header, ...selectedTheme?.typography?.banner?.banner2, color: selectedTheme?.palette?.primary[700]}}>
                        {OnboardingScreen1Helper.header}
                    </Typography>
                    <br />
                    <br />
                    <Typography sx={{
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body3,
                        ...selectedTheme?.typography?.body?.regular,
                    }}>
                        {OnboardingScreen1Helper.subHeader}
                    </Typography>
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ float: 'right', textTransform: 'none', px: 2.75, py: 1}}
                        onClick={getStarted}
                        color={'primary'}
                    >
                        <Typography component={'span'} sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            {OnboardingScreen1Helper.buttonLabel}
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
