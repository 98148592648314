import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import lifetrekCardIcon from 'assets/images/icons/marketplace/lifetrek-card-icon.svg';
import burstIQCardIcon from 'assets/images/icons/marketplace/burstIQ-card-icon.svg';
import learingChainCardIcon from 'assets/images/icons/marketplace/learning-chain-card-icon.svg';
import suzieCardIcon from 'assets/images/icons/marketplace/suzie-card-icon.svg';
import healthyHelixCardIcon from 'assets/images/icons/marketplace/healthy-helix-card-icon.svg';
import lotusMeditationCardIcon from 'assets/images/icons/marketplace/lotus-meditation-card-icon.svg';
import unhcCardIcon from 'assets/images/icons/marketplace/uhnc-card-icon.svg';
import boinkCardIcon from 'assets/images/icons/marketplace/boink-card-icon.svg';
import bankerBoxCardIcon from 'assets/images/icons/marketplace/banker-box-card-icon.svg';
import spendFriendCardIcon from 'assets/images/icons/marketplace/spend-friend-card-icon.svg';
import masterCardIcon from 'assets/images/icons/marketplace/mastercard-card-icon.svg';


export interface ImarketPlaceItem {
    headingIconUrl: string;
    headingIconWidth: string | number;
    headingIconHeight: string | number;
    headingText?: string;
    headingTextColor?: string;
    subHeading: string;
    description: string;
    addBtnText: string;
    StartIcon: any;
    isAdded: boolean;
}

export type IMarketplaceJSON = {
    section: {
        sectionHeading: string;
        SectionIcon?: any;
        data: ImarketPlaceItem[];
    }[];
}

export const MarketplaceJSON: IMarketplaceJSON = {
    section: [
        {
            sectionHeading: 'Learning',
            SectionIcon: {
                icon: AutoStoriesOutlinedIcon,
                color: '#3FB3B0',
                height: '24px',
                width: '25.54px'
            },
            data: [
                {
                    headingIconUrl: lifetrekCardIcon,
                    headingIconWidth: '47.57px',
                    headingIconHeight: '30px',
                    headingText: '',
                    subHeading: 'LifeTrek',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Added',
                    StartIcon: DoneIcon,
                    isAdded: true,
                },
                {
                    headingIconUrl: burstIQCardIcon,
                    headingIconWidth: '39.18px',
                    headingIconHeight: '35px',
                    headingText: 'Lifegraph app',
                    headingTextColor: '#43838E',
                    subHeading: 'LearnIQ',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Added',
                    StartIcon: DoneIcon,
                    isAdded: true,
                },
                {
                    headingIconUrl: learingChainCardIcon,
                    headingIconWidth: '31.59px',
                    headingIconHeight: '35px',
                    headingText: 'New!',
                    headingTextColor: '#0FA795',
                    subHeading: 'Learning Chain',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: suzieCardIcon,
                    headingIconWidth: '35px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'Suzie',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                }
            ],
        },
        {
            sectionHeading: 'Health',
            SectionIcon: {
                icon: FavoriteBorderOutlinedIcon,
                color: '#E6577B',
                height: '24px',
                width: '26px'
            },
            data: [
                {
                    headingIconUrl: healthyHelixCardIcon,
                    headingIconWidth: '35px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'Healthy Helix',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: lotusMeditationCardIcon,
                    headingIconWidth: '35px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'Lotus Meditations',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: burstIQCardIcon,
                    headingIconWidth: '39.18px',
                    headingIconHeight: '35px',
                    headingText: 'Lifegraph app',
                    headingTextColor: '#43838E',
                    subHeading: 'WellU',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: unhcCardIcon,
                    headingIconWidth: '28px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'UHNC Health',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
            ],
        },
        {
            sectionHeading: 'Banking',
            SectionIcon: {
                icon: AccountBalanceOutlinedIcon,
                color: '#00BCD4',
                height: '24px',
                width: '25px'
            },
            data: [
                {
                    headingIconUrl: boinkCardIcon,
                    headingIconWidth: '35px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'Boink',
                    description: 'Change starts here. We help you save your change and grow it in a meaningful way.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: burstIQCardIcon,
                    headingIconWidth: '39.18px',
                    headingIconHeight: '35px',
                    headingText: 'Lifegraph app',
                    headingTextColor: '#43838E',
                    subHeading: 'BIQ Bank',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Added',
                    StartIcon: DoneIcon,
                    isAdded: true,
                },
                {
                    headingIconUrl: bankerBoxCardIcon,
                    headingIconWidth: '38px',
                    headingIconHeight: '30px',
                    headingText: 'New!',
                    headingTextColor: '#0FA795',
                    subHeading: 'Bankers Box',
                    description: 'Retirement savings and financial health and management.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: spendFriendCardIcon,
                    headingIconWidth: '33.33px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'Spend Friend',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
            ],
        },
        {
            sectionHeading: 'Verification',
            SectionIcon: {
                icon: AssignmentTurnedInOutlinedIcon,
                color: '#2FC89A',
                height: '24px',
                width: '21px'
            },
            data: [
                {
                    headingIconUrl: masterCardIcon,
                    headingIconWidth: '57.11px',
                    headingIconHeight: '35px',
                    headingText: '',
                    subHeading: 'MasterCard',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Add to lifegraph',
                    StartIcon: AddIcon,
                    isAdded: false,
                },
                {
                    headingIconUrl: burstIQCardIcon,
                    headingIconWidth: '39.18px',
                    headingIconHeight: '35px',
                    headingText: 'Lifegraph app',
                    headingTextColor: '#43838E',
                    subHeading: 'Verifier Pro',
                    description: 'Manage your professional learning journey, achievements, and goals.',
                    addBtnText: 'Added',
                    StartIcon: DoneIcon,
                    isAdded: true,
                },
            ],
        }

    ] 
};
