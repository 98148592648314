import { Paper, useTheme, useMediaQuery, Container, Typography, Grid, Box, Skeleton } from '@mui/material';
import { routerSelector, skillDetailSelector } from 'redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Children, useEffect } from 'react';
import { skillsActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { SkillsDetailHeaderComponent } from 'pages/skills/components';
import { toLocalDateString } from 'util/date.utils';

const styles = {
    paperTitle: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        lineHeight: '18px',
        textTransform: 'uppercase'
    },
    paperOtherValue: {
        color: '#202124',
        fontSize: '28px',
        fontWeight: 600
    },
    paperDateValue: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#202124',
    }
}

export function SkillDetailComponent () {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const selectedSkillId = useSelector(routerSelector.selectSecondPathFromURL);
    const loading = useSelector(skillDetailSelector.selectLoading);
    const skillDetail = useSelector(skillDetailSelector.selectSkillDetail);
    const customCardData = useSelector(skillDetailSelector.selectSkillCustomCardData);

    useEffect(() => {
        if (selectedSkillId) {
            dispatch(skillsActionCreators.getSelectedSkillDetail(selectedSkillId));
        } else {
            history.push('/learning/skills/map');
        }
    }, [selectedSkillId]);

    return (
        <>
            <SkillsDetailHeaderComponent loading={loading} currentSkill={skillDetail} />
            <Container maxWidth="lg" disableGutters={isMobile}>
                <Paper sx={{ borderRadius: '12px', height: '150px', mt: 4, px: 5,}}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', height: 'inherit', textAlign: 'center' }} >
                        {
                            loading ? <Skeleton width={'100%'} /> : (
                                Children.toArray(customCardData.map((obj: any) => {
                                    return (
                                        <Box sx={{ maxWidth: '112px', height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Typography sx={styles.paperTitle}>{ obj.title }</Typography>
                                            {
                                                obj.type === 'date' ? (
                                                    <Typography sx={styles.paperDateValue}>{ toLocalDateString(obj.value) }</Typography>
                                                ) : (
                                                    <Typography sx={styles.paperOtherValue}>{ obj.value }</Typography>
                                                )
                                            }
                                            
                                        </Box>
                                    )
                                }))
                            )
                        }
                    </Grid>
                </Paper>
            </Container>
        </>
    )
}