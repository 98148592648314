import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { trainingSelector } from 'redux/selectors';
import { trainingActionType } from 'redux/actionsTypes';
import {
    IProfileTrainingObject,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import { TrainingForm } from './trainingForm';
import { ThemeContext } from 'util/themes';
import { TrainingComponent } from './trainingComponent';
interface ITrainingComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const TrainingCardComponent = (props: ITrainingComponent) => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { title, canEdit, type } = props;

    const trainingLoading = useSelector(trainingSelector.selectTrainingLoading);
    const trainingList = useSelector(trainingSelector.selectTrainingData);

    const trainingConfig: IReduxDataConfig = {
        loadingAction: trainingActionType.PROFILE_TRAINING_LOADING,
        successAction: trainingActionType.PROFILE_TRAINING_SUCCESS,
        errorAction: trainingActionType.PROFILE_TRAINING_ERROR,
        endpoint: '/v2/training',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(trainingConfig)
        );
    }, []);

    const handleModalOpen = (id: string) => {
        const find = trainingList.find(
            (x: IProfileTrainingObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addTraining = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Grid item xs sx={selectedTheme.card?.gridWrapper}>
            <Paper sx={selectedTheme.card?.default}>
                <CardTitleComponent
                    title={title}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addTraining}
                />
                <Divider />
                <Grid container sx={selectedTheme.card?.cardPadding}>
                    {trainingList.length === 0 && !trainingLoading ? (
                        <NoRecordsComponent />
                    ) : (
                        <> </>
                    )}
                    {Children.toArray(
                        trainingList.map(
                            (x: IProfileTrainingObject, index: number) => (
                                <TrainingComponent
                                    cardTitle={title}
                                    data={{ ...x }}
                                    index={index}
                                    selectedTheme={selectedTheme}
                                    canEdit={canEdit}
                                    handleModalOpen={handleModalOpen}
                                    educationLoading={trainingLoading}
                                />
                            )
                        )
                    )}
                </Grid>
            </Paper>
            {modalOpen ? (
                <TrainingForm
                    modalOpen={modalOpen}
                    trainingReduxConfig={trainingConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <> </>
            )}
        </Grid>
    );
};

TrainingCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
