import { createTheme } from '@mui/material/styles';
import { baseTheme } from './base';

const lifeGraph = createTheme(baseTheme, {
    palette: {
        primary: {
            main: '#69D7D2', // 300
            dark: '#53C3C0', // 400
            light: '#8FE9E3', // 200
            contrastText: '#113F4D', // 900
            50: '#DFFDF9',
            100: '#B5F5EF',
            200: '#8FE9E3',
            300: '#69D7D2',
            400: '#53C3C0',
            500: '#3FB3B0',
            600: '#43838E',
            700: '#285C6C',
            800: '#1B4E5E',
            900: '#113F4D',
            A100: '#A9FFF5',
            A200: '#67FEEC',
            A400: '#59D8C8',
            A700: '#49B4A6',
        },
        secondary: {
            main: '#5D6A71', // 300
            dark: '#2B3640', // 400
            light: '#5D6A71', // 200
            contrastText: '#FFFFFF', // 900
            grayScale: {
                50: '#F7F9FA',
                100: '#E7ECEE',
                200: '#D2DBDE',
                300: '#AABABF',
                400: '#8499A0',
                500: '#6F858C',
                600: '#5D6A71',
                700: '#47515A',
                800: '#293541',
                900: '#1F2932',
                A100: '#D2DBDE',
                A200: '#8499A0',
                A400: '#47515A',
                A700: '#1F2932',
            },
            50: '#FFDC82',
            100: '#FFC467',
            200: '#FCA84F',
            300: '#F58838',
            400: '#E26220',
            500: '#D43418',
            600: '#BA0F16',
            700: '#9D082B',
            800: '#7C0338',
            900: '#5C003C',
            A100: '#FFE57F',
            A200: '#FFD740',
            A400: '#FF9100',
            A700: '#FF6D00',
        },
        tertiary: {
            pink: {
                400: '#E6577B',
            },
            cyan: {
                500: '#00BCD4',
            },
            red: {
                50: '#FFF3F3',
                100: '#FFB3B5',
                200: '#FF777B',
                300: '#FB5E62',
                400: '#EA4B4F',
                500: '#D53338',
                600: '#CA2C30',
                700: '#A91216',
                800: '#770E11',
                900: '#510407',
                A100_1: '#FF8A80',
                A100_2: '#FFBFC3',
                A200: '#FF99A3',
                A400: '#EE516C',
                A700: '#CB223F',
            },
            green: {
                50: '#CFFFED',
                100: '#B0FBDC',
                200: '#83F3C4',
                300: '#65E3AE',
                400: '#44D095',
                500: '#30BE82',
                600: '#24AB73',
                700: '#208F61',
                800: '#197C52',
                900: '#2D674E',
                A100: '#B0FFDF',
                A200: '#6CF9BD',
                A400: '#41D698',
                A700: '#22BA7A',
            },
            yellow: {
                50: '#FFFDE7',
                100: '#FFF9C4',
                200: '#FFF388',
                300: '#FFEE58',
                400: '#FFE500',
                500: '#FFD02B',
                600: '#FFC11B',
                700: '#FCA600',
                800: '#F58F17',
                900: '#F57F17',
                A100: '#FFE57F',
                A200: '#FFD740',
                A400: '#FFC400',
                A700: '#FFAB00',
            },
        },
        error: {
            main: '#CA2C30',
            dark: '#A91216',
            light: '#EA4B4F',
            contrastText: '#FFF3F3',
        },
        warning: {
            main: '#FFD02A',
            dark: '#FFC11B',
            light: '#FFE500',
            contrastText: '#5C003C',
        },
        info: {
            main: '#2375C0',
            dark: '#1A64A8',
            light: '#3291E9',
            contrastText: '#FFFFFF',
        },
        success: {
            main: '#197C52',
            dark: '#2D674E',
            light: '#24AB72',
            contrastText: '#CFFFED',
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
        },
        action: {
            active: 'rgba(43, 54, 64, 0.54)',
            hover: 'rgba(43, 54, 64, 0.04)',
            selected: 'rgba(43, 54, 64, 0.08)',
            disabled: 'rgba(43, 54, 64, 0.26)',
            disabledBackground: 'rgba(43, 54, 64, 0.12)',
            focus: 'rgba(0, 0, 0, 0.12)',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        text: {
            primary: '#293541',
            secondary: '#47515A',
            disabled: '#5D6A71',
        },
    },
    typography: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        h1: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '32px',
            lineHeight: '38px',
        },
        h2: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '28px',
            lineHeight: '33px',
        },
        h3: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '28px',
        },
        h4: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '23px',
        },
        h5: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '21px',
        },
        h6: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '5px',
        },
        subtitle1: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '.15px',
        },
        subtitle2: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '.1px',
        },
        body1: {
            fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '21px',
        },
        body2: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '19px',
        },
        body3: {
            fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(','),
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '26px',
            color: '#202124',
        },
        body: {
            default: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                color: '#000000',
            },
            body1: {
                fontSize: '16px',
                lineHeight: '21px',
            },
            body2: {
                fontSize: '14px',
                lineHeight: '19px',
            },
            body3: {
                fontSize: '20px',
                lineHeight: '26px',
                color: '#202124',
            },
            light: {
                fontWeight: '300',
            },
            regular: {
                fontWeight: '400',
            },
            medium: {
                fontWeight: '500',
            },
            semibold: {
                fontWeight: '600',
            },
            bold: {
                fontWeight: '700',
            },
        },
        subtitles: {
            default: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                lineHeight: '19px',
                color: '#000000',
            },
            subtitle1: {
                fontSize: '16px',
                letterSpacing: '0.15px',
            },
            subtitle2: {
                fontSize: '14px',
                letterSpacing: '0.1px',
            },
            regular: {
                fontWeight: '400',
            },
            medium: {
                fontWeight: '500',
            },
            semibold: {
                fontWeight: '600',
            },
            bold: {
                fontWeight: '700',
            },
        },
        captions: {
            default: {
                fontFamily: 'Red Hat Text',
                fontStyle: 'normal',
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
                color: '#000000',
            },
            regular: {
                fontWeight: '400',
            },
            medium: {
                fontWeight: '500',
            },
            semibold: {
                fontWeight: '600',
            },
            bold: {
                fontWeight: '700',
            },
        },
        banner: {
            banner1: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '80px',
                lineHeight: '94px',
            },
            banner2: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '64px',
                lineHeight: '75px',
            },
            bannerSubtitle: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                fontWeight: '300',
                fontSize: '40px',
                lineHeight: '47px',
                letterSpacing: '8px',
            },
        },
        caption: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '.4px',
        },
        overline: {
            fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '2px',
            textTransform: 'uppercase',
        },
        overlines: {
            overline1: {
                default: {
                    fontFamily: [
                        'Source Code Pro',
                        'Open Sans',
                        'sans serif',
                    ].join(','),
                    fontStyle: 'normal',
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    color: '#000000',
                },
                light: {
                    fontWeight: '300',
                },
                regular: {
                    fontWeight: '400',
                },
                medium: {
                    fontWeight: '500',
                },
                semibold: {
                    fontWeight: '600',
                },
                bold: {
                    fontWeight: '700',
                },
                extrabold: {
                    fontWeight: '800',
                },
                black: {
                    fontWeight: '900',
                },
            },
            overline2: {
                default: {
                    fontFamily: [
                        'Source Code Pro',
                        'Open Sans',
                        'sans serif',
                    ].join(','),
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase',
                    color: '#000000',
                },
                light: {
                    fontWeight: '300',
                },
                regular: {
                    fontWeight: '400',
                },
                medium: {
                    fontWeight: '500',
                },
                semibold: {
                    fontWeight: '600',
                },
                bold: {
                    fontWeight: '700',
                },
            },
        },
        Components: {
            alertTitle: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: '#000000',
            },
            avatarInitials: {
                fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '20px',
                letterSpacing: '0.14px',
                textTransform: 'uppercase',
                color: '#000000',
            },
            badgeLabel: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: '0.14px',
                color: '#000000',
            },
            button: {
                default: {
                    fontFamily: [
                        'Red Hat Text',
                        'Open Sans',
                        'sans serif',
                    ].join(','),
                    fontStyle: 'normal',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase',
                },
                large: {
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '26px',
                },
                medium: {
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '24px',
                },
                small: {
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            breadcrumb : {
                default : {
                    px : 5, 
                    pt : 2, 
                    pb : 0
                },
                link : {
                    '&  > a' : { 
                        textDecoration : "none",
                        color: "#47515A",
                        fontWeight : '500',
                    }
                },
                linkFade:{
                    '&  > a' : { 
                        textDecoration : "none",
                        color : '#6F858C',
                    }
                },
                linkText : {
                    '&  > a > span': {
                        height: '1 rem',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        position: 'relative',
                        '&:after': {
                            content: '""',
                            backgroundColor: '#B5F5EF',
                            position: 'absolute' as const,
                            bottom: '0px',
                            width: '100%',
                            height: '6px',
                            zIndex: '-1',
                            transition: 'all .3s ease-in-out',
                        },
                        '&:hover': {
                            '&:after': {
                                height: '1rem'
                            },
                        }
                    }
                },
            },
            inputLabel: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '21px',
                letterSpacing: '0.15px',
                color: '#000000',
            },
            helperText: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: '0.4px',
                color: '#000000',
            },
            inputText: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                letterSpacing: '0.15px',
                color: '#000000',
            },
            chip: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                color: '#000000',
            },
            tabBar: {
                tabs: {
                    '& .MuiTab-root.Mui-selected': {
                        color: '#47515A',
                        fontWeight: '500',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#B5F5EF',
                        bottom: '15px',
                        height: '8px',
                        zIndex: -1,
                    },
                },
                tabHover: {
                    '& .MuiTabs-scroller': {
                        '& .MuiTabs-flexContainer': {
                            '& button': {
                                height: '3.5rem',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                position: 'relative',
                                '&[aria-selected = "false"]': {
                                    '&:after': {
                                        content: '""',
                                        backgroundColor: '#B5F5EF',
                                        position: 'absolute' as const,
                                        left: '.5rem',
                                        bottom: '1.125rem',
                                        width: 'calc(100% - 1.25rem)',
                                        height: '0px',
                                        zIndex: '-1',
                                        transition: 'all .3s ease-in-out',
                                    },
                                    '&:hover': {
                                        '&:after': {
                                            bottom: '1rem',
                                            height: 'calc(100% - 2rem)'
                                        }
                                    }
                                },
                                '&[aria-selected = "true"]': {
                                    '&:after': {
                                        content: '""',
                                        backgroundColor: '#B5F5EF',
                                        position: 'absolute' as const,
                                        left: '.5rem',
                                        bottom: '1.125rem',
                                        width: 'calc(100% - 1.25rem)',
                                        height: '.5rem',
                                        zIndex: '-1',
                                        transition: 'all .3s ease-in-out',
                                    },
                                    '&:hover': {
                                        '&:after': {
                                            bottom: '1rem',
                                            height: 'calc(100% - 2rem)'
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                tab: {
                    fontFamily: [
                        'Red Hat Text',
                        'Open Sans',
                        'sans serif',
                    ].join(','),
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#47515A',
                },
            },
            tooltip: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#000000',
            },
            tableHeader: {
                fontFamily: ['Source Code Pro', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.17px',
                color: '#000000',
            },
            menuItem: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: '#000000',
            },
            menuItemSelected: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '18px',
                lineHight: '150%',
                letterSpacing: '0.15px',
                color: '#000000',
            },
            menuItemDense: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.17px',
                color: '#000000',
            },
            listSubHeader: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '48px',
                letterSpacing: '0.1px',
                color: '#000000',
            },
            bottomNavigationActiveLabel: {
                fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
                    ','
                ),
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '166%',
                letterSpacing: '0.4px',
                color: '#000000',
            },
        },
    },
    card: {
        base: {
            mb: 3,
        },
        default: {
            background: '#FFFFFF',
            border: '2px solid #AABABF',
            borderRadius: '5px',
            boxShadow: 'none',
            overflow: 'hidden',
        },
        deActivate: {
          background: '#F7F9FA',
        },
        active: {
            background: 'rgba(223, 253, 249, 0.3)',
        },
        elevated: {
            border: '2px solid #3FB3B0',
            backgroundColor: 'rgba(223, 253, 249, 0.45)',
        },
        cardPadding: { p: 3 },
        gridWrapper: { mb: 3, flexGrow: 0 },
        milestone: {
            border: '2px solid #69D7D2',
            borderTop: '12px solid #69D7D2'
        }
    },
    imageBackground : {
        display: "block",
        maxWidth: "100%",
        maxHeight: "65vh",
        height: "auto",
        width: "auto",
        marginLeft: "auto",
        marginRight: "auto"
    },
    table: {
        default: {
            border: '2px solid #AABABF',
            borderRadius: '5px',
        },

        header: {
            background: '#F7F9FA',
            border: 'none',
        },
        headerCell: {
            fontFamily: 'Source Code Pro',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.17px',
            color: '#293541',
        },
        headerCellTitle: {
            fontWeight: 700,
        },
        rows: {
            background: '#FFFFFF',
            borderWidth: '1px 0px',
            borderStyle: 'solid',
            borderColor: '#D2DBDE',
        },
        rowCell: {
            fontFamily: 'Red Hat Text',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#47515A',
        },
        rowCellBold: {
            fontWeight: 600,
        },
        rowExpanded: {
            background: '#F7F9FA',
            border: '1px solid #D2DBDE',
            height: '100%',
        },
        rowExpandedCell: {
            fontSize: '16px',
            lineHeight: '21px',
        },
        rowExpandedCellBold: {
            fontWeight: 700,
        },
    },
    button: {
        default: {
            borderRadius: '2px',
        },
        size: {
            large: {
                px: 2.75,
                py: 1,
            },
            medium: {
                px: 2,
                py: 0.75,
            },
            small: {
                px: 1.25,
                py: 0.5,
            },
        },
        disabled: {
            border: '1px dashed #AABABF',
            color: '#5D6A71',
            background: '#FFFFFF',
        },
        contained: {
            primary: {
                background: '#285C6C',
                color: '#B5F5EF',
                '&:hover': {
                    background: '#43838E',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(105, 215, 210, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            secondary: {
                background: '#B5F5EF',
                color: '#285C6C',
                border: '1.5px solid #53C3C0',
                '&:hover': {
                    background: '#DFFDF9',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            error: {
                background: '#CA2C30',
                color: '#FFF3F3',
                '&:hover': {
                    background: '#A91216',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            warning: {
                background: '#FFD02A',
                color: '#5C003C',
                '&:hover': {
                    background: '#FFC11B',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            info: {
                background: '#2375C0',
                color: '#FFFFFF',
                '&:hover': {
                    background: '#1A64A8',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            success: {
                background: '#197C52',
                color: '#CFFFED',
                '&:hover': {
                    background: '#2D674E',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            inherit: {
                background: '#E7ECEE',
                color: '#293541',
                '&:hover': {
                    background: '#D2DBDE',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
        },
        outlined: {
            primary: {
                border: '1px solid #69D7D2',
                background: '#FFFFFF',
                color: '#285C6C',
                '&:hover': {
                    background: '#DFFDF9',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(105, 215, 210, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            secondary: {
                border: '1px solid #8499A0',
                background: '#FFFFFF',
                color: '#47515A',
                '&:hover': {
                    background: 'rgba(93, 106, 113, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            error: {
                background: 'rgba(202, 44, 48, 0.04)',
                border: '1px solid #CA2C30',
                color: '#CA2C30',
                '&:hover': {
                    background: 'rgba(202, 44, 48, 0.12)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            warning: {
                border: '1px solid #FFC11B',
                background: '#FFFFFF',
                color: '#7C0338',
                '&:hover': {
                    background: 'rgba(255, 208, 42, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            info: {
                border: '1px solid #2375C0',
                background: '#FFFFFF',
                color: '#2375C0',
                '&:hover': {
                    background: 'rgba(35, 117, 192, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            success: {
                border: '1px solid #83F3C4',
                background: '#FFFFFF',
                color: '#197C52',
                '&:hover': {
                    background: 'rgba(131, 243, 196, 0.09)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            inherit: {
                border: '1px solid #293541',
                background: '#FFFFFF',
                color: '#293541',
                '&:hover': {
                    background: 'rgba(43, 54, 64, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
        },
        text: {
            primary: {
                color: '#43838E',
                '&:hover': {
                    background: '#DFFDF9',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(105, 215, 210, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            secondary: {
                color: '#5D6A71',
                '&:hover': {
                    background: 'rgba(93, 106, 113, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            error: {
                color: '#CA2C30',
                '&:hover': {
                    background: 'rgba(202, 44, 48, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            warning: {
                color: '#7C0338',
                '&:hover': {
                    background: 'rgba(255, 208, 42, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            info: {
                color: '#2375C0',
                '&:hover': {
                    background: 'rgba(35, 117, 192, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            success: {
                color: '#197C52',
                '&:hover': {
                    background: 'rgba(131, 243, 196, 0.09)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
            inherit: {
                color: '#293541',
                '&:hover': {
                    background: 'rgba(43, 54, 64, 0.04)',
                },
                '> .MuiTouchRipple-root span': {
                    background:
                        'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
                },
            },
        },
    },
    summaryTable: {
        header: {
            border: '1px solid #DADCE0',
            backgroundColor: '#F5F6F8',
        },
        headerCell: {
            textTransform: 'uppercase',
            py: 1,
            fontSize: '12px',
            color: '#80848D',
            fontWeight: 'bold',
            border: 'none',
        },
        rowCell: {
            fontSize: '14px',
            border: 'none',
        },
        row: {
            backgroundColor: 'white',
            border: '1px solid #DADCE0',
            width: '100%',
        },
        rowExpanded: {
            backgroundColor: 'none',
            border: '1px solid #DADCE0',
            width: '100%',
        },
    },
    input: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px !important',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
            },
            '& fieldset': {
                border: '1px solid #6F858C !important',
            },
            '&:hover fieldset': {
                border: '1px solid #69D7D2 !important',
            },
            '&.Mui-focused fieldset': {
                border: '1.5px solid #53C3C0 !important',
            },
        },
    },
    paper: {
        card: {
            default: {
                background: '#ffffff',
                boxShadow: 0,
                borderColor: '#3FB3B0'
            },
            active: {
                background:  '#DFFDF9',
                boxShadow: 6
            },
            disabled: {
                background: 'rgba(0, 0, 0, 0.26)',
                boxShadow: 0
            },
        }
    }
});

export { lifeGraph };
