import { Box, Grid, Paper, Divider, Typography } from '@mui/material';
import { Children, useContext } from 'react';
import { CardTitleComponent } from 'components';
import { ThemeContext } from 'util/themes';
import bloodPressureIcon from '../../../assets/images/icons/health/blood_pressure.svg';
import bmiIcon from '../../../assets/images/icons/health/bmi.svg';
import breathIcon from '../../../assets/images/icons/health/breath.svg';
import heartIconIcon from '../../../assets/images/icons/health/heart_rate.svg';
import temperatureIcon from '../../../assets/images/icons/health/temperature.svg';
import weightIcon from '../../../assets/images/icons/health/weight.svg';
import { FitnessBarGraph } from './FitnessGraph';

const styles = {
    filtnessValueStyle: {
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '40px',
        lineHeight: '47px',
    },
    filtnessLabelStyle: {
        position: 'relative' as const,
        top: '-.3rem',
        left: '.5rem'
    }
}

const fitnessData = [
    {
        itemValue: '122/79',
        itemName: 'Blood Pressure',
        icon: bloodPressureIcon
    },
    {
        itemValue: '98ºF',
        itemName: 'Temperature',
        icon: temperatureIcon
    },
    {
        itemValue: '32',
        itemName: 'BMI',
        icon: bmiIcon
    },
    {
        itemValue: '82',
        itemName: 'Resting Heart Rate',
        icon: heartIconIcon
    },
    {
        itemValue: '245 lbs',
        itemName: 'Weight',
        icon: weightIcon
    },
    {
        itemValue: '17',
        itemName: 'Breath/Minute',
        icon: breathIcon
    }
]

export function FitnessProfileComponent() {
    const { selectedTheme } = useContext(ThemeContext);
    
    return (
        <> 
        <Box>
            <Grid item xs sx={selectedTheme?.card?.gridWrapper}>
                <Paper sx={selectedTheme?.card?.default}>
                    <CardTitleComponent title={'Fitness Profile'} />
                    <Divider sx={{border: `1px solid ${selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[300]}`}}/>
                    <Grid container>
                        <Grid item xs={8}>
                            <Grid container mt={5} mb={5}>
                                {Children.toArray(
                                    fitnessData.map(data => 
                                        <Grid xs={4} item container justifyContent='center' alignItems='center' flexDirection='column'>
                                            <Grid>
                                                <Typography sx={{
                                                    ...styles.filtnessValueStyle,
                                                    color: selectedTheme?.palette?.primary[600]
                                                }}>
                                                    {data.itemValue}
                                                </Typography>
                                            </Grid>
                                            <Grid mt={1}>
                                                <img src={data.icon} width={20} height={20}/>
                                                <Typography component={'span'} sx={{
                                                    ...styles.filtnessLabelStyle,
                                                    ...selectedTheme?.typography?.overlines?.overline1?.default,
                                                    ...selectedTheme?.typography?.overlines?.overline1?.semibold,
                                                    textTransform: 'uppercase',
                                                    color: selectedTheme?.palette?.secondary?.grayScale && 
                                                        selectedTheme?.palette?.secondary?.grayScale[600]
                                                }}>
                                                    {data.itemName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{
							backgroundColor: selectedTheme?.palette?.secondary?.grayScale &&
							selectedTheme?.palette?.secondary?.grayScale[50]
						}}>
                            <FitnessBarGraph/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Box>
        </>
    )
}