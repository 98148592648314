export const recentAppCardData = {
  learning: {
    heading: 'LEARNING',
    subHeading: 'Lifetrek',
    description: 'Manage your professional learning journey, achievements, and goals.',
    goToLink: '/learning/pathway'
  },
  health: {
    heading: 'HEALTH',
    subHeading: 'Healthy Helix',
    description: 'Manage your health and set personal wellness goals.',
    goToLink: ''
  },
  verifier: {
	  heading: 'Verification',
	  subHeading: 'Verifier Pro',
	  description: 'Manage your health and set personal wellness goals',
	  goToLink: '/verify/dashboard'
	},
  banking: {
    heading: 'BANKING',
    subHeading: 'BIQ Bank',
    description: 'Manage your accounts, spending, and financial goals.',
    goToLink: ''
  }
};