import React, { ReactChild, ReactComponentElement } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
} from '@mui/material';
import { Button } from '../../controls/button/Button';

const styles = {
    dialogPaper: {
        p: 2,
        py: 5,
        position: 'absolute' as const,
    },
    dialogTitle: {
        textAlign: 'center' as const,
    },
    dialogContent: {
        textAlign: 'center' as const,
    },
    dialogAction: {
        justifyContent: 'center',
    },
    titleIcon: {
        backgroundColor: 'secondary.main',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'secondary.light',
            cursor: 'default',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        },
    },
};

export const ConfirmDialog = (props: {
    title?: string;
    children?: string | ReactChild;
    open: boolean;
    setOpen: any;
    onConfirm: any;
    cancelText?: string;
    confirmText?: string;
    dialogIcon?: any;
    maxWidth?: any;
}) => {
    const {
        title,
        children,
        open,
        setOpen,
        onConfirm,
        cancelText,
        confirmText,
        dialogIcon,
        maxWidth,
    } = props;

    return dialogIcon ? (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            maxWidth={maxWidth}
            PaperProps={{ sx: styles.dialogPaper }}
        >
            <DialogTitle id="confirm-dialog" sx={styles.dialogTitle}>
                <IconButton disableRipple sx={styles.titleIcon}>
                    {dialogIcon}
                </IconButton>
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <Typography variant="h5">{title}</Typography>
                <br />
                {children}
            </DialogContent>
            <DialogActions sx={styles.dialogAction}>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="secondary"
                >
                    {cancelText ? cancelText : 'Cancel'}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                >
                    {confirmText ? confirmText : 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            PaperProps={{ sx: styles.dialogPaper }}
            maxWidth={maxWidth}
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="secondary"
                >
                    {cancelText ? cancelText : 'Cancel'}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                >
                    {confirmText ? confirmText : 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
