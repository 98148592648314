import React, { Children, useEffect, useState, useContext } from 'react';
import { Grid, Box, Button, Drawer } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Header from '../header/Header';
import config from '../../config';

import badge from '../../assets/images/logos/Lifegraph Badge.svg';
import logo from '../../assets/images/logos/Lifegraph Logo.svg';
import { SystemStyleObject } from '@mui/system';
import { ThemeContext, lifeGraph } from 'util/themes';
import { useSelector } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import { INavLink } from 'lib/types';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;
const desktopWidth = 240;
const headerDesktopHeight = '3.5rem';
const headermobileHeight = '3rem';
const homeOutlineUrl = 'url("/images/assets/nav/home_outline.svg") no-repeat';
const learningOutlineUrl =
    'url("/images/assets/nav/learning_outline.svg") no-repeat';
const healthOutlineUrl =
    'url("/images/assets/nav/health_outline.svg") no-repeat';
const marketPlaceOutlineUrl =
    'url("/images/assets/nav/marketplace_outline.svg") no-repeat';
const verifyOutlineUrl =
    'url("/images/assets/nav/verify_outline.svg") no-repeat';
const homeContainedUrl =
    'url("/images/assets/nav/home_contained.svg") no-repeat';
const learningContainedUrl =
    'url("/images/assets/nav/learning_contained.svg") no-repeat';
const healthContainedUrl =
    'url("/images/assets/nav/health_contained.svg") no-repeat';
const marketPlaceContainedUrl =
    'url("/images/assets/nav/marketplace_contained.svg") no-repeat';
const verifyContainedUrl =
    'url("/images/assets/nav/verify_contained.svg") no-repeat';

const navIconStyle = (
    outlineUrl: string,
    containedUrl: string,
    theme: any
) => ({
    '&:before': {
        background: outlineUrl,
        backgroundSize: 'contain',
    },
    '&:hover': {
        backgroundColor:
            (theme?.palette.secondary?.grayScale &&
                theme?.palette.secondary?.grayScale[50]) ||
            '#F7F9FA',
        '&:before': {
            background: outlineUrl,
            backgroundSize: 'contain',
        },
    },
    '&[aria-selected = "true"]': {
        '&:before': {
            background: containedUrl,
            backgroundSize: 'contain',
        },
    },
});

const styles = {
    appLogoContainer: {
        position: 'relative' as const,
    },
    appLogoFull: {
        width: '12.5rem',
        height: 'auto',
    },
    appLogoSubHeading: {
        top: '0',
        fontSize: '0.5rem',
        position: 'absolute' as const,
    },
    navBtn_1: {
        width: '2.75rem',
        height: '0',
        border: '1px solid #47515A',
    },
    appLogo: {
        height: 'auto',
        width: '2.5rem',
    },
    main: (theme: any, open: boolean) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        marginTop: headerDesktopHeight,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${open ? drawerWidth : 0}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
    drawer: (theme: any, open: boolean): SystemStyleObject => ({
        width: open ? drawerWidth : `${theme.spacing(12)}`,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& .MuiDrawer-paper': {
            width: open ? drawerWidth : `${theme.spacing(12)}`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
        },
    }),
    drawerHeader: (theme: any, open: boolean) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: open ? 'flex-start' : 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }),
    tab: (theme: any) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme?.palette?.secondary?.main,
        '&.Mui-selected': {
            backgroundColor:
                (theme?.palette.secondary?.grayScale &&
                    theme?.palette.secondary?.grayScale[50]) ||
                '#F7F9FA',
            '& .tabLabel': {
                fontWeight: '700',
                color:
                    theme?.palette.secondary?.grayScale &&
                    theme?.palette.secondary?.grayScale[700],
            },
            '&:hover': {
                opacity: 1,
            },
        },
        '& .MuiButtonBase-root': {
            '&:hover': {
                backgroundColor:
                    (theme?.palette.secondary?.grayScale &&
                        theme?.palette.secondary?.grayScale[50]) ||
                    '#F7F9FA',
                color: theme.palette.primary.main,
                fontWeight: '700',
                opacity: '0.8',
                '&.tabLabel': {
                    fontWeight: '700',
                },
            },
        },
    }),
    tabs: (theme: any, open: boolean) => ({
        '& .MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
                '& button': {
                    height: '3.5rem',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    '& .tabLabel': {
                        ml: theme.spacing(7),
                    },
                    '&:hover': {
                        '&:after': {
                            content: '""',
                            position: 'absolute' as const,
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '.625rem',
                            backgroundColor: theme?.palette?.primary[200],
                        },
                    },
                    '&:before': {
                        position: 'absolute' as const,
                        width: '1.5rem',
                        height: '1.5rem',
                        top: theme.spacing(2.25),
                        left: open ? theme.spacing(3.5) : theme.spacing(4.75),
                        content: '""',
                    },
                },
                '& [aria-label="Home"]': {
                    ...navIconStyle(homeOutlineUrl, homeContainedUrl, theme),
                },
                '& [aria-label="Learning"]': {
                    ...navIconStyle(
                        learningOutlineUrl,
                        learningContainedUrl,
                        theme
                    ),
                },
                '& [aria-label="Health"]': {
                    ...navIconStyle(
                        healthOutlineUrl,
                        healthContainedUrl,
                        theme
                    ),
                },
                '& [aria-label="Marketplace"]': {
                    ...navIconStyle(
                        marketPlaceOutlineUrl,
                        marketPlaceContainedUrl,
                        theme
                    ),
                },
                '& [aria-label="Verifier Pro"]': {
                    ...navIconStyle(
                        verifyOutlineUrl,
                        verifyContainedUrl,
                        theme
                    ),
                },
            },
        },
        '& .MuiTabs-indicator': {
            left: '0',
            width: '.625rem',
            backgroundColor: theme?.palette?.primary[400],
        },
    }),
};

interface IDrawerProps {
    sideMenuConfig: {
        title: string;
        urlKey: string;
        filteredRoutes: INavLink[];
        moduleTheme: string;
        subModule?: {
            title?: string;
            urlKey?: string;
            filteredRoutes?: INavLink[];
            moduleTheme?: string;
        }
    }[];
    children?: React.ReactNode;
}

export default function SideDrawer(props: IDrawerProps) {
    const { sideMenuConfig, children } = props;
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const selectedModule = useSelector(routerSelector.selectCurrentModule);
    const [value, setValue] = useState(selectedModule);

    useEffect(() => {
        let appliedTheme = 'lifeGraph';
        sideMenuConfig.forEach((menu) => {
            if (menu.urlKey === selectedModule) {
                appliedTheme = menu.moduleTheme;
            } else if(menu?.subModule?.urlKey === selectedModule) {
                appliedTheme = menu?.subModule?.moduleTheme || 'lifeGraph';
            }
        });
        setCurrentTheme(appliedTheme);
        setValue(selectedModule);
    }, [selectedModule]);

    const onTabClick = (params: any) => {
        if (params.filteredRoutes.length > 0) {
            history.push(params.filteredRoutes[0].path);
            setCurrentTheme(params.moduleTheme);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const getTabValue = (menu: any) => {
        if (menu.urlKey === selectedModule) {
            return menu.urlKey;
        } else if(menu?.subModule?.urlKey === selectedModule) {
            return menu?.subModule?.urlKey;
        }
    };

    const getCurrentTabValue = (val: string | number) => {
        if(selectedModule !== val) {
            return selectedModule;
        } else {
            return val;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box height={{ xs: headermobileHeight, md: headerDesktopHeight }}>
                <Header
                    appName={config.appName}
                    desktopWidth={desktopWidth}
                    open={open}
                />
            </Box>
            <Drawer
                sx={{ ...styles.drawer(selectedTheme, open) }}
                variant="permanent"
            >
                <Grid sx={styles.drawerHeader(selectedTheme, open)}>
                    <Grid
                        component={Button}
                        onClick={handleDrawerOpen}
                        container
                        sx={{ width: '44px', height: '36px' }}
                    >
                        <Grid sx={styles.navBtn_1} item />
                        <Grid sx={styles.navBtn_1} item />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                    pt={0}
                    sx={styles.appLogoContainer}
                >
                    {open && <img src={logo} style={styles.appLogoFull} />}
                    {!open && <img src={badge} style={styles.appLogo} />}
                </Grid>
                <Tabs
                    orientation="vertical"
                    sx={styles.tabs(lifeGraph, open)}
                    value={getCurrentTabValue(value)}
                    aria-label="sideBarNav"
                >
                    {Children.toArray(
                        sideMenuConfig
                            .filter((x) => x.filteredRoutes.length > 0)
                            .map((menu) => (
                                <Tab
                                    value={getTabValue(menu)}
                                    sx={styles.tab(lifeGraph)}
                                    onClick={() => onTabClick(menu)}
                                    label={
                                        open && (
                                            <>
                                                <Typography
                                                    component="span"
                                                    className="tabLabel"
                                                >
                                                    {menu.title}
                                                </Typography>
                                            </>
                                        )
                                    }
                                    aria-label={menu.title}
                                />
                            ))
                    )}
                </Tabs>
            </Drawer>
            <main style={styles.main(selectedTheme, open)}>{children}</main>
        </Box>
    );
}
