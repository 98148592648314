import { useEffect, useState, Children, useContext } from 'react';
import { Container, useMediaQuery, Tabs, Tab } from '@mui/material';
import { checkOnboarding } from '../../../util/getUserData';
import { useHistory } from 'react-router-dom';
import { SkillsDashboardHeaderComponent, SkillsMapComponent, VerifiedCredentialComponent } from '../components';
import { useSelector } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import { ThemeContext } from 'util/themes';

export function SkillsDashboardComponent() {
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const currentLocation = useSelector(routerSelector.selectCurrentLocation);
    const disableContainerGutters = isMobile ? true : false;
    const [value, setValue] = useState<string>(currentLocation);
    const skillsTabs = [
        { title: 'Skill Map', path: '/learning/skills/map', component: SkillsMapComponent },
        { title: 'Verified Credentials', path: '/learning/skills/vc', component: VerifiedCredentialComponent }
    ];
    const imagePath = `/images/skills/skills_cropped.svg`;

    useEffect(() => {
        if (!checkOnboarding('skills')) {
            history.push('/learning/skills/onboarding');
        }
    }, []);

    useEffect(() => {
        setValue(currentLocation);
    }, [currentLocation])

    const onTabChange = (skillTab: any) => {
        history.push(skillTab.path);
    }

    const Component = skillsTabs.find((x) => x.path === value)?.component;
    
    return (
        <>
            <SkillsDashboardHeaderComponent 
                imagePath={imagePath}
                header={'LearnIQ Skills Center'}
                subHeader={'Browse the skill center and assemble your learning toolkit.'}
            />
            <Container maxWidth={false} disableGutters={disableContainerGutters} sx={{px: '6.75rem !important'}}>
                <Tabs
                    value={value}
                    centered={isMobile}
                    sx={{
                        '& .MuiTab-root.Mui-selected': {
                            color: selectedTheme?.palette?.secondary?.grayScale?.main
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: selectedTheme?.palette?.purple?.light,
                            bottom: '8px',
                            height: '2px',
                            borderRadius: '2px'
                          },
                    }}
                >
                    { 
                        Children.toArray(skillsTabs.map((skillTab) => {
                            return (
                                <Tab 
                                    onClick={() => onTabChange(skillTab)}
                                    value={skillTab.path}
                                    label={skillTab.title}
                                    sx={{
                                        color: '#80848D',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        letterSpacing: '1px'
                                    }}
                                    
                                />
                            )
                        }))
                    }
                </Tabs>
                {
                    Component ? <Component /> : <></>
                }
            </Container>
        </>
    )
}