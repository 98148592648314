import {
    Typography,
    Grid,
    Container,
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { ProfileAvatarComponent } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { baseTheme, ThemeContext } from 'util/themes';
import { Edit as EditIcon, Close as CloseIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { personalInfoSelector, skillsSelector } from 'redux/selectors';
import { TOGGLE_PROFILE_EDIT_MODE, CLOSE_PROFILE_EDIT_MODE } from 'redux/actionsTypes/profile/personalInfo.actionType';
import { ShareMyDataButton } from 'pages/share-my-data';
import { useEffect, Fragment, useContext } from 'react';
import { skillsActionCreators } from 'redux/actionCreators';
import { SkillCarouselComponent } from 'pages/skills/components';
import { ISkill } from '../../@types/skills.types';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';

const styles = {
    appTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: baseTheme.palette.secondary.light,
        textAlign: 'left',
    },
    userFullNameDesktop: {
        fontSize: '28px',
        lineHeight: '42px',
    },
    userFullNameMobile: {
        fontSize: '24px',
        lineHeight: '36px',
    },
    editProfileButton: {
        textTransform: 'none',
    },
    viewAllSkillsButton: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
        mt: 2
    },
    skillsTitle: {
        textTransform: 'uppercase',
        textAlign: 'start',
        mb: 2,
        color: '#80848D',
        fontWeight: 'bold'
    },
    banner: {
        background: 'url("/images/profile/profile-banner-img.svg") no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    hexagon:(selectedTheme: any) => ({
        width: '9.75rem',
        height: '11.25rem',
        '& .leftBorderContainer': {
            float: 'left',
            height: '5.625rem',
            width: '4px',
            backgroundColor: selectedTheme?.palette?.primary[600],
            transform: 'translateY(50%)',
            '&:after, &:before': {
                position: 'absolute',
                content: '""',
                height: '5.625rem',
                width: '4px',
                backgroundColor: selectedTheme?.palette?.primary[600],
            },
            '&:after': {
                bottom: '-4.125rem',
                right: '-2.375rem',
                transform: 'rotate(120deg)'
            },
            '&:before': {
                top: '-4.125rem',
                right: '-2.375rem',
                transform: 'rotate(60deg)'
            }
        },
        '& .rightBorderContainer': {
            float: 'right',
            height: '5.625rem',
            width: '4px',
            backgroundColor: selectedTheme?.palette?.primary[600],
            position: 'relative',
            transform: 'translateY(50%)',
            '&:after, &:before': {
                position: 'absolute',
                content: '""',
                height: '5.625rem',
                width: '4px',
                backgroundColor: selectedTheme?.palette?.primary[600],
            },
            '&:after': {
                bottom: '-4.125rem',
                left: '-2.375rem',
                transform: 'rotate(60deg)'
            },
            '&:before': {
                top: '-4.125rem',
                left: '-2.375rem',
                transform: 'rotate(120deg)'
            }
        }
    }),
    hexagonPosition: {
        top: '-5rem',
        position: 'relative',
        height: '10rem',
        width: '10rem',
        left: '-1px'
    }
};

const getHeaderDesign = (
    screen: 'mobile' | 'desktop',
    fullName: string,
    isInEditMode: boolean,
    onEditClicked: Function,
    skills: ISkill [],
    skillLoading: boolean,
    onViewSkillsAllClicked: Function,
    onSkillSelect: Function,
    selectedTheme: any
) => {
    const imageSize = screen === 'mobile' ? 'large' : 'xlarge';
    const itemGap = screen === 'mobile' ? 2 : 1;
    const nameStyle =
        screen === 'mobile'
            ? styles.userFullNameMobile
            : styles.userFullNameDesktop;
    const buttonLabel = isInEditMode ? 'Exit Edit Mode' : 'Edit Profile';
    const buttonVariant = isInEditMode ? 'contained' : 'text';

    return (
        <Container maxWidth={false}>
            {
                screen === 'desktop' ? (
                    <Grid container sx={{mt: 4}}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Box sx={{ float: 'right' }}>
                                {isInEditMode ? <> </> : <ShareMyDataButton />}
                            </Box>
                        </Grid>
                    </Grid>
                ) : <></>
            }
            <Grid container display={'flex'} flexDirection={'row'} justifyContent='center'>
                <Grid item xs={12} md={3}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={itemGap}
                    >
                        {
                            screen === 'mobile' ? (
                                <Fragment>
                                    <Grid item alignSelf={'flex-start'}>
                                        <Typography
                                            sx={styles.appTitle}
                                            align={'center'}
                                            mx={{ xs: '24px', md: '108px' }}
                                            paddingTop="14px"
                                        >
                                            My LifeGraph
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {isInEditMode ? (
                                            <Box sx={{ height: '32px' }}> </Box>
                                        ) : (
                                            <ShareMyDataButton />
                                        )}
                                    </Grid>
                            </Fragment>
                            ) : <></>
                        }
                        <Grid item>
                            <Grid width={'12rem'} sx={{...styles.hexagon(selectedTheme)}}>
                                <div className="leftBorderContainer"/>
                                <div className="rightBorderContainer"/>
                                <ProfileAvatarComponent size={imageSize} shape={'hexagon'} sx={styles.hexagonPosition}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h1'} sx={{
                                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                            }}> {fullName} </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={buttonVariant}
                                sx={styles.editProfileButton}
                                color={'primary'}
                                size={'large'}
                                startIcon={isInEditMode ? <CloseIcon sx={{color: selectedTheme?.palette?.primary[100]}}/> : <EditIcon sx={{color: selectedTheme?.palette?.primary[400]}}/>}
                                onClick={() => onEditClicked()}
                            >
                                <Typography sx={{
                                    ...selectedTheme?.typography?.Components?.button?.default,
                                    ...selectedTheme?.typography?.Components?.button?.large,
                                 }}>
                                    {buttonLabel}
                                 </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Commenting for now as it may be required in other project */}

                {/* <Grid item xs={12} md={9} mt={{ xs: 3, md: 0 }}>
                    <Typography sx={styles.skillsTitle}>Skills</Typography>
                    <SkillCarouselComponent loading={skillLoading} skillList={skills} selectSkillClicked={onSkillSelect}/>
                    <Grid item sx={{ textAlign: 'center' }}>
                        <Button
                            variant={'text'} 
                            endIcon={<ArrowForwardIcon />}
                            color='secondary'
                            sx={styles.viewAllSkillsButton} 
                            onClick={() => onViewSkillsAllClicked()}>
                                View All
                        </Button>
                    </Grid>
                </Grid> */}
            </Grid>
        </Container>
    );
};

export const ProfilePageHeaderComponent = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    const isInEditMode = useSelector(personalInfoSelector.getProfileEditMode);

    const skillsLoading = useSelector(skillsSelector.selectLoading);
    const userVerifiedSkills = useSelector(skillsSelector.selectVerifiedSkills);

    const fullName = personalInfo.preferredName;

    const onEdit = () => {
        dispatch({ type: TOGGLE_PROFILE_EDIT_MODE });
    };

    const onViewSkillsAllClicked = () => {
        history.push('/learning/skills/map');
    }

    const onSkillSelect = (skill: ISkill) => {
        history.push(`/learning/skills/${skill.id}`);
    }

    useEffect(() => {
        return () => {
            dispatch({ type: CLOSE_PROFILE_EDIT_MODE });
        }
    }, []);

    useEffect(() => {
        if (skillsLoading) {
            dispatch(skillsActionCreators.getUserSkills());
        }
    }, [skillsLoading]);

    return (
        <Grid
            item
            xs={12}
        >
            <Grid container direction={{ xs: 'column', md: 'row' }}>
                {
                    isMobile ? (
                        <Grid className="profile-header-rectangle-mobile">
                            {
                                getHeaderDesign(
                                    'mobile',
                                    fullName,
                                    isInEditMode,
                                    onEdit,
                                    userVerifiedSkills,
                                    skillsLoading,
                                    onViewSkillsAllClicked,
                                    onSkillSelect,
                                    selectedTheme
                                )
                            }
                        </Grid>
                    ) : (
                        <Grid item sx={{...styles.banner , height: '21.75rem'}} width="100%">
                            {
                                getHeaderDesign(
                                    'desktop',
                                    fullName,
                                    isInEditMode,
                                    onEdit,
                                    userVerifiedSkills,
                                    skillsLoading,
                                    onViewSkillsAllClicked,
                                    onSkillSelect,
                                    selectedTheme
                            )
                        }
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
};
