import { useCallback, useContext, useState } from 'react';
import {
    DataGridPro,
    GridColDef,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import { Box, Skeleton } from '@mui/material';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';


import { dataGridStyleOverrides, rowOverrideStyles } from './verifyGrid.helper';
import { VerifyGridExpandedRow } from './VerifyGridExpandedRow';

import { ThemeContext } from 'util/themes';
import { CustomPagination } from '../custom-pagination/CustomPagination';

interface IDataGrid {
    rows: any[];
    columns: GridColDef[];
    loading: boolean;
    onReloadVerifications: Function;
}

export function VerifyGrid(props: IDataGrid) {
    const gridStyles = dataGridStyleOverrides();
    const { rows, columns, loading, onReloadVerifications } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const rowHeight = 70;
    const headerHeight = 40;
    const [pageSize, setPageSize] = useState<number>(10);

    const loadingRows = Array.from(Array(5), (_, i) => {
        return { asset_id: i };
    });

    const find = columns.find(
        (x: GridColDef) => x.field === '__detail_panel_toggle__'
    );
    const getDetailPanelHeight = useCallback(() => {
        const totalChild = 5;
        return 64 + 24 * totalChild + 16 * (totalChild - 1);
    }, []);

    const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] =
        useCallback(({ row }) => {
            const lastRowId = rows[rows.length - 1];
            return (
                <VerifyGridExpandedRow
                    isExpanded={true}
                    row={row}
                    isLastRow={row.id === lastRowId}
                    onReloadVerifications={onReloadVerifications}
                />
            );
        }, []);

    const expanColumnConfig =
        find && !loading
            ? {
                  getDetailPanelHeight: getDetailPanelHeight,
                  getDetailPanelContent: getDetailPanelContent,
              }
            : {};

    if (loading) {
        for (const column of columns) {
            column.renderCell = () => (
                <Skeleton width={'80%'} animation={'wave'} />
            );
        }
    }
    const gridDefaults = {
        autoHeight: true,
        headerHeight: headerHeight,

        rowHeight: rowHeight,
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', ...rowOverrideStyles }}>
                <DataGridPro
                    sx={selectedTheme.table?.default}
                    className={gridStyles.desktopDataGrid}
                    columns={columns}
                    rows={loading ? loadingRows : rows}
                    {...gridDefaults}
                    getRowId={(row) => row.asset_id}
                    {...expanColumnConfig}
                    components={{
                        Pagination: CustomPagination,
                        DetailPanelExpandIcon: ArrowRightIcon,
                        DetailPanelCollapseIcon: ArrowDownIcon,
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                />
            </Box>
        </Box>
    );
}
