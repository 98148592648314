import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { volunteerSelector } from 'redux/selectors';
import { volunteerActionType } from 'redux/actionsTypes';
import {
    IProfileVolunteer,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import { VolunteerForm } from './volunteerForm';
import { ThemeContext } from 'util/themes';
import { VolunteerComponent } from './volunteerComponent';

interface IVolunteerComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const VolunteerCardComponent = (props: IVolunteerComponent) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { title, canEdit, type } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const volunteerLoading = useSelector(
        volunteerSelector.selectVolunteerLoading
    );
    const volunteerList = useSelector(volunteerSelector.selectVolunteerData);

    const volunteerConfig: IReduxDataConfig = {
        loadingAction: volunteerActionType.PROFILE_VOLUNTEER_LOADING,
        successAction: volunteerActionType.PROFILE_VOLUNTEER_SUCCESS,
        errorAction: volunteerActionType.PROFILE_VOLUNTEER_ERROR,
        endpoint: '/v2/volunteer',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(volunteerConfig)
        );
    }, []);

    const handleModalOpen = (id: string) => {
        const find = volunteerList.find(
            (x: IProfileVolunteer) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addVolunteer = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Grid item xs sx={selectedTheme.card?.gridWrapper}>
            <Paper sx={selectedTheme.card?.default}>
                <CardTitleComponent
                    title={title}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addVolunteer}
                />
                <Divider />
                <Grid container sx={selectedTheme.card?.cardPadding}>
                    {volunteerList.length === 0 && !volunteerLoading ? (
                        <NoRecordsComponent />
                    ) : (
                        <> </>
                    )}
                    {Children.toArray(
                        volunteerList.map(
                            (x: IProfileVolunteer, index: number) => (
                                <VolunteerComponent
                                    data={{ ...x }}
                                    index={index}
                                    volunteerLoading={volunteerLoading}
                                    cardTitle={title}
                                    selectedTheme={selectedTheme}
                                    canEdit={canEdit}
                                    handleModalOpen={handleModalOpen}
                                />
                            )

                        )
                    )}
                </Grid>
            </Paper>
            {modalOpen ? (
                <VolunteerForm
                    modalOpen={modalOpen}
                    volunteerReduxConfig={volunteerConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};

VolunteerCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
