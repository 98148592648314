import { ILifeGraphVerification } from '../@types/profile-types';

export const convertProfileObjectToMaryVilleVerification = (profileObject: any): ILifeGraphVerification => {
    return {
        asset_id: profileObject?.asset_id || '',
        assetType: profileObject?.assetType || '',
        userId: profileObject?.userId || '',
        verificationCompany: profileObject?.verificationCompany || '',
        verificationStatus: profileObject?.verificationStatus || '',
        verificationType: profileObject?.verificationType || '',
        verifier: profileObject?.verifier || '',
        verificationAssetId: profileObject?.verificationAssetId || '',
        verificationTimestamp: profileObject?.verificationTimestamp?.$date || '',
        verificationHash: profileObject?.verificationHash || '',
        verificationSignature: profileObject?.verificationSignature || '',
    }
}

export const VERIFICATION_VERIFIED_MESSAGE = `This data has been formally validated by a trusted third party.`;

export const VERIFICATION_PENDING_MESSAGE = `Data added to your profile can be verified by a trusted third party. This allows whomever you share this information with to completely trust that it’s accurate and complete. When data has been shared for verification but has not yet been verified, it will display the pending state above.`;

export const VERIFICATION_UNVERIFIED_MESSAGE = `Data added to your profile can be verified by a trusted third party. This allows whomever you share this information with to completely trust that it’s accurate and complete. If data has not yet been verified, it will display as the Unverified state above.`;