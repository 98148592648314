import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import { ThemeContext } from 'util/themes';
import { ArrowForward as ArrowForwardIcon} from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export interface ICardProps {
    heading: string;
    headingStyle?: any;
    isHeadingIcon: boolean;
    containerStyle?: any;
    icon?: React.ReactElement;
    subHeading: string;
    subHeadingStyle?: any;
    description?: string;
    imageName?: string;
    isLinkButton: boolean;
    goToLink: string;
    buttonText?: string;
    rightIcon?:boolean;
    buttonStyle? : any;
    descriptionStyle?: any;
    buttonOrder? : any;
    descriptionOrder?: any;
}

const styles = {
    container: (selectedTheme: any, background: string, containerStyle: any) => ({
        ...selectedTheme?.card?.default,
        ...containerStyle,
        "&:hover" : selectedTheme?.card?.elevated,
        borderColor: selectedTheme?.palette?.secondary?.grayScale
            && selectedTheme?.palette?.secondary?.grayScale[300],
        p: '2rem',
        background: background,
        backgroundPositionX: 'right',
        backgroundSize: 'contain',
        cursor: 'pointer',
    }),
    headingStyle: (selectedTheme: any, headingStyle: any) => ({
        ...selectedTheme.typography?.overlines?.overline1?.default,
        ...selectedTheme.typography?.overlines?.overline1?.semibold,
        color: selectedTheme?.palette?.secondary?.grayScale &&
            selectedTheme?.palette?.secondary?.grayScale[700],
        ...headingStyle
    })
};

const DashboardCard = (props: ICardProps) => {
    const {
        heading,
        headingStyle,
        containerStyle,
        description = '',
        isHeadingIcon = false,
        icon,
        subHeading,
        subHeadingStyle,
        imageName,
        buttonText,
        rightIcon=false,
        buttonStyle = {},
        isLinkButton = false,
        goToLink = '',
        descriptionStyle = {},
        buttonOrder = {},
        descriptionOrder = {}
    } = props;

    const background = `url(/images/dashboard/${imageName}) no-repeat`;

    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const goToLinkUrl = () => {
        if (goToLink)
            history.push(goToLink)
    };

    return (
        <Grid sx={styles.container(selectedTheme, background, containerStyle)}
            display='flex' flex={1} flexDirection='column' item onClick={goToLinkUrl}>
            <Grid>
                {isHeadingIcon && icon}
                <Typography component='span' sx={styles.headingStyle(selectedTheme, headingStyle)}>
                    {heading}
                </Typography>
            </Grid>
            <Grid>
                <Typography sx={{
                    ...selectedTheme.typography?.h1,
                    ...subHeadingStyle
                }}>
                    {subHeading}
                </Typography>
            </Grid>
            {isLinkButton && <Grid {...buttonOrder}>
                <Button
                    variant='text'
                    size='large'
                    color='primary'
                    sx={{
                        mt: 4,
                        mb: 2,
                        ...buttonStyle
                    }}
                    endIcon={ !rightIcon ? <ArrowForwardIcon sx={{ color: selectedTheme?.palette?.primary[500] }} /> : <ArrowForwardIosIcon sx={{ color: selectedTheme?.palette?.primary[500] }} />}
                    onClick={goToLinkUrl}
                >
                    <Typography sx={{
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.large,
                        color: selectedTheme?.palette?.primary[700],
                    }}>
                        {buttonText}
                    </Typography>
                </Button>
            </Grid>}
            {description.length > 0 && <Grid {...descriptionOrder}>
                <Typography sx={{
                    ...selectedTheme.typography?.body.default,
                    ...selectedTheme.typography?.body.body,
                    ...selectedTheme.typography?.body.regular,
                    ...descriptionStyle
                }}>
                    {description}
                </Typography>
            </Grid>}
        </Grid>
    );
};

export default DashboardCard;