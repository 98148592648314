import { shareMyDataActionTypes, notificationActionTypes } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';
import mockShareData from 'assets/data/data-manager/share-my-data.json';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const getShareMyDataSections = () => async (dispatch: Function) => {
    dispatch({ type: shareMyDataActionTypes.GET_ALL_SHAREABLE_DATA_ACTION_TYPE });
    try {
        await delay(3000);
        // const resp = await axios().get('some/url', withCredentials());
        dispatch({
            type: shareMyDataActionTypes.GET_ALL_SHAREABLE_DATA_SUCCESS_ACTION_TYPE,
            payload: mockShareData.data,
        });
    } catch (error) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load Share my data' });
    }
};