import React, { useState, useEffect, Children } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    Box,
    AccordionDetails,
    AppBar,
    Tab,
    Tabs,
} from '@mui/material';
import VerificationStatus from '../../../../components/VerificationStatus/VerificationStatus';
import { Loader } from '../../../../lib/components/Loader/BarSkeleton';
import Vaccination, {
    IProfileVaccinationObject,
    IProfileVaccinationDetail,
} from './Vaccinations/Vaccinations';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { profileActionCreators } from '../../../../redux/actionCreators';
import ShareVaccineWithQR from '../../../../components/ShareVaccineWithQR';
import SwipeableViews from 'react-swipeable-views';
import BlockHeader from '../../../../components/blockHeader';
import ProfileInfoRow from '../../../../components/profile-info-row/ProfileInfoRow';
import RequestVerification from '../../../../components/RequestVerification';
import { Controls } from '../../../../lib';

const styles = {
    cardPadding: {
        p: 2,
    },
    reqVerification: {
        m: 1,
        mt: 0,
        color: '#4799D3',
        borderColor: '#4799D3',
    },
    fullWidth: {
        width: '100%',
    },
    bold: {
        fontWeight: 'bold',
    },
    editButton: {
        m: 1,
        mt: 0,
        color: '#4799D3',
        borderColor: '#4799D3',
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
export interface IReduxConfig {
    loadingSelector: (state: RootState) => any;
    dataSelector: (state: RootState) => any;
    errorSelector: (state: RootState) => any;
    loadingAction: string;
    successAction: string;
    errorAction: string;
}

type IRootObject = IProfileVaccinationObject;

type IDetailsRoot = IProfileVaccinationDetail;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ProfileCards = (props: {
    title: string;
    endpoint: string;
    constructTitle: Function;
    detailsSectionData: Array<IDetailsRoot>;
    reduxConfig: IReduxConfig;
    handleModalOpen?: Function;
    setModalEditData?: Function;
    canEdit?: boolean;
    canAdd?: boolean;
}) => {
    const dispatch = useDispatch();

    const data = useSelector(props.reduxConfig.dataSelector);
    const isLoading = useSelector(props.reduxConfig.loadingSelector);
    const isError = useSelector(props.reduxConfig.errorSelector);

    const {
        title,
        endpoint,
        constructTitle,
        handleModalOpen,
        setModalEditData,
        detailsSectionData,
        canAdd,
        canEdit,
    } = props;

    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const handleEditButton = (dataRow: any) => {
        if (handleModalOpen) handleModalOpen();
        if (setModalEditData) setModalEditData(dataRow);
    };

    const fetchData = () => {
        const { loadingAction, successAction, errorAction } = props.reduxConfig;
        const config = {
            successAction,
            loadingAction,
            errorAction,
            endpoint,
        };
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(config));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box>
            <Grid item xs sx={{ mb: 1.875, flexGrow: 0 }}>
                <Paper>
                    <Grid
                        sx={styles.cardPadding}
                        container
                        alignContent="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography sx={styles.bold} variant="h6">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    {isLoading && (
                        <Grid container>
                            <Accordion sx={styles.fullWidth}>
                                <AccordionSummary
                                    aria-controls="phoneNumbersCard-content"
                                    id="phoneNumbersCard"
                                >
                                    <Loader />
                                </AccordionSummary>
                            </Accordion>
                        </Grid>
                    )}
                    {!!data.length &&
                        Children.toArray(
                            data.map((dataRow: IRootObject) => {
                                return (
                                    <Grid container>
                                        <Accordion sx={styles.fullWidth}>
                                            <AccordionSummary
                                                aria-controls="phoneNumbersCard-content"
                                                id="phoneNumbersCard"
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignContent="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Typography>
                                                            {constructTitle(
                                                                dataRow
                                                            )}
                                                        </Typography>
                                                        <ShareVaccineWithQR />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        {/* <VerificationStatus
                                                        status={
                                                            dataRow.verificationStatus
                                                        }
                                                    /> */}
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    sx={{ flexGrow: 1 }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            width: 'inherit',
                                                        }}
                                                    >
                                                        <AppBar
                                                            position="static"
                                                            color="transparent"
                                                            elevation={0}
                                                        >
                                                            <Tabs
                                                                value={value}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                indicatorColor="primary"
                                                                textColor="primary"
                                                                variant="fullWidth"
                                                                aria-label="full width tabs example"
                                                            >
                                                                <Tab
                                                                    label="Detail"
                                                                    {...a11yProps(
                                                                        0
                                                                    )}
                                                                />
                                                                <Tab
                                                                    label="Verification"
                                                                    {...a11yProps(
                                                                        1
                                                                    )}
                                                                />
                                                            </Tabs>
                                                        </AppBar>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            width: 'inherit',
                                                        }}
                                                    >
                                                        <SwipeableViews
                                                            axis={'x'}
                                                            index={value}
                                                            onChangeIndex={
                                                                handleChangeIndex
                                                            }
                                                        >
                                                            <TabPanel
                                                                value={value}
                                                                index={0}
                                                            >
                                                                <Box width="100%">
                                                                    <Grid
                                                                        container
                                                                        justifyContent="space-between"
                                                                        wrap="nowrap"
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            item
                                                                        >
                                                                            {Children.toArray(
                                                                                detailsSectionData.map(
                                                                                    (
                                                                                        detail,
                                                                                        detailsIndex
                                                                                    ) => (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                6
                                                                                            }
                                                                                        >
                                                                                            <ProfileInfoRow
                                                                                                label={
                                                                                                    detail.label
                                                                                                }
                                                                                                value={detail.getter(
                                                                                                    dataRow
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            sx={{
                                                                                mt: 1.25,
                                                                            }}
                                                                        >
                                                                            <BlockHeader
                                                                                assetId={
                                                                                    dataRow.asset_id
                                                                                }
                                                                                assetType={
                                                                                    dataRow.assetType
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        container
                                                                        justifyContent="flex-end"
                                                                    >
                                                                        {dataRow.verificationStatus ===
                                                                            'unverified' && (
                                                                            <RequestVerification
                                                                                assetType={
                                                                                    dataRow.assetType
                                                                                }
                                                                                assetId={
                                                                                    dataRow.asset_id
                                                                                }
                                                                                callback={() =>
                                                                                    fetchData()
                                                                                }
                                                                            />
                                                                        )}
                                                                        {canEdit && (
                                                                            <Controls.Button
                                                                                style={
                                                                                    styles.editButton
                                                                                }
                                                                                onClick={() =>
                                                                                    handleEditButton(
                                                                                        dataRow
                                                                                    )
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </Controls.Button>
                                                                        )}
                                                                    </Grid>
                                                                </Box>
                                                            </TabPanel>
                                                            <TabPanel
                                                                value={value}
                                                                index={1}
                                                            >
                                                                <Box width="100%">
                                                                    <Grid
                                                                        container
                                                                        justifyContent="space-between"
                                                                        wrap="nowrap"
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            item
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <ProfileInfoRow
                                                                                    label="Verifier Company"
                                                                                    value={
                                                                                        dataRow.verificationCompany
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            sx={{
                                                                                mt: 1.25,
                                                                            }}
                                                                        >
                                                                            {dataRow.verificationStatus !==
                                                                                'unverified' && (
                                                                                <BlockHeader
                                                                                    assetId={
                                                                                        dataRow.asset_id
                                                                                    }
                                                                                    assetType={
                                                                                        dataRow.verificationType
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </TabPanel>
                                                        </SwipeableViews>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                );
                            })
                        )}
                </Paper>
            </Grid>
        </Box>
    );
};
export default ProfileCards;
