import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { minBirthDate } from 'util/date.utils';
import validationMessage from 'util/validations';
import moment from 'moment';

export interface IAboutFormInputs {
    givenName: string;
    middleName?: string;
    familyName: string;
    preferredName: string;
    suffix: string;
    birthDate: string;
    gender: string;
    taxNumber: string;
    passportNumber: string;
}

const minBirthDateValue = minBirthDate();

const schema = yupObject({
    familyName: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    // middleName: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    birthDate: yupString().test('Date of Birth Min', validationMessage.dateMin(minBirthDateValue), function (value) {
        return moment().diff(moment(value), 'years') <= 120;
    }).test('Date of Birth Max', validationMessage.dateMax(), function (value) {
        return moment().diff(moment(value)) >= 0;
    }).required(validationMessage.required),
    givenName: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    gender: yupString(),
    preferredName: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    passportNumber: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    taxNumber: yupString().matches(/\d{3}-\d{2}-\d{4}$/, validationMessage.regex).required(validationMessage.required),
    suffix: yupString(),
});

export const formSchemaResolver = yupResolver(schema);