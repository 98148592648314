import {
    Typography,
    Box,
    Grid,
    useMediaQuery,
    useTheme,
    Paper,
    Skeleton,
    ListItem,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { commonStyles } from './share-my-data.helper';
import { useState, Fragment, useEffect, Children, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BorderLinearProgress } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataSelector } from 'redux/selectors';
import { IShareData, IShareableItem } from '../../@types/share-my-data.types';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

const styles = {
    cardTitle: {
        fontSize: '16px',
        color: '#80848D',
        fontWeight: 600,
        textTransform: 'uppercase',
        mb: 1.5,
        mt: 4,
    },
    paper: {
        mb: 2,
        px: 3,
        py: 2,
        background: '#FFFFFF',
        border: '1px solid #AABABF',
        borderRadius: '2px',
        boxShadow: 'none'
    },
    listItemText: {
        color: '#47515A',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '21px',
    },
};

export function ShareMyDataWhatComponent() {
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const dispatch = useDispatch();

    const loading = useSelector(shareMyDataSelector.selectLoading);
    const data = useSelector(shareMyDataSelector.selectData);
    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedvalues, setSelectedValues] = useState<string[]>([]);
    const loadingValues = Array.from(Array(5), (_, i) => {
        return {};
    });
    const loadingData = [{ values: loadingValues }];
    const width = isMobile ? '92%' : '500px';
    const [dataToUse, setDataToUse] = useState<any[]>(loadingData);

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        } else {
            const type = shareObject.type;
            const find = data.find((x: IShareData) => x.type === type);
            let stateDefaults: string[] = [];
            if (find && !shareObject.assetTypes) {
                for (const shareAsset of find.shareableAssetList) {
                    stateDefaults = stateDefaults.concat(
                        shareAsset.values
                            .filter(
                                (x: IShareableItem) =>
                                    x.isCompulsory || x.default
                            )
                            .map((x: IShareableItem) => x.value)
                    );
                }
            }
            const defaultSelectedValues = shareObject.assetTypes
                ? shareObject.assetTypes
                : stateDefaults;
            setSelectedValues(defaultSelectedValues);
            setDataToUse(find?.shareableAssetList || []);
        }
    }, [loading]);

    const nextClicked = () => {
        shareObject.assetTypes = selectedvalues;
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        if (shareObject.type === 'employer') {
            history.push('/share-my-data/when');
        } else {
            history.push('/share-my-data/how');
        }
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    const valueToggled = (value: string) => {
        const copyValues = JSON.parse(JSON.stringify(selectedvalues));
        const findIndex = copyValues.findIndex((x: string) => x === value);
        if (findIndex >= 0) {
            copyValues.splice(findIndex, 1);
        } else {
            copyValues.push(value);
        }
        setSelectedValues(copyValues);
    };

    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width }}>
                <Typography sx={{...commonStyles.header, color: selectedTheme.palette.primary[700]}} variant='h1'>Share My Data</Typography>
                <BorderLinearProgress variant="determinate" value={40} bgcolor={selectedTheme.palette.primary[500]}/>
                <Typography sx={{
                    ...commonStyles.subHeader,
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body3,
                    ...selectedTheme?.typography?.body?.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                }}
                >
                    What would you like to share?
                </Typography>
                {Children.toArray(
                    dataToUse.map((x: any, index: number) => {
                        return (
                            <Fragment key={`share_2_fragment_${index}`}>
                                <Typography
                                    sx={{
                                        ...styles.cardTitle,
                                        ...selectedTheme?.typography?.overlines?.overline1?.default,
                                        ...selectedTheme?.typography?.overlines?.overline1?.medium,
                                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                                    }}
                                    key={`share_2_title_${index}`}
                                >
                                    {loading ? (
                                        <Skeleton width={'25%'} />
                                    ) : (
                                        x.title
                                    )}
                                </Typography>
                                {Children.toArray(
                                    x.values.map(
                                        (y: any, valueIndex: number) => {
                                            return (
                                                <Paper sx={styles.paper}>
                                                    <ListItem
                                                        disabled={y.disabled}
                                                        disablePadding
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge={'end'}
                                                                disabled={
                                                                    !y.isEditable ||
                                                                    y.disabled
                                                                }
                                                                sx={{
                                                                    '&.Mui-checked': {
                                                                        color: selectedTheme?.palette?.primary[500]
                                                                    },
                                                                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                                                                }}
                                                                checked={selectedvalues.includes(
                                                                    y.value
                                                                )}
                                                                onChange={() =>
                                                                    valueToggled(
                                                                        y.value
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                loading ? (
                                                                    <Skeleton
                                                                        width={
                                                                            '50%'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    y.title
                                                                )
                                                            }
                                                            primaryTypographyProps={{
                                                                ...styles.listItemText,
                                                                ...selectedTheme?.typography?.body?.default,
                                                                ...selectedTheme?.typography?.body?.body1,
                                                                ...selectedTheme?.typography?.body?.semibold,
                                                                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                                                            }}
                                                        />
                                                    </ListItem>
                                                </Paper>
                                            );
                                        }
                                    )
                                )}
                            </Fragment>
                        );
                    })
                )}
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color="secondary"
                        size="large"
                        disabled={loading}
                        onClick={cancelClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        size="large"
                        disabled={loading}
                        onClick={previousClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Back
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color="primary"
                        size={'large'}
                        disabled={loading}
                        onClick={nextClicked}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
