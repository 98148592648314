import { useEffect, useState, useContext } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Controls, Components } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import axios, { withCredentials } from 'util/axiosInstance';
import { profileActionCreators } from 'redux/actionCreators';
import { INotify } from 'lib/types';
import { IProfileEmailAddressesObject } from '../../../@types/profile-types';
import { emailLookupSelector, personalInfoSelector } from 'redux/selectors';
import { IEmailFormInputs, formDefaults, formSchemaResolver, styles } from './emailForm.helper'
import { emailAddressConfig } from './contact.helper';
import { areObjectDifferent } from 'util/object.util';
import { ThemeContext } from 'util/themes';

type EmailPageType = 'PROFILE' | 'ONBOARDING';

interface IEmailForm {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileEmailAddressesObject | null;
    type: EmailPageType;
    autoSubmit?: boolean;
    isRequired?: boolean;
}

export const getEmailForm = (modalEditData: any, submitFormValues: Function, typeOptions: Partial<HTMLInputElement>[], pageType: EmailPageType, autoSubmit: boolean | undefined, isRequired: boolean, selectedTheme: any) => {
    const [defaultFormData, setDefaultFormData] = useState<IEmailFormInputs>(formDefaults);
    const inputStyleProp = {
        ...styles[pageType].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };
    const labelStyleProp = {
        ...styles[pageType].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const submitForm = async (formValues: any) => {
        submitFormValues(formValues, defaultFormData);
    }

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
        setValue
    } = useForm<IEmailFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit(submitForm)();
        }
        return;
    }, [autoSubmit]);


    useEffect(() => {
        const formData = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.emailAddress = modalEditData.emailAddress || '';
            formData.type = modalEditData.type || '';
            formData.asset_id = modalEditData.asset_id;
            formData.primary = modalEditData.primary || false;
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    const emailLabel = pageType === 'ONBOARDING' ? 'Personal Email' : 'Email';
    const displayOnboarding = pageType === 'ONBOARDING' ? 'none' : 'block';

    return (
        <Grid container display={'flex'} direction={'row'} component="form" id={'email_form'} onSubmit={handleSubmit(submitForm)}>
            <Grid item xs={12} display={displayOnboarding}>
                <Controls.Checkbox
                    name="primary"
                    label="Is Primary"
                    value={defaultFormData.primary}
                    checkedColor={selectedTheme?.palette?.primary[500]}
                    unCheckedColor={selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]}
                    control={control}
                    error={errors?.primary}
                    inputStyleProps={inputStyleProp}
                />
            </Grid>
            <Grid item xs={12} display={displayOnboarding}>
                <Controls.TextSelectWithOtherDropdown
                    name="type"
                    label="Email Type"
                    defaultValue={defaultFormData.type}
                    options={typeOptions}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    labelStyles={labelStyleProp}
                    inputStyleProps={inputStyleProp}
                    inputInlineStyle={{ ...selectedTheme?.input }}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                {pageType !== 'ONBOARDING' ?
                    <Controls.Input
                        label=""
                        error=""
                        name="emailRequired"
                        control={control}
                        defaultValue="yes"
                        type="hidden"
                        sx={{ display: 'none' }}
                    />
                    : ''}
                <Controls.Input
                    name="emailAddress"
                    label={emailLabel}
                    defaultValue={defaultFormData.emailAddress}
                    control={control}
                    error={errors?.emailAddress || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input }}
                    labelStyles={labelStyleProp}
                    required={isRequired}
                />
            </Grid>
        </Grid>
    );
}

export const EmailForm = (props: IEmailForm) => {
    const { modalOpen, modalEditData, type, autoSubmit, isRequired = true } = props;
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });

    let emailLookupData = useSelector(emailLookupSelector.selectEmailLookupData);

    if (type === 'ONBOARDING') {
        emailLookupData = emailLookupData.filter((x) => x.value !== '__other__');
    }

    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const email of emailLookupData) {
        typeOptions.push({ id: email.value, title: email.display });
    }

    const handleModalClose = () => {
        props.handleModalClose();
    };

    useEffect(() => {
        setDidMount(true);
        return () => {
            setDidMount(false);
        }
    }, []);

    const submitForm = async (formValues: any, defaultFormData: IEmailFormInputs) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }
        const sendApiRequest = areObjectDifferent(formValues, defaultFormData, ['type', 'emailAddress']);
        if (!sendApiRequest) {
            handleModalClose();
            return;
        }
        delete requestValues.otherType;
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(emailAddressConfig.endpoint, requestValues, withCredentials());
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(`${emailAddressConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(emailAddressConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    if (type === 'PROFILE') {
        return (
            <>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle data-testid="email_form_title">
                        <Typography component={'span'} sx={{
                            letterSpacing: 0,
                            ...selectedTheme?.typography?.h4,
                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                        }}>
                            {modalEditData ? 'Edit' : 'Add'} Email Info
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            getEmailForm(modalEditData, submitForm, typeOptions, type, autoSubmit, isRequired, selectedTheme)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <Controls.Button form="email_form" type="submit" variant='contained' color='primary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Submit
                            </Typography>
                        </Controls.Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    } else {
        return (
            <>
                {
                    getEmailForm(modalEditData, submitForm, typeOptions, type, autoSubmit, isRequired, selectedTheme)
                }
            </>
        )
    }
};
