import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { routerSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { pathwaySelector } from 'redux/selectors';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';
import { PathwayDetailComponent } from '../../../components/Pathway/PathwayDetail';

export function HomePathwayDetail() {
    const pathway = useSelector(pathwaySelector.selectPathwayExpandedStep);
    const loading = useSelector(pathwaySelector.selectedPathwayLoading);
    const selectedAssetId = useSelector(routerSelector.selectCurrentPathwayLessonCourse);
    const completedMilestones = useSelector(pathwaySelector.selectTotalCompletedMilestones);
    const currentModule = useSelector(routerSelector.selectCurrentModule);

    // const theme = useTheme();
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();

    const { pathwayId } = useParams<any>();

    useEffect(() => {
        dispatch(pathwayActionCreators.getSelectedPathwayData(pathwayId));
        setCurrentTheme('lifetrek');
    }, []);

    return (
        <PathwayDetailComponent
            isHealthMode={false}
            loading={loading}
            pathway={pathway}
            selectedAssetId={selectedAssetId}
            completedMilestones={completedMilestones}
            currentModule={currentModule}
        />
    );
}
