import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { emailAddressSelector, phoneNoSelector } from 'redux/selectors';
import { EmailForm } from './emailForm';
import { PhoneForm } from './phoneForm';
import {
    ILookup,
    IProfilePhoneNoObject,
    IProfileEmailAddressesObject,
} from '../../../@types/profile-types';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import mockPhoneTypes from 'assets/data/profile/phone-types.json';
import {
    emailAddressConfig,
    emailLookupConfig,
    phoneLookupConfig,
    phoneNumberConfig,
} from './contact.helper';
import { ThemeContext } from '../../../util/themes';
import { ContactComponent, EmailComponent } from './contactComponent';

interface IContactCardComponent {
    title: string;
    canEdit: boolean;
    itemsToShow: string[];
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const ContactCardCompoment = (props: IContactCardComponent) => {
    const { title, canEdit, itemsToShow, type } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [phoneModalOpen, setPhoneModalOpen] = useState(false);
    const [emailModalEditData, setEmailModalEditData] = useState(null);
    const [phoneModalEditData, setPhoneModalEditData] = useState(null);
    const emailKey = 'email_info';
    const phoneKey = 'phone_info';

    const phoneLoading = useSelector(phoneNoSelector.selectPhoneNoLoading);
    const phoneDataListRaw = useSelector(phoneNoSelector.selectPhoneNoDataRaw);
    const phoneDataList = useSelector(phoneNoSelector.selectPhoneNoData);

    const emailLoading = useSelector(
        emailAddressSelector.selectEmailAddressLoading
    );
    const emailListRaw = useSelector(
        emailAddressSelector.selectEmailAddressDataRaw
    );
    const emailList = useSelector(emailAddressSelector.selectEmailAddressData);

    useEffect(() => {
        if (itemsToShow.includes(emailKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    emailAddressConfig
                )
            );
            // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(emailLookupConfig));
        }
        if (itemsToShow.includes(phoneKey)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    phoneNumberConfig
                )
            );
            // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneLookupConfig));
        }

        // TODO remove next block when api for external data is ready
        const mockEmail = mockEmailTypes.data as ILookup[];
        const mockPhone = mockPhoneTypes.data as ILookup[];
        dispatch({ type: phoneLookupConfig.successAction, payload: mockPhone });
        dispatch({ type: emailLookupConfig.successAction, payload: mockEmail });
    }, []);

    const addButtonClicked = (modal?: string) => {
        if (modal === emailKey) {
            setEmailModalOpen(true);
            setEmailModalEditData(null);
        } else if (modal === phoneKey) {
            setPhoneModalOpen(true);
            setPhoneModalEditData(null);
        }
    };

    const handleModalClose = () => {
        setEmailModalOpen(false);
        setPhoneModalOpen(false);
    };

    const handleEmailModalOpen = (id: string) => {
        const find: any = emailListRaw.find(
            (x: IProfileEmailAddressesObject) => x.asset_id === id
        );
        const formObject = find ? find : null;
        setEmailModalOpen(true);
        setEmailModalEditData(formObject);
    };

    const handlePhoneModalOpen = (id: string) => {
        const find = phoneDataListRaw.find(
            (x: IProfilePhoneNoObject) => x.asset_id === id
        );
        const formObject = find ? JSON.parse(JSON.stringify(find)) : null;
        setPhoneModalOpen(true);
        setPhoneModalEditData(formObject);
    };

    let combinedLoading = false;
    if (itemsToShow.includes(phoneKey)) {
        combinedLoading = combinedLoading || phoneLoading;
    }
    if (itemsToShow.includes(emailKey)) {
        combinedLoading = combinedLoading || emailLoading;
    }

    let totalRecords = 0;
    if (itemsToShow.includes(phoneKey)) {
        totalRecords += phoneDataList.length;
    }
    if (itemsToShow.includes(emailKey)) {
        totalRecords += emailList.length;
    }

    const addOptions = [
        { key: emailKey, value: 'Email' },
        { key: phoneKey, value: 'Phone' },
    ];
    return (
        <Box>
            <Grid item xs sx={selectedTheme.card?.gridWrapper}>
                <Paper sx={selectedTheme.card?.default}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addButtonClicked}
                        addOptionList={addOptions}
                    />
                    <Divider />
                    {totalRecords === 0 && !combinedLoading ? (
                        <Grid container sx={selectedTheme.card?.cardPadding}>
                            <NoRecordsComponent />
                        </Grid>
                    ) : (
                        <> </>
                    )}
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        {itemsToShow.includes(phoneKey) ? (
                            Children.toArray(
                                phoneDataList.map((x) => {
                                    return (
                                        <ContactComponent
                                            phoneLoading={phoneLoading}
                                            cardTitl={title}
                                            data={{ ...x }}
                                            selectedTheme={selectedTheme}
                                            handlePhoneModalOpen={handlePhoneModalOpen}
                                            canEdit={canEdit}
                                        />
                                    );
                                })
                            )
                        ) : (
                            <></>
                        )}
                        {itemsToShow.includes(emailKey) ? (
                            Children.toArray(
                                emailList.map((x) => {
                                    return (
                                        <EmailComponent
                                            phoneLoading={phoneLoading}
                                            data={{ ...x }}
                                            cardTitl={title}
                                            selectedTheme={selectedTheme}
                                            handleEmailModalOpen={handleEmailModalOpen}
                                            canEdit={canEdit}
                                        />
                                    );
                                })
                            )
                        ) : (
                            <> </>
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {emailModalOpen ? (
                <EmailForm
                    modalOpen={emailModalOpen}
                    handleModalClose={handleModalClose}
                    modalEditData={emailModalEditData}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
            {phoneModalOpen ? (
                <PhoneForm
                    modalOpen={phoneModalOpen}
                    handleModalClose={handleModalClose}
                    modalEditData={phoneModalEditData}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

ContactCardCompoment.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: ['email_info', 'phone_info'],
    type: 'PAGE',
};
