import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import {
    formalEducationSelector,
    educationLookupSelector,
} from 'redux/selectors';
import {
    ProfileLookupDataActions,
    formalEducationActionType,
} from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileEducationObject,
    ILookup,
} from '../../../@types/profile-types';
import mockEducationDegree from 'assets/data/profile/education-degree.json';
import { FormalEducationForm } from './formalEducationForm';
import { ThemeContext } from 'util/themes';
import { FormalEducationComponent } from './formalEducationComponent';

interface IFormalEducationComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any
}

export const FormalEducationCardComponent = (
    props: IFormalEducationComponent
) => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);

    const educationLoading = useSelector(
        formalEducationSelector.selectFormalEducationLoading
    );
    const educationListRaw = useSelector(
        formalEducationSelector.selectFormalEducationData
    );
    const educationLookupLoading = useSelector(
        educationLookupSelector.selectEducationLookupLoading
    );
    const educationLookupData = useSelector(
        educationLookupSelector.selectEducationLookupData
    );

    const loading = educationLoading || educationLookupLoading;
    const educationList: IProfileEducationObject[] = JSON.parse(
        JSON.stringify(educationListRaw)
    );
    if (!loading) {
        for (const education of educationList) {
            const findType = educationLookupData.find(
                (x) => x.value === education.degreeEarned
            );
            education.degreeEarned = findType
                ? findType.display
                : education.degreeEarned;
        }
    }

    const educationConfig: IReduxDataConfig = {
        loadingAction: formalEducationActionType.PROFILE_EDUCATION_LOADING,
        successAction: formalEducationActionType.PROFILE_EDUCATION_SUCCESS,
        errorAction: formalEducationActionType.PROFILE_EDUCATION_ERROR,
        endpoint: '/v2/education',
    };

    const educationLookupConfig: IReduxDataConfig = {
        loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_LOADING,
        successAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_SUCCESS,
        errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_ERROR,
        endpoint: '/v2/education-external',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(educationConfig)
        );
        // TODO remove next block of line when external data api is ready
        const mockEducation = mockEducationDegree.data as ILookup[];
        dispatch({
            type: educationLookupConfig.successAction,
            payload: mockEducation,
        });

        // TODO uncomment next line when external data api is ready
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(educationLookupConfig));
    }, []);

    const { title, canEdit, type } = props;

    const handleModalOpen = (id: string) => {
        const find = educationListRaw.find(
            (x: IProfileEducationObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEducation = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Box>
            <Grid item xs sx={selectedTheme.card?.gridWrapper}>
                <Paper sx={selectedTheme.card?.default}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addEducation}
                    />
                    <Divider />
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        {educationList.length === 0 && !loading ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            educationList.map((x) => (
                                <FormalEducationComponent
                                    cardTitle={title}
                                    data={{ ...x }}
                                    selectedTheme={selectedTheme}
                                    canEdit={canEdit}
                                    handleModalOpen={handleModalOpen}
                                    loading={loading}
                                />
                            ))
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <FormalEducationForm
                    modalOpen={modalOpen}
                    formalEducationReduxConfig={educationConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <> </>
            )}
        </Box>
    );
};

FormalEducationCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
