import { useContext } from 'react';
import { Grid } from '@mui/material';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from 'util/themes';
import { formSchemaResolver } from './medication.helper';
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { modalStyles } from '../health-dashboard.helper';
import { ModalFormWrapper } from '../HealthDashboardModalWrapper';

interface IMedicationFormInputs {
    medicine: string;
    prescribedBy: string;
}

const styles = {
    controlPadding: { },
    inputWidth: {
        width: '100%'
    },
    labelStyles: { }
};

export const MedicationForm = (props:any) => {
    const { modalOpen, selectedData = {}, isEdit = false } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const copyData = {...selectedData};
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IMedicationFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: copyData
    });

    const submitForm = async (formValues: any) => {
        try {
            const requestData = {
                medicine: formValues.medicine,
                prescribedBy: formValues.prescribedBy,
                userId: selectedData.userId
            };
        
            if(selectedData.assetId) {
                await axios().put(`/v2/medication/${selectedData.assetId}`, requestData, withCredentials());
            } else {
                delete requestData.userId;
                await axios().post(`/v2/medication`, requestData, withCredentials())
            }
            dispatch(healthDashboardActionCreators.getHealthDashboardMedicationList());
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ModalFormWrapper
            formId='medication'
            modalOpen={modalOpen}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            title='Medication'
            handleModalClose={props.handleModalClose}
            onSubmitForm={submitForm}
        >
            <>
                <Grid item xs={12}>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item sx={styles.inputWidth}>
                            <Controls.Input
                                name="medicine"
                                label="Medication Name"
                                defaultValue={copyData.medicine}
                                control={control}
                                error={errors?.medicine || ''}
                                inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                                sx={{...selectedTheme?.input}}
                                labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item sx={styles.inputWidth}>
                        <Controls.Input
                            name="prescribedBy"
                            label="Prescribing Doctor*"
                            defaultValue={copyData.prescribedBy}
                            control={control}
                            error={errors?.prescribedBy || ''}
                            inputStyleProps={modalStyles.inputStyleProp(styles, selectedTheme)}
                            sx={{...selectedTheme?.input}}
                            labelStyles={modalStyles.labelStyleProp(styles, selectedTheme)}
                            required
                        />
                    </Grid>
                </Grid>
            </>
        </ModalFormWrapper>
    )
}