import React, { useState, useEffect } from 'react';
import { Avatar as MuiAvatar, Box } from '@mui/material';
import axios, { withCredentials } from '../../util/axiosInstance';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import hexImage from '../../assets/images/icons/iQ-hex.png';
import hoverHexImage from '../../assets/images/icons/iQ-hex-hover.png';

interface IBlockHeader {
    assetId: string;
    assetType: string;
}

export const RowItem = (props: { title: string; value: string }) => {
    const { title, value } = props;
    return (
        <>
            <Box sx={{ textTransform: 'uppercase' }}>{title}</Box>
            <Box sx={{ mb: 1.25 }}>{value}</Box>
        </>
    );
};

interface IchainData {
    assetId: string;
    timestamp: string;
    hash: string;
    signature: string;
}

const initChainData = {
    assetId: '',
    timestamp: '',
    hash: '',
    signature: '',
};

const BlockHeader = (props: IBlockHeader) => {
    const { assetId, assetType } = props;
    const [imgSrc, setImgSrc] = useState(hexImage);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chainData, setchainData] = useState<IchainData>(initChainData);

    const fetchAssetStatus = async () => {
        const resp = await axios().get(
            `/block-header/${assetType}/${assetId}`,
            withCredentials()
        );
        const respData = resp.data?.data;
        setchainData({
            assetId: respData.asset_id,
            timestamp: respData.timestamp?.$date,
            hash: respData.hash,
            signature: respData.signature,
        });
        setDialogOpen(true);
    };

    const handleEnter = () => {
        setImgSrc(hoverHexImage);
    };

    const handleLeave = () => {
        setImgSrc(hexImage);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Box
                onClick={fetchAssetStatus}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                sx={{ cursor: 'pointer' }}
            >
                <MuiAvatar alt={'BurstIQ'} src={imgSrc} />
            </Box>
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="simple-dialog-title"
                open={dialogOpen}
            >
                <DialogContent
                    sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        height: 'auto',
                        overflowWrap: 'break-word',
                    }}
                >
                    <RowItem title="Asset Id" value={chainData.assetId} />
                    <RowItem title="Timestamp" value={chainData.timestamp} />
                    <RowItem title="Hash" value={chainData.hash} />
                    <RowItem title="Signature" value={chainData.signature} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BlockHeader;
