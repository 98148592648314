import React from 'react';
import { Box } from '@mui/material';

import {
    IInstanceData,
    IMetaData,
    IArticle
} from '../../../@types/pathway-types';
import { Articles } from '../Steps';

interface IArticlesData {
    articles: IArticle[];
}

interface IArticlesComponent {
    instanceData: IInstanceData;
    metaData: IMetaData;
    data: IArticlesData;
    sectionSelected: Function;
    isHealthMode: boolean;
}

function Article(props: IArticlesComponent) {
    const { data, sectionSelected } = props;

    return (
        <Box sx={{ display: 'flex' }}>
            {!!data.articles.length ? (
                <Box style={{ width: '100%' }}>
                    {React.Children.toArray(
                        data?.articles.map((article: IArticle) => {
                            return (
                                <Articles
                                    {...article}
                                    sectionSelected={sectionSelected}
                                />
                            );
                        })
                    )}
                </Box>
            ) : (
                <Box />
            )}
        </Box>
    );
}

export default Article
