import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Link,
    Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import axios from '../../util/axiosInstance';
import {
    INotificationProps,
    Notification,
} from '../../lib/components/notification/Notification';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    schema,
    FormValues,
    TermsAndConditionsResponse,
    LocationState,
} from './termsAndConditionsHelper';
import { useLocation } from 'react-router-dom';
import { getUserDefaultRoute } from 'util/getUserData';
import LearnIQLogo from 'assets/images/logos/LearnIQ Logo.png';

interface IFormInputs {
    username: string;
    password: string;
}

export const TermsAndConditions = () => {
    const listInnerRef = useRef<HTMLDivElement>();
    const location = useLocation<LocationState>();
    const [reachedBottom, setReachedBottom] = useState(false);

    const [notify, setNotify] = useState<INotificationProps>({
        isOpen: false,
        title: '',
        message: '',
        type: 'success',
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitSuccessful, errors },
    } = useForm<FormValues>({
        defaultValues: {
            signature: '',
            accepted: false,
        },
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    let { from, terms } = location.state || {
        from: { pathname: '/' },
        terms: {
            username: '',
            password: '',
            terms: [],
        },
    };

    const [termsToAgreeData, setTermsToAgreeData] =
        useState<TermsAndConditionsResponse>({
            username: terms.username,
            password: terms.password,
            hash: terms.terms[0]?.hash || '',
            signature: '',
        });

    const [markdown, setMarkdown] = useState(
        terms.terms[0].term ||
            '#There was a problem fetching our Terms and Conditions.'
    );

    // Handles the case where the markdown is smaller than the Markdown component
    useEffect(() => {
        if (listInnerRef.current) {
            if (
                listInnerRef.current.scrollHeight <=
                listInnerRef.current.clientHeight
            ) {
                setReachedBottom(true);
            } else {
                setReachedBottom(false);
            }
        }
    }, [markdown, listInnerRef]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;

            if (scrollTop + clientHeight >= scrollHeight * 0.75) {
                if (!reachedBottom) {
                    setReachedBottom(true);
                }
            }
        }
    };

    const successHandler = () => {
        const route = getUserDefaultRoute();
        window.location.href = route;
    };

    const failureHandler = () => {
        window.location.href = from.pathname;
    };

    const setLocalStorage = (responseData: {}): void => {
        if (!responseData) {
            return;
        } else {
            // Given an object, map over it and set the localStorage
            Object.keys(responseData).forEach((key) => {
                localStorage.setItem(
                    key,
                    JSON.stringify(
                        responseData[key as keyof typeof responseData]
                    )
                );
            });
        }
    };

    const executeLogin = async (formValues: IFormInputs): Promise<any> => {
        await axios()
            .post('/auth/login', formValues)
            .then((response) => {
                setLocalStorage(response.data);
            })
            .then(() => {
                setNotify({
                    isOpen: true,
                    title: 'Success',
                    message: 'You have successfully logged in.',
                    type: 'success',
                    onClosed: successHandler,
                });
            })
            .catch((error) => {
                console.log(error);
                setNotify({
                    isOpen: true,
                    title: 'Error',
                    message: 'There was an error logging in.',
                    type: 'error',
                    onClosed: failureHandler,
                });
            });
    };

    // Handles user clicking the "Accept & Continue" button after agreeing to the Terms and Conditions.
    const onSubmit = (data: FormValues) => {
        axios()
            .post('/terms/agree', {
                ...termsToAgreeData,
                signature: data.signature,
            })
            .then(() => {
                // Successful response.
                setNotify({
                    isOpen: true,
                    title: 'Success',
                    message: 'You have accepted our Terms and Conditions.',
                    type: 'success',
                    onClosed: () => {
                        executeLogin({
                            username: terms.username,
                            password: terms.password,
                        });
                    },
                });
            })
            .catch(() => {
                // Error response.
                setNotify({
                    isOpen: true,
                    title: 'Error',
                    message:
                        'There was a problem accepting our Terms and Conditions.',
                    type: 'error',
                    onClosed: failureHandler,
                });
            });
    };

    return (
        <Grid
            container
            direction="column"
            spacing={4}
            sx={{
                py: 4,
            }}
        >
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link href="/login">
                    <Box
                        component="img"
                        src={LearnIQLogo}
                        alt="LearnIQ Logo"
                        sx={{ width: '196px', height: 'auto' }}
                    />
                </Link>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ maxWidth: 'md', mx: 'auto' }}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        sx={{ py: 1.5, pl: 2, pr: 4 }}
                    >
                        <Typography
                            variant="subtitle1"
                            color="companyColors.cornflowerBlue"
                        >
                            Terms & Conditions
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ ml: 'auto', color: '#80848D' }}
                        >
                            Scroll to the bottom to enable electronic signature.
                        </Typography>
                    </Box>

                    <Divider />
                    <CardContent sx={{ p: 0 }}>
                        <Box
                            onScroll={onScroll}
                            ref={listInnerRef}
                            id="list-inner"
                            sx={{
                                overflow: 'scroll',
                                maxHeight: `calc(100vh - 450px)`,
                                px: 2,
                                pt: 2,
                            }}
                        >
                            <ReactMarkdown
                                children={markdown}
                                components={{
                                    h1: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    h2: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    h3: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    h4: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    h5: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    h6: (props: any) => (
                                        <Typography
                                            variant="subtitle1"
                                            color="secondary.main"
                                            sx={{ mb: 2 }}
                                            {...props}
                                        />
                                    ),
                                    p: (props: any) => (
                                        <Typography
                                            variant="body2"
                                            sx={{ mb: 3 }}
                                            {...props}
                                        />
                                    ),
                                }}
                            />
                        </Box>
                    </CardContent>
                    <CardActions sx={{ p: 3, backgroundColor: '#F9F9FA' }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                                <Typography
                                    variant="subtitle1"
                                    color="primary.main"
                                    sx={{ mb: 2 }}
                                >
                                    Electronic Signature
                                </Typography>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    sx={{ mb: 1.5 }}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                ...(!reachedBottom && {
                                                    opacity: 0.5,
                                                }),
                                            }}
                                        >
                                            Type your full name here to sign
                                            electronically:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Controls.Input
                                            control={control}
                                            name="signature"
                                            label=""
                                            error={errors.signature}
                                            placeholder="Your name"
                                            disabled={!reachedBottom}
                                            size="small"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box sx={{ mb: 1.5 }}>
                                    <Controls.ControlledSwitch
                                        control={control}
                                        name="accepted"
                                        required
                                        disabled={!reachedBottom}
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    ...(!reachedBottom && {
                                                        opacity: 0.5,
                                                    }),
                                                }}
                                            >
                                                I understand this is a legal
                                                representation of my signature.
                                                I acknowledge that I have read
                                                and accept the terms and
                                                conditions.
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitSuccessful}
                                        sx={{
                                            float: 'right',
                                        }}
                                    >
                                        Accept & Continue
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </CardActions>
                </Card>
            </Grid>
            <Notification notify={notify} setNotify={setNotify} />
        </Grid>
    );
};
