import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';

import { pathwaySelector, routerSelector } from 'redux/selectors';
import { checkOnboarding } from '../../../util/getUserData';
import { useHistory } from 'react-router-dom';
import { PathwayListComponent } from '../../../components/Pathway/PathwayList';


export function HomePathwayList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const myPathways = useSelector(pathwaySelector.selectUserPathways);
    const otherPathways = useSelector(pathwaySelector.selectOtherPathways);
    const loading = useSelector(pathwaySelector.selectPathwayLoading);
    const currentModule = useSelector(routerSelector.selectCurrentModule);

    useEffect(() => {
        if (!checkOnboarding('pathway')) {
            history.push('/learning/pathway/onboarding');
        }
        dispatch(pathwayActionCreators.getPathwayData());
    }, []);

    return (
        <PathwayListComponent myPathways={myPathways} loading={loading} currentModule={currentModule} otherPathways={otherPathways} />
    );
}
