import { IRoute } from 'lib/types';
import config from 'config';
import {
    CommunityDashboardComponent
} from './index';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const communityRoutes: IRoute[] = [
    {
        path: '/learning/community',
        roles: ['LEARNER'],
        component: CommunityDashboardComponent,
        title: `${appTitle}: Community`,
        authErrorPaths: authErrorPaths,
    }
];