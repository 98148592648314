import { 
    object as yupObject,
    string as yupString,
    date as yupDate,
    ref as yupRef
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationAddressForm } from 'components';
import { IOgranizationAddress } from '../../../@types/profile-types';
import validationMessage from 'util/validations';

export interface ILicenseFormInputs extends IOgranizationAddress {
    entityName: string;
    name: string;
    number: string;
    issueDate: string;
    expirationDate: string;
    asset_id?: string;
};

export const formDefaults: ILicenseFormInputs = {
    entityName: '',
    name: '',
    number: '',
    issueDate: '',
    expirationDate: '',
    asset_id: '',
    ...OrganizationAddressForm.formDefaults
};


const schema = yupObject({
    entityName: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    name: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    number: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    issueDate: yupDate()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(validationMessage.required),
    expirationDate: yupDate().min(
        yupRef('issueDate'),
        validationMessage.futureDate
    ).transform((curr, orig) => orig === '' ? null : curr).nullable(),
}).concat(OrganizationAddressForm.schema);

export const formSchemaResolver = yupResolver(schema);