import React from 'react';
import { Box, Stack } from '@mui/material';
import { VerifyGridDetailRow } from './verifyGridDetailRow';

import { styles } from '../verifyGrid.helper';

function verifyGridEmail(props: any) {
    const { data } = props;

    return (
        <>
            {!!data ? (
                <Stack direction="row" spacing={2}>
                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="Is Primary"
                            value={data?.primary?.toString() || 'n/a'}
                        />

                        <VerifyGridDetailRow
                            label="Type"
                            value={data?.type?.toString() || 'n/a'}
                        />

                        <VerifyGridDetailRow
                            label="Email Address"
                            value={data?.emailAddress?.toString() || 'n/a'}
                        />

                       
                    </Stack>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
}

export default verifyGridEmail;
