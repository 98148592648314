import React from 'react';
import { ProfileInfoRowComponent } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { ProfileConstants } from 'app-constants';

export const ContactComponent = (props: any) => {
    const {
        phoneLoading,
        data,
        selectedTheme,
        handlePhoneModalOpen,
        canEdit,
        hideVerification = false
    } = props;
    return (
        <ProfileInfoRowComponent
            isLoading={phoneLoading}
            label={`Phone : ${data.type}`}
            selectedTheme={selectedTheme}
            value={data.number}
            onEditClicked={handlePhoneModalOpen}
            assetId={data.asset_id}
            canEdit={
                canEdit ? 'ACTIVE' : 'HIDDEN'
            }
            verificationData={convertProfileObjectToMaryVilleVerification(
                data
            )}
            verificationDetail={{key: ProfileConstants.PHONE_INFO, data: props}}
            hideVerification={hideVerification}
        />
    )
};

export const EmailComponent = (props: any) => {
    const {emailLoading, data, selectedTheme, handleEmailModalOpen, canEdit, hideVerification} = props;
    return (
        <ProfileInfoRowComponent
            isLoading={emailLoading}
            label={`Mail : ${data.type}`}
            selectedTheme={selectedTheme}
            value={data.emailAddress}
            onEditClicked={handleEmailModalOpen}
            assetId={data.asset_id}
            canEdit={
                canEdit ? 'ACTIVE' : 'HIDDEN'
            }
            verificationData={convertProfileObjectToMaryVilleVerification(
                data
            )}
            verificationDetail={{key: ProfileConstants.EMAIL_INFO, data: props}}
            hideVerification={hideVerification}
        />
    )
};
