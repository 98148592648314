import { IRoute } from 'lib/types';
import { Marketplace } from 'pages/marketplace';

import { appTitle, authErrorPaths } from './route-constants';

export const marketPlaceRoutes: IRoute[] = [
    {
        path: '/marketplace',
        roles: ['VIEWER'],
        component: Marketplace,
        title: `${appTitle}: Marketplace`,
        authErrorPaths: authErrorPaths,
    },
];