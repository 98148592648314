import { useState, useContext } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import { useHistory } from 'react-router-dom';
import { INotify } from 'lib/types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { citizenshipSelector, taxSelector, personalInfoSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { areObjectDifferent } from 'util/object.util';
import { formSchemaResolver, IAboutFormInputs } from './aboutForm.helper';
import { citizenshipConfig, taxConfig } from './about.helper';
import { BorderLinearProgress } from 'components';
import { ThemeContext } from 'util/themes';

type AboutPageType = 'PROFILE' | 'ONBOARDING';

interface IAboutForm {
    modalOpen: boolean;
    handleModalClose: Function;
    type: AboutPageType;
}

const styles = {
    PROFILE: {
        controlPadding: { },
        inputWidth: {
            width: '100%'
        },
        labelStyles: { }
    },
    ONBOARDING: {
        controlPadding: {
            style: {
                padding: '10px 12px 8px 12px'
            }
        },
        inputWidth: {
            width: '48%'
        },
        labelStyles: {
            fontSize: '14px'
        }
    }
}

const getAboutForm = (copyPersonalInfo: any, submitFormValues: Function, birthdateValue: string, type: AboutPageType, selectedTheme: any) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IAboutFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: copyPersonalInfo
    });
    const inputStyleProp = {
        ...styles[type].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };
    const labelStyleProp = {
        ...styles[type].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const submitForm = async (formValues: any) => {
        submitFormValues(formValues);
    }

    return (
        <Grid container
            component="form"
            id="personal"
            onSubmit={handleSubmit(submitForm)}>
            <Grid item xs={12}>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item sx={styles[type].inputWidth}>
                        <Controls.Input
                            name="givenName"
                            label="First Name"
                            defaultValue={copyPersonalInfo.givenName}
                            control={control}
                            error={errors?.givenName || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Grid>
                    {
                        type === 'ONBOARDING' ? <></> : (
                            <Grid item sx={styles[type].inputWidth}>
                                <Controls.Input
                                    name="middleName"
                                    label="Middle Name"
                                    defaultValue={copyPersonalInfo.middleName}
                                    control={control}
                                    error={errors?.middleName || ''}
                                    inputStyleProps={inputStyleProp}
                                    sx={{...selectedTheme?.input}}
                                    labelStyles={labelStyleProp}
                                />
                            </Grid>
                        )
                    }
                    <Grid item sx={styles[type].inputWidth}>
                        <Controls.Input
                            name="familyName"
                            label="Last Name"
                            defaultValue={copyPersonalInfo.familyName}
                            control={control}
                            error={errors?.familyName || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    name="preferredName"
                    label="Preferred Name"
                    defaultValue={copyPersonalInfo.preferredName}
                    control={control}
                    error={errors?.preferredName || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{...selectedTheme?.input}}
                    labelStyles={labelStyleProp}
                    required
                />
            </Grid>
            {
                type === 'ONBOARDING' ? <></> : (
                    <>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="suffix"
                                label="Suffix"
                                defaultValue={copyPersonalInfo.suffix}
                                control={control}
                                error={errors?.suffix || ''}
                                inputStyleProps={inputStyleProp}
                                sx={{...selectedTheme?.input}}
                                labelStyles={labelStyleProp}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.TextSelect
                                name="gender"
                                label="Gender"
                                defaultValue={copyPersonalInfo.gender}
                                options={[
                                    { id: 'Man', title: 'Man' },
                                    { id: 'Woman', title: 'Woman' },
                                    { id: 'Non-binary', title: 'Non-binary' }
                                ]}
                                control={control}
                                error={errors?.gender || ''}
                                includeNoneOption={false}
                                inputStyleProps={inputStyleProp}
                                sx={{...selectedTheme?.input}}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Grid>
                    </>
                )
            }
            <Grid item xs={12}>
                <Grid item sx={styles[type].inputWidth}>
                    <Controls.Input
                        name="birthDate"
                        label="Birth Date"
                        type="date"
                        defaultValue={birthdateValue}
                        control={control}
                        error={errors?.birthDate || ''}
                        inputStyleProps={inputStyleProp}
                        sx={{...selectedTheme?.input}}
                        labelStyles={labelStyleProp}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item sx={styles[type].inputWidth}>
                    <Controls.Input
                        name="taxNumber"
                        label="SSN"
                        defaultValue={copyPersonalInfo.taxNumber}
                        control={control}
                        error={errors?.taxNumber || ''}
                        inputStyleProps={inputStyleProp}
                        sx={{...selectedTheme?.input}}
                        labelStyles={labelStyleProp}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item sx={styles[type].inputWidth}>
                    <Controls.Input
                        name="passportNumber"
                        label="Passport Number"
                        defaultValue={copyPersonalInfo.passportNumber}
                        control={control}
                        error={errors?.passportNumber || ''}
                        inputStyleProps={inputStyleProp}
                        sx={{...selectedTheme?.input}}
                        labelStyles={labelStyleProp}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export const AboutForm = (props: IAboutForm) => {
    const { modalOpen, type } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    const citizenshipData = useSelector(citizenshipSelector.selectCitizenshipData);
    const taxData = useSelector(taxSelector.selectTaxData);

    const copyPersonalInfo = JSON.parse(JSON.stringify(personalInfo));
    copyPersonalInfo.middleName = copyPersonalInfo.middleName || '';
    copyPersonalInfo.passportNumber = citizenshipData.length > 0 ? citizenshipData[0].passportNumber : '';
    copyPersonalInfo.taxNumber = taxData.length > 0 ? taxData[0].number : '';

    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const previousClicked = () => {
        history.push('/onboarding/1');
    }

    const submitForm = async (formValues: any) => {
        const formData = JSON.parse(JSON.stringify(formValues));
        try {
            const personalForm: any = {
                asset_id: personalInfo.asset_id ? personalInfo.asset_id : '',
                userId: personalInfo.userId,
                birthdate: stringToUTCDate(formData.birthDate),
                familyName: formData.familyName,
                middleName: formData.middleName,
                formattedName: formData.formattedName,
                gender: formData.gender,
                givenName: formData.givenName,
                preferredName: formData.preferredName,
                suffix: formData.suffix,
            };
            const passportForm = {
                asset_id: citizenshipData.length > 0 ? citizenshipData[0].asset_id : null,
                userId: personalInfo.userId,
                passportNumber: formData.passportNumber,
            };
            const taxForm = {
                asset_id: taxData.length > 0 ? taxData[0].asset_id : null,
                userId: personalInfo.userId,
                number: formData.taxNumber
            };
            const isDateValueUpdated = formData.birthDate !== stringToLocalDate(copyPersonalInfo.birthdate);
            const isPersonalFormUpdated = areObjectDifferent(personalForm, copyPersonalInfo, ['givenName', 'gender', 'middleName', 'familyName', 'preferredName', 'suffix']);
            const requestList = [];
            const dispatchList = [];
            if (taxForm.number?.trim() !== copyPersonalInfo.taxNumber?.trim()) {
                if (taxForm.asset_id) {
                    requestList.push(axios().put(`${taxConfig.endpoint}/${taxForm.asset_id}`, taxForm, withCredentials()));
                } else {
                    requestList.push(axios().post(`${taxConfig.endpoint}`, taxForm, withCredentials()));
                }
                dispatchList.push(profileActionCreators.getPersonalDynamicCardsInfo(taxConfig));
            }
            if (passportForm.passportNumber !== copyPersonalInfo.passportNumber) {
                if (passportForm.asset_id) {
                    requestList.push(axios().put(`${citizenshipConfig.endpoint}/${passportForm.asset_id}`, passportForm, withCredentials()));
                } else {
                    requestList.push(axios().post(`${citizenshipConfig.endpoint}`, passportForm, withCredentials()));
                }
                dispatchList.push(profileActionCreators.getPersonalDynamicCardsInfo(citizenshipConfig));
            }
            if (isDateValueUpdated || isPersonalFormUpdated) {
                if (personalForm.asset_id) {
                    requestList.push(axios().put(`/v2/person/${personalForm.asset_id}`, personalForm, withCredentials()));
                } else {
                    requestList.push(axios().post(`/v2/person`, personalForm, withCredentials()));
                }
                dispatchList.push(profileActionCreators.getProfilePersonalInfo());
            }
            if (dispatchList.length > 0) {
                await Promise.all(requestList);
                for (const toBeDispatched of dispatchList) {
                    dispatch(toBeDispatched);
                }
                setNotify({ message: 'Profile Info updated successfully.', type: 'success', isOpen: true });
            }
        } catch (error) {
            console.log(error);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
        handleModalClose();
    };

    const birthDate = { ...personalInfo.birthdate };
    const birthDateValue = stringToLocalDate(birthDate);

    if (type === 'PROFILE') {
        return (
            <Box>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle data-testid="about_form_title">
                        <Typography component={'span'} sx={{
                            letterSpacing: 0,
                            ...selectedTheme?.typography?.h4,
                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                        }}>
                            Edit Personal Info
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            getAboutForm(copyPersonalInfo, submitForm, birthDateValue, type, selectedTheme)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <Controls.Button form="personal" type="submit" variant='contained' color='primary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Submit
                            </Typography>
                        </Controls.Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    } else {
        return (
            <>
                {
                    getAboutForm(copyPersonalInfo, submitForm, birthDateValue, type, selectedTheme)
                }
                <Grid container alignItems={'center'} justifyContent={'space-between'} marginTop={'26px'} item xs={12}>
                    <Grid item sx={{ height: '12px', borderRadius: '6px', width: 'calc(100%- 30px))'}} xs={12} md={6}>
                        <BorderLinearProgress variant="determinate" value={33} bgcolor={selectedTheme.palette.primary[500]}/>
                    </Grid>
                    <Grid item xs={6} md={6} display='flex' flexDirection='row' justifyContent='end'>
                        <Controls.Button
                            form="personal"
                            variant='outlined' 
                            size='large' 
                            color='primary'
                            sx={{ px: 1.8, py: 1, mr: 2 }}
                            onClick={previousClicked}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Back
                            </Typography>
                        </Controls.Button>
                        <Controls.Button
                            form="personal"
                            type='submit'
                            variant='contained' 
                            size='large' 
                            color='primary'
                            sx={{ px: 1.8, py: 1 }}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Next
                            </Typography>
                        </Controls.Button>
                    </Grid>
                </Grid>
            </>
        )
    }
};