const max = (totalChars: number) => {
    return `Must be atmost of ${totalChars} number`;
};

const required = 'Required';

const regex = `Invalid format`;

const email = 'Must be a valid email';

const dateMax = (date? : string) => {
    return `Date should not be greater than ${date || 'current date'}`; 
};

const dateMin = (date? : string) => {
    return `Date should not be less than ${date || 'current date'}`; 
};

const futureDate = 'Date must be a future value!';

export default {
    max,
    required,
    regex,
    email,
    dateMax,
    dateMin,
    futureDate
}