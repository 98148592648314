import {
    Paper,
    Grid,
    Box,
    Container,
    useMediaQuery,
    useTheme,
    Skeleton,
} from '@mui/material';
import { styles } from './pathwaySummary.helper';
import { Children, Fragment } from 'react';

interface IPathwaySummaryLoading {
    mode: 'SUMMARY' | 'PAGE';
}

export function PathwaySummaryLoading(props: IPathwaySummaryLoading) {
    const { mode } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const totalElements = mode === 'SUMMARY' ? 2 : 1;
    const summaryHeight = mode === 'SUMMARY' ? '250px' : '275px';

    if (isMobile) {
        const mStyles: any = {
            header: {
                height: 50,
            },
            subHeader: {
                height: 50,
            },
            circle: {
                m: 'auto',
            },
            image: {
                m: 'auto',
                height: 42,
                width: 100,
            },
        };
        if (mode === 'PAGE') {
            mStyles.header.width = 120;
            mStyles.header.height = 30;
            mStyles.image.height = '300px';
            mStyles.image.width = '100%';
            mStyles.subHeader.width = '100%';
        }
        const mockArray: any[] = Array.apply('', Array(totalElements));
        return (
            <>
                {Children.toArray(
                    mockArray.map((element: any, index: number) => {
                        return (
                            <Paper sx={{ ...styles.cardBase, height: '380px' }}>
                                <Box sx={{ px: 2 }}>
                                    <Skeleton sx={mStyles.header} />
                                    <Skeleton sx={mStyles.subHeader} />
                                </Box>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Skeleton
                                            height={120}
                                            width={120}
                                            variant={'circular'}
                                            sx={mStyles.circle}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton sx={mStyles.image} />
                                    </Grid>
                                    {mode === 'PAGE' ? (
                                        <Fragment></Fragment>
                                    ) : (
                                        <Grid
                                            item
                                            xs={7}
                                            margin={'auto'}
                                            mb={-1}
                                        >
                                            <Skeleton height={170} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    } else {
        const mockArray: any[] = Array.apply('', Array(totalElements));
        const viewDetailsButtonWidth = mode === 'SUMMARY' ? 100 : 0;
        const backToPathwaysWidthStyles: any = { width: 0 };
        if (mode === 'PAGE') {
            backToPathwaysWidthStyles.width = 150;
            backToPathwaysWidthStyles.mb = 1;
        }
        return (
            <>
                {Children.toArray(
                    mockArray.map((element: any, index: number) => {
                        return (
                            <Paper
                                sx={{
                                    ...styles.cardBase,
                                    height: summaryHeight,
                                }}
                            >
                                <Container maxWidth={false} sx={{ px: mode.toLowerCase() !== 'summary' ? '6.75rem !important' : 5}}>
                                    <Grid
                                        container
                                        sx={{
                                            paddingBottom: 3,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Grid
                                            item
                                            md={2}
                                            sx={{ ...styles.pie }}
                                        >
                                            <Skeleton
                                                height={30}
                                                sx={backToPathwaysWidthStyles}
                                            />
                                            <Skeleton
                                                variant={'circular'}
                                                width={160}
                                                height={160}
                                            ></Skeleton>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            sx={{ paddingLeft: '32px' }}
                                        >
                                            <Skeleton height={40} />
                                            <Skeleton height={40} />
                                            <Skeleton height={30} />
                                            <br />
                                            <Skeleton
                                                height={50}
                                                width={viewDetailsButtonWidth}
                                            />
                                        </Grid>
                                        <br />
                                        <Grid item md={4}>
                                            <Skeleton
                                                variant={'rectangular'}
                                                height={'100%'}
                                                width={'80%'}
                                                sx={{ float: 'right' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    }
}
