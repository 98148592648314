import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Box, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useRef, MutableRefObject , useContext} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector, phoneLookupSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { IProfilePhoneNoObject } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, IPhoneFormInputs, styles } from './phoneForm.helper';
import { phoneNumberConfig } from './contact.helper';
import { areObjectDifferent } from 'util/object.util';
import { ThemeContext } from 'util/themes';

type PhonePageType = 'PROFILE' | 'ONBOARDING';

interface IPhoneForm {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfilePhoneNoObject | null;
    type: PhonePageType;
    autoSubmit?: boolean;
}

export const getPhoneForm = (modalEditData: any, submitFormValues: Function, typeOptions: Partial<HTMLInputElement>[], pageType: PhonePageType, autoSubmit: boolean | undefined, selectedTheme: any) => {
    const [defaultFormData, setDefaultFormData] = useState<IPhoneFormInputs>(formDefaults);
    const inputStyleProp = {
        ...styles[pageType].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']  
    };
    const labelStyleProp = {
        ...styles[pageType].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const submitForm = async (formValues: any) => {
        submitFormValues(formValues, defaultFormData);
    }

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
        setValue
    } = useForm<IPhoneFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit(submitForm)();
        } 
    }, [autoSubmit]);

    useEffect(() => {
        const formData = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.countryCode = modalEditData.countryCode || '';
            formData.extension = modalEditData.extension || '';
            formData.number = modalEditData.number || '';
            formData.type = modalEditData.type || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.primary = modalEditData.primary || false;
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <Grid container display={'flex'} direction={'row'} component="form" id={'phone_form'} onSubmit={handleSubmit(submitForm)}>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Checkbox
                    name="primary"
                    label="Is Primary"
                    value={defaultFormData.primary}
                    checkedColor={selectedTheme?.palette?.primary[500]}
                    uncheckedcolor={selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]}
                    control={control}
                    error={errors?.primary}
                    inputStyleProps={inputStyleProp}
                    inputProps={styles[pageType].controlPadding}
                />
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.TextSelectWithOtherDropdown
                    name="type"
                    label="Phone Type"
                    defaultValue={defaultFormData.type}
                    options={typeOptions}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    inputInlineStyle={{...selectedTheme?.input}}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container display={'flex'} direction={'row'} justifyContent={'space-between'}>
                    <Box sx={styles[pageType].numberWidth}>
                        <Controls.Input
                            name="number"
                            label="Number"
                            defaultValue={defaultFormData.number}
                            control={control}
                            error={errors?.number || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                            required
                        />
                    </Box>
                    <Box sx={styles[pageType].typeWidth} display={pageType === 'ONBOARDING' ? 'block' : 'none'}>
                        <Controls.TextSelect
                            name="type"
                            label="Phone Type"
                            defaultValue={defaultFormData.type}
                            options={typeOptions}
                            control={control}
                            error={errors?.type || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Input
                    name="countryCode"
                    label="Country Code"
                    defaultValue={defaultFormData.countryCode}
                    control={control}
                    error={errors?.countryCode || ''}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    sx={{...selectedTheme?.input}}
                />
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Input
                    name="extension"
                    label="Extension"
                    defaultValue={defaultFormData.extension}
                    control={control}
                    error={errors?.extension || ''}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    sx={{...selectedTheme?.input}}
                />
            </Grid>
        </Grid>
    )

}

export const PhoneForm = (props: IPhoneForm) => {
    const { modalOpen, modalEditData, type, autoSubmit } = props;
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });

    let phoneLookupData = useSelector(phoneLookupSelector.selectPhoneLookupData);

    if (type === 'ONBOARDING') {
       phoneLookupData = phoneLookupData.filter((x) => x.value !== '__other__');
    }

    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const phone of phoneLookupData) {
        typeOptions.push({ id: phone.value, title: phone.display });
    }

    const handleModalClose = () => {
        props.handleModalClose();
    };

    useEffect(() => {
        setDidMount(true);
        return () => {
            setDidMount(false);
        }
    }, []);

    const submitForm = async (formValues: any, defaultFormData: IPhoneFormInputs) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }
        const sendApiRequest = areObjectDifferent(formValues, defaultFormData, ['type', 'emailAddress']);
        if (!sendApiRequest) {
            handleModalClose();
            return;
        }
        delete requestValues.otherType;
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(phoneNumberConfig.endpoint, requestValues, withCredentials());
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(`${phoneNumberConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneNumberConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    if (type === 'PROFILE') {
        return (
            <>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle data-testid="phone_form_title">
                        <Typography component={'span'} sx={{
                            letterSpacing: 0,
                            ...selectedTheme?.typography?.h4,
                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                        }}>
                            {modalEditData ? 'Edit' : 'Add'} Phone Info
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            getPhoneForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <Controls.Button form="phone_form" type="submit" variant='contained' color='primary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                Submit
                            </Typography>
                        </Controls.Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    } else {
        return (
            <>
                {
                    getPhoneForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                }
            </>
        );
    }
};
