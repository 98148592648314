import { phoneNoActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfilePhoneNoObject } from '../../../@types/profile-types';
import mockPhoneData from 'assets/data/profile/phone.json';

const initialData: IProfilePhoneNoObject[] = mockPhoneData.data as IProfilePhoneNoObject[];

export interface IProfilePhoneNoInfo {
    error: boolean;
    data: IProfilePhoneNoObject[];
    loading: boolean;
}

interface IprofilePhoneNoAction {
    type: string;
    payload: IProfilePhoneNoObject[];
}

const initialStateData = {
    error: false,
    data: initialData,
    loading: true,
};

const reducer = (
    state: IProfilePhoneNoInfo = initialStateData,
    action: IprofilePhoneNoAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case phoneNoActionType.PROFILE_PHONE_NUMBERS_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case phoneNoActionType.PROFILE_PHONE_NUMBERS_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case phoneNoActionType.PROFILE_PHONE_NUMBERS_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
