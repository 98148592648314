import React, { useContext, useEffect } from 'react';
import { Typography, Box, SvgIcon, Paper, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { accountCheck, lock } from '../pathwayIcons';
import { BorderLinearProgress } from 'components';
import { ILesson } from '../../../@types/pathway-types';
import { baseTheme } from 'util/themes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { routerSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'util/themes';
import {
    toLocalDateString,
    toHoursAndMinutes,
} from '../../../util/date.utils';

const styles = {
    root: {
        boxSizing: 'border-box',
        border: '0.5px solid #BDC1C5',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 2px 0 #E3E8EE',
        my: baseTheme.spacing(2),
        p: baseTheme.spacing(1),
    },
    title: {
        color: '#202124',
        fontSize: baseTheme.spacing(2),
        fontWeight: '600',
        letterSpacing: 0,
        lineHeight: baseTheme.spacing(3),
    },
    dateComplete: {
        color: '#80848D',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '18px',
    },
    projectCompletionTime: {
        color: '#80848D',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '18px',
    },
};

export function Lesson(props: ILesson) {
    // this assetId would be unique, we can fetch details by assetId
    const { assetId, metaData, instanceData, data, sectionSelected } = props;
    const lessonBoxReference = React.useRef<HTMLInputElement>();
    const history = useHistory();
    const selectedLessonAssetId = useSelector(
        routerSelector.selectCurrentMilestoneLessonId
    );

    const { selectedTheme } = useContext(ThemeContext);

    const getIcon = (lessonStatus: string) => {
        if (lessonStatus === 'completed') {
            return <Icon icon={accountCheck} style={{ color: 'green' }} />;
        } else if (lessonStatus === 'locked') {
            return <Icon icon={lock} />;
        }
    };

    const showActivities = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('course_sis_id', metaData?.course_sis_id);
        currentUrlParams.set('lesson_id', (metaData as any).id);
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
        // setOpen(true);
        if (lessonBoxReference.current) {
            sectionSelected({
                childTopMargin:
                    lessonBoxReference.current.getBoundingClientRect().top,
            });
        }
    };

    useEffect(() => {
        if (
            (metaData as any).id === selectedLessonAssetId &&
            lessonBoxReference.current
        ) {
            sectionSelected({
                childTopMargin:
                    lessonBoxReference.current.getBoundingClientRect().top,
            });
        }
    }, []);

    const getLessonDetail = () => {
        if (
            !!instanceData?.percentComplete &&
            instanceData?.percentComplete < 100
        ) {
            return (
                <Box sx={{ width: '200px' }}>
                    <BorderLinearProgress
                        variant="determinate"
                        value={instanceData?.percentComplete}
                        bgcolor={'linear-gradient(45.3deg, #C456BF 16.4%, #AC8AFC 82.29%)'}
                        sx={{borderRadius: '6px'}}
                    />
                </Box>
            );
        } else if (instanceData?.dateComplete) {
            return (
                <Box sx={styles.dateComplete}>
                    Completed {toLocalDateString(instanceData?.dateComplete)}
                </Box>
            );
        } else {
            return (
                <Box sx={styles.projectCompletionTime}>
                    {`~${toHoursAndMinutes(metaData?.projectCompletionTime)}`}
                </Box>
            );
        }
    };

    const selectedStyleOverride: any = {};
    if ((metaData as any).id === selectedLessonAssetId) {
        selectedStyleOverride.boxShadow = '';
    }

    const cardStyle: any = {};
    if(instanceData?.status === 'completed'){
        cardStyle.card =  selectedTheme?.card?.muted;
    }
    return (
        <>
            <Box ref={lessonBoxReference}>
                <Paper
                    sx={{ ...styles.root, ...selectedStyleOverride, ...selectedTheme.card?.default, ...cardStyle.card }}
                    elevation={9}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ ml: 1.5 }}>
                            <Typography variant="h3" sx={styles.title}>
                                {metaData.title}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1.5 }}>
                            {!!instanceData?.status ? (
                                <SvgIcon fontSize="large" viewBox="36 36">
                                    {getIcon(instanceData?.status)}
                                </SvgIcon>
                            ) : (
                                <Box component={'span'} />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ p: 1 }}>{getLessonDetail()}</Box>
                        {!!instanceData && <IconButton onClick={showActivities}>
                            <ChevronRightIcon />
                        </IconButton>}
                        {/* TODO: bring below back once we have lesson instance data populating
                            {  
                            <IconButton onClick={showActivities}>
                                {!!instanceData ? <ChevronRightIcon /> : <></>}
                            </IconButton>
                        } */}
                    </Box>
                </Paper>
            </Box>
        </>
    );
}