import { TextSelect } from './TextSelect';
import { Input } from '../input/Input';
import { useState, useEffect } from 'react';
import { capitalizeFirstLetter } from 'util/string.util';

interface ISelectDropdownWithOther {
    name: string;
    label: string;
    options: any[];
    control: any;
    errors: any;
    defaultValue: string;
    setValue: Function;
    required?: boolean;
    labelStyles?: any;
    inputStyleProps?: any;
    inputInlineStyle?: any;
}

export function TextSelectWithOtherDropdown (props: ISelectDropdownWithOther) {
    const { name, label, options, control, errors = null, setValue, required, labelStyles, inputStyleProps, inputInlineStyle } = props;
    let { defaultValue } = props;

    const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
    const [newOtherValue, setNewOtherValue] = useState<string>('');
    // we get warning by mat that field options do not match the values, so created this variable
    const [areFieldsInitialized, setAreFieldsInitialized] = useState<boolean>(false);
    
    const onValueChange = (event: any) => {
        const newValue = event.target.value;
        setValue(name, newValue);
        if (newValue === '__other__') {
            setShowOtherInput(true);
        } else {
            setShowOtherInput(false);
        }
    }

    useEffect(() => {
        if (defaultValue === '' || options.map((x: any) => x.id).includes(defaultValue)) {
            setShowOtherInput(false);
            setNewOtherValue('');
        } else {
            setShowOtherInput(true);
            setNewOtherValue(defaultValue);
            setValue(name, '__other__');
        }
        setAreFieldsInitialized(true);
    }, []);

    if (!areFieldsInitialized) {
        return <></>;
    }

    return (
        <>
            <TextSelect
                name={name}
                label={label}
                defaultValue={defaultValue}
                options={options}
                includeNoneOption={false}
                control={control}
                error={errors?.type || ''}
                onChange={onValueChange}
                required={required}
                labelStyles={labelStyles}
                inputStyleProps={inputStyleProps}
                inputInlineStyle={inputInlineStyle}
            ></TextSelect>
            {
                showOtherInput ? (
                    <Input
                        name={`other${capitalizeFirstLetter(name)}`}
                        label={`Other ${label}`}
                        defaultValue={newOtherValue}
                        control={control}
                        error={errors[`other${capitalizeFirstLetter(name)}`] || ''}
                        required={required}
                        labelStyles={labelStyles}
                        inputStyleProps={inputStyleProps}
                        sx={inputInlineStyle}
                    />
                ) : <></>
            }
        </>
    );
}
