import React from 'react';
import { Stack } from '@mui/material';

import { styles } from '../verifyGrid.helper';
import { VerifyGridDetailRow } from './verifyGridDetailRow';

export default function verifyGridMedication(props: any) {
    const { data } = props;

    return (
        <>
            {!!data ? (
                <Stack direction="row" spacing={2}>
                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="Medicine"
                            value={data?.medicine?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label="Prescribed By"
                            value={data?.prescribedBy?.toString() || 'n/a'}
                        />
                    </Stack>
                    <Stack sx={styles.column}></Stack>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
}
