import { combineReducers } from 'redux';

import loggedInUserData from './loggedInUserData';
import getConsentLogs from './consentLog/getConsentLogs';
import revokeConsentLog from './consentLog/revokeConsentLog';
import eVerify from './eVerify';
import pathway from './pathway/pathway';
import profileLookups from './profileLookups';
import profile from './profile';
import notification from './notification.reducer'; 
import { connectRouter } from 'connected-react-router';
import shareMyData from './shareMydata/shareMyData.reducer';
import skills from './skills';
import healthPathway from './HealthPathway/HealthPathway.reducer';
import dataManager from './data-manager';
import healthDashboard from './HealthDashboard';

export const createRootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    loggedInUserData: loggedInUserData,
    getConsentLogs: getConsentLogs,
    revokeConsentLog: revokeConsentLog,
    eVerify: eVerify,
    pathway: pathway,
    profileLookups: profileLookups,
    profile: profile,
    notification: notification,
    shareMyData: shareMyData,
    skills: skills,
    healthPathway: healthPathway,
    dataManager: dataManager,
    healthDashboard: healthDashboard
});