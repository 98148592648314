import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { workSelector } from 'redux/selectors';
import { workActionType } from 'redux/actionsTypes';
import {
    IProfileEmploymentObject,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import { WorkForm } from './workForm';
import { ThemeContext } from 'util/themes';
import { WorkComponent } from './workComponent';

interface IWorkCardCompoment {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const WorkCardComponent = (props: IWorkCardCompoment) => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);

    const employmentLoading = useSelector(workSelector.selectEmploymentLoading);
    const employmentList = useSelector(workSelector.selectEmploymentData);

    const employmentConfig: IReduxDataConfig = {
        loadingAction: workActionType.PROFILE_EMPLOYMENT_LOADING,
        successAction: workActionType.PROFILE_EMPLOYMENT_SUCCESS,
        errorAction: workActionType.PROFILE_EMPLOYMENT_ERROR,
        endpoint: '/v2/employmentposition',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(employmentConfig)
        );
    }, []);

    const { title, canEdit, type } = props;

    const handleModalOpen = (id: string) => {
        const find = employmentList.find(
            (x: IProfileEmploymentObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEmployment = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Box>
            <Grid item xs sx={selectedTheme.card?.gridWrapper}>
                <Paper sx={selectedTheme.card?.default}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addEmployment}
                    />
                    <Divider />
                    <Grid container sx={selectedTheme.card?.cardPadding}>
                        {employmentList.length === 0 && !employmentLoading ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            employmentList.map(
                                (
                                    x: IProfileEmploymentObject,
                                    index: number
                                ) => (
                                    <WorkComponent
                                        cardTitle={title}
                                        data={{ ...x }}
                                        employmentLoading={employmentLoading}
                                        selectedTheme={selectedTheme}
                                        handleModalOpen={handleModalOpen}
                                        canEdit={canEdit}
                                    />
                                )
                            )
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <WorkForm
                    modalOpen={modalOpen}
                    employmentReduxConfig={employmentConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <> </>
            )}
        </Box>
    );
};

WorkCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
