import { combineReducers } from 'redux';

import getPersonalInfo from './getPersonalInfo';
import getAddressInfo from './getAddressInfo';
import getCertificationInfo from './getCertificationInfo';
import getCourseInfo from './getCourseInfo';
import getEducationInfo from './getEducationInfo';
import getEmailInfo from './getEmailInfo';
import getLicensureInfo from './getLicensureInfo';
import getOccupationInfo from './getOccupationInfo';
import getPhoneInfo from './getPhoneInfo';
import getTrainingInfo from './getTrainingInfo';
import getVaccinationInfo from './getVaccinationInfo';

export default combineReducers({
    getPersonalInfo,
    getAddressInfo,
    getCertificationInfo,
    getCourseInfo,
    getEducationInfo,
    getEmailInfo,
    getLicensureInfo,
    getOccupationInfo,
    getPhoneInfo,
    getTrainingInfo,
    getVaccinationInfo,
});
