import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { continuingEducationSelector } from 'redux/selectors';
import { continuingEducationActionType } from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileContinuingEducation,
} from '../../../@types/profile-types';
import { ContinuingEducationForm } from './continuingEducationForm';
import { ThemeContext } from 'util/themes';
import { ContinuingEducationComponent } from './continuingEducationComponent';

interface IContinuingEducationComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
    selectedTheme?: any;
}

export const ContinuingEducationCardComponent = (
    props: IContinuingEducationComponent
) => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const { title, canEdit, type } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);

    const educationConfig: IReduxDataConfig = {
        loadingAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_LOADING,
        successAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_SUCCESS,
        errorAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_ERROR,
        endpoint: '/v2/continuingeducation',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(educationConfig)
        );
    }, []);

    const educationLoading = useSelector(
        continuingEducationSelector.selectContinuingEducationLoading
    );
    const educationList = useSelector(
        continuingEducationSelector.selectContinuingEducationData
    );

    const handleModalOpen = (id: string) => {
        const find = educationList.find(
            (x: IProfileContinuingEducation) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEducation = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Grid item xs sx={selectedTheme.card?.gridWrapper}>
            <Paper sx={selectedTheme.card?.default}>
                <CardTitleComponent
                    title={title}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addEducation}
                />
                <Divider />
                <Grid container sx={selectedTheme.card?.cardPadding}>
                    {educationList.length === 0 && !educationLoading ? (
                        <NoRecordsComponent />
                    ) : (
                        <> </>
                    )}
                    {Children.toArray(
                        educationList.map(
                            (x: IProfileContinuingEducation) => (
                                <ContinuingEducationComponent
                                    cardTitle={title}
                                    data={{ ...x }}
                                    selectedTheme={selectedTheme}
                                    canEdit={canEdit}
                                    handleModalOpen={handleModalOpen}
                                    educationLoading={educationLoading}
                                />
                            )
                        )
                    )}
                </Grid>
            </Paper>
            {modalOpen ? (
                <ContinuingEducationForm
                    modalOpen={modalOpen}
                    continingEducationReduxConfig={educationConfig}
                    modalEditData={modalEditData}
                    handleModalClose={handleModalClose}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};

ContinuingEducationCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
