import { Children, useContext } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { CardTitleComponent } from 'components';
import { HealthDashboardCardInfoRowComponent } from './HealthDashboardCardRow';
import { ThemeContext } from 'util/themes';
import { Link } from 'react-router-dom';

interface IHealthDashboardCard {
    title: string;
    canEdit: boolean;
    itemsToShow?: any;
    type: 'PAGE' | 'PREVIEW';
    isLoading?: boolean;
    initialCardObj?: any;
    handleModalOpen?: (id: string) => void,
    onAddIconClicked?: () => void,
    hasLabelTopPadding?: boolean;
}

const containerStyle = {
    p: '11px 13px 6px 14px',
    "&:hover": {
        background: '#f4f6f7',
        borderRadius: '1px',
        cursor : 'pointer'
    }
};

const linkStyle = {
    width:'100%', 
    textDecoration : 'none'
};

export const HealthDashboardCard = (props: IHealthDashboardCard) => {
    const { 
        title,
        itemsToShow,
        isLoading = false, 
        initialCardObj = {}, 
        canEdit, 
        handleModalOpen,
        onAddIconClicked,
        hasLabelTopPadding = true
     } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const showItem = (item : any) => <HealthDashboardCardInfoRowComponent
        isLoading={isLoading}
        assetId={item.assetId}
        assetType = {item.assetType}
        label={item.label}
        hasLabelTopPadding={hasLabelTopPadding}
        value={item.value}
        Icon={item.Icon}
        onEditClicked={handleModalOpen}
        subHeading={item.subHeading ? item.subHeading : ''}
        selectedTheme={selectedTheme}
        verificationData={item}
        verificationDetail={{...item, cardTitle : title}}
        containerStyle={containerStyle}
        secondRow={item.secondRow}
        canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
    />;

    return (
        <Grid item xs sx={selectedTheme?.card?.gridWrapper}>
            <Paper sx={selectedTheme?.card?.default}>
                <CardTitleComponent title={title} canAdd={canEdit ? 'ACTIVE' : 'HIDDEN'} onAddIconClicked={onAddIconClicked} />
                <Divider />
                <Grid container sx={{
                    ...selectedTheme?.card?.cardPadding,
                    p: '11.5px 17px 10px 13px'    
                }}>
                    {isLoading  ? showItem(initialCardObj) :
                        Children.toArray(
                            itemsToShow.map((item: any) => 
                                item.link ? <Link to={item.link} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                    {showItem(item)}
                                </Link> :
                                showItem(item)
                            )
                        )
                    }
                </Grid>
            </Paper>
        </Grid>
    );
};

HealthDashboardCard.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: [],
    type: 'PAGE',
};
