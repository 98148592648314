import { vaccinationActionType } from 'redux/actionsTypes';
import produce from 'immer';
import {
    IProfileVaccinationObject,
    initialProfileVaccination as initialData,
} from '../../../pages/health-profile/assetCards/dynamicCards/Vaccinations/Vaccinations';

export interface IProfileVaccinationInfo {
    error: boolean;
    data: IProfileVaccinationObject[];
    loading: boolean;
}

interface IprofileVaccinationAction {
    type: string;
    payload: IProfileVaccinationObject[];
}

const initialStateData = {
    error: false,
    data: initialData,
    loading: false,
};

const reducer = (
    state: IProfileVaccinationInfo = initialStateData,
    action: IprofileVaccinationAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case vaccinationActionType.PROFILE_VACCINATIONS_ERROR:
                draft.error = true;
                draft.data = initialData;
                draft.loading = false;
                return draft;
            case vaccinationActionType.PROFILE_VACCINATIONS_LOADING:
                draft.error = false;
                draft.data = initialData;
                draft.loading = true;
                return draft;
            case vaccinationActionType.PROFILE_VACCINATIONS_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
