import { IRoute } from 'lib/types';
import { HealthDashboardComponent } from 'pages/health-dashboard/HealthDashboard';
import {
    HealthPathwayDetail,
    HealthPathwayListComponent,
    HealthPathwayOnboardingComponent,
} from 'pages/health-pathway';

import { appTitle, authErrorPaths } from './route-constants';

const healthDashboardRoutes: IRoute[] = [
    {
        path: '/health/dashboard',
        roles: ['LEARNER'],
        component: HealthDashboardComponent,
        title: `${appTitle}: Health Pathway`,
        authErrorPaths: authErrorPaths,
    },
];

const healthPathwayRoutes: IRoute[] = [
    {
        path: '/health/pathway/onboarding',
        roles: ['LEARNER'],
        component: HealthPathwayOnboardingComponent,
        title: `${appTitle}: Health Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/health/pathway/:pathwayId',
        roles: ['LEARNER'],
        component: HealthPathwayDetail,
        title: `${appTitle}: Health Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/health/pathway',
        roles: ['LEARNER'],
        component: HealthPathwayListComponent,
        title: `${appTitle}: Health Pathway`,
        authErrorPaths: authErrorPaths,
    },
];

export const healthRoutes: IRoute[] = [
    ...healthDashboardRoutes,
    ...healthPathwayRoutes,
];
