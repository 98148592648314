import React from 'react';
import { Stack } from '@mui/material';

import { styles } from '../verifyGrid.helper';
import { VerifyGridDetailRow } from './verifyGridDetailRow';

export default function VerifyGridAddress(props: any) {
    const { data } = props;

    return (
        <>
            {!!data ? (
                <Stack direction="row" spacing={2}>
                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label=" Is Primary"
                            value={data?.primary?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label="Type"
                            value={data?.type?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label=" Street Address"
                            value={data?.streetAddress?.toString() || 'n/a'}
                        />
                    </Stack>

                    <Stack sx={styles.column}>
                        <VerifyGridDetailRow
                            label="City"
                            value={data?.city?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label="State/Province"
                            value={data?.state?.toString() || 'n/a'}
                        />
                        <VerifyGridDetailRow
                            label="Postal Code"
                            value={data?.postalCode?.toString() || 'n/a'}
                        />
                    </Stack>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
}
