import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Grid, useTheme, Theme}  from '@mui/material'

const styles = {
    barStyle: (theme: Theme, bgcolor?: string, isDiagonalLines?: boolean) => ({
        height: 12,
        borderRadius: 0,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: isDiagonalLines ? 'transparent' : theme.palette.grey[200]
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 0,
            background: bgcolor || `linear-gradient(90deg, #54BFBF  60%, #436DBA 90%)`,
        },
    }),
    barContainerStyle: (theme: any) => ({
        background: `url("/images/assets/progress-bar-diagnols.svg"), ${theme.palette.grey[200]}`,
        width: 'inherit'
    })
};

export const BorderLinearProgress = ({bgcolor = '', sx = {}, isDiagonalLines = true, ...rest}) => {
    const theme = useTheme();
    return (
        <Grid sx={styles.barContainerStyle(theme)}>
            <LinearProgress sx={{...styles.barStyle(theme, bgcolor, isDiagonalLines), ...sx}} {...rest}/>
        </Grid>
    )
};