import { Controller } from 'react-hook-form';
import {
    RadioGroup as MuiRadioGroup,
    Radio,
    FormControlLabel,
    FormHelperText,
    FormControl,
    Box
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { Children } from 'react';

// TODO: still need to support initialValue
// I think that this is just missing an onChange method

interface IInputProps {
    // All other props
    [other: string]: any;
}

const RadioGroupWrapper: SxProps<Theme> = {
    m: 2,
};

export function RadioGroup(props: IInputProps) {
    const {
        name,
        label,
        control,
        value,
        options,
        defaultValue,
        error,
        color = 'primary',
        ...other
    } = props;

    return (
        <Box sx={RadioGroupWrapper}>
            <label htmlFor={name}>{label}</label>
            <br />
            <FormControl
                component="fieldset"
                // className={classes.formControl}
                error={!!error}
            >
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <MuiRadioGroup
                            row
                            {...field}
                            {...other}
                            name={name}
                            color="primary"
                        >
                            {Children.toArray(
                                options.map((item: HTMLInputElement) => (
                                    <FormControlLabel
                                        value={item.id}
                                        control={
                                            <Radio {...other} color={color} />
                                        }
                                        label={item.title}
                                    />
                                ))
                            )}
                        </MuiRadioGroup>
                    )}
                />
                {error && (
                    <FormHelperText color="">{error.message}</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
