import React from 'react';
import {
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { useState, Fragment } from 'react';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';
import { IDataIntegration } from '../../../@types/data-manager-types';
import { toLocalDateString } from 'util/date.utils';
import { ConfirmModalComponent } from '../components';
import { Button } from 'lib/controls';

const styles = {
    sectionTitle: {
        pt : 4,
        px : 3,
        pb : 1.5,
    },
    sectionSubHeader: {
        px: 3,
    },
    itemkey: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#202124',
    },
    itemValue: {
        fontSize: '14px',
        color: '#202124',
    },
    deleteButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    addButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    removeAccessText: {
        mb: 3,
    },
};

interface IThirdPartyIntegrationsComponent {
    datalist: IDataIntegration[];
    selectedTheme?:any;
}

export function ThirdPartyIntegrationsComponent(
    props: IThirdPartyIntegrationsComponent
) {
    const { selectedTheme, datalist } = props;
    const [expandedIndex, setExpandedIndex] = useState<number>(-1);
    const [resetModalOpen, setResetModalOpen] = useState<boolean>(false);

    const updateSelectedIndex = (newIndex: number) => {
        if (expandedIndex === newIndex) {
            setExpandedIndex(-1);
        } else {
            setExpandedIndex(newIndex);
        }
    };

    const modalEventHandler = (event: any) => {
        console.log(event);
        setResetModalOpen(false);
        if (event === 'CLOSE') {
            return;
        }
    };

    const confirmDialogButtonList = [
        {
            style: {
                borderRadius: '2px',
                py: '.25rem'
            },
            clickEvent: 'REMOVE',
            text: 'Yes, remove access to my data',
            color: 'error',
            variant: 'contained'
        },
        { 
            style: {
                borderRadius: '2px',
                py: '.25rem'
            }, 
            clickEvent: 'CANCEL', 
            text: 'Cancel',
            color: 'primary',
            variant: 'outlined'
        },
    ];

    return (
        <Paper sx={{...selectedTheme.card?.default, maxWidth: { xs: '100%', md: '98%' }, mb: 2 }}>
            <Typography variant="h2" sx={{...styles.sectionTitle, color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]}}>
                Third-Party Integrations
            </Typography>
            <Typography sx={{
                ...styles.sectionSubHeader,
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.regular,
                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
            }}>
                Third-party API integrations enable external access to your
                activities in LifeGraph.
            </Typography>
            <br />
            <Divider />
            {React.Children.toArray(
                datalist.map((x: IDataIntegration, index: number) => {
                    const imageLink = `/images/data-manager/${x.logo}`;
                    return (
                        <>
                            <ListItemButton
                                onClick={() => updateSelectedIndex(index)}
                                sx={{ px: 3, border: '1px solid #DADCE0' }}
                            >
                                <ListItemIcon>
                                    <img
                                        src={imageLink}
                                        height={42}
                                        width={'auto'}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={x.title}
                                    primaryTypographyProps={{
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        ...selectedTheme?.typography?.body?.semibold,
                                        color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800], 
                                        pl: 2,
                                    }}
                                />
                                {expandedIndex === index ? (
                                    <ArrowUpIcon sx={{color: selectedTheme?.palette?.primary[700]}}/>
                                ) : (
                                    <ArrowDownIcon sx={{color: selectedTheme?.palette?.primary[700]}}/>
                                )}
                            </ListItemButton>
                            {expandedIndex !== index ? (
                                <></>
                            ) : (
                                <Box
                                    sx={{
                                        backgroundColor: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[50],
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            m: 2,
                                            width: '-webkit-fill-available',
                                        }}
                                    >
                                        <Paper
                                            sx={{ p: 3, borderRadius: '12px' }}
                                        >
                                            <Grid
                                                container
                                                sx={{ display: 'flex', gap: 2 }}
                                            >
                                                {React.Children.toArray(
                                                    x.additionalInfo.map(
                                                        (info) => (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                    sx={{
                                                                        ...selectedTheme?.typography?.body?.default,
                                                                        ...selectedTheme?.typography?.body?.body1,
                                                                        ...selectedTheme?.typography?.body?.bold,
                                                                        color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                                    }}
                                                                >
                                                                    {info.key}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                    sx={{
                                                                        ...selectedTheme?.typography?.body?.default,
                                                                        ...selectedTheme?.typography?.body?.body1,
                                                                        ...selectedTheme?.typography?.body?.regular,
                                                                        color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                                    }}
                                                                >
                                                                    {info.value}
                                                                </Grid>
                                                            </>
                                                        )
                                                    )
                                                )}
                                                <>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            ...selectedTheme?.typography?.body?.default,
                                                            ...selectedTheme?.typography?.body?.body1,
                                                            ...selectedTheme?.typography?.body?.bold,
                                                            color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                        }}
                                                    >
                                                        Access Given On
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        key={`expanded_row_access_value`}
                                                        sx={{
                                                            ...selectedTheme?.typography?.body?.default,
                                                            ...selectedTheme?.typography?.body?.body1,
                                                            ...selectedTheme?.typography?.body?.regular,
                                                            color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                        }}
                                                    >
                                                        {x.accessDate
                                                            ? toLocalDateString(
                                                                  x.accessDate
                                                              )
                                                            : ''}
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                    {x.accessDate ? (
                                        <Fragment>
                                            <Button
                                                variant={'outlined'}
                                                size={'small'}
                                                sx={{borderRadius : '4px' }}
                                                color='error'
                                                disabled={false}
                                                onClick={() =>
                                                    setResetModalOpen(true)
                                                }
                                            >
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.small
                                                }}>
                                                    Remove Access
                                                </Typography>
                                                
                                            </Button>
                                            <br />
                                            <Typography
                                                sx={{
                                                    ...styles.removeAccessText,
                                                    ...selectedTheme?.typography?.body?.default,
                                                    ...selectedTheme?.typography?.body?.body2,
                                                    ...selectedTheme?.typography?.body?.regular,
                                                    color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                }}
                                            >
                                                Removing access will terminate
                                                your ability to use this
                                                application with LifeGraph.
                                            </Typography>
                                        </Fragment>
                                    ) : (
                                        <>
                                            <Button
                                                disabled={false}
                                                variant={'outlined'}
                                                size={'small'}
                                                sx={{borderRadius : '4px'}}
                                                color='primary'
                                            >
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.small
                                                }}>
                                                    Grant Access
                                                </Typography>
                                            </Button>
                                            <br />
                                            <Typography
                                                sx={{
                                                    ...styles.removeAccessText,
                                                    ...selectedTheme?.typography?.body?.default,
                                                    ...selectedTheme?.typography?.body?.body2,
                                                    ...selectedTheme?.typography?.body?.regular,
                                                    color:selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800],
                                                }}
                                            >
                                                Granting access will enable you
                                                to use this application with
                                                LifeGraph.
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </>
                    );
                })
            )}
            {
                <ConfirmModalComponent
                    isOpen={resetModalOpen}
                    responseEvent={modalEventHandler}
                    headerText={'Are you sure?'}
                    subHeaderText={
                        'Removing access will terminate your ability to use this application with LifeGraph.'
                    }
                    buttonList={confirmDialogButtonList}
                />
            }
        </Paper>
    );
}
