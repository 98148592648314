import { Typography, Box, Grid, Container, useMediaQuery, useTheme, Skeleton} from '@mui/material';
import { ISkillDetail } from '../../../@types/skills.types';
import { baseTheme, ThemeContext } from 'util/themes';
import { ArrowBack as ArrowBackIcon, Tab as TabIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { SkillCircularHeaderComponent } from './SkillCircularHeader';
import { useContext } from 'react';
import { Button } from 'lib/controls';

export interface ISkillsDetailHeaderComponent {
    loading: boolean;
    currentSkill: ISkillDetail;
}

const styles = {
    mainBox: {
        boxSizing: 'border-box',
        background: 'linear-gradient(85.68deg, #FBFED2 -11.56%, #FFC4DF 48.86%, #B1ADFF 100.97%)',
        boxShadow: '0 1px 2px 0 #E3E8EE',
    },
    goBack: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        mt: 2.25,
        pl: 0
    },
    name: {
        name: '#202124',
        fontSize: '32px',
        pt: 2.25,
        lineHeight: '40px'
    },
    id: {
        color: '#80848D',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.5px',
        pt: 0.5
    },
    description: {
        color: '#202124',
        fontSize: '16px',
        fontWeight: 300,
        pt: 1,
        lineHeight: '24px',
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    },
    readMore: {
        color: baseTheme.palette.secondary.main,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        mt: .75,
        textTransform: 'none',
        pl: 0
    }
}

export function SkillsDetailHeaderComponent (props: ISkillsDetailHeaderComponent) {
    const { currentSkill, loading } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const height = isMobile ? '228px' : '280px';

    const goBackClicked = () => {
        history.push('/learning/skills/vc');
    }

    const readMoreClicked = () => {
        console.log('user clicked on read more');
    }

    return (
        <Box sx={{ ...styles.mainBox, height: height }}>
            <Container maxWidth={false} disableGutters={false} sx={{px: '6.75rem !important'}}>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12} md={9}>
                        <Button 
                            variant={'text'} 
                            color='primary'
                            size='medium'
                            sx={{
                                ...styles.goBack,
                                color: selectedTheme?.palette?.purple?.light
                            }}
                            onClick={goBackClicked}
                            startIcon={<ArrowBackIcon sx={{color: selectedTheme?.palette?.blue?.light}}/>}>
                                <Typography sx={{
                                    ...selectedTheme?.typography?.Components?.button?.default,
                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                    lineHeight: '20px',
                                    textTransform: 'none'
                                }}>
                                    Back
                                </Typography>
                        </Button>
                        <Typography sx={styles.name}>
                            { loading ? <Skeleton width={'20%'} /> : currentSkill.name }
                        </Typography>
                        <Typography sx={styles.id}>
                            { loading ? <Skeleton width={'30%'} /> : currentSkill.id }
                        </Typography>
                        <Typography sx={{...styles.description, fontStyle: 'italic'}}>
                            { loading ? <Skeleton width={'80%'} /> :  currentSkill.description }
                        </Typography>
                        {
                            loading ? <Skeleton width={'10%'} /> : (
                                <Button 
                                    variant={'text'}
                                    color='primary'
                                    size='medium'
                                    sx={{
                                        ...styles.readMore,
                                        color: selectedTheme?.palette?.purple?.light
                                    }}
                                    onClick={readMoreClicked}
                                    endIcon={<TabIcon />}>
                                        <Typography sx={{
                                            ...selectedTheme?.typography?.Components?.button?.default,
                                            ...selectedTheme?.typography?.Components?.button?.medium,
                                            textTransform: 'none'
                                        }}>
                                            Read More on Wikipedia
                                        </Typography>
                                </Button>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={3} mt={5}>    
                        <SkillCircularHeaderComponent skill={currentSkill} loading={loading} diameter={180} onClick={() => {}}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )    
}