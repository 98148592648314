import axios, { withCredentials } from 'util/axiosInstance';

const onboardingDefault = {
    profile: false,
    pathway: false,
    dataManager: false,
};

const localstorageDataIndex = ['firstName', 'lastName', 'occupationTitle'];

const getUserDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('userData') || '{}');
};

const getUserToken = () => {
    return localStorage.getItem('token');
};

const getUserData = (param?: string) => {
    const data = getUserDataFromLocalStorage();
    let userData: any = {};
    // a extractor for localstorage items listed in the localstorageDataIndex array
    userData = localstorageDataIndex.reduce((acc: any, e) => {
        acc[e] = localStorage.getItem(e);
        return acc;
    }, {});
    try {
        // creating new object from localstorage index data and parsed userdata objects
        userData = Object.assign({}, userData, data);
    } catch (error) {
        userData = {};
    }
    return param ? userData[param] : userData;
};

const getUserDefaultRoute = () => {
    const userData = getUserDataFromLocalStorage();
    const profileOnboarding =
        userData?.userPreferences?.learniq?.onboarding?.profile;

    if (profileOnboarding) {
        return '/dashboard';
    } else {
        return '/onboarding/1';
    }
};

const getUserRouteStatus = () => {
    const userDefaultRoute = getUserDefaultRoute();
    const userToken = getUserToken();
    if (!userToken) {
        return 'UNAUTHENTICATED';
    }
    if (userDefaultRoute === '/onboarding/1') {
        return 'ONBOARDING';
    } else {
        return 'AUTHENTICATED';
    }
};

const updateUserDataLoggedInStatus = (value: boolean) => {
    const data = getUserDataFromLocalStorage();
    data.hasLoggedIn = true;
    localStorage.setItem('userData', JSON.stringify(data));
};

const checkOnboarding = (onboardingScreen: string) => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const onboardingData =
        userData?.userPreferences?.learniq.onboarding || onboardingDefault;

    return onboardingData[onboardingScreen];
};

const updateOnboarding = async (onboardingScreen: string) => {
    const userData = JSON.parse(localStorage.getItem('userData') || '');
    const onboardingData =
        userData?.userPreferences?.learniq.onboarding || onboardingDefault;
    onboardingData[onboardingScreen] = true;

    // TODO: wish there was a better way to do this
    if (!userData.userPreferences) {
        userData.userPreferences = {};
    }
    // TODO: wish there was a better way to do this
    if (!userData.userPreferences?.learniq) {
        userData.userPreferences.learniq = {};
    }

    userData.userPreferences.learniq.onboarding = onboardingData;

    await axios()
        .put(
            '/user/preferences',
            userData.userPreferences.learniq,
            withCredentials()
        )
        .then((resp) => {
            localStorage.setItem('userData', JSON.stringify(userData));
            return resp.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export {
    checkOnboarding,
    updateOnboarding,
    getUserData,
    getUserDefaultRoute,
    getUserRouteStatus,
    updateUserDataLoggedInStatus,
};
