import { PathwaySummaryLoading } from '.';
import {
    Grid,
    Box,
    Container,
    Skeleton,
    Paper,
    Typography,
} from '@mui/material';
import { Children } from 'react';

export function PathwaySkeletonComponent() {
    const mockArray: any[] = Array.apply('', Array(3));
    return (
        <Box>
            <PathwaySummaryLoading mode={'PAGE'} />
            <Container maxWidth={false} sx={{ px: '6.75rem !important' }}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={12} md={4} px={1}>
                        {Children.toArray(
                            mockArray.map((element: string, index: number) => {
                                return (
                                    <Paper
                                        sx={{
                                            p: 2,
                                            borderRadius: '12px',
                                            mb: 2,
                                        }}
                                    >
                                        <Skeleton
                                            key={`pathway_sk1_${index}`}
                                            width={'60%'}
                                        ></Skeleton>
                                        <Skeleton
                                            key={`pathway_sk2_${index}`}
                                            width={'60%'}
                                        ></Skeleton>
                                        <br />
                                        <Skeleton
                                            key={`pathway_sk3_${index}`}
                                            width={'80%'}
                                        ></Skeleton>
                                    </Paper>
                                );
                            })
                        )}
                    </Grid>
                    <Grid item xs={0} md={8}>
                        <Box sx={{ ml: 3 }}>
                            <Box sx={{ display: 'flex', gap: '16px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Started
                                </Typography>
                                <Skeleton width={90} />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                                Goal
                            </Typography>
                            <Skeleton width={'90%'} />
                            <Skeleton width={'90%'} />
                            <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                                Description
                            </Typography>
                            <Skeleton width={'90%'} />
                            <Skeleton width={'90%'} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
