import { useState, useContext, useEffect } from 'react';
import { OnboardingLayoutComponent } from 'pages/onboarding';
import { Typography, Box, Grid } from '@mui/material';
import { LoginForm } from 'lib/components/login-form/LoginForm';
import Logo from 'assets/images/logos/Lifegraph Logo.svg';
import { Popup } from 'lib/components';
import ResetPasswordRequest from 'pages/login/ResetPasswordRequest';
import { LoginHelper } from './LoginHelper';
import { getUserDefaultRoute } from 'util/getUserData';
import { ThemeContext } from 'util/themes';
import { Button } from '../../lib/controls';

const styles = {
    gradientMaxHeight: {
        xs: '30vh',
        md: '100%',
    },
    logoHolder: {
        m: 'auto',
        height: '56px',
        mt: '20%',
        width: {
            xs: '95%',
            md: '48%',
        },
    },
    formFooterStyle: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.default,
        ...selectedTheme?.typography?.body?.body2,
        ...selectedTheme?.typography?.body?.regular,
        color:
            selectedTheme?.palette?.grayScale &&
            selectedTheme?.palette?.grayScale[800],
    }),
    forgotPasswordUnderlineStyle: (selectedTheme: any) => ({
        position: 'absolute',
        width: '8.5rem',
        height: '3px',
        transform: 'translate(-8.625rem, 1.0625rem)',
        background: selectedTheme?.palette?.primary[200],
        zIndex: -1,
    }),
};

export const Login = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    }, []);

    const successHandler = () => {
        const route = getUserDefaultRoute();
        window.location.href = route;
    };

    const failureHandler = () => {
        console.log('failed');
    };

    const openForgotPasswordForm = () => {
        setOpenPopup(true);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} maxHeight={styles.gradientMaxHeight}>
                    <OnboardingLayoutComponent imageFileName={'Login-img'} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.logoHolder}>
                        <img
                            src={Logo}
                            alt="Lifegraph"
                            className="center-image"
                            style={{ height: '100%' }}
                        />
                        <LoginForm
                            onSuccess={successHandler}
                            loginButtonText={LoginHelper.formSubmitButtonLabel}
                            usernameLabel={LoginHelper.formUsernameLabel}
                            onFail={failureHandler}
                        />
                        <br></br>
                        <Button
                            variant="text"
                            fullWidth
                            size="small"
                            onClick={openForgotPasswordForm}
                            color={'primary'}
                            sx={{ textTransform: 'none', zIndex: '0' }}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body1,
                                    ...selectedTheme?.typography?.body?.medium,
                                    color: selectedTheme?.palette?.primary[700],
                                }}
                            >
                                {LoginHelper.formForgotPasswordLabel}
                                <Typography
                                    component={'span'}
                                    sx={styles.forgotPasswordUnderlineStyle(
                                        selectedTheme
                                    )}
                                />
                            </Typography>
                        </Button>
                        <br></br>
                        <br></br>
                        <Typography sx={styles.formFooterStyle(selectedTheme)}>
                            {LoginHelper.formFooter1}
                        </Typography>
                        <br></br>
                        <Typography sx={styles.formFooterStyle(selectedTheme)}>
                            {LoginHelper.formFooter2}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title="Reset Password"
            >
                <ResetPasswordRequest setOpenPopup={setOpenPopup} />
            </Popup>
        </>
    );
};
