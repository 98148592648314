import { healthDashboardVaccinationActionTypes } from 'redux/actionsTypes';
import produce from 'immer';

interface IVaccinationObj {
    administerDate: any;
    administeredBy: string;
    assetType: string;
    asset_id: string;
    medicine: any;
    vaccine: string;
    userId: string;
    verificationAssetId: string;
    verificationCompany: string;
    verificationHash: string;
    verificationSignature: string;
    verificationStatus: string;
    verificationTimestamp: any;
    verificationType: string;
    verifier: string;
}

export interface IVaccinationInfo {
    error: boolean;
    data: IVaccinationObj[];
    loading: boolean;
}

interface IVaccinationAction {
    type: string;
    payload: {
        data: IVaccinationObj[];
    }
}

const initialStateData = {
    error: false,
    data: [],
    loading: false,
};

const reducer = (
    state: IVaccinationInfo = initialStateData,
    action: IVaccinationAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_LOADING:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case healthDashboardVaccinationActionTypes.GET_HEALTH_DASHBOARD_VACCINATION_SUCCESS:
                draft.error = false;
                draft.data = action.payload.data;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;