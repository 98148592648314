import { OnboardingComponent, IOnboardingComponent } from 'components/Onboarding/Onboarding';
import { Box, Grid } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Button } from 'lib/controls';

const styles = {
    buttonHolder: {
        mt: { xs: -3, md: 2 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        mb: 3,
        alignItems: { xs: 'start', md: 'center' },
        px: '6.25rem'
    },
    button: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        border: '1px solid',
        maxHeight: '32px',
        borderRadius: '4px'
    }
}

export function CommunityDashboardComponent () {
    const data: IOnboardingComponent = {
        imageName: 'community_dashboard.svg',
        redirectLink: '',
        header: `LearnIQ Community of Professionals`,
        headerStyle: {
            lineHeight: '3.125rem',
            pt: '8rem'
        },
        subHeader: `Connect with other lifelong learners for support and encouragement.`,
        subHeaderStyle: {
            lineHeight: '2.25rem',
            pb: '3.5rem'
        },
        bottomTextList: [
            `Professional networks are best built through communities of lifelong learners. The LearnIQ community connects you with support, mentorship, encouragement and inspiration so that you can stay on track to meet your career goals.`,
        ],
        bottomTextStyle: { lineHeight: '28px', fontSize: '18px' },
        buttonText: '',
        onboardingScreen: '',
        background: 'linear-gradient(87.05deg, #E3FCFF 9.21%, #B3D2FF 54.39%, #DEC6FF 102.37%)',
        backgroundStyle: { width: '30rem', height: '30rem', top: '5rem' },
        headerContainerStyle: { px: '4.75rem' },
        bottomBoxContainer: { px: '4.75rem', mt: '3.5rem' }
    };

    const joinOurCommunityClicked = () => {
        console.log('clicked on join our community');
    }

    const signInSlackClicked = () => {
        console.log('Sign in slack clicked');
    }
    const imageLink = '/images/community/slack_logo.png';
    return (
        <>
            <OnboardingComponent {...data} />
            <Grid pb={23}>
                <Box sx={styles.buttonHolder}>
                    <Box sx={{ mb: { xs : 3, md: 0 }, display: 'flex' }}>
                        <img
                            src={imageLink}
                            height={40}
                            width={157}
                        />
                    </Box>
                    <Button
                        variant='outlined'
                        size='medium'
                        color='primary'
                        sx={styles.button}
                        onClick={joinOurCommunityClicked}
                        endIcon={<OpenInNewIcon />}>
                        Join Our Community
                    </Button>
                    <Button
                        variant={'outlined'}
                        size='medium'
                        color='primary'
                        sx={styles.button}
                        onClick={signInSlackClicked}
                        endIcon={<OpenInNewIcon />}>
                        Sign In
                    </Button>
                </Box>
            </Grid>
        </>
    )
}