import { OnboardingLayoutComponent } from 'pages/onboarding';
import { Typography, Box, Grid, useMediaQuery } from '@mui/material';
import { OnboardingScreen3Helper, styles, findThePrimaryOrDefaultObject } from './onboardingScreen3.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { phoneNoSelector, emailAddressSelector, addressSelector } from 'redux/selectors';
import { useTheme } from '@mui/material/styles';
import { phoneNumberConfig, phoneLookupConfig, emailAddressConfig, emailLookupConfig } from 'pages/profile/contact/contact.helper';
import { addressConfig, addressLookupConfig } from 'pages/profile/address/address.helper';
import { useEffect, useState, useContext } from 'react';
import { ILookup } from '../../../@types/profile-types';
import { PhoneForm } from 'pages/profile/contact/phoneForm';
import { EmailForm } from 'pages/profile/contact/emailForm';
import { AddressForm } from 'pages/profile/address/addressForm';
import { BorderLinearProgress } from 'components';
import mockPhoneTypes from 'assets/data/profile/phone-types.json';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import mockAddressTypes from 'assets/data/profile/address-types.json';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';

export const OnboardingScreen3Component = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const {selectedTheme, setCurrentTheme} = useContext(ThemeContext);

    const [autoSubmit, setAutoSubmit] = useState<boolean>(false);

    const nextClicked = () => {
        setAutoSubmit(true);
        // Note: It will set autosubmit to false in 1 second if any validation fails for the first time.
        setTimeout(() => {
            setAutoSubmit(false);
        }, 1000);
    }

    const previousClicked = () => {
        history.push('/onboarding/2');
    }

    const doneSubmitEvents = [];

    const submitSuccess = (event: 'PHONE' | 'EMAIL' | 'ADDRESS') => {
        doneSubmitEvents.push(event);
        if (doneSubmitEvents.length === 3) {
            setAutoSubmit(false);
            history.push('/onboarding/4');   
        }
    }

    const typographyList: string[] = [];

    const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
    if (isDesktopView) {
        typographyList.push(OnboardingScreen3Helper.header1);
        typographyList.push(OnboardingScreen3Helper.header2);
        typographyList.push(OnboardingScreen3Helper.header3);
    } else {
        typographyList.push(OnboardingScreen3Helper.mobileHeader);
    }

    useEffect(() => {
        // we need this in case of user refreshes the page on screen 3, we might need to move this to upper level
        dispatch(profileActionCreators.getProfilePersonalInfo());
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneNumberConfig));
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(emailAddressConfig));
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressConfig));

        // TODO remove next block when api for external data is ready
        const mockPhone = mockPhoneTypes.data as ILookup[];
        const mockEmail = mockEmailTypes.data as ILookup[];
        const mockData = mockAddressTypes.data as ILookup[];
        dispatch({ type: phoneLookupConfig.successAction, payload: mockPhone });
        dispatch({ type: emailLookupConfig.successAction, payload: mockEmail });
        dispatch({ type: addressLookupConfig.successAction, payload: mockData });
 
        // TODO uncomment next block when api for external data is ready
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneLookupConfig));
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(emailLookupConfig));
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressLookupConfig));

        setCurrentTheme('lifeGraph');
    }, []);

    const phoneLoading = useSelector(phoneNoSelector.selectPhoneNoLoading);
    const phoneData = useSelector(phoneNoSelector.selectPhoneNoDataRaw);

    const emailLoading = useSelector(emailAddressSelector.selectEmailAddressLoading);
    const emailData = useSelector(emailAddressSelector.selectEmailAddressDataRaw);

    const addressData = useSelector(addressSelector.selectAddressDataRaw);
    const addressLoading = useSelector(addressSelector.selectAddressLoading);

    const combinedLoading = phoneLoading || emailLoading || addressLoading;

    const selectedEmailData = emailData.find((x: any) => x.type.toLowerCase() === 'personal') || undefined;
    const selectedPhoneData = findThePrimaryOrDefaultObject(phoneData, 'work');
    const selectedAddressData = findThePrimaryOrDefaultObject(addressData, 'work');

    return (
        <Grid container>
            <Grid item xs={12} md={6} maxHeight={styles.gradientMaxHeight}>
                <OnboardingLayoutComponent imageFileName={'onboarding-no-top-hexes'} typographyList={typographyList} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={styles.sectionHolder}>
                    <Typography 
                        variant='h1' 
                        marginBottom= {{ xs: 2.5, md: 3.75 }}
                        color={selectedTheme.palette.primary[700]}
                        marginTop={{ xs: 7.5, md: 12.5 }}
                    >{ OnboardingScreen3Helper.title }</Typography>

                    {
                        !combinedLoading ? (
                            <Grid container display="flex" direction="row">
                                <Grid item xs={12}>
                                    <AddressForm modalEditData={selectedAddressData} modalOpen={true} type='ONBOARDING' handleModalClose={() => submitSuccess('ADDRESS')} autoSubmit={autoSubmit} />
                                </Grid>
                                <Grid item xs={12}>
                                    <PhoneForm modalEditData={selectedPhoneData} modalOpen={true} type={'ONBOARDING'} handleModalClose={() => submitSuccess('PHONE')} autoSubmit={autoSubmit}/>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant={'h1'} sx={{color: selectedTheme.palette.primary[700] }}>Account Recovery</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <Typography sx={{
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        ...selectedTheme?.typography?.body?.regular, 
                                        color: selectedTheme.palette.text.secondary 
                                    }}>Please provide your personal email address for recovery.</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <EmailForm modalEditData={selectedEmailData} modalOpen={true} type='ONBOARDING' handleModalClose={() => submitSuccess('EMAIL')} autoSubmit={autoSubmit} isRequired={false} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container display="flex" direction="row" justifyContent={'space-between'} alignItems={'center'} marginTop={'26px'}>
                                        <Grid item xs={12} md={6} sx={styles.progressBarContainer}>
                                            <BorderLinearProgress variant="determinate" value={66} bgcolor={selectedTheme.palette.primary[500]}/>
                                        </Grid>
                                        <Grid item xs={12} md={6} display='flex' justifyContent='end'>
                                            <Button onClick={previousClicked} variant='outlined' size='large' sx={{...styles.button, mr: 2}} color={'primary'}>
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.large
                                                }}>Back</Typography>
                                            </Button>
                                            <Button onClick={nextClicked} variant='contained' size='large' 
                                                sx={{...styles.button, px: 1.19}} color={'primary'}>
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.large
                                                }}>
                                                    Complete
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : <></>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}