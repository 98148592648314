import React from 'react';
import { Box, Grid } from '@mui/material';
import { HeaderCard } from './EVerifyHeaderCard';

export const EVerifyHeaderCards = () => {
    return (
        <Box sx={{ pb: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={6} lg={4}>
                    <HeaderCard
                        title="398"
                        subtitle="Points Earned This Month"
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <HeaderCard
                        title="200"
                        subtitle="Completion Rate"
                        caption="Verifications/Month"
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <HeaderCard subtitle="Ranking" title="Top 10%" />
                </Grid>
            </Grid>
        </Box>
    );
};
