import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { PageHeader } from '../../components';
import { IMarketplaceJSON, MarketplaceJSON } from './constants';
import MarketplaceSection from './MarketplaceSection';
import { ThemeContext } from 'util/themes';

export const Marketplace = () => {
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Box sx={{ mx: 4 }}>
            <PageHeader 
                title="Marketplace"
                sx={{
                    ...selectedTheme?.typography?.h1,
                    color: selectedTheme?.palette?.primary[700],
                        }}/>
            {React.Children.toArray(
                MarketplaceJSON.section.map(
                    (item: IMarketplaceJSON['section'][0]) => (
                        <MarketplaceSection sectionData={item} />
                    )
                )
            )}
        </Box>
    );
};
