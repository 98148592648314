import React from 'react';
import { Box } from '@mui/material';

import {
    ILesson,
    IInstanceData,
    IMetaData,
} from '../../../@types/pathway-types';
import { Lesson } from '../Steps';

interface IKnowledgeData {
    lessons: ILesson[];
}

interface IKnowledgeComponent {
    instanceData: IInstanceData;
    metaData: IMetaData;
    data: IKnowledgeData;
    sectionSelected: Function;
    isHealthMode: boolean;
}

function Knowledge(props: IKnowledgeComponent) {
    const { data, instanceData, metaData, sectionSelected } = props;
    if (!data) {
        return <></>;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {!!data.lessons.length ? (
                <Box style={{ width: '100%' }}>
                    {React.Children.toArray(
                        data?.lessons.map((lesson: ILesson) => {
                            return (
                                <Lesson
                                    {...lesson}
                                    sectionSelected={sectionSelected}
                                />
                            );
                        })
                    )}
                </Box>
            ) : (
                <Box />
            )}
        </Box>
    );
}

export default Knowledge;
