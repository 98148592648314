import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { IImageSummaryComponent, ImageSummaryComponent } from './ImageSummary';
import NoDataIcon from 'assets/images/no-data.svg';
import { TableComponent } from 'components';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { ITableComponent } from 'components/Table/Table';
import { Button } from 'lib/controls';
import { ShareMyDataButton } from '../../share-my-data';
import React from 'react';

const styles = {
    buttonHolder: {
        height: '50px',
        borderRadius: '0px',
        border: '1px solid #DADCE0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    noDataWrapper: {
        borderTop: '1px solid #AABABF',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "45px"
    },
    loadingWrapper: {
        flexDirection: "column"
    },
    noDataTitle: {
        fontWeight: "500",
        fontSize: "28px",
        lineHeight: "33px",
        color: "#5D6A71",
        marginBottom: '6px !important',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces'
    },
    noDataInfo: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "21px",
        color: "#5D6A71",
        marginBottom: '6px !important',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces'
    }
}

interface IDataSummary {
    dataList: any;
    loading: boolean;
    url: string;
    dataProps: IImageSummaryComponent,
    dataTable: ITableComponent
    isMobile: boolean;
    selectedTheme?: any;
    isShared: boolean;
}

export const DataSummary = ({
    url,
    dataProps,
    loading,
    dataList,
    dataTable,
    isMobile,
    selectedTheme,
    isShared
}: IDataSummary) => {
    const history = useHistory();

    const traverseToDetail = (force: boolean) => {
        if (isMobile || force) {
            history.push(url);
        }
    }

    const getDataHolder = (currentDataList: any[]) => {
        if (!currentDataList?.length) {
            return (
                <Box
                    sx={styles.noDataWrapper}
                >
                    <Typography sx={styles.noDataTitle}>Nothing to see here...</Typography>
                    <Typography sx={styles.noDataInfo}>{
                        isShared ?
                            'Data will appear here when you share \n it with a third party.' :
                            'Data will appear here when you export \n it in a downloadable format.'
                    }</Typography>
                    <Box
                        component="img"
                        alt="No data."
                        src={NoDataIcon}
                        sx={{ marginBottom: 4 }}
                    />
                    <ShareMyDataButton />
                </Box>
            );
        } else {
            return (
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <TableComponent {...dataTable} />
                    <Box sx={styles.buttonHolder}>
                        <Button
                            variant="text"
                            color='primary'
                            size='large'
                            endIcon={
                                <ArrowForwardIcon sx={{color: selectedTheme?.palette?.primary[500]}}/>
                            }
                            sx={styles.button}
                            onClick={() => traverseToDetail(true)}>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                View All
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )
        }
    }
    return (
        <Paper elevation={1}
               sx={{ ...selectedTheme.card?.default, overflow: 'hidden' }}
               onClick={() => traverseToDetail(false)}
        >
            <ImageSummaryComponent {...dataProps} />
            {loading ?
                (
                    <Box sx={{ ...styles.wrapper, ...styles.loadingWrapper }}>
                        <Skeleton width={500} height={50} animation="wave" />
                        <Skeleton width={500} height={50} animation="wave" />
                        <Skeleton width={500} height={50} animation="wave" />
                        <Skeleton width={500} height={50} animation="wave" />
                        <Skeleton width={500} height={50} animation="wave" />
                    </Box>
                ) : getDataHolder(dataList)
            }
        </Paper>
    );
}

