import React from 'react';
import { Box } from '@mui/material';
import { dataGridStyleOverrides } from './sharingGrid.helper';

interface ITableExpandedRow {
    isExpanded: boolean;
    rows: IExpandedRowValue[];
    isLastRow: boolean;
}

interface IExpandedRowValue {
    key: string;
    value: string;
}

const styles = {
    parent: {
        backgroundColor: '#F9FAFB',
        borderColor: '#BDC1C5',
        borderTop: 0,
        borderBottom: '1px solid #BDC1C5',
        boxShadow: '0 1px #e3e8ee inset',
    },
    keyStyle: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
        flexBasis: '13%',
    },
    valueStyle: {
        fontSize: '16px',
        lineHeight: '24px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        px: 3,
        py: 2,
    },
    container: {
        gap: 2,
    },
};

export function SharingGridExpandedRow(props: ITableExpandedRow) {
    const dataGridStyles = dataGridStyleOverrides();
    const { isExpanded, rows, isLastRow } = props;
    if (!isExpanded) {
        return <></>;
    }
    const lastRowOverrides: any = {};
    if (isLastRow) {
        lastRowOverrides.borderRadius = '0 0 12px 12px';
        lastRowOverrides.borderBottom = 'none';
    }
    return (
        <Box className={dataGridStyles.expandedRow} sx={{ ...styles.parent, ...lastRowOverrides}}>
            <Box sx={{ ...styles.container, ...lastRowOverrides }}>
                {React.Children.toArray(
                    rows.map((x: IExpandedRowValue, index: number) => {
                        return (
                            <Box sx={styles.row}>
                                <Box sx={styles.keyStyle}>{x.key}</Box>
                                <Box sx={styles.valueStyle}>{x.value}</Box>
                            </Box>
                        );
                    })
                )}
            </Box>
        </Box>
    );
}
