export const OnboardingScreen3Helper = {
    header1: `You're doing great!`,
    header2:
        'LifeGraph is digital representation of you that you own and control.',
    header3:
        'From this moment forward, you own your data and control who can see it and when.',
    title: 'Contact Info',
    mobileHeader: `LifeTrek is powered by LifeGraph, a digital representation of you that you own and control.  From this moment forward, you own your data and control who can see it and when.`,
};

export const styles = {
    gradientMaxHeight: {
        xs: '40vh',
        md: '100%',
    },
    sectionHolder: {
        m: 'auto',
        width: {
            xs: '100%',
            md: '70%',
        },
    },
    progressBarContainer: {
        width: '100%',
        '@media (max-width: 1440px)': {
            width: 'calc(100% - 30px)'
        },    
    },
    header: {
        mt: '30%',
        fontSize: '40px',
    },
    subHeader: {
        fontSize: '20px',
    },
    controlPadding: {
        style: {
            p: '10px 12px 8px 12px',
        },
    },
    button: {
        textTransform: 'none',
        px: 1.75,
        py: 1
    },
};

export const findThePrimaryOrDefaultObject = (
    list: any[],
    defaultTypeToSelect: string
) => {
    if (list.length === 0) {
        return null;
    }
    list.sort((a, b) => {
        return +!b.type.localeCompare(defaultTypeToSelect);
    });
    const findPrimary = list.find((x) => x.primary);
    if (findPrimary) {
        return findPrimary;
    }
    return list[0];
};
