import {
    FormControlLabel,
    Switch,
    SwitchProps,
    FormControlLabelProps,
    FormControl,
    FormHelperText,
} from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';

export const ControlledSwitch = (
    props: UseControllerProps<any> &
        Partial<SwitchProps> &
        Partial<Omit<FormControlLabelProps, 'control'>>
) => {
    const { field, fieldState } = useController(props);
    const { ref, ...fieldProps } = field; //Remove ref from fieldProps, causes focus/reference to be lost
    const { label, required, ...switchProps } = props;
    return (
        <FormControl error={!!fieldState.error} required={required}>
            <FormControlLabel
                {...switchProps}
                {...fieldProps} // Spread onChange, onBlur, value, and name props
                inputRef={field.ref} // send input ref, so we can focus on input when error appear
                control={
                    <Switch
                        id={field.name}
                        aria-describedby="switch-helper-text"
                    />
                }
                label={label || ''}
            />
            {fieldState.error && (
                <FormHelperText id="switch-helper-text">
                    {fieldState.error.message}
                </FormHelperText>
            )}
        </FormControl>
    );
};
