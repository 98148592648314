import { Typography, Box, Skeleton, Paper} from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';
import { ISkillDetail, ISkill } from '../../../@types/skills.types';

interface ISkillCircularHeaderComponent {
    loading: boolean;
    skill: ISkillDetail | ISkill,
    diameter: number,
    onClick: Function
}

const styles = {
    circle: {
        backgroundColor: '#2E5376',
        borderRadius: '50%',
        height: 'inherit'
    },
    circleLevel: {
        color: 'rgba(255,255,255,0.8)',
        fontSize: '12px',
        textAlign: 'center',
        fontWeight: 'bold',
        letterSpacing: '1px',
        pt: 4.5
    },
    circleTitle: {
        color: 'white',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: '600',
        pt: 0.5,
        lineHeight: '24px',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        width: 0.8,
        margin: 'auto'
    },
    totalPoints: {
        color: 'white',
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
        textAlign: 'center',
        pt: 2
    }
}

export function SkillCircularHeaderComponent(props: ISkillCircularHeaderComponent) {
    const { loading, skill, diameter, onClick } = props;
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Paper elevation={0} sx={{ backgroundColor: 'transparent' }} onClick={() => onClick(skill)}>
            <Box sx={{ height: diameter, width: diameter, margin: 'auto' }}>
                {
                    loading ? <Skeleton variant={'circular'} height={'inherit'}  width={'inherit'} /> : (
                        <Box sx={{...styles.circle, backgroundColor: selectedTheme?.palette?.purple?.light}}>
                            <Typography sx={styles.circleLevel}>
                                { skill.level }
                            </Typography>
                            <Typography sx={styles.circleTitle}>
                                { skill.name }
                            </Typography>
                            <Typography sx={styles.totalPoints}>
                                { skill.totalPoints }
                            </Typography>
                        </Box>
                    )
                }
            </Box>
        </Paper>
    )
}