import { useState, useEffect, useContext } from 'react';

import {
    Box,
    Container,
    useMediaQuery,
    Switch,
    Grid,
    FormGroup,
    FormControlLabel,
    Divider,
    Typography
} from '@mui/material';
import { Edit as EditIcon} from '@mui/icons-material';

import { ImageSummaryComponent } from 'pages/data-manager/components';
import {
    GridColDef,
    GridRenderCellParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { dataGridStyleOverrides } from '../components/sharing-grid/sharingGrid.helper';
import { SearchTextField } from 'components/SearchBox/SearchTextField';
import { SharingGrid, IImageSummaryComponent } from '../components';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';
import { useDispatch, useSelector } from 'react-redux';
import { dataManagerActionCreators } from '../../../redux/actionCreators';
import { sharedDataSelector } from '../../../redux/selectors/data-manager';

const styles = {
    button: {
        textTransform: 'none',
        fontWeight: 'bold',
    },
    activeOnlySwitch: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
    },
};

interface IFilterState {
    searchString: string;
    activeRecords: boolean;
}

export function DataManagerConsented() {
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();

    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const dataGridStyles = dataGridStyleOverrides();
    const rows = JSON.parse(JSON.stringify(useSelector(sharedDataSelector.selectSharedDataForDetailPage)));
    const loading = useSelector(sharedDataSelector.selectLoading);
    const [filters, setFilters] = useState<IFilterState>({
        searchString: '',
        activeRecords: false,
    });

    useEffect(() => {
        if (loading) {
            dispatch(dataManagerActionCreators.getSharedDataContracts());
        }
    }, []);

    let filteredRows = JSON.parse(JSON.stringify(rows));
    if (filters.activeRecords) {
        filteredRows = filteredRows.filter((x: any) => !x.isExpired);
    }
    if (filters.searchString) {
        filteredRows = filteredRows.filter((x: any) =>
            x.searchValue.includes(filters.searchString.toLowerCase())
        );
    }

    const sharedDataProps: IImageSummaryComponent = {
        mode: 'PAGE',
        header: `Data I've Shared`,
        subHeader:
            'This is data you have granted access to see continually until access is revoked or expired.',
        imageName: 'data-ive-shared-page.svg',
        bgColor: selectedTheme?.palette.background?.default || '#FFFFFF',
        summaryType: 'SHARED',
    };

    const onEditClicked = (row: any) => {
        console.log('on edit clicked', row);
    };

    const getEditButton = (params: GridRenderCellParams) => {
        if (isMobile) {
            return (
                <EditIcon
                    onClick={() => onEditClicked(params.row)}
                    className="cursor-pointer"
                />
            );
        } else {
            return (
                <Button
                    size={'medium'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => onEditClicked(params.row)}
                    sx={{ borderRadius: '4px'}}
                >
                    <Typography sx={{
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.medium
                    }}>
                        Revoke
                    </Typography>
                </Button>
            );
        }
    };

    const toggleActiveRecords = () => {
        setFilters({ ...filters, activeRecords: !filters.activeRecords });
    };

    const updateSearchString = (params: any) => {
        setFilters({ ...filters, searchString: params.target.value });
    };

    const idColumn: GridColDef = {
        field: 'id',
        headerName: 'Id',
        hide: true,
        renderCell: (cellValues) => cellValues.value,
    };

    const recipientColumn: GridColDef = {
        field: 'recipient',
        headerName: 'Recipient',
        flex: 2,
        cellClassName: dataGridStyles.cellValueBold,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const dataColumn: GridColDef = {
        field: 'data',
        headerName: 'Data',
        flex: 3.2,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => {
            return (
                <Box
                    sx={{
                        maxHeight: 'inherit',
                        width: '100%',
                        whiteSpace: 'initial',
                        lineHeight: '20px',
                    }}
                >
                    {cellValues.value}
                </Box>
            );
        },
    };

    const expandColumn: GridColDef = {
        field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        headerName: '',
        flex: 0.3,
        renderCell: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell,
        headerClassName: dataGridStyles.headerVariant,
    };

    const methodColumn: GridColDef = {
        field: 'method',
        headerName: 'Method',
        flex: 1.3,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const createdDateColumn: GridColDef = {
        field: 'created',
        headerName: 'Created',
        flex: 1.8,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const expirationColumn: GridColDef = {
        field: 'expiration',
        headerName: 'Expiration',
        flex: 1.8,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const editButtonColumn: GridColDef = {
        field: 'edit',
        headerName: '',
        flex: isMobile ? 0.5 : 1.3,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => {
            if(!cellValues.row.isExpired) {
                return getEditButton(cellValues);
            }
        }
    };

    const desktopColumns: GridColDef[] = [];
    desktopColumns.push(expandColumn);
    desktopColumns.push(idColumn);
    desktopColumns.push(recipientColumn);
    desktopColumns.push(dataColumn);
    desktopColumns.push(methodColumn);
    desktopColumns.push(createdDateColumn);
    desktopColumns.push(expirationColumn);
    desktopColumns.push(editButtonColumn);

    const mobileColumns: GridColDef[] = [];
    mobileColumns.push(idColumn);
    mobileColumns.push(recipientColumn);
    mobileColumns.push(createdDateColumn);
    mobileColumns.push(editButtonColumn);

    
    const defineColumns = () => {
        const columnDecision =  isMobile ? mobileColumns : desktopColumns;
        let columns = columnDecision.map(column => ({
            ...column,
            cellClassName: (params: any) => {
                let isExpired = params.row.isExpired;
                return (isExpired ? "disabledRowStyle": "")
            },
        }))
        return columns;
    }

    return (
        <>
            <ImageSummaryComponent {...sharedDataProps} headerVariant={'h1'}/>
            <Divider variant='fullWidth' sx={
                {
                    borderBottomWidth: 'thick',
                    // width: 'calc(100% + 6rem)',
                    position: 'relative',
                    // left: '-3rem'
                }}
            />
            <Container maxWidth={false} disableGutters={isMobile}>
                <Box margin={{ xs: 2, md: 0 }}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            my: 2,
                        }}
                    >
                        <Grid item xs={12} md={3}>
                            <SearchTextField selectedTheme={selectedTheme} updateSearchString={updateSearchString} />
                        </Grid>
                        <Grid item>
                            <FormGroup
                                sx={{ display: { xs: 'none', md: 'block' } }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={toggleActiveRecords}
                                            sx={{
                                                color: selectedTheme?.palette.primary[400]
                                            }}
                                        />
                                    }
                                    label="Active only"
                                    disableTypography
                                    sx={{
                                        ...styles.activeOnlySwitch,
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        ...selectedTheme?.typography?.body?.regular,
                                        color: selectedTheme?.palette.secondary?.grayScale &&
                                        selectedTheme?.palette.secondary?.grayScale[700]
                                    }}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
                <SharingGrid
                    rows={filteredRows}
                    columns={defineColumns()}
                    isMobile={isMobile}
                    loading={loading}
                />
                <br />
            </Container>
        </>
    );
}
