import React from 'react';
import { ProfileInfoRowComponent } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { ProfileConstants } from 'app-constants';

export const AboutWrapper = (props: any) => {
    const {
        selectedTheme,
        combinedLoading,
        handleModalOpen,
        fullName,
        personalInfo,
        canEdit,
        birthDateLabel,
        hideVerification = false
    } = props
    return (
        <>
            <ProfileInfoRowComponent
                isLoading={combinedLoading}
                label="Full Name"
                value={fullName}
                selectedTheme={selectedTheme}
                onEditClicked={handleModalOpen}
                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                verificationData={convertProfileObjectToMaryVilleVerification(
                    personalInfo
                )}
                verificationDetail={{key: ProfileConstants.PERSONAL_INFO, data: props}}
                hideVerification={hideVerification}
            />
            <ProfileInfoRowComponent
                isLoading={combinedLoading}
                label="Preferred Name"
                selectedTheme={selectedTheme}
                value={personalInfo?.preferredName}
                onEditClicked={handleModalOpen}
                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
            />
            <ProfileInfoRowComponent
                isLoading={combinedLoading}
                label="Birthday"
                selectedTheme={selectedTheme}
                value={birthDateLabel}
                onEditClicked={handleModalOpen}
                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
            />
            <ProfileInfoRowComponent
                isLoading={combinedLoading}
                label="Gender"
                selectedTheme={selectedTheme}
                value={
                    personalInfo.gender ? personalInfo.gender : ''
                }
                onEditClicked={handleModalOpen}
                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
            />
        </>
    )
}
export const PassportWrapper = (props: any) => {
    const {
        selectedTheme,
        combinedLoading,
        handleModalOpen,
        canEdit,
        passportInfo,
        citizenshipData,
    } = props
    return (
        <ProfileInfoRowComponent
            isLoading={combinedLoading}
            label="Passport Number"
            selectedTheme={selectedTheme}
            value={passportInfo.passportNumber}
            onEditClicked={handleModalOpen}
            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
            verificationData={convertProfileObjectToMaryVilleVerification(
                citizenshipData[0]
            )}
        />
    )
}
export const SocialSecurityWrapper = (props: any) => {
    const {
        selectedTheme,
        combinedLoading,
        handleModalOpen,
        canEdit,
        taxInfo,
        taxData,
    } = props
    return (
        <ProfileInfoRowComponent
            isLoading={combinedLoading}
            label="Social Security Number"
            selectedTheme={selectedTheme}
            value={taxInfo.taxNumber}
            onEditClicked={handleModalOpen}
            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
            verificationData={convertProfileObjectToMaryVilleVerification(
                taxData[0]
            )}
        />
    )
}
