import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { ISharedDataSummary, ISharedDataDetail } from '../../../@types/data-manager-types';
import { isDateExpiredFromCurrentDate, toLocalDateTimeString } from 'util/date.utils';
import attributeMappings from 'assets/data/data-manager/shared-data-attribute-mapping.json';

const sharedDataState = (state: RootState) => state.dataManager.sharedData;

const selectSharedDataState = createSelector([sharedDataState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectSharedDataState, (state) => {
    return state.loading;
});

export const selectSharedDataList = createSelector(selectSharedDataState, (state) => {
    return state.data;
});

export const selectSharedDataForDashboardPage = createSelector(selectSharedDataList, (sharedDataList) => {
    const summarySharedDataList: ISharedDataSummary[] = [];
    for (const sharedData of sharedDataList) {
        summarySharedDataList.push({
            recipient: sharedData.recipient || 'HSBC (mock name)',
            created: sharedData.timestamp.$date || '',
            expiration: sharedData.expirationDate?.$date || 'Open-ended',
        });
    }
    return summarySharedDataList.splice(0, 5);
});

export const selectSharedDataForDetailPage = createSelector(selectSharedDataList, (sharedDataList) => {
    const sharedDataDetailList: ISharedDataDetail[] = [];
    for (const sharedData of sharedDataList) {
        const expandedRowValue: any[] = [];
        for (const chain of sharedData.consentInfo) {
            const chainMapping = (attributeMappings as any)[chain.assetType] || null;
            const displayName = chainMapping ? chainMapping.__display_name || chain.assetType : chain.assetType;
            if (expandedRowValue.map((x: any) => x.key).includes(displayName)) {
                continue;
            }
            if (chainMapping) {
                if (!chainMapping.__isVisibleInUI) {
                    continue;
                }
                const displayArray: any[] = [];
                for (const attribute of chain.attributes) {
                    if(!chainMapping[attribute]) {
                        displayArray.push({ display: attribute, sortOrder: 100 });
                    } else if (chainMapping[attribute].showInUI) {
                        displayArray.push({ display: chainMapping[attribute].displayName, sortOrder: chainMapping[attribute].sortOrder });
                    }
                }
                const sortedValues = [ ...displayArray].sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1 );
                expandedRowValue.push({ key: displayName, value: sortedValues.map((x) => x.display).join(', ') });
            } else {
                expandedRowValue.push({ key: displayName, value: chain.attributes.join(', ')});
            }
        }
        const isExpired = sharedData.expirationDate?.$date ? isDateExpiredFromCurrentDate(sharedData.expirationDate?.$date) : false;
        const createdDate = toLocalDateTimeString(sharedData.timestamp.$date);
        const expirationDate = sharedData.expirationDate?.$date ? toLocalDateTimeString(sharedData.expirationDate?.$date) : 'Open-ended';
        const sharedDetailObject: ISharedDataDetail = {
            id: `${sharedData.sharedWith}_${sharedData.timestamp.$date}`,
            recipient: sharedData.recipient || 'HSBC (mock name)',
            data: sharedData.chains.join(', '),
            isExpired: isExpired,
            rowStyle: isExpired ? 'disabledRowStyle' : '',
            created: createdDate,
            expiration: expirationDate,
            method: 'Consent Contract',
            expandedRowValue: expandedRowValue
        }
        sharedDetailObject.searchValue = `${sharedDetailObject.recipient} ${sharedDetailObject.data} ${sharedDetailObject.created} ${sharedDetailObject.expiration} ${sharedDetailObject.expandedRowValue.map((x: any) => x.value).join(' ')}`;
        sharedDetailObject.searchValue = sharedDetailObject.searchValue.replaceAll(',', '').toLowerCase();
        sharedDataDetailList.push(sharedDetailObject);
    }
    return sharedDataDetailList;
});