import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IExportedDataDetail, IExportedData } from '../../../@types/data-manager-types';
import { toLocalDateTimeString } from 'util/date.utils';
import attributeMappings from 'assets/data/data-manager/shared-data-attribute-mapping.json';

const exportedDataState = (state: RootState) => state.dataManager.exportedData;

const selectExportedDataState = createSelector([exportedDataState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectExportedDataState, (state) => {
    return state.loading;
});

export const selectExportedDataList = createSelector(selectExportedDataState, (state) => {
    return state.data;
});

export const selectExportedSummaryDataList = createSelector(selectExportedDataList, (exportDataList) => {
    const summaryExportDataList: IExportedData[] = [];
    for (const exportData of exportDataList) {
        summaryExportDataList.push({
            recipient: exportData.recipient || 'HSBC (mock name)',
            created: toLocalDateTimeString(exportData.created),
            asset_id: exportData.asset_id,
            expiration: '',
            data: '',
            method: exportData.method,
            chainData: []
        });
    }
    return summaryExportDataList.splice(0, 5);
});

export const selectExportedDataForDetailPage = createSelector(selectExportedDataList, (exportedDataList) => {
    const exportedDataDetailList: IExportedDataDetail[] = [];
    for (const exportedData of exportedDataList) {
        const createdDate = toLocalDateTimeString(exportedData.created);
        const expandedRowValue: any[] = [];
        for (const chain of exportedData.chainData) {
            const chainMapping = (attributeMappings as any)[chain.name] || null;
            const displayName = chainMapping ? chainMapping.__display_name || chain.name : chain.name;
            if (expandedRowValue.map((x: any) => x.key).includes(displayName)) {
                continue;
            }
            if (chainMapping) {
                if (!chainMapping.__isVisibleInUI) {
                    continue;
                }
                const displayArray: any[] = [];
                for (const attribute of chain.fields) {
                    if(!chainMapping[attribute]) {
                        displayArray.push({ display: attribute, sortOrder: 100 });
                    } else if (chainMapping[attribute].showInUI) {
                        displayArray.push({ display: chainMapping[attribute].displayName, sortOrder: chainMapping[attribute].sortOrder });
                    }
                }
                const sortedValues = [ ...displayArray].sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1 );
                expandedRowValue.push({ key: displayName, value: sortedValues.map((x) => x.display).join(', ') });
            } else {
                expandedRowValue.push({ key: displayName, value: chain.fields.join(', ')});
            }
        }
        const exportedDetailObject: IExportedDataDetail = {
            id: exportedData.asset_id,
            recipient: exportedData.recipient || 'HSBC (mock name)',
            data: expandedRowValue.map(item => item.key).join(", "),
            created: createdDate,
            method: exportedData.method,
            expandedRowValue: expandedRowValue
        }
        exportedDetailObject.searchValue = `${exportedDetailObject.recipient} ${exportedDetailObject.data} ${exportedDetailObject.created} ${exportedDetailObject.expandedRowValue.map((x: any) => x.value).join(' ')}`;
        exportedDetailObject.searchValue = exportedDetailObject.searchValue.replaceAll(',', '').toLowerCase();
        exportedDataDetailList.push(exportedDetailObject);
    }
    return exportedDataDetailList;
});