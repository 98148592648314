import { useContext } from 'react';
import { IOgranizationAddress } from '../../@types/profile-types';
import { object as yupObject, string as yupString } from 'yup';
import { Controls } from 'lib';
import { ThemeContext } from 'util/themes';

const formDefaults: IOgranizationAddress = {
    city: '',
    country: '',
    organizationName: '',
    postalCode: '',
    stateOrProvince: '',
    streetAddress: '',
    streetAddressLine2: ''
}

const schema = yupObject({
    // organizationName: yupString().required('Required'),
    // postalCode: yupString().required('Required'),
});

interface IOrganizationAddressForm {
    defaultFormData: any,
    control: any,
    errors: any,
    hasOrganizationName?: boolean
}

const mainForm = (formData: IOrganizationAddressForm) => {
    const { selectedTheme } = useContext(ThemeContext);
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']  
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const { defaultFormData, control, errors, hasOrganizationName = true } = formData;

    return (
        <>
            {
                hasOrganizationName ?
                    <Controls.Input
                        name="organizationName"
                        label="Organization Name"
                        defaultValue={defaultFormData.organizationName}
                        control={control}
                        error={errors?.organizationName || ''}
                        inputStyleProps={inputStyleProp}
                        sx={{...selectedTheme?.input}}
                        labelStyles={labelStyleProp}
                    />
                    : <></>
            }
            <Controls.Input
                name="streetAddress"
                label="Street Address"
                defaultValue={defaultFormData.streetAddress}
                control={control}
                error={errors?.streetAddress || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
            <Controls.Input
                name="streetAddressLine2"
                label="Street Address Line 2"
                defaultValue={defaultFormData.streetAddressLine2}
                control={control}
                error={errors?.streetAddressLine2 || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
            <Controls.Input
                name="city"
                label="City"
                defaultValue={defaultFormData.city}
                control={control}
                error={errors?.city || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
            <Controls.Input
                name="stateOrProvince"
                label="State"
                defaultValue={defaultFormData.stateOrProvince}
                control={control}
                error={errors?.stateOrProvince || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
            <Controls.Input
                name="postalCode"
                label="Postal Code"
                defaultValue={defaultFormData.postalCode}
                control={control}
                error={errors?.postalCode || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
            <Controls.Input
                name="country"
                label="Country"
                defaultValue={defaultFormData.country}
                control={control}
                error={errors?.country || ''}
                inputStyleProps={inputStyleProp}
                sx={{...selectedTheme?.input}}
                labelStyles={labelStyleProp}
            />
        </>
    )
}

export default {
    formDefaults,
    schema,
    mainForm
}