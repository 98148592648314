import { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Icon as ReactIcons } from '@iconify/react';
import { Tooltip, IconButton } from '@mui/material';

const tempImageURL =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png';
const whiteFontBorder = {
    color: '#fff',
    borderColor: '#fff',
    '&.MuiButton-outlined': {
        border: '1px solid #fff',
    },
};
const ShareVaccineWithQR = () => {
    const [open, setOpen] = useState(false);
    const [QRCodeURL, setQRCodeURL] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchQRCodeImage = async () => {
        // todo api call here
        setQRCodeURL(tempImageURL);
    };
    useEffect(() => {
        fetchQRCodeImage();
    }, []);

    return (
        <>
            <Tooltip title={'Share Vaccination Info'} placement="right">
                <IconButton aria-label="share" onClick={handleClickOpen}>
                    <ReactIcons icon={'mdi:share-outline'} fontSize="24" />
                </IconButton>
            </Tooltip>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle
                    sx={{ backgroundColor: 'primary.main', color: '#fff' }}
                >
                    Share Vaccination Info
                </DialogTitle>
                <Divider sx={{ backgroundColor: '#fff' }} />
                <DialogContent sx={{ backgroundColor: 'primary.main' }}>
                    <img
                        style={{ backgroundColor: '#fff' }}
                        alt="qrCode"
                        src={QRCodeURL}
                        width="350px"
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        backgroundColor: 'primary.main',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={whiteFontBorder}
                        onClick={handleClose}
                    >
                        Download
                    </Button>
                    <Button
                        variant="outlined"
                        sx={whiteFontBorder}
                        onClick={handleClose}
                    >
                        Email
                    </Button>
                    <Button
                        variant="outlined"
                        sx={whiteFontBorder}
                        onClick={handleClose}
                    >
                        Text
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ShareVaccineWithQR;
