import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridColDef,
    GridRenderCellParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import {
    Download as DownloadIcon,
} from '@mui/icons-material';
import {
    Container,
    useMediaQuery,
    Box,
    Grid,
    Divider,
    Typography
} from '@mui/material';
import { ImageSummaryComponent } from 'pages/data-manager/components';
import { IImageSummaryComponent, SharingGrid } from '../components';
import { dataGridStyleOverrides } from '../components/sharing-grid/sharingGrid.helper';
import { ThemeContext } from 'util/themes';
import { Button } from 'lib/controls';
import { SearchTextField } from 'components/SearchBox/SearchTextField';
import { exportedDataSelector } from '../../../redux/selectors/data-manager';
import { dataManagerActionCreators } from '../../../redux/actionCreators';
import axios, { withCredentials } from '../../../util/axiosInstance';
import { downloadDataMangerData } from '../../../util/helper';

interface IFilterState {
    searchString: string;
}

export function DataManagerExported() {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));

    const loading = useSelector(exportedDataSelector.selectLoading);
    const rows = useSelector(exportedDataSelector.selectExportedDataForDetailPage);
    const disableContainerGutters = isMobile ? true : false;
    const dataGridStyles = dataGridStyleOverrides();
    const [filters, setFilters] = useState<IFilterState>({ searchString: '' });

    useEffect(() => {
        if (loading) {
            dispatch(dataManagerActionCreators.getExportedDataContracts());
        }
    }, []);

    let filteredRows = JSON.parse(JSON.stringify(rows));
    if (filters.searchString) {
        filteredRows = filteredRows.filter((x: any) =>
            x.searchValue.includes(filters.searchString.toLowerCase())
        );
    }

    const exportedDataProps: IImageSummaryComponent = {
        mode: 'PAGE',
        header: `Data I've Exported`,
        subHeader:
            'This is data you have exported from LifeGraph in a sharable format. To see the exact data that you shared, download the export file.',
        imageName: 'data-ive-exported-screen.svg',
        bgColor: selectedTheme?.palette?.background?.default || '#FFFFFF',
        summaryType: 'EXPORTED',
    };

    const downloadClicked = async (row: any) => {
        const url = `/export-data/vc/${row.id}`;

        let response = await axios().get(url, {...withCredentials(), responseType: 'arraybuffer'});
        downloadDataMangerData(response)
    };

    const updateSearchString = (params: any) => {
        setFilters({ ...filters, searchString: params.target.value });
    };

    const idColumn: GridColDef = {
        field: 'id',
        headerName: 'Id',
        hide: true,
        renderCell: (cellValues) => cellValues.value,
    };

    const recipientColumn: GridColDef = {
        field: 'recipient',
        headerName: 'Recipient',
        flex: 0.1,
        cellClassName: dataGridStyles.cellValueBold,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const dataColumn: GridColDef = {
        field: 'data',
        headerName: 'Data',
        flex: 0.19,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => {
            return (
                <Box
                    sx={{
                        maxHeight: 'inherit',
                        width: '100%',
                        whiteSpace: 'initial',
                        lineHeight: '20px',
                    }}
                >
                    {cellValues.value}
                </Box>
            );
        },
    };

    const getDownloadButton = (params: GridRenderCellParams) => {
        if (isMobile) {
            return (
                <DownloadIcon
                    onClick={() => downloadClicked(params.row)}
                    className="cursor-pointer"
                />
            );
        } else {
            return (
                <Button
                    size={'medium'}
                    color='primary'
                    variant={'outlined'}
                    onClick={() => downloadClicked(params.row)}
                    sx={{ borderRadius: '4px' }}
                >
                    <Typography sx={{
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.medium,
                    }}>
                        Download
                    </Typography>
                </Button>
            );
        }
    };

    const expandColumn: GridColDef = {
        field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        headerName: '',
        flex: 0.02,
        renderCell: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell,
        headerClassName: dataGridStyles.headerVariant,
    };

    const methodColumn: GridColDef = {
        field: 'method',
        headerName: 'Method',
        flex: 0.07,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const createdDateColumn: GridColDef = {
        field: 'created',
        headerName: 'Created',
        flex: 0.1,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const downloadButtonColumn: GridColDef = {
        field: 'download',
        headerName: '',
        flex: isMobile ? 0.01 : 0.08,
        renderCell: getDownloadButton,
        headerClassName: dataGridStyles.headerVariant,
    };

    const desktopColumns: GridColDef[] = [];
    desktopColumns.push(expandColumn);
    desktopColumns.push(idColumn);
    desktopColumns.push(recipientColumn);
    desktopColumns.push(dataColumn);
    desktopColumns.push(methodColumn);
    desktopColumns.push(createdDateColumn);
    desktopColumns.push(downloadButtonColumn);

    const mobileColumns: GridColDef[] = [];
    mobileColumns.push(idColumn);
    mobileColumns.push(recipientColumn);
    mobileColumns.push(createdDateColumn);
    mobileColumns.push(downloadButtonColumn);

    return (
        <>
            <ImageSummaryComponent {...exportedDataProps} headerVariant={'h1'}/>
            <Divider variant='fullWidth' sx={
                {
                    borderBottomWidth: 'thick',
                    // width: 'calc(100% + 4rem)',
                    position: 'relative',
                    // left: '-2.5rem'
                }}/>
            <Container maxWidth={false} disableGutters={disableContainerGutters}>
                <Box margin={{ xs: 2, md: 0 }}>
                    <Grid
                        container
                        sx={{ my: 2 }}
                    >
                        <Grid item xs={12} md={3}>
                            <SearchTextField selectedTheme={selectedTheme} updateSearchString={updateSearchString} />
                        </Grid>
                    </Grid>
                </Box>
                <SharingGrid
                    rows={filteredRows}
                    columns={isMobile ? mobileColumns : desktopColumns}
                    isMobile={isMobile}
                    loading={loading}
                />
                <br/>
            </Container>
        </>
    );
}
