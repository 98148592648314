import { consentLogActionTypes } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';

export const getConsentLogs = () => async (dispatch: Function) => {
    dispatch({ type: consentLogActionTypes.GET_CONSENT_LOGS_LOADING });
    try {
        const resp = await axios().get('/contract', withCredentials());
        dispatch({
            type: consentLogActionTypes.GET_CONSENT_LOGS_SUCCESS,
            payload: resp.data.data,
        });
    } catch (error) {
        dispatch({ type: consentLogActionTypes.GET_CONSENT_LOGS_ERROR });
    }
};

export const revokeConsentLogs = (payload: any) => async (dispatch: Function) => {
    dispatch({ type: consentLogActionTypes.REVOKE_CONSENT_LOGS_LOADING });
    try {
        const resp = await axios().post(
            '/contract/revoke',
            payload,
            withCredentials()
        );
        dispatch({
            type: consentLogActionTypes.REVOKE_CONSENT_LOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({ type: consentLogActionTypes.REVOKE_CONSENT_LOGS_ERROR });
    }
};