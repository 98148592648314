import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { ThemeContext } from 'util/themes';

type HeaderCardProps = {
    title: string;
    subtitle: string;
    caption?: string;
};

const styles = {
    card: {
        height: '146px',
        background: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    title: {
        fontSize: '80px',
        lineHeight: '94px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#43838E',
        mb: 2,
    },
    subtitle: {
        fontFamily: 'Source Code Pro',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '.4px',
        textTransform: 'uppercase',
        mb: 1,
    },
    caption: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: '#47515A',
    },
};

export const HeaderCard = (props: HeaderCardProps) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { title, subtitle, caption } = props;
    return (
        <Box sx={styles.card}>
            <Typography
                variant="h2"
                color={selectedTheme.palette.primary[600]}
                sx={styles.title}
            >
                {title}
            </Typography>

            <Typography
                color={selectedTheme.palette.secondary.main}
                sx={styles.subtitle}
                gutterBottom
            >
                {subtitle}
            </Typography>

            {caption && (
                <Typography
                    variant="body1"
                    color={selectedTheme.palette.secondary.grayScale[700]}
                    sx={styles.caption}
                >
                    {caption}
                </Typography>
            )}
        </Box>
    );
};
