import { Children, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Typography,
    IconButton,
    Box,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import RevokeConsentLogModal from './RevokeConsentLogModal';
import { getDate } from '../../util/getDate';

const styles = {
    root: {
        pt: 2,
        width: 1
    },
    summaryGrid: {
        pt: 1,
        pb: 1,
    },
    detailsGrid: {
        p: 1,
    },
    accordionDetails: {
        borderTop: 'solid',
        borderTopColor: 'divider',
        borderTopWidth: 2,
        backgroundColor: 'background.paper',
    },
    detailHeader: {
        fontWeight: 'bold',
    },
    top: {
        minHeight: 74,
    },
};

const ConsentLogSummary = (props: any) => {
    const result = props.consentLog;
    const [revoke, setRevoke] = useState(false);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
        <Box sx={styles.root}>
            <Accordion square sx={styles.top}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ minHeight: 74 }}
                >
                    <Grid
                        container
                        sx={styles.summaryGrid}
                        display="flex"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography>{result.sharedWith}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                {result.expirationDate
                                    ? getDate(result.expirationDate)
                                    : 'No Expiration'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>{result.context}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{result.source}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            {new Date(result.expirationDate?.$date) >= today ? (
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRevoke(true);
                                    }}
                                    size="small"
                                    id={result.groupId}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ) : null}
                            {!result.expirationDate && (
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRevoke(true);
                                    }}
                                    size="small"
                                    id={result.groupId}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionDetails}>
                    <Grid container>
                        <Grid item xs={3} sx={styles.detailsGrid}>
                            <Typography
                                variant="subtitle2"
                                sx={styles.detailHeader}
                            >
                                Asset Type
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={styles.detailsGrid}>
                            <Typography
                                variant="subtitle2"
                                sx={styles.detailHeader}
                            >
                                Shared Attributes
                            </Typography>
                        </Grid>
                        {Children.toArray(
                            result.consentInfo.map((constent: any) => (
                                <Grid container wrap="nowrap">
                                    <Grid item xs={3} sx={styles.detailsGrid}>
                                        <Typography variant="subtitle2">
                                            {constent.assetType}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sx={styles.detailsGrid}>
                                        <Typography variant="subtitle2">
                                            {constent.attributes.join(', ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <RevokeConsentLogModal
                consentLog={result}
                revoke={revoke}
                setRevoke={setRevoke}
            />
        </Box>
    );
};

export default ConsentLogSummary;
