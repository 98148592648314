import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '../../controls';
import CloseIcon from '@mui/icons-material/Close';

interface IComponentProps {
    title: string;
    children: any;
    openPopup: boolean;
    setOpenPopup: (value: boolean) => void;

    // All other props
    [other: string]: any;
}

const useStyles = makeStyles((theme: any) => ({
    dialogWrapper: {
        position: 'absolute',
        top: theme.spacing(5),
    },
    dialogTitle: {
        mr: theme.spacing(3),
        pr: 0,
    },
    dialogContent: {
        m: 0,
        p: 0,
    },
}));

export function Popup(props: IComponentProps) {
    const { title, children, maxWidth = 'md', openPopup, setOpenPopup } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={openPopup}
            classes={{ paper: classes.dialogWrapper }}
            maxWidth={maxWidth}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h5"
                        color="primary"
                        style={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>

                    <Button
                        style={{ border: 'none' }}
                        onClick={() => {
                            setOpenPopup(false);
                        }}
                    >
                        <CloseIcon color="secondary" />
                    </Button>
                </Box>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
}
