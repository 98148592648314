import { useContext } from 'react';
import { OnboardingComponent, IOnboardingComponent } from 'components/Onboarding/Onboarding';
import { ThemeContext } from 'util/themes';

export function HealthPathwayOnboardingComponent() {
    const { selectedTheme } = useContext(ThemeContext);

    const data: IOnboardingComponent = {
        imageName: 'health-onboarding.svg',
        redirectLink: '/health/pathway',
        overline: 'Health Pathway',
        overlineStyle: {
            ...selectedTheme?.typography?.overlines?.overline1?.default,
            ...selectedTheme?.typography?.overlines?.overline1?.semibold,
            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
        },
        header: `Diabetes Self-Management Education & Support (DSMES)`,
        headerStyle: {
            ...selectedTheme?.typography?.banner?.banner1,
            fontSize: '40px',
            lineHeight: '47px',
            width: '30.06rem',
            color: selectedTheme?.palette?.primary[700]
        },
        subHeader: `Learn how to effectively self-manage your diabetes and improve your health.`,
        subHeaderStyle: {
            pb: 12,
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body3,
            ...selectedTheme?.typography?.body?.regular,
            width: '24.69rem',
            color: selectedTheme?.palette?.secondary?.grayScale &&
            selectedTheme?.palette?.secondary?.grayScale[700]
        },
        bottomTextList: [
            `Your doctor has assigned you this pathway to help you take control of you health.`,
            `Diabetes is a complex disease that requires you to actively manage your health on a daily basis, including making numerous decisions throughout the day, as part of your management and treatment regimen. Diabetes Self-Management Education and Support (DSMES), is an evidence-based service that teaches you how to effectively self-manage your diabetes and cope with the disease.`,
        ],
        bottomTextStyle: {
            pb: 0,
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body3,
            ...selectedTheme?.typography?.body?.regular,
            color: selectedTheme?.palette?.secondary?.grayScale &&
            selectedTheme?.palette?.secondary?.grayScale[700]
        },
        buttonText: `Get Started`,
        buttonTextStyle: {
            textTransform: 'uppercase'
        },
        onboardingScreen: 'pathway',
        background: '#FFFFFF',
        backgroundStyle: {width: '100%', top: '0', height: '100%'},
        isSectionVisible: true,
        imageWrapperClass: 'data-manager-img-wrapper',
        generalOnboardingContainerWrapper: 'data-manager-general-onboarding-container-wrapper',
        headerContainerStyle: { pl: '3rem' },
        bottomBoxContainer: { px: '3rem', mt: '3.5rem' }
    };
 return <OnboardingComponent { ...data } />;
}
