import React, { useState, useContext } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box } from '@mui/material';

import { INotify } from 'lib/types';
import * as Components from '../../lib/components';
import * as Controls from '../../lib/controls';
import {
    executeResetRequest,
    initialValues,
    schema,
} from './resetPasswordRequestHelpers';
import { ThemeContext } from 'util/themes';

interface IProps {
    setOpenPopup: (val: boolean) => void;
}

interface IFormInputs {
    email: string;
}

function ResetPasswordRequest(props: IProps) {
    const { setOpenPopup } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const submitForm = (formValues: IFormInputs): void => {
        executeResetRequest(formValues)
            .then(() => {
                setNotify({
                    message: 'Password reset request received',
                    type: 'success',
                    isOpen: true,
                    onClosed: () => {
                        setOpenPopup(false);
                    },
                });
                console.log('Password Reset Request Successful');
            })
            .catch((error: any) => {
                setNotify({
                    message: 'There was a problem setting your password',
                    type: 'error',
                    isOpen: true,
                });
                console.error('Password Reset Request Failed:', error.message);
            });
    };

    return (
        <Box>
            <Typography
                variant="body1"
                color="primary"
                style={{ fontWeight: 'bold' }}
            >
                Provide your email address and we will send you a link to reset
                your password.
            </Typography>

            <form
                data-testid="forgot-password-form"
                onSubmit={handleSubmit(submitForm)}
            >
                <Box>
                    <Controls.Input
                        name="email"
                        label="Email Address"
                        defaultValue=""
                        control={control}
                        error={errors?.email}
                    />
                </Box>
                <Box>
                    <Controls.Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ m: 1, mt: 2 }}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                        }}>
                            Submit
                        </Typography>
                    </Controls.Button>
                </Box>
            </form>
            <Components.Notify notify={notify} setNotify={setNotify} />
        </Box>
    );
}

export default ResetPasswordRequest;
