import { useDispatch, useSelector } from 'react-redux';
import { notificationSelector } from 'redux/selectors';
import { notificationActionTypes } from 'redux/actionsTypes';
import { Notify } from 'lib/components';

const APP_NOTIFICATION_TIME_MILLIS = 4000;

export function AppNotificationComponent () {
    const message = useSelector(notificationSelector.selectMessageAndTypeToShow);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: notificationActionTypes.CLEAR_ALL_MESSAGES });
    }

    if (message) {
        setTimeout(() => {
            dispatch({ type: notificationActionTypes.CLEAR_ALL_MESSAGES });
        }, APP_NOTIFICATION_TIME_MILLIS);
    }

    const setNotify = ({}: any) => { };

    return (
        <Notify 
            setNotify = {setNotify}
            notify = {{
                isOpen: message ? true : false,
                message: message?.message,
                type: message?.type as 'success' | 'error' | 'info' | 'warning',
                onClosed: handleClose,
            }}
            position = {'top'}
        />
    );
}