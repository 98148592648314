import React, { useContext } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

import { IMarketplaceJSON, ImarketPlaceItem } from '../constants';
import { ThemeContext } from 'util/themes';
import { Controls } from 'lib';


const MarketplaceSection = (props: { sectionData: IMarketplaceJSON['section'][0] }) => {
    const { selectedTheme } = useContext(ThemeContext);
    
    const { sectionData } = props;
    const { icon: SectionIcon, color: iconColor, height: iconHeight, width: iconWidth } = sectionData.SectionIcon;
    
    return (
        <>
            <Box>
                <Grid container alignItems={'center'}>
                    <Grid item mt={1.25}>
                        {<SectionIcon sx={{color: iconColor, width: iconWidth, height: iconHeight}}/>}
                    </Grid>
                    <Grid item ml={1}>
                        <Typography variant={'h2'} sx={{
                            color: selectedTheme?.palette?.secondary?.grayScale &&
                                selectedTheme?.palette?.secondary?.grayScale[600]
                        }}>
                            {sectionData.sectionHeading}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Controls.Button
                            variant="text"
                            size='large'
                            endIcon={
                                <ArrowForwardIcon sx={{color: selectedTheme?.palette?.primary[500]}}/>
                            }
                            sx={{color: selectedTheme?.palette?.primary[700]}}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                            }}>
                                View All
                            </Typography>
                        </Controls.Button>
                    </Grid>
                </Grid>
                <Grid
                    direction="row"
                    container
                    justifyContent="flex-start"
                    sx={{
                        my: 2,
                        mx: 0,
                    }}
                >
                    {React.Children.toArray(
                        sectionData?.data?.map((e: ImarketPlaceItem) => (
                            <Box
                                component={Grid}
                                sx={{
                                    padding: '18px 15px 24.66px 21px',
                                    m: 2,
                                    minHeight: '15rem',
                                    width: 274,
                                    minWidth: 274, //Makes sure the cards do not get crunched on small resolutions.
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                    ...selectedTheme?.card?.default
                                }}
                            >
                                <Grid container justifyContent={'space-between'}>
                                    <Grid item sx={{
                                        height: '11.08rem'
                                    }}>
                                        <Grid container justifyContent={'space-between'}>
                                            <Grid item>
                                                <img src={e.headingIconUrl} height={e.headingIconHeight} width={e.headingIconWidth}/>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.overlines?.overline2.default,
                                                    ...selectedTheme?.typography?.overlines?.overline2.semibold,
                                                    color: e?.headingTextColor
                                                }}>
                                                    {e.headingText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid pt={2.5}>
                                            <Typography variant='h2' sx={{
                                                color: selectedTheme?.palette?.secondary?.grayScale &&
                                                selectedTheme?.palette?.secondary?.grayScale[800]
                                            }}>
                                                {e.subHeading}
                                            </Typography>
                                        </Grid>
                                        <Grid item pt={1.5}>
                                            <Typography sx={{
                                                ...selectedTheme?.typography?.body?.default,
                                                ...selectedTheme?.tpyography?.body?.body1,
                                                ...selectedTheme?.tpyography?.body?.regular,
                                                color: selectedTheme?.palette?.secondary?.grayScale &&
                                                    selectedTheme?.palette?.secondary?.grayScale[700]
                                            }}>
                                                {e.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={'start'} mt={1.75}>
                                        <Controls.Button
                                            fullWidth={false}
                                            size="medium"
                                            variant="text"
                                            startIcon={
                                                <e.StartIcon sx={{
                                                    color: e?.isAdded ? '#52D9AE' : '#3FB3B0'
                                                }}/>
                                            }
                                            sx={{
                                                color: e?.isAdded ? '#14928A' : '#285C6C',
                                                paddingLeft: '6px'
                                                }}
                                        >
                                            <Typography sx={{
                                                ...selectedTheme?.typography?.Components?.button?.default,
                                                ...selectedTheme?.typography?.Components?.button?.medium,
                                            }}>
                                                {e.addBtnText}
                                            </Typography>
                                        </Controls.Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default MarketplaceSection;
