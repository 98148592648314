import {
    GridColDef,
    GridComparatorFn,
    gridNumberComparator,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';

import { toLocalDateTimeString } from '../../util/date.utils';
import { axiosInstance, withCredentials } from 'util/axiosInstance';
import axios from 'axios';
import { capitalizeFirstLetter } from 'util/string.util';

interface IBurstDate {
    $date?: any;
}

export interface IFilterState {
    searchString: string;
    pendingRecordsOnly: boolean;
}

export interface IVerificationRecord {
    assetType: string;
    asset_id: string;
    postedDate: IBurstDate;
    taskName: string;
    value: number;
    verificationCompany: string;
    verificationStatus: string;
    verifier: string;
}
const burstDateComparator: GridComparatorFn<IBurstDate> = (
    v1: IBurstDate,
    v2: IBurstDate
) => {
    const date1: any = new Date(v1?.$date);
    const date2: any = new Date(v2?.$date);
    return date1 - date2;
};

const styles = {
    heading: {
        mt: 4,
    },
    subheading: {
        textAlign: 'left',
    },
    searchBar: {
        display: 'flex',
    },
    banner: {
        background: 'url("/images/e-verify/e-verify-banner.svg") no-repeat',
        backgroundSize: 'contain',
        px: 3,
    },
    activeOnlySwitch: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
    },
    searchControl: {
        borderRadius: '2px',
        display: 'block',
    },
};

export const filterDefaults = {
    searchString: '',
    pendingRecordsOnly: false,
};

export const getSearchString = (row: IVerificationRecord) => {
    return `${row.taskName.toLowerCase()} ${
        row.value
    } ${row.assetType.toLowerCase()} 
    ${toLocalDateTimeString(row.postedDate.$date)}`;
};

const getColumns = (dataGridStyles: any) => {
    const idColumn: GridColDef = {
        field: 'asset_id',
        headerName: 'Id',
        flex: 1,
        hide: true,
        renderCell: (cellValues) => cellValues.value,
    };

    const taskIdColumn: GridColDef = {
        field: 'taskName',
        headerName: 'Task Id',
        flex: 1,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const valueColumn: GridColDef = {
        field: 'value',
        headerName: 'Value',
        flex: 2,
        cellClassName: dataGridStyles.cellValueBold,
        headerClassName: dataGridStyles.headerVariant,
        sortComparator: gridNumberComparator,
        renderCell: (cellValues) => parseInt(cellValues.value),
    };

    const datePostedColumn: GridColDef = {
        field: 'postedDate',
        headerName: 'Date Posted',
        flex: 2,
        headerClassName: dataGridStyles.headerVariant,
        sortComparator: burstDateComparator,
        renderCell: (cellValues) =>
            toLocalDateTimeString(cellValues.value.$date),
    };

    const typeColumn: GridColDef = {
        field: 'assetType',
        headerName: 'Type',
        flex: 1.3,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const statusColumn: GridColDef = {
        field: 'verificationStatus',
        headerName: 'Status',
        flex: 1.8,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => capitalizeFirstLetter(cellValues.value),
    };

    const expandColumn: GridColDef = {
        field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        headerName: '',
        flex: 0.1,
        renderCell: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell,
        headerClassName: dataGridStyles.headerVariant,
    };

    const columns: GridColDef[] = [];
    columns.push(expandColumn);
    columns.push(taskIdColumn);
    columns.push(idColumn);
    columns.push(valueColumn);
    columns.push(datePostedColumn);
    columns.push(typeColumn);
    columns.push(statusColumn);

    return columns;
};

export const loadVerifications = (
    setAllVerifications: Function,
    setLoading: Function
) => {
    const getAddress = axiosInstance().get(
        '/v2/address/verification',
        withCredentials()
    );

    const getEmail = axiosInstance().get(
        '/v2/email/verification',
        withCredentials()
    );
    const getPhone = axiosInstance().get(
        '/v2/phone/verification',
        withCredentials()
    );

    const getMedication = axiosInstance().get(
        '/v2/medication/verification',
        withCredentials()
    );

    const getVaccination = axiosInstance().get(
        '/v2/vaccination/verification',
        withCredentials()
    );

    const getAllergy = axiosInstance().get(
        '/v2/allergy/verification',
        withCredentials()
    );

    axios
        .all([
            getAddress,
            getEmail,
            getPhone,
            getMedication,
            getVaccination,
            getAllergy,
        ])
        .then(
            axios.spread((...responses) => {
                const addressResponse =
                    [
                        ...(responses[0].data?.unclaimed || []),
                        ...(responses[0].data?.claimed || []),
                    ] || [];
                const emailResponse =
                    [
                        ...(responses[1].data?.unclaimed || []),
                        ...(responses[1].data?.claimed || []),
                    ] || [];
                const phoneResponse =
                    [
                        ...(responses[2].data?.unclaimed || []),
                        ...(responses[2].data?.claimed || []),
                    ] || [];

                const medicationResponse =
                    [
                        ...(responses[3].data?.unclaimed || []),
                        ...(responses[3].data?.claimed || []),
                    ] || [];


                    const vaccinationResponse =
                        [
                            ...(responses[4].data?.unclaimed || []),
                            ...(responses[4].data?.claimed || []),
                        ] || [];

                
                    const allergyResponse =
                        [
                            ...(responses[5].data?.unclaimed || []),
                            ...(responses[5].data?.claimed || []),
                        ] || [];

                setAllVerifications(
                    [
                        ...addressResponse,
                        ...emailResponse,
                        ...phoneResponse,
                        ...medicationResponse,
                        ...vaccinationResponse,
                        ...allergyResponse
                    ]

                );

                setLoading(false);
            })
        )
        .catch((error) => {
            console.log(error);
        });
};

export { styles, getColumns };
