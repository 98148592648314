import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Paper,
    Typography,
    SvgIcon,
} from '@mui/material';
import { healthPathwaySelector, pathwaySelector, routerSelector } from 'redux/selectors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BorderLinearProgress } from 'components';

import * as milestoneCards from './Milestones';
import { baseTheme } from 'util/themes';
import { IMilestone } from '../../@types/pathway-types';
import { accountCheck, lock } from './pathwayIcons';
import { useContext, useEffect, useState } from 'react';
import { toHoursAndMinutes, toLocalDateString } from '../../util/date.utils';
import { ThemeContext } from 'util/themes';

const styles = {
    baseStyle: {
        borderRadius: '12px',
        mt: 0,
        mb: 1.5,
        mx: 1.5,
    },
    completed: {
        opacity: '40%',
    },
    header: {
        p: baseTheme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '.5px',
        lineHeight: '18px',
        textTransform: 'uppercase' as const,
    },
    title: {
        color: '#202124',
        fontSize: '20px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '30px',
    },
    dateComplete: {
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: '21px',
    },
    healthDateComplete: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#5D6A71'
    },
    accordionStyle: {
        backgroundColor: '#E8EAED',
        p: 2,
    },
    accordionOverrideStyle: {
        backgroundColor: '#F7F9FA',
        p: 2,
        borderTop: '2px solid #AABABF',
    }
};

export interface IPathwayMilestoneHolder {
    milestone: IMilestone;
    sectionSelected: Function;
    isHealthMode: boolean;
}

const getProgressBarHolder = (milestone: IMilestone, isHealthMode: boolean) => {
    let value = milestone?.instanceData?.percentComplete;
    if (milestone.metaData?.type === 'Demonstration') {
        const status = milestone?.instanceData?.status || '';
        if (['underEvaluation', 'onHold'].includes(status)) {
            value = 50;
        } else if (
            ['blocked', 'pendingConsent', 'pendingEvaluation'].includes(status)
        ) {
            value = 0;
        }
    }
    if (
        milestone?.instanceData?.percentComplete !== 100
    ) {
        return !isHealthMode ?
            <BorderLinearProgress variant="determinate" value={value} bgcolor={'linear-gradient(45.3deg, #C456BF 16.4%, #AC8AFC 82.29%)'} sx={{borderRadius: '6px'}}/> :
            <BorderLinearProgress variant="determinate" value={value} bgcolor="#3FB3B0" sx={{borderRadius: '6px'}}/>;
    } else {
        return <></>;
    }
};

export function PathwayMilestoneHolder(props: IPathwayMilestoneHolder) {
    const { milestone, sectionSelected, isHealthMode } = props;
    const [isMilestoneExpanded, setIsMilestoneExpanded] = useState(false);
    const selectedAssetId = useSelector(
        routerSelector.selectCurrentPathwayLessonCourse
    );
    const selectedPathwayLoading = useSelector(
        pathwaySelector.selectedPathwayLoading
    );
    const selectedLessonId = useSelector(
        routerSelector.selectCurrentMilestoneLessonId
    );

    const selectedHealthPathwayLoading = useSelector(
        healthPathwaySelector.selectedHealthPathwayLoading
    );
    const selectedArticleId = useSelector(
        routerSelector.selectCurrentMilestoneArticleId
    );
    
    const {selectedTheme } = useContext(ThemeContext);

    useEffect(() => {
        if (!selectedPathwayLoading && selectedAssetId) {
            if (milestone.data && milestone.data.lessons) {
                const selectedMileStone = milestone.data.lessons.find(
                    (x: any) => x.metaData.id === selectedLessonId
                );
                if (selectedMileStone) {
                    setIsMilestoneExpanded(true);
                }
            }
        }
    }, [selectedPathwayLoading, milestone]);

    useEffect(() => {
        if(!selectedHealthPathwayLoading && selectedArticleId) {
            if (milestone.data && milestone.data.articles) {
                const selectedMileStone = milestone.data.articles.find(
                    (x: any) => x.assetId === selectedArticleId
                );
                if (selectedMileStone) {
                    setIsMilestoneExpanded(true);
                }
            }
        }
    }, [selectedHealthPathwayLoading, milestone])

    const getCardStyle = () => {
        if (
            milestone?.instanceData?.percentComplete === 100 &&
            milestone.metaData.type === 'Knowledge'
        ) {
            return styles.completed;
        }
    };

    const getIcon = (status: string) => {
        if (!status) {
            return <Box />;
        } else if (status === 'completed') {
            return <Icon icon={accountCheck} style={{ color: isHealthMode ? '#3FB3B0' : 'green' }} width={24} height={24} />;
        } else if (status === 'locked' || status === 'blocked') {
            return <Icon icon={lock} style={{color: "#8499A0"}} />;
        }
    };

    const milestoneSelected = () => {
        setIsMilestoneExpanded(!isMilestoneExpanded);
    };

    const renderMilestoneCard = (currentMilestone: IMilestone) => {
        const result = new Map(
            Object.entries(milestoneCards).map((x) => [x[0], x[1]])
        );

        const Component = result.get(currentMilestone?.metaData.type);
        const milestoneStyle = milestone?.instanceData?.status === 'completed' ? selectedTheme?.card?.disabled : '';
        const milestoneBackgroundStyle =
            isHealthMode && milestone?.instanceData?.status === 'completed' ?
                selectedTheme?.card?.deActivate || '' :
                '';
        const cardStyle = isHealthMode ? { color: '#3FB3B0' } : {};

        return (
            <Paper
                sx={{
                    ...getCardStyle(),
                    ...selectedTheme.card?.base, ...selectedTheme.card?.accordion, ...selectedTheme.card?.default, ...milestoneStyle, ...milestoneBackgroundStyle}}
                elevation={3}
            >
                <Box sx={styles.header}>
                    <Box>
                        <Typography
                            variant="overline"
                            component="div"
                            sx={styles.label}
                        >
                            {milestone.metaData.label}
                        </Typography>
                        <Typography variant="h2" sx={styles.title}>
                            {milestone.metaData.title}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 1.5 }}>
                        {!!milestone?.instanceData?.status ? (
                            <SvgIcon fontSize="large" viewBox="36 36">
                                {getIcon(milestone?.instanceData?.status)}
                            </SvgIcon>
                        ) : (
                            <Box component={'span'} />
                        )}
                    </Box>
                </Box>
                <Box style={{ paddingBottom: 0 }}>
                    <Box>
                        <Accordion
                            sx={{
                                backgroundColor: 'initial',
                                boxShadow: 'none',
                            }}
                            expanded={isMilestoneExpanded}
                            onChange={milestoneSelected}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={cardStyle}/>}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    {getProgressBarHolder(milestone, isHealthMode)}
                                    {milestone?.instanceData?.dateComplete ? (
                                        <Box sx={!isHealthMode ? styles.dateComplete : styles.healthDateComplete}>
                                            Completed:&nbsp;
                                            {toLocalDateString(
                                                milestone?.instanceData
                                                    ?.dateComplete
                                            )}
                                        </Box>
                                    ) : (
                                        <Box />
                                    )}
                                    {!milestone?.instanceData ? (
                                        <Box sx={styles.dateComplete}>
                                            {toHoursAndMinutes(
                                                milestone?.metaData
                                                    ?.projectCompletionTime
                                            )}
                                        </Box>
                                    ) : (
                                        <Box />
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={isHealthMode? styles.accordionOverrideStyle : styles.accordionStyle}
                            >   
                                <Box style={{ paddingBottom: 0 }}>
                                    {Component ? (
                                        <Component
                                            instanceData={
                                                milestone?.instanceData
                                            }
                                            data={milestone.data}
                                            metaData={milestone.metaData}
                                            sectionSelected={sectionSelected}
                                            isHealthMode={isHealthMode}
                                        />
                                    ) : (
                                        <Box />
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            </Paper>
        );
    };

    return <>{renderMilestoneCard(milestone)}</>;
}
