import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Typography, Box } from '@mui/material';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';

import { Controls, Components } from '../../lib';
import {
    IFormInputs,
    schema,
    initialValues,
    defaultOptions,
    top100Films,
} from './formDemoHelpers';
// import { Color } from '@mui/material';

const styles = {
    form: {
        width: '100%',
    },
    formContainer: {
        width: '100%',
    },
    additionalSectionHeading: {
        my: 2,
        mx : 0,
        fontWeight: 'bold',
        fontSize: '1.17rem',
        lineHeight: '1.5',
    }

};

export const FormDemo = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    // const successVar: Color = 'success';

    const [notify, setNotify] = useState({
        isOpen: false,
        message: '',
        // type: successVar,
    });

    const submitForm = (e: any): void => {
        console.log('e', e);
        setNotify({
            message: 'Form Submission Successful (Look at Console) ',
            // type: 'success',
            isOpen: true,
        });
    };

    const handleNotificationClick = () => {
        setNotify({
            message: 'Button Successfully Clicked',
            // type: 'success',
            isOpen: true,
        });
    };

    const [confirmSimpleOpen, setConfirmSimpleOpen] = useState(false);
    const [confirmComplexOpen, setConfirmComplexOpen] = useState(false);

    return (
        <Box sx={styles.formContainer}>
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                sx={styles.form}
            >
                <Controls.Input
                    name="firstName"
                    label="First Name"
                    defaultValue="lll"
                    control={control}
                    error={errors?.firstName || ''}
                />

                <Controls.Input
                    name="lastName"
                    label="Last Name"
                    defaultValue="kkkj"
                    control={control}
                    error={errors?.lastName || ''}
                />

                <Controls.Input
                    name="age"
                    label="Age"
                    type="number"
                    color="secondary"
                    defaultValue="22"
                    control={control}
                    error={errors?.age || ''}
                />

                <Controls.Checkbox
                    name="checkBoxTest"
                    label="Checkbox Test"
                    value="test"
                    color="primary"
                    control={control}
                    error={errors?.checkBoxTest}
                />

                <Controls.RadioGroup
                    name="radioGroup"
                    label="Radio Group Test"
                    color="secondary"
                    options={defaultOptions()}
                    control={control}
                    error={errors?.radioGroup || null}
                />

                <Controls.TextSelect
                    name="textSelect"
                    label="Text Select Test"
                    value=""
                    options={defaultOptions()}
                    color="secondary"
                    control={control}
                    error={errors?.textSelect || ''}
                />

                <Controls.ComboBox
                    name="comboBox"
                    label="Combo Box"
                    options={top100Films}
                    getOptionLabel={(option: { title: any }) => option.title}
                    getOptionValue={(option: { year: any }) => option.year}
                    control={control}
                    error={errors?.comboBox || ''}
                    size="small"
                />

                <Controls.Button type="submit">Submit</Controls.Button>
            </Box>

            <br />
            <br />
            <Divider />
            <br />

            <Typography variant={'h3'} sx={styles.additionalSectionHeading}>Additional Components: </Typography>

            <Controls.Button
                variant="outlined"
                onClick={handleNotificationClick}
            >
                Open success snackbar
            </Controls.Button>
            <Components.Notify notify={notify} setNotify={setNotify} />

            <br />

            <Controls.Button
                variant="outlined"
                color="primary"
                onClick={() => setConfirmSimpleOpen(true)}
            >
                Open simple dialog
            </Controls.Button>

            <Components.ConfirmDialog
                title="Are you sure you want to submit?"
                open={confirmSimpleOpen}
                setOpen={setConfirmSimpleOpen}
                onConfirm={() => {
                    alert('Simple Dialog Confirmed');
                }}
                confirmText="Submit Form"
            >
                <Typography variant="body1">
                    Are you sure you want to submit this form? Did you make sure
                    everything was filled out correctly.
                </Typography>
            </Components.ConfirmDialog>

            <br />

            <Controls.Button
                variant="outlined"
                color="primary"
                onClick={() => setConfirmComplexOpen(true)}
            >
                Open complex dialog w/ icon
            </Controls.Button>

            <Components.ConfirmDialog
                title="Are you sure you want to submit?"
                dialogIcon={<NotListedLocationOutlinedIcon />}
                open={confirmComplexOpen}
                setOpen={setConfirmComplexOpen}
                onConfirm={() => {
                    alert('Complex Dialog Confirmed');
                }}
                confirmText="Submit Form"
            >
                <Typography variant="body1">
                    Are you sure you want to submit this form? Did you make sure
                    everything was filled out correctly.
                </Typography>
            </Components.ConfirmDialog>
        </Box>
    );
};
