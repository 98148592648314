import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';
import {
    Typography,
    Box,
    List,
    ListItem,
    Container,
    Grid,
} from '@mui/material';

import * as Components from '../../lib/components';
import * as Controls from '../../lib/controls';
import {
    executeResetRequest,
    schema,
    IFormInputs,
} from './resetPasswordHelpers';

import { useLocation } from 'react-router-dom';
import { INotify } from 'lib/types';
import { ThemeContext } from 'util/themes';


const styles = {
    formHeading: {
        display: 'block',
        fontSize: '2em',
        my: 1.34,
        mx: 0,
        fontWeight: 'bold',
        lineHeight: '1.5'
    }
};

export function ResetPassword() {
    const { search } = useLocation();
    const querystring = queryString.parse(search);
    const token = querystring.token ? querystring.token : '';
    const userEmail = querystring.email ? querystring.email : '';
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });

    let initialValues = {
        username: userEmail?.toString(),
        password: '',
        passwordConfirmation: '',
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const submitForm = (formValues: IFormInputs): void => {
        executeResetRequest(
            formValues,
            userEmail?.toString(),
            token?.toString()
        )
            .then(() => {
                setNotify({
                    message: 'Password Updated, you may now login',
                    type: 'success',
                    isOpen: true,
                    onClosed: () => {
                        history.push('/login');
                    },
                });
                console.log('Password Reset Request Successful');
            })
            .catch((error: any) => {
                setNotify({
                    message: 'There was a problem setting your password',
                    type: 'error',
                    isOpen: true,
                });
                console.error('Password Reset Failed:', error.message);
            });
    };

    return (
        <Container>
            <Typography variant={'h1'} sx={styles.formHeading}>Set Password</Typography>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <form
                            data-testid="set-password-form"
                            onSubmit={handleSubmit(submitForm)}
                        >
                            <Box>
                                <Controls.Input
                                    disabled
                                    name="username"
                                    label="Email Address"
                                    defaultValue=""
                                    control={control}
                                    error={errors?.username}
                                />
                            </Box>
                            <Box>
                                <Controls.Input
                                    name="newPassword"
                                    label="New Password"
                                    defaultValue=""
                                    control={control}
                                    error={errors?.newPassword}
                                />
                            </Box>
                            <Box>
                                <Controls.Input
                                    name="passwordConfirmation"
                                    label="Confirm Password"
                                    defaultValue=""
                                    control={control}
                                    error={errors?.passwordConfirmation}
                                />
                            </Box>
                            <Box>
                                <Controls.Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{ m: 1, mt: 2  }}
                                >
                                    <Typography sx={{
                                        ...selectedTheme?.typography?.Components?.button?.default,
                                        ...selectedTheme?.typography?.Components?.button?.large,
                                    }}>
                                        Submit
                                    </Typography>
                                </Controls.Button>
                            </Box>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Password Requirements:</Typography>
                        <List>
                            <ListItem>
                                Cannot be one of last 24 passwords used
                            </ListItem>
                            <ListItem>
                                At least 10 characters in length
                            </ListItem>
                            <ListItem>At least 1 upper case letter</ListItem>
                            <ListItem>At least 1 lower case letter</ListItem>
                            <ListItem>At least 1 special character</ListItem>
                            <ListItem>At least 1 number</ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
            <Components.Notify notify={notify} setNotify={setNotify} />
        </Container>
    );
}
