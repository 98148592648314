import { Typography, Box, Grid, Container, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ShareMyDataButton } from 'pages/share-my-data';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';

export interface IImageSummaryComponent {
    mode: 'SUMMARY' | 'PAGE';
    header: string;
    subHeader: string;
    imageName: string;
    bgColor: string;
    summaryType: 'SHARED' | 'EXPORTED' | 'PRIVACY';
    headerVariant?: string;
    subHeaderWidth?: number | string;
}

const styles = {
    header: {
        pb: 2,
    },
    backButton: {
        PAGE: {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        SUMMARY: {
            display: 'none',
        },
    },
    shareMyDataButton: {
        textTransform: 'none',
    },
};

const getShareMyDataButton = (isVisible: boolean) => {
    if (isVisible) {
        return <ShareMyDataButton />;
    }
    return <></>;
};

export function ImageSummaryComponent(props: IImageSummaryComponent) {
    const { header, mode, subHeader, imageName, bgColor, summaryType, headerVariant = 'h2', subHeaderWidth = 444 } = props;
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const borderRadius = '0px';
    const imageLink = `url(/images/data-manager/${imageName}) no-repeat`;
    const height = mode === 'SUMMARY' ? '200px' : '224px';
    const imageMarginTop = mode === 'SUMMARY' ? 0 : '-30px';
    const imageWidth = mode === 'SUMMARY' ? '185px' : '240px';
    const itemWidth = mode === 'SUMMARY' ? [7, 5, 0] : [8, 2, 2];
    let headerStyleFromTheme = selectedTheme.typography.h2;
    let headerColor;
    let isShareMyDataButtonVisible = true;
    if(headerVariant !== 'h2') {
        headerStyleFromTheme = selectedTheme.typography.h1;
        headerColor = selectedTheme.palette.primary[700];
    }
    if (summaryType === 'PRIVACY' || mode === 'SUMMARY') {
        isShareMyDataButtonVisible = false;
    }

    const goBack = () => {
        history.push('/data-manager');
    };
    
    return (
        <Box
            sx={{
                height: { xs: 'auto', md: height },
                backgroundColor: bgColor,
                borderRadius: borderRadius,
                background: imageLink,
                backgroundPositionX: 'right',
                backgroundSize: 'contain'
            }}
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        height: '100%',
                        pt: 2,
                        overflow: 'hidden',
                        justifyContent: 'space-between'
                    }}
                >
                    <Grid item xs={12} md={itemWidth[0]}>
                        <Button sx={styles.backButton[mode]} color='secondary' variant='text' startIcon={<ArrowBackIcon />} onClick={goBack}>Back</Button>
                        <Typography sx={{...styles.header, ...headerStyleFromTheme, color: headerColor}}> { header }</Typography>
                        <Typography variant='body1' width={subHeaderWidth}> { subHeader }</Typography>
                    </Grid>
                    {/* <Grid
                        item
                        xs={12}
                        display={{ xs: 'block', md: 'none' }}
                        sx={{ alignSelf: 'center', mt: 2 }}
                    >
                        {getShareMyDataButton(isShareMyDataButtonVisible)}
                    </Grid> */}
                    {/* <Grid
                        item
                        xs={12}
                        md={itemWidth[1]}
                        sx={{
                            textAlign: 'end',
                            m: 'auto',
                            mb: -1,
                            mt: { xs: 0, md: imageMarginTop },
                        }}
                    >
                        <img
                            src={imageLink}
                            height={'auto'}
                            width={imageWidth}
                        ></img>
                    </Grid> */}
                    <Grid
                        item
                        xs={itemWidth[2]}
                        display={{ xs: 'none', md: 'flex' }}
                        sx={{ justifyContent: 'end', height: 'fit-content' }}
                    >
                        {getShareMyDataButton(isShareMyDataButtonVisible)}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
