import {
    Typography,
    Box,
    Paper,
    Divider,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { useState } from 'react';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';
import logo from 'assets/images/logos/Lifegraph Logo.svg';
import { ConfirmModalComponent } from '../components';
import { Button } from 'lib/controls';

const styles = {
    sectionTitle: {
        pt: 4,
        pb: 1.5,
        px : 3,
    },
    sectionSubHeader: {
        px: 3,
    },
    detailHeader: {
        pb: 1,
        alignSelf: 'flex-start',
    },
    detailSubHeader: {
        pb: 3,
        alignSelf: 'flex-start',
    },
    downloadButton: {
        mb: 4,
    },
    deleteButton: {
        mb: 3,
    },
    privacyButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        textDecoration: 'none',
        zIndex: '1'
    },
    expandCollapseIcon: {
        position: 'absolute',
        right: '1.8rem'
    },
    termsUnderlineStyle: (selectedTheme: any) => ({
        position: 'absolute',
        width: '11.6rem',
        height: '3px',
        transform: 'translate(-11.625rem, 1.0625rem)',
        background: selectedTheme?.palette?.primary[200],
        zIndex: '-1',
    }),
    privacyUnderlineStyle: (selectedTheme: any) => ({
        position: 'absolute',
        width: '8.6rem',
        height: '3px',
        transform: 'translate(-8.7rem, 1.0625rem)',
        background: selectedTheme?.palette?.primary[200],
        zIndex: '-1',
    }),
};

interface IPlatformPrivacyComponent {
    selectedTheme?:any;
}

export function PlatformPrivacyComponent(props:IPlatformPrivacyComponent) {
    const { selectedTheme } = props;
    const [expand, setExpand] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const modalEventHandler = (event: any) => {
        setShowDeleteModal(false);
        console.log(event, 'clicked');
    };

    const confirmDialogButtonList = [
        {
            style: {
                borderRadius: '2px',
                py: '.25rem'
            },
            clickEvent: 'DOWNLOAD',
            text: 'Wait, let me download my data first',
            color: 'primary',
            variant: 'contained'
        },
        {
            style: {
                borderRadius: '2px',
                py: '.25rem',
                px: '.40rem'
            },
            clickEvent: 'DELETE',
            text: 'Yes, Delete my data & deactivate my account',
            color: 'error',
            variant: 'contained'
        },
        {
            style: {
                borderRadius: '2px',
                py: '.25rem'
            },
            clickEvent: 'CANCEL',
            text: 'No, I want to keep my data and account',
            color: 'primary',
            variant: 'outlined'
        },
    ];

    return (
        <>
            <Paper sx={{...selectedTheme.card?.default, maxWidth: { xs: '100%', md: '98%' }, mb: 2 }}>
                <Typography variant="h2" sx={{...styles.sectionTitle, color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]}}>
                    Platform Privacy
                </Typography>
                <Typography sx={{
                    ...styles.sectionSubHeader,
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body2,
                    ...selectedTheme?.typography?.body?.regular,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
                }}>
                    Platform privacy ensures that data you choose to interact
                    with within LifeGraph is secure, trustworthy, and
                    controllable.
                </Typography>
                <br />
                <Divider />
                <ListItemButton
                    onClick={() => setExpand(!expand)}
                    sx={{ px: 3 }}
                >
                    <ListItemIcon sx={{py: 1.75, px: 1}}>
                        <img src={logo} height={40} width={'auto'} />
                    </ListItemIcon>
                    {expand ? <ArrowUpIcon sx={{...styles.expandCollapseIcon, color: selectedTheme?.palette?.primary[700]}}/> : <ArrowDownIcon sx={{...styles.expandCollapseIcon, color: selectedTheme?.palette?.primary[700]}}/>}
                </ListItemButton>
                {!expand ? (
                    <></>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[50],
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" sx={{
                            ...styles.detailHeader,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}>
                            Download All Data
                        </Typography>
                        <Typography sx={{
                            ...styles.detailSubHeader,
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body2,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]    
                        }}>
                            With LifeGraph, you have access to all of your data anytime, anywhere.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            color={'primary'}
                            disabled={false}
                            sx={{...styles.downloadButton, borderRadius: '4px' }}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.small
                            }}>
                                Download My Data
                            </Typography>
                        </Button>
                        <Typography variant="h4" sx={{
                            ...styles.detailHeader,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}>
                            Delete All Data
                        </Typography>
                        <Typography sx={{
                            ...styles.detailSubHeader,
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body2,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]    
                        }}>
                            Deleting all of your data from our system will
                            trigger the deactivation of your LifeGraph account. We
                            encourage you to download all your data before you delete.
                            Should you choose to come back, a new account can be created at any time.{' '}
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            sx={{...styles.deleteButton, borderRadius: '4px'}}
                            disabled={false}
                            color='error'
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.small
                            }}>
                                Delete my data & deactivate account
                            </Typography>
                        </Button>
                        <Button
                            variant={'text'}
                            size={'small'}
                            sx={styles.privacyButton}
                            color='secondary'
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.body?.default,
                                ...selectedTheme?.typography?.body?.body1,
                                ...selectedTheme?.typography?.body?.medium,
                                fontWeight: '500',
                                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]    
                            }}>
                                View Terms & Conditions
                                <Typography component={'span'} sx={styles.termsUnderlineStyle(selectedTheme)}/>
                            </Typography>
                        </Button>
                        <Button
                            variant={'text'}
                            size={'small'}
                            sx={styles.privacyButton}
                            color='secondary'
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.body?.default,
                                ...selectedTheme?.typography?.body?.body1,
                                ...selectedTheme?.typography?.body?.medium,
                                fontWeight: '500',
                                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]    
                            }}>
                                View Privacy Policy
                                <Typography component={'span'} sx={styles.privacyUnderlineStyle(selectedTheme)}/>
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Paper>
            <ConfirmModalComponent
                isOpen={showDeleteModal}
                responseEvent={modalEventHandler}
                headerText={'Are you sure?'}
                subHeaderText={
                    'Are you sure you want to delete all data and deactivate your LifeGraph account?'
                }
                buttonList={confirmDialogButtonList}
            />
        </>
    );
}
