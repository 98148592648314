import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const medicationState = (state: RootState) => state.healthDashboard.medication;

const selectMedicationState = createSelector([medicationState], (state) => {
    return state;
});

export const selectMedicationLoading = createSelector(selectMedicationState, (state) => {
    return state.loading;
});

export const selectMedicationData = createSelector(selectMedicationState, (state) => {
    const formattedData = state?.data?.map((item) => {
        return {
            'label': 'Prescribed By',
            'assetId': item?.asset_id,
            'assetType' : item.assetType,
            'userId': item.userId,
            'value': item?.medicine || '',
            'subHeading': item?.prescribedBy || '',
            "verificationStatus": item?.verificationStatus || '',
            "verificationCompany" : item?.verificationCompany || '',
            "verifier" : item?.verifier || '',
            "verificationTimestamp" : item?.verificationTimestamp?.$date || '',
            "verificationAssetId" : item?.verificationAssetId || '',
            "verificationHash" : item?.verificationHash || '',
            "verificationSignature" : item?.verificationSignature || '',
        }
    }) || [];

    return formattedData;
});

export const selectMedicationError = createSelector(selectMedicationState, (state) => {
    return state.error;
});