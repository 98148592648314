import { Chip, Box } from '@mui/material';
import { Children } from 'react';

const styles = {
    chip: {
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: 'white',
    },
};

interface IChipComponent {
    values: string[];
    sx?: any;
}

export const ChipsComponent = (props: IChipComponent) => {
    const { values, sx } = props;
    if (!values || values.length === 0) {
        return <></>;
    }
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={1}>
            {Children.toArray(
                values.map((x: string, index: number) => {
                    return (
                        <Chip
                            label={x}
                            variant="outlined"
                            size={'small'}
                            sx={{...styles.chip, ...sx}}
                        />
                    );
                })
            )}
        </Box>
    );
};

ChipsComponent.defaultProps = {
    values: [''],
};
