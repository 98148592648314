import * as Types from '../lib/types';
import { hasRequiredRoles } from './getRoles';

const featureRoutes: Types.INavLink[] = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        roles: ['VIEWER'],
        color: 'inherit',
        isLinkHover: true,
        variant: 'text',
    },
    {
        title: 'Profile',
        path: '/profile',
        roles: ['PROFILE'],
        color: 'inherit',
        isLinkHover: true,
        variant: 'text',
        icon: 'personal@1.5x',
    },
    {
        title: 'Data Manager',
        path: '/data-manager',
        roles: ['VIEWER'],
        isLinkHover: true,
        color: 'inherit',
        variant: 'text',
    },
];

const otherRoutes: Types.INavLink[] = [
    {
        title: 'Logout',
        path: '/logout',
        roles: ['VIEWER'],
        type: 'button',
        color: 'secondary',
        variant: 'outlined',
    },
    {
        title: 'Reset',
        path: '/reset',
        roles: ['VIEWER'],
        type: 'button',
        color: 'secondary',
        variant: 'outlined',
    },
];

const healthRoutes: Types.INavLink[] = [
    {
        title: 'Health Dashboard',
        path: '/health/dashboard',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
        isLinkHover : true
    },
    {
        title: 'Health Pathway',
        path: '/health/pathway/bd0abcb5-c5f3-4d0c-b3b8-019a39721bdb',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
        isLinkHover : true
    },
];

const learningRoutes: Types.INavLink[] = [
    {
        title: 'Pathways',
        path: '/learning/pathway',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
    },
    {
        title: 'Skills',
        path: '/learning/skills/map',
        roles: ['PROFILE'],
        color: 'inherit',
        variant: 'text',
        icon: 'personal@1.5x',
    },
    {
        title: 'Community',
        path: '/learning/community',
        roles: ['VIEWER'],
        color: 'inherit',
        variant: 'text',
    },
];

const verifyRoutes: Types.INavLink[] = [
    {
        title: 'Verifier Pro Dashboard',
        path: '/verify/dashboard',
        roles: ['VERIFIER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
    },
    {
        title: 'Verifier Pro Marketplace',
        path: '/verify',
        roles: ['VERIFIER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
    },
];

const marketPlaceRoutes: Types.INavLink[] = [
    {
        title: 'Marketplace',
        path: '/marketplace',
        roles: ['VIEWER'],
        color: 'inherit',
        variant: 'text'
    },
    {
        title: 'My Apps',
        path: '/marketplace/myApps',
        roles: ['VIEWER'],
        color: 'inherit',
        variant: 'text',
    }
];

const learnerRoutes: Types.INavLink[] = [
    {
        title: 'Learning Dashboard',
        path: '/learner/dashboard',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        isLinkHover : true
    },
    {
        title: 'Verified Skills & Credentials',
        path: '/learning/skills/map',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        isLinkHover : true
    },
    {
        title: 'Pathways',
        path: '/learning/pathway',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        isLinkHover : true
    }
];

const filteredUserHomeRoutes = featureRoutes.filter((route) =>
    hasRequiredRoles(route.roles)
);
const filteredUserHealthRoutes = healthRoutes.filter((route) =>
    hasRequiredRoles(route.roles)
);
const filteredUserLearningRoutes = learningRoutes.filter((route) =>
    hasRequiredRoles(route.roles)
);
const filteredVerifyRoutes = verifyRoutes.filter((route) =>
    hasRequiredRoles(route.roles)
);

const filteredMarketPlaceRoutes = marketPlaceRoutes.filter((route) => 
    hasRequiredRoles(route.roles)
);

const filteredUserLearnerRoutes = learnerRoutes.filter((route) =>
    hasRequiredRoles(route.roles)
);

const sideMenuConfig = [
    {
        title: 'Home',
        urlKey: 'home',
        filteredRoutes: filteredUserHomeRoutes,
        moduleTheme: 'lifeGraph',
    },
    {
        title: 'Health',
        urlKey: 'health',
        filteredRoutes: filteredUserHealthRoutes,
        moduleTheme: 'lifeGraph',
    },
    // {
    //     title: 'Learning',
    //     urlKey: 'learning',
    //     filteredRoutes: filteredUserLearningRoutes,
    //     moduleTheme: 'lifetrek',
    // },
    {
        title: 'Learning',
        urlKey: 'learner',
        filteredRoutes: filteredUserLearnerRoutes,
        moduleTheme: 'lifeGraph',
        subModule: {
            title: 'Learning',
            urlKey: 'learning',
            filteredRoutes: filteredUserLearningRoutes,
            moduleTheme: 'lifetrek',
        }
    },
    {
        title: 'Verifier Pro',
        urlKey: 'verify',
        filteredRoutes: filteredVerifyRoutes,
        moduleTheme: 'lifeGraph',
    },
    {
        title: 'Marketplace',
        urlKey: 'marketplace',
        filteredRoutes: filteredMarketPlaceRoutes,
        moduleTheme: 'lifeGraph',
    },
];

const getNavLinks = () => {
    return featureRoutes.filter((route) => {
        return hasRequiredRoles(route.roles);
    });
};

const getOtherNavLinks = () => {
    return otherRoutes.filter((route) => {
        return hasRequiredRoles(route.roles);
    });
};

export const getNavLinksBySelectedModule = (selectedModule: string) => {
    const selectedSideMenu = sideMenuConfig.find(
        (x) => {
            if(x.urlKey !== selectedModule){
                return x?.subModule?.urlKey === selectedModule
            } else {
                return x.urlKey === selectedModule
            }
        }
    );

    if (selectedSideMenu) {
        if(selectedSideMenu?.subModule && selectedSideMenu.urlKey !== selectedModule){
            return selectedSideMenu?.subModule.filteredRoutes;
        }
        return selectedSideMenu.filteredRoutes;
    }
    // ideally this should not be invoked, but keeping it for now
    return featureRoutes.filter((route) => {
        return hasRequiredRoles(route.roles);
    });
};

export { getNavLinks, getOtherNavLinks, sideMenuConfig };
