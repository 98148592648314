import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import axiosInstance, { withCredentials } from 'util/axiosInstance';

function ResetDemo() {
    useEffect(() => {
        axiosInstance()
            .post('/data/demo-data', {}, withCredentials())
            .then(() => {
                window.location.href = '/logout';
            });
    }, []);

    return <Box>Demo Is Resetting</Box>;
}

export default ResetDemo;
