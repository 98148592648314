import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
    ProfileInfoRowComponent,
    ProfileInfoRowVaraint2Component,
} from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { getDateStatusOf2Dates, toLocalDateString } from 'util/date.utils';
import { IProfileCertificationsObject, IProfileLicensuresObject } from '../../../@types/profile-types';
import { getColorByStatus } from 'util/color.utils';
import { ProfileConstants } from 'app-constants';

const convertObjectToProfileRowVariantForLicense = (
    object: IProfileLicensuresObject, selectedTheme: any
) => {
    const header = object.name;
    const subHeader = object.entityName;
    const bodyList: (string | JSX.Element)[] = [];
    if (object.organizationName) {
        bodyList.push(
            <Typography key={'license_org'}
                        sx={{
                            fontSize: '12px',
                            ...selectedTheme?.typography?.captions?.default,
                            ...selectedTheme?.typography?.captions?.bold,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}
            >
                {object.organizationName}
            </Typography>
        );
    }
    const status = getDateStatusOf2Dates(
        object.issueDate?.$date,
        object.expirationDate?.$date
    );
    const statusColor = getColorByStatus(status, selectedTheme);
    bodyList.push(
        <Typography
            fontSize={14}
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.bold,
                color: statusColor
            }}
            fontWeight={600}
            key={'lic_status_col'}
        >
            {status}
        </Typography>
    );
    return { header, subHeader, bodyList };
};

const convertObjectToProfileRowVariantForCertification = (
    object: IProfileCertificationsObject, selectedTheme: any
) => {
    const header = object.name;
    const subHeader = object.certificationBoard;
    const bodyList: (string | JSX.Element)[] = [];
    if (object.organizationName) {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'certi_org'}
            >
                {object.organizationName}
            </Typography>
        );
    }
    const status = getDateStatusOf2Dates(
        object.dateReceived?.$date,
        object.expirationDate?.$date
    );
    const statusColor = getColorByStatus(status, selectedTheme);
    bodyList.push(
        <Typography
            fontSize={14}
            fontWeight={600}
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.bold,
                color: statusColor
            }}
            key={'certi_status_col'}
        >
            {status}
        </Typography>
    );
    return { header, subHeader, bodyList };
};

export const LicenceKeyComponent = (props: any) => {
    const {
        data,
        index,
        selectedTheme,
        licensureLoading,
        canEdit,
        handleLicenseModalOpen,
        hideVerification = false,
        boxStyle = {}
    } = props
    const row =
        convertObjectToProfileRowVariantForLicense(
            data, selectedTheme
        );

    return (
        <Box key={`lic_${index}`} className="flex-column full-width" sx={boxStyle}>
            <ProfileInfoRowVaraint2Component
                isLoading={licensureLoading}
                header={row.header}
                subHeader={row.subHeader}
                selectedTheme={selectedTheme}
                bodyDataList={row.bodyList}
                objectId={data.asset_id}
                onEditClicked={
                    handleLicenseModalOpen
                }
                canEdit={
                    canEdit
                        ? 'ACTIVE'
                        : 'HIDDEN'
                }
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                verificationDetail={{ key: ProfileConstants.LICENSE_INFO, data: props }}
                hideVerification={hideVerification}
            />
            <ProfileInfoRowComponent
                isLoading={licensureLoading}
                label="License Number"
                selectedTheme={selectedTheme}
                value={data.number}
                canEdit={'HIDDEN'}
            />
            <Grid container direction="row">
                <Grid item xs={6} lg={6}>
                    <ProfileInfoRowComponent
                        isLoading={
                            licensureLoading
                        }
                        selectedTheme={selectedTheme}
                        label="Issued"
                        value={toLocalDateString(
                            data.issueDate?.$date
                        )}
                        canEdit={'HIDDEN'}
                        verificationHolderSize={
                            2
                        }
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    {data.expirationDate ? (
                        <ProfileInfoRowComponent
                            isLoading={
                                licensureLoading
                            }
                            selectedTheme={selectedTheme}
                            label="Expires"
                            value={toLocalDateString(
                                data.expirationDate
                                    ?.$date
                            )}
                            canEdit={'HIDDEN'}
                            verificationHolderSize={
                                2
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export const CertificationKeyComponent = (props: any) => {
    const {
        data,
        selectedTheme,
        certificationsLoading,
        canEdit,
        index,
        handleCerticationsModalOpen,
        hideVerification = false
    } = props
    const row =
        convertObjectToProfileRowVariantForCertification(
            data, selectedTheme
        );
    return (
        <Box
            key={`certi_${index}`}
            className="flex-column full-width"
        >
            <ProfileInfoRowVaraint2Component
                key={`certi_${index}`}
                isLoading={
                    certificationsLoading
                }
                selectedTheme={selectedTheme}
                header={row.header}
                subHeader={row.subHeader}
                bodyDataList={row.bodyList}
                objectId={data.asset_id}
                onEditClicked={
                    handleCerticationsModalOpen
                }
                canEdit={
                    canEdit
                        ? 'ACTIVE'
                        : 'HIDDEN'
                }
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                verificationDetail={{ key: ProfileConstants.CERTIFICATION_INFO, data: props }}
                hideVerification={hideVerification}
            />
            <ProfileInfoRowComponent
                key={`certi_id_${index}`}
                isLoading={
                    certificationsLoading
                }
                selectedTheme={selectedTheme}
                label="Certification Id"
                value={data.number}
                canEdit={'HIDDEN'}
            />
            <Grid container direction="row">
                <Grid item xs={6} lg={6}>
                    <ProfileInfoRowComponent
                        key={`certi_issue_${index}`}
                        isLoading={
                            certificationsLoading
                        }
                        selectedTheme={selectedTheme}
                        label="Issued"
                        value={toLocalDateString(
                            data.dateReceived
                                ?.$date
                        )}
                        canEdit={'HIDDEN'}
                        verificationHolderSize={
                            2
                        }
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    {data.expirationDate ? (
                        <ProfileInfoRowComponent
                            key={`certi_end_${index}`}
                            isLoading={
                                certificationsLoading
                            }
                            selectedTheme={selectedTheme}
                            label="Expires"
                            value={toLocalDateString(
                                data.expirationDate
                                    ?.$date
                            )}
                            canEdit={'HIDDEN'}
                            verificationHolderSize={
                                2
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
