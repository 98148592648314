import { dataManagerSummaryActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IExportedData } from '../../../@types/data-manager-types';

export interface IDataManagerExportedState {
    error: boolean;
    data: IExportedData[];
    loading: boolean;
}

interface IDataManagerExportedAction {
    type: string;
    payload: IExportedData[];
}

const initialStateData: IDataManagerExportedState = {
    error: false,
    data: [],
    loading: true,
};

const reducer = (
    state: IDataManagerExportedState = initialStateData,
    action: IDataManagerExportedAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case dataManagerSummaryActionType.GET_DATA_MANAGER_EXPORTED_SUMMARY_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
