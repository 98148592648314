import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { PathwaySummary, PathwaySummaryLoading } from '.';
import { PageHeader } from '..';
import { IPathway } from '../../@types/pathway-types';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes';

const styles = {
    root: {
        pt: 2,
        px: { xs: 0 },
        pb: 6,
    },
};
interface IProps {
    myPathways: IPathway[],
    loading: boolean,
    currentModule: string,
    otherPathways: IPathway[],
}

export const PathwayListComponent = (props: IProps) => {
    const {myPathways, loading, currentModule, otherPathways} = props;
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Box>
            <Box sx={styles.root} style={{ backgroundColor: 'white' }}>
                <Container maxWidth={false} sx={{ px: '6.75rem !important' }}>
                    <PageHeader
                        title="My Pathways"
                        sx={{
                            ...selectedTheme?.typography?.h2,
                            color: selectedTheme?.palette?.purple?.light,
                        }}
                    />
                    {!myPathways.length && !loading ? (
                        <Box>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body1,
                                    color: selectedTheme?.palette?.secondary
                                        ?.grayScale?.main,
                                }}
                            >
                                You don’t have any pathways yet.
                            </Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {loading ? (
                        <PathwaySummaryLoading mode={'SUMMARY'} />
                    ) : (
                        React.Children.toArray(
                            myPathways.map((p: IPathway) => (
                                <PathwaySummary
                                    assetId={p?.assetId}
                                    metadata={p?.metaData}
                                    instancedata={p?.instanceData}
                                    mode="summary"
                                    applicationModule={currentModule}
                                    pieTextColor={
                                        selectedTheme?.palette.purple?.dark
                                    }
                                />
                            ))
                        )
                    )}
                </Container>
            </Box>

            <Box sx={styles.root}>
                <Container maxWidth={false} sx={{ px: '6.75rem !important'}}>
                    <PageHeader
                        title="Explore Pathways"
                        sx={{
                            ...selectedTheme?.typography?.h2,
                            color: selectedTheme?.palette?.purple?.light,
                        }}
                    />
                    {!otherPathways.length && !loading ? (
                        <Box>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body1,
                                    color: selectedTheme?.palette?.secondary
                                        ?.grayScale?.main,
                                }}
                            >
                                There are no new pathways to explore.
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ mb: 3 }}>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body1,
                                    color: selectedTheme?.palette?.secondary
                                        ?.grayScale?.main,
                                }}
                            >
                                Below are some possible pathways curated by your
                                employer. Each has a goal you can reach, and a
                                series of education opportunities and actions to
                                help you along the way. These are available
                                completely free of charge, and can be completed
                                on your own time.
                            </Typography>
                        </Box>
                    )}

                    {loading ? (
                        <PathwaySummaryLoading mode={'SUMMARY'} />
                    ) : (
                        React.Children.toArray(
                            otherPathways.map((p: IPathway) => (
                                <PathwaySummary
                                    assetId={p?.assetId}
                                    metadata={p?.metaData}
                                    instancedata={p?.instanceData}
                                    mode="summary"
                                    applicationModule={currentModule}
                                    pieTextColor={
                                        selectedTheme?.palette?.secondary
                                            ?.grayScale?.main
                                    }
                                />
                            ))
                        )
                    )}
                </Container>
            </Box>
        </Box>
    );
}