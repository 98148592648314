import { createTheme } from '@mui/material/styles';
import { baseTheme } from './base';

const lifetrek = createTheme(baseTheme, {
  palette: {
    primary: {
      main: '#69D7D2',
      300: '#53C3C0',
      400: '#69D7D2',
      600 : '#43838E'
    },
    secondary: {
      grayScale: {
        main: '#202124'
      }
    },
    purple: {
      light: '#645DD8',
      lighter: '#DECFFF',
      dark: '#342DA7',
      darker: '#262086'
    },
    blue: {
      light: '#457CCC'
    },
    pieColor: {
      primary: '#446DBB',
      secondary: '#C355BF'
    }
  },
  typography: {
    fontFamily: ['Open Sans', 'sans serif'].join(','),
    h1: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '40px',
    },
    h2: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '36px',
    },
    h3: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '23px',
    },
    h5: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '21px',
    },
    h6: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '5px'
    },
    subtitle1: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '.15px'
    },
    subtitle2: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '.1px',
    },
    body1: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
    },
    body2: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
    },
    body3: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '26px',
      color: '#202124'
    },
    body: {
      default: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        color: '#000000',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      body3: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      light: {
        fontWeight: '300',
      },
      regular: {
        fontWeight: '400'
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      },
    },
    subtitles: {
      default: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        lineHeight: '19px',
        color: '#000000'
      },
      subtitle1: {
        fontSize: '16px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        letterSpacing: '0.1px',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      }
    },
    captions: {
      default: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: '#000000',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      }
    },
    banner: {
      banner1: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '80px',
        lineHeight: '94px'
      },
      banner2: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '64px',
        lineHeight: '75px'
      },
      bannerSubtitle: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '40px',
        lineHeight: '47px',
        letterSpacing: '8px'
      }
    },
    caption: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '.4px',
    },
    overline: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '2px',
      textTransform: 'uppercase'
    },
    overlines: {
      overline1: {
        default: {
          fontFamily: ['Open Sans', 'sans serif'].join(','),
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '2px',
          textTransform: 'uppercase',
          color: '#000000'
        },
        light: {
          fontWeight: '300'
        },
        regular: {
          fontWeight: '400'
        },
        medium: {
          fontWeight: '500'
        },
        semibold: {
          fontWeight: '600'
        },
        bold: {
          fontWeight: '700'
        },
        extrabold: {
          fontWeight: '800'
        },
        black: {
          fontWeight: '900'
        }
      },
      overline2: {
        default: {
          fontFamily: ['Open Sans', 'sans serif'].join(','),
          fontStyle: 'normal',
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
          color: '#000000'
        },
        light: {
          fontWeight: '300'
        },
        regular: {
          fontWeight: '400'
        },
        medium: {
          fontWeight: '500'
        },
        semibold: {
          fontWeight: '600'
        },
        bold: {
          fontWeight: '700'
        },
      }
    },
    Components: {
      alertTitle: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      avatarInitials: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textTransform: 'uppercase',
        color: '#000000'
      },
      badgeLabel: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#000000',
      },
      button: {
        default: {
          fontFamily: ['Open Sans', 'sans serif'].join(','),
          fontStyle: 'normal',
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
        },
        large: {
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '26px',
        },
        medium: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '24px',
        },
        small: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
      inputLabel: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '21px',
        letterSpacing: '0.15px',
        color: '#000000'
      },
      helperText: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
        color: '#000000'
      },
      inputText: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '0.15px',
        color: '#000000'
      },
      chip: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.16px',
        color: '#000000'
      },
      tabBar: {
        tabs: {
          '& .MuiTab-root.Mui-selected': {
            color: '#202124',
            fontStyle: 'normal',
            fontWeight: '600',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#645DD8',
            bottom: '8px',
            height: '2px',
            borderRadius: '2px'
          },
        },
        tab: {
          fontFamily: ['Open Sans', 'sans serif'].join(','),
          fontStyle: 'italic',
          fontWeight: '300',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#80848D'
        }
      },
      tooltip: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#000000'
      },
      tableHeader: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: '#000000'
      },
      menuItem: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000'
      },
      menuItemSelected: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHight: '150%',
        letterSpacing: '0.15px',
        color: '#000000'
      },
      menuItemDense: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: '#000000'
      },
      listSubHeader: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '48px',
        letterSpacing: '0.1px',
        color: '#000000'
      },
      bottomNavigationActiveLabel: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '166%',
        letterSpacing: '0.4px',
        color: '#000000'
      },
    }
  },
  button: {
    default: {
      borderRadius: '2px',
    },
    size: {
      large: {
        px: 2.75,
        py: 1
      },
      medium: {
        px: 2,
        py: .75
      },
      small: {
        px: 1.25,
        py: .5
      }
    },
    disabled: {
      border: '1px dashed #AABABF',
      color: '#5D6A71',
      background: '#FFFFFF'
    },
    contained: {
      primary: {
        background: '#645DD8',
        color: '#FFFFFF',
        '&:hover': {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #645DD8',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
        }
      },
      secondary: {
        background: '#B5F5EF',
        color: '#285C6C',
        border: '1.5px solid #53C3C0',
        '&:hover': {
          background: '#DFFDF9',
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      error: {
        background: '#CA2C30',
        color: '#FFF3F3',
        '&:hover': {
          background: '#A91216',
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      warning: {
        background: '#FFD02A',
        color: '#5C003C',
        '&:hover': {
          background: '#FFC11B',
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      info: {
        background: '#2375C0',
        color: '#FFFFFF',
        '&:hover': {
          background: '#1A64A8',
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      success: {
        background: '#197C52',
        color: '#CFFFED',
        '&:hover': {
          background: '#2D674E',
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      inherit: {
        background: '#E7ECEE',
        color: '#293541',
        '&:hover': {
          background: '#D2DBDE',
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      }
    },
    outlined: {
      primary: {
        border: '1px solid #645DD8',
        background: '#FFFFFF',
        color: '#645DD8'
      },
      secondary: {
        border: '1px solid #8499A0',
        background: '#FFFFFF',
        color: '#47515A',
        '&:hover': {
          background: 'rgba(93, 106, 113, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      error: {
        background: 'rgba(202, 44, 48, 0.04)',
        border: '1px solid #CA2C30',
        color: '#CA2C30',
        '&:hover': {
          background: 'rgba(202, 44, 48, 0.12)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      warning: {
        border: '1px solid #FFC11B',
        background: '#FFFFFF',
        color: '#7C0338',
        '&:hover': {
          background: 'rgba(255, 208, 42, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      info: {
        border: '1px solid #2375C0',
        background: '#FFFFFF',
        color: '#2375C0',
        '&:hover': {
          background: 'rgba(35, 117, 192, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      success: {
        border: '1px solid #83F3C4',
        background: '#FFFFFF',
        color: '#197C52',
        '&:hover': {
          background: 'rgba(131, 243, 196, 0.09)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      inherit: {
        border: '1px solid #293541',
        background: '#FFFFFF',
        color: '#293541',
        '&:hover': {
          background: 'rgba(43, 54, 64, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      }
    },
    text: {
      primary: {
        color: '#645DD8',
      },
      secondary: {
        color: '#5D6A71',
        '&:hover': {
          background: 'rgba(93, 106, 113, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      error: {
        color: '#CA2C30',
        '&:hover': {
          background: 'rgba(202, 44, 48, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      warning: {
        color: '#7C0338',
        '&:hover': {
          background: 'rgba(255, 208, 42, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      info: {
        color: '#2375C0',
        '&:hover': {
          background: 'rgba(35, 117, 192, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      success: {
        color: '#197C52',
        '&:hover': {
          background: 'rgba(131, 243, 196, 0.09)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
      inherit: {
        color: '#293541',
        '&:hover': {
          background: 'rgba(43, 54, 64, 0.04)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      }
    }
  },
  table: {
    default: {
      border: "2px solid #AABABF",
      borderRadius: "5px"
    },
    header: {
      background: "#F7F9FA",
      border: "1px solid #D2DBDE",
      borderRadius: "5px 5px 0px 0px"
    },
    headerCell: {
      fontFamily: "Source Code Pro",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.17px",
      color: "#293541"
    },
    headerCellTitle: {
      fontWeight: 700,
    },
    rows: {
      background: "#FFFFFF",
      borderWidth: "1px 0px",
      borderStyle: "solid",
      borderColor: "#D2DBDE"
    },
    rowCell: {
      fontFamily: "Red Hat Text",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#47515A"
    },
    rowCellBold: {
      fontWeight: 600
    },
    rowExpanded: {
      background: "#F7F9FA",
      border: "1px solid #D2DBDE",
      height: "100%"
    },
    rowExpandedCell: {
      fontSize: "16px",
      lineHeight: "21px"
    },
    rowExpandedCellBold: {
      fontWeight: 700
    }
  },
  card: {
    base : {
        // pt: { xs: 2, md: 3 },
        mb: 3,
    },
    default: {
        border: "0.5px solid #BDC1C5",
        boxShadow: "0px 1px 2px #E3E8EE",
        overflow: "hidden"
    },
    muted : {
        background: "#f6f7f8",
        opacity : "0.6"
    },
    disabled : {
      opacity : "0.5"
    },
    accordion: {
        background: "#FFFFFF",
        borderRadius : "12px"
    },
  },
});

export { lifetrek };
