import React, { useEffect, useContext } from 'react';
import { Typography, Grid, Container, useMediaQuery, useTheme } from '@mui/material';
import { baseTheme, ThemeContext } from 'util/themes';
import {
    ConsentedDataSummary,
    ExportedDataSummary,
    IntegrationSummary,
} from 'pages/data-manager/components';
import { ShareMyDataButton } from 'pages/share-my-data';
import { checkOnboarding } from '../../../util/getUserData';
import { useHistory } from 'react-router-dom';
import { exportedDataSelector, sharedDataSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { dataManagerActionCreators } from 'redux/actionCreators';

const styles = {
    header: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '36px',
        color: baseTheme.palette.primary.main,
    },
};

export function DataManagerDashboard() {
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const disableContainerGutters = isMobile ? true : false;
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();

    const sharedDataSummary = useSelector(sharedDataSelector.selectSharedDataForDashboardPage);
    const sharedDataLoading = useSelector(sharedDataSelector.selectLoading);

    const exportedDataSummary = useSelector(exportedDataSelector.selectExportedSummaryDataList);
    const exportedDataLoading = useSelector(exportedDataSelector.selectLoading);

    useEffect(() => {
        setCurrentTheme('lifeGraph');
        if (!checkOnboarding('dataManager')) {
            history.push('/data-manager/onboarding');
        } else {
            if (sharedDataLoading) {
                dispatch(dataManagerActionCreators.getSharedDataContracts());
            }
            if (exportedDataLoading) {
                dispatch(dataManagerActionCreators.getExportedDataContracts());
            }
        }
    }, []);

    return (
        <Container maxWidth={false} disableGutters={disableContainerGutters}>
            <Grid container style={{ display: 'block' }}>
                <Grid
                    item
                    sx={{
                        my: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2,
                    }}
                >
                    <Typography sx={{ color: selectedTheme.palette.primary[700] }} variant='h1'>Data Manager</Typography>
                    <ShareMyDataButton />
                    {/* <Button
                        onClick={() => shareMyDataClicked()}
                        size="small"
                        variant="contained"
                        sx={{
                            backgroundColor: '#457CCC',
                            textTransform: 'none',
                            padding: '5px 16px',
                        }}
                    >
                        Share My Data
                    </Button> */}
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        my: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ mr: { xs: 0, md: 1 } }}>
                        <ConsentedDataSummary
                            selectedTheme={selectedTheme}
                            dataList={sharedDataSummary}
                            loading={sharedDataLoading}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 3, md: 0 } }}
                    >
                        <ExportedDataSummary 
                            selectedTheme={selectedTheme}
                            dataList={exportedDataSummary}
                            loading={sharedDataLoading}
                        />
                    </Grid>
                </Grid>

                <Grid item sx={{ my: 3 }}>
                    <IntegrationSummary 
                        selectedTheme={selectedTheme}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
