import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

export const SearchTextField = (props: {selectedTheme: { input?: any }, updateSearchString: (params: any) => void }) => {
    return (
        <TextField
            fullWidth={true}
            inputProps={{
                style: {
                    padding: '7px',
                },
            }}
            sx={{...props.selectedTheme?.input}}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            label="Search..."
            InputLabelProps={{
                style: { marginTop: '-2px' },
            }}
            variant="outlined"
            size="small"
            onChange={props.updateSearchString}
        />
    )
}