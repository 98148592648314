export const getColorByStatus = (status: string, selectedTheme?: any) => {
    const colorObject: any = {
        'Active': (selectedTheme?.palette?.tertiary?.green && selectedTheme?.palette?.tertiary?.green[800]) || 'primary',
        'Expired': (selectedTheme?.palette?.tertiary?.red && selectedTheme?.palette?.tertiary?.red[500]) || 'error',
        'Inactive': 'warning',
        'Expires Soon': 'secondary',
        'In Progress': 'secondary',
        'Complete': 'primary'
    }

    return colorObject[status] || 'error';
}