import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';

export interface IVaccinationFormInputs {
    vaccine: string;
    administerDate: string;
    administeredBy: string;
}

const schema = yupObject({
    vaccine: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    administerDate: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
});

export const formSchemaResolver = yupResolver(schema);