import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, MouseEvent, Fragment, useEffect, Children, useContext } from 'react';
import { stringToLocalDate, toLocalDateTimeString } from 'util/date.utils';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
    Download as DownloadIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import { TableExpandedRow } from './TableExpandedRow';
import { Button } from 'lib/controls';
import { ThemeContext } from 'util/themes';

type Order = 'asc' | 'desc';

const tableBorder = '1px solid #DADCE0';

export interface ITableColumn {
    id: string;
    dataType: string;
    label: string;
    hasSorting: boolean;
    valueStyleOverride: any;
    onClick: Function;
    width?: string;
}

export interface ITableComponent {
    loading: boolean;
    columns: ITableColumn[];
    data: any[];
}

const sortData = (data: any[], key: string, sortOrder: Order) => {
    data.sort((a: any, b: any) => {
        if (a[key] < b[key]) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
    });
};

const styles = {
    headerCell: {
        textTransform: 'uppercase',
        py: 1,
        fontSize: '12px',
        color: '#80848D',
        fontWeight: 'bold',
        border: 'none',
    },
    rowCell: {
        fontSize: '14px',
        border: 'none',
    },
    button: {
        textTransform: 'none',
        color: '#457CCC',
    },
    downloadIcon: {
        color: '#457CCC',
    },
};

export function TableComponent(props: ITableComponent) {
    const { columns, data, loading } = props;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('');
    const [openRow, setOpenRow] = useState<number>(-1);
    const [tableRows, setTableRows] = useState<any[]>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { selectedTheme } = useContext(ThemeContext);

    useEffect(() => {
        if (loading) {
            setTableRows(Array(5).fill({}));
        } else {
            setTableRows(data);
        }
    }, [loading]);

    const expandRow = (rowIndex: number) => {
        if (rowIndex === openRow) {
            setOpenRow(-1);
        } else {
            setOpenRow(rowIndex);
        }
    };

    const handleSortRequest =
        (columnId: string) => (event: MouseEvent<unknown>) => {
            const isAsc = orderBy === columnId && order === 'asc';
            const newSortOrder = isAsc ? 'desc' : 'asc';
            setOrder(newSortOrder);
            setOrderBy(columnId);
            sortData(data, columnId, newSortOrder);
        };

    if (!tableRows) {
        return <></>;
    }

    return (
        <Table>
            <TableHead sx={selectedTheme.summaryTable?.header}>
                <TableRow sx={{ width: '100%' }}>
                    {Children.toArray(
                        columns.map((x) => {
                            if (!x.hasSorting) {
                                return (
                                    <TableCell
                                        sx={{
                                            ...styles.headerCell,
                                            width: x.width || 'auto',
                                            ...selectedTheme?.typography?.Components?.tableHeader,
                                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                                        }}
                                    >
                                        {x.label}
                                    </TableCell>
                                );
                            }
                            return (
                                <TableCell
                                    sortDirection={
                                        orderBy === x.id ? order : 'asc'
                                    }
                                    sx={styles.headerCell}
                                >
                                    <TableSortLabel
                                        active={orderBy === x.id}
                                        direction={
                                            orderBy === x.id ? order : 'asc'
                                        }
                                        sx={{
                                            ...selectedTheme?.typography?.Components?.tableHeader,
                                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                                        }}
                                        onClick={handleSortRequest(x.id)}
                                    >
                                        {x.label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {Children.toArray(
                    tableRows.map((row: any, index: number) => {
                        return (
                            <Fragment>
                                <TableRow sx={selectedTheme.summaryTable?.row}>
                                    {Children.toArray(
                                        columns.map((column) => {
                                            let value;
                                            switch (column.dataType) {
                                                case 'date': {
                                                    let date =
                                                        toLocalDateTimeString(
                                                            row[column.id]
                                                        );
                                                    if (
                                                        date !== 'Invalid date'
                                                    ) {
                                                        value = date;
                                                    } else {
                                                        value = row[column.id];
                                                    }
                                                    break;
                                                }
                                                case 'downloadButton':
                                                    if (isMobile) {
                                                        value = (
                                                            <DownloadIcon
                                                                onClick={() =>
                                                                    column.onClick(
                                                                        row
                                                                    )
                                                                }
                                                                sx={
                                                                    styles.downloadIcon
                                                                }
                                                            />
                                                        );
                                                    } else {
                                                        value = (
                                                            <Button
                                                                size={'medium'}
                                                                variant={
                                                                    'outlined'
                                                                }
                                                                color="secondary"
                                                                sx={{
                                                                    textTransform:
                                                                        'none',
                                                                }}
                                                                onClick={() =>
                                                                    column.onClick(
                                                                        row
                                                                    )
                                                                }
                                                            >
                                                                <Typography sx={{
                                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                                                }}>
                                                                    Download
                                                                </Typography>
                                                            </Button>
                                                        );
                                                    }
                                                    break;
                                                case 'editButton':
                                                    if (isMobile) {
                                                        value = (
                                                            <EditIcon
                                                                onClick={() =>
                                                                    column.onClick(
                                                                        row
                                                                    )
                                                                }
                                                            
                                                                sx={
                                                                    styles.downloadIcon
                                                                }
                                                            />
                                                        );
                                                    } else {
                                                        value = (
                                                            <Button
                                                                size={'medium'}
                                                                variant={
                                                                    'outlined'
                                                                }
                                                                color="secondary"
                                                                sx={{
                                                                    textTransform:
                                                                        'none',
                                                                }}
                                                                onClick={() =>
                                                                    column.onClick(
                                                                        row
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        );
                                                    }
                                                    break;
                                                case 'expand':
                                                    value = (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                expandRow(index)
                                                            }
                                                       
                                                        >
                                                            {openRow ===
                                                            index ? (
                                                                <ArrowUpIcon
                                                                
                                                                />
                                                            ) : (
                                                                <ArrowDownIcon
                                                              
                                                                />
                                                            )}
                                                        </IconButton>
                                                    );
                                                    break;
                                                default:
                                                    value = row[column.id];
                                            }
                                            return (
                                                <TableCell
                                                 
                                                    sx={{
                                                        ...styles.rowCell,
                                                        ...selectedTheme?.typography?.body?.default,
                                                        ...selectedTheme?.typography?.body?.body2,
                                                        ...selectedTheme?.typography?.body?.regular,
                                                        color: selectedTheme?.palette.secondary?.grayScale &&
                                                            selectedTheme?.palette.secondary?.grayScale[800], 
                                                        ...column.valueStyleOverride,
                                                        width:
                                                            column.width ||
                                                            'auto',
                                                        
                                                    }}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        })
                                    )}
                                </TableRow>
                                <TableRow
                                    sx={selectedTheme.summaryTable?.rowExpanded}
                                >
                                    <TableCell
                                        style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}
                                        colSpan={columns.length}
                                    >
                                        <Collapse
                                            in={openRow === index}
                                            timeout={'auto'}
                                            unmountOnExit
                                            key={'collapse_row_' + index}
                                        >
                                            <TableExpandedRow
                                                rows={row.expandedRowValue}
                                                isExpanded={openRow === index}
                                                isLastRow={false}
                                            />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        );
                    })
                )}
            </TableBody>
        </Table>
    );
}
