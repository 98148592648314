export const styles = {
    blue: {
        background: `linear-gradient(87.47deg, #FEFFE9 -0.78%, #FFC5E3 53.34%, #FFABAB 103.13%)`,
    },
    green: {
        background: `linear-gradient(87.08deg, #E0FFF7 0.27%, #E0DFFF 55.64%, #FFBDE9 102.37%)`,
    },
    gray: {
        background: `linear-gradient(90deg, #F5F5F5 0%, #DCDCDC  100%)`,
    },
    white: {
        background: '#FFFFFF',
        borderBottom: '7px solid #E7ECEE',
        boxShadow: 'none',
    },
    pie: {
        px: {
            xs: 4,
            sm: 0,
            md: 0,
        },
    },
    cardBase: {
        pt: { xs: 2, md: 3 },
        mb: 3,
    },
    button: {
        color: '#457CCC',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        lineHeight: '20px',
        textDecoration: 'none',
        textTransform: 'none',
    },
    title: {
        color: '#202124',
        fontSize: { xs: '24px', md: '32px' },
        letterSpacing: 0,
        lineHeight: '40px',
        fontWeight: 400,
    },
    goal: {
        color: '#202124',
        fontSize: '16px',
        lineHeight: '24px',
        Letter: '0.15px',
        mb: 2,
    },
    cta: {
        textTransform: 'none',
    },
    completed: {
        color: '#202124',
        fontSize: '16px',
        letterSpacing: '0.15px',
        lineHeight: '24px',
    },
    pathwayImage: {
        height: '-webkit-fill-available',
        width: { xs: '270px', md: '300px', lg: '310px' },
        pr: 2,
    },
    healthPathwayImage: {
        height: '100%',
        width: '90%',
    },
};
