import * as React from 'react';
import {
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
} from '@mui/material';
import { Button } from '../../lib/controls';

import axios, { withCredentials } from '../../util/axiosInstance';
import ProfileInfoRow from '../profile-info-row/ProfileInfoRow';

const styles = {
    cardPadding: {
        p: 2,
    },
    reqVerification: {
        m: 1,
        mt: 0,
        color: '#4799D3',
        borderColor: '#4799D3',
    },
};

const RequestVerification = (props: {
    assetType: string;
    assetId: string;
    callback?: Function;
}) => {
    const { assetType, assetId, callback } = props;
    const [attributes, setAttributes] = React.useState<string[]>([]);
    const [shareWith, setShareWith] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [openToast, setToastOpen] = React.useState(false);

    const handleToastOpen = () => {
        setToastOpen(true);
    };

    const handleToastClose = (
        event: Event | React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            const resp = await axios().post(
                `/verification/${assetType}/verification-request`,
                { asset_id: assetId, source: 'profile' },
                withCredentials()
            );
            handleClose();
            handleToastOpen();
            if (callback) {
                callback();
            }
        } catch (error) {
            // console.error(error);
        }
    };

    const getAttributes = async () => {
        try {
            const resp = await axios().get(
                `/verification/${assetType}/contract-stub`,
                withCredentials()
            );

            setShareWith(resp.data?.data?.sharedWith);
            setAttributes(resp.data?.data?.attributes);
            handleClickOpen();
        } catch (error) {
            // console.error(error);
        }
    };
    return (
        <Box>
            <Button onClick={getAttributes} color="secondary" size="small">
                REQUEST VERIFICATION
            </Button>
            <Dialog
                open={open}
                sx={styles.cardPadding}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    REQUEST VERIFICATION
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            In order to verify this information you need to
                            authorize the verifier to see the information.
                        </Grid>
                        <Grid
                            sx={{
                                m: 0.625,
                                p: 0.625,
                                backgroundColor: '#f7f8fa',
                                borderRadius: '10px',
                            }}
                            item
                            xs={6}
                        >
                            <ProfileInfoRow
                                label="SHARE WITH"
                                value={shareWith}
                            />
                            <ProfileInfoRow
                                label="ATTRIBUTES"
                                value={
                                    <Box
                                        component="p"
                                        sx={{
                                            wordBreak: 'break-word',
                                            mt: 0,
                                        }}
                                    >
                                        {attributes.join(', ')}
                                    </Box>
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={styles.cardPadding}>
                    <Button
                        size="large"
                        onClick={handleClose}
                        variant="text"
                        color="primary"
                    >
                        CANCEL
                    </Button>
                    <Button
                        size="large"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        REQUEST VERIFICATION
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openToast}
                autoHideDuration={6000}
                onClose={handleToastClose}
                message="Request Verification is success"
            />
        </Box>
    );
};

export default RequestVerification;
