import { Box, Typography, Grid, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from 'assets/images/logos/Lifegraph Badge.svg';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes';

const styles = {
    mobile: {
        height: '100%',
        display: {
            xs: 'block',
            md: 'none',
        },
    },
    desktop: {
        height: '100%',
        position: 'relative' as const,
        display: {
            xs: 'none',
            md: 'block',
        },
    },
    desktopLogo: (theme: Theme) => ({
        height: 'auto',
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(15),
        width: '68px',
    }),
    mobileLogo: (theme: Theme) => ({
        height: 'auto',
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4) ,
        width: '40px',
    }),
    desktopTypography: (theme: Theme) => ({
        my: '2%',
        marginLeft: theme.spacing(15)
    }),
};

interface IOnboardingLayoutProps {
    imageFileName: string;
    typographyList?: string[];
}

export const OnboardingLayoutComponent = (props: IOnboardingLayoutProps) => {
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);
    const { imageFileName, typographyList } = props;
    const imageLink = `/images/onboarding/${imageFileName}.png`;
    const maxHeight =
        typographyList && typographyList.length > 0 ? '200px' : '450px';
    return (
        <Box
            className="onboarding-bg-rectangle"
            minHeight={{ xs: '90%', md: '90vh' }}
        >
            <Box sx={styles.mobile}>
                <Box>
                    <img
                        src={Logo}
                        alt="Lifegraph"
                        style={styles.mobileLogo(theme)}
                    ></img>
                </Box>
                {typographyList && typographyList.length > 0 ? (
                    <Grid
                        container
                        direction={'row'}
                        height={'15vh'}
                        justifyContent={'space-between'}
                    >
                         <Grid item sx={{ width: 0.47, height: '100%' }}>
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '80%',
                                    m: 'auto',
                                    mt: 3.75,
                                }}
                            >
                                <img
                                    src={imageLink}
                                    className="center-image"
                                    height={'100%'}
                                ></img>
                            </Box>
                        </Grid>
                        <Grid item sx={{ width: 0.47 }}>
                            {Children.toArray(
                                typographyList.map(
                                    (x: string, index: number) => {
                                        return (
                                            <Typography sx={{
                                                ...selectedTheme?.typography?.body?.default,
                                                ...selectedTheme?.typography?.body?.body1,
                                                ...selectedTheme?.typography?.body?.regular,
                                                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                                            }}>
                                                {x}
                                            </Typography>
                                        );
                                    }
                                )
                            )}
                            <Typography></Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Box sx={{ height: '100%', width: '80%', m: 'auto' }}>
                        <img
                            src={imageLink}
                            className="center-image"
                            height={'100%'}
                        ></img>
                    </Box>
                )}
            </Box>
            <Box sx={styles.desktop}>
                <Box sx={{display : 'flex', justifyContent : 'space-between'}}>
                    <img
                        src={Logo}
                        alt="Lifegraph"
                        style={styles.desktopLogo(theme)}
                    ></img>
                    <img
                        src='/images/onboarding/hex-group.svg'
                        alt="Lifegraph"
                        style={styles.desktopLogo(theme)}
                    ></img>
                </Box>
                <Box sx={typographyList?.length ? styles.desktopTypography(theme): {}}>
                    {typographyList && typographyList.length > 0 ? (
                        Children.toArray(
                            typographyList.map((x: string, index: number) => {
                                return (
                                    <Typography paddingBottom={'16px'} sx={{
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        ...selectedTheme?.typography?.body?.regular,
                                        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                                    }}>
                                        {x}
                                    </Typography>
                                );
                            })
                        )
                    ) : (
                        <></>
                    )}
                </Box>
                <Box sx={{ width: '100%', top: '3%', left: '4rem'}}>
                    <img src={imageLink}  
                        style={selectedTheme.imageBackground}></img>
                </Box>
            </Box>
        </Box>
    );
};
