import { useContext, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import axios from '../../../util/axiosInstance';
import { Input, Button } from '../../controls';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from 'util/themes';
import { notificationActionTypes } from '../../../redux/actionsTypes';
import { useDispatch } from 'react-redux';

interface IProps {
    usernameLabel?: string;
    loginButtonText?: string;
    onSuccess: () => void;
    onFail?: () => void;
}

interface IFormInputs {
    username: string;
    password: string;
}

const schema = yup.object({
    username: yup.string().required('Required'),
    password: yup.string().required('Required'),
});

const initialValues = { username: '', password: '' };

export const LoginForm = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme, setCurrentTheme } = useContext(ThemeContext);
    const {
        usernameLabel = 'Username',
        loginButtonText = 'Login',
        onSuccess,
        onFail,
    } = props;
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const inputProp = {
        style: { 
            padding: '8px 12px', 
        },
    };

    useEffect(() => {
        setCurrentTheme('lifeGraph');
    }, []);

    const setLocalStorage = (responseData: {}): void => {
        if (!responseData) {
            return;
        } else {
            // Given an object, map over it and set the localStorage
            Object.keys(responseData).forEach((key) => {
                localStorage.setItem(
                    key,
                    JSON.stringify(
                        responseData[key as keyof typeof responseData]
                    )
                );
            });
        }
    };

    const executeLogin = async (formValues: IFormInputs) => {
        await axios()
            .post('/auth/login', formValues)
            .then((response) => {
                if (response.data.terms.length > 0) {
                    history.push({
                        pathname: '/terms-and-conditions',
                        state: {
                            from: {
                                pathname: '/login',
                            },
                            terms: {
                                ...formValues,
                                terms: response.data.terms,
                            },
                        },
                    });
                } else {
                    setLocalStorage(response.data);
                    onSuccess();
                }
            })
            .catch(() => {
                dispatch({
                    type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION,
                    message: 'There is a problem with your login, try again later',
                });
            });
    };

    const submitForm = (formValues: IFormInputs): void => {
        executeLogin(formValues)
            .then(() => {
                console.log('Login Successful');
            })
            .catch((error: any) => {
                console.error('Login Failed:', error.message);
                if (onFail instanceof Function) {
                    onFail();
                }
            });
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <Box>
                <Input
                    name="username"
                    label={usernameLabel}
                    defaultValue=""
                    control={control}
                    error={errors?.username || ''}
                    labelStyles={{
                        ...selectedTheme?.typography?.Components?.inputLabel,
                        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                    }}
                    inputProps={{...inputProp}}
                    inputStyleProps={{
                        ...selectedTheme?.typography?.Components?.inputText,
                        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                    }}
                    sx={{...selectedTheme?.input}}
                />

                <Input
                    name="password"
                    label="Password"
                    type="password"
                    defaultValue=""
                    control={control}
                    error={errors?.password || ''}
                    labelStyles={{
                        ...selectedTheme?.typography?.Components?.inputLabel,
                        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                    }}
                    inputProps={{...inputProp}}
                    inputStyleProps={{
                        ...selectedTheme?.typography?.Components?.inputText,
                        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                    }}
                    sx={{...selectedTheme?.input}}
                />
            </Box>

            <Box mt={4}>
                <Button
                    type="submit"
                    variant="contained"
                    color='primary'
                    fullWidth
                    size="medium"
                >
                    <Typography sx={{
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.medium,
                    }}>
                        {loginButtonText}
                    </Typography>
                </Button>
            </Box>
        </form>
    );
};
