import { Box, Grid, Paper, Divider, Typography } from '@mui/material';
import { Children, useContext } from 'react';
import { CardTitleComponent } from 'components';
import { ThemeContext } from 'util/themes';

const styles = {
    labelStyle: {
        wordSpacing: '100vw',
        textAlign : 'center' 
    }
}

const skillsData = [
    {
        name: 'Time Management',
        value: 'Trainer'
    },
    {
        name: 'Written Communication',
        value: 'Expert'
    },
    {
        name: 'Teamwork',
        value: 'Expert'
    },
    {
        name: 'Oral Communication',
        value: 'Intermediate'
    },
    {
        name: 'Building Relationships',
        value: 'Beginner'
    },
    {
        name: 'Customer Relationships',
        value: 'Intermediate'
    },
    {
        name: 'Customer Service',
        value: 'Beginner'
    },
    {
        name: 'Leadership',
        value: 'Beginner'
    }
]

export function VerifiedSkillsCard() {

    const { selectedTheme } = useContext(ThemeContext);
   
    return (
        <> 
        <Box>
            <Grid item xs sx={selectedTheme?.card?.gridWrapper}>
                <Paper sx={selectedTheme?.card?.default}>
                    <CardTitleComponent title={'Verified Skills'} />
                    <Divider sx={{border: `1px solid ${selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[300]}`}}/>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container mt={4.5} mb={3}>
                                {Children.toArray(
                                    skillsData.map(data => 
                                        <Grid xs={3} mb={4} item container justifyContent='center' alignItems='center' flexDirection='column'>
                                            <Grid>
                                                <Typography sx={{
                                                    ...styles.labelStyle,
                                                    ...selectedTheme?.typography?.h3,
                                                    color: selectedTheme?.palette?.primary[600]
                                                }}>
                                                    {data.name}
                                                </Typography>
                                            </Grid>
                                            <Grid mt={1}>
                                                <Typography component={'span'} sx={{
                                                    ...selectedTheme?.typography?.overlines?.overline1?.default,
                                                    ...selectedTheme?.typography?.overlines?.overline1?.semibold,
                                                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
                                                }}>
                                                    {data.value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Box>
        </>
    )
}