import { 
    object as yupObject, 
    string as yupString, 
    boolean as yupBoolean, 
    date as yupDate,
    ref as yupRef
 } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationAddressForm } from 'components';
import { IOgranizationAddress } from '../../../@types/profile-types';
import validationMessage from 'util/validations';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';

export interface IEmploymentFormInputs extends IOgranizationAddress {
    current: boolean;
    department: string;
    title: string;
    startDate: string;
    endDate: string;
    asset_id?: string;
};

export const formDefaults: IEmploymentFormInputs = {
    current: false,
    department: '',
    title: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    current: yupBoolean().required(validationMessage.required),
    department: yupString().max(255, validationMessage.max(255)),
    title: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
}).concat(OrganizationAddressForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);