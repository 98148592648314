import { healthPathwayActionType } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';
import articleByIdMockData from 'assets/data/health-pathway/article-by-id.json';

export const getHealthPathwayList = () => async (dispatch: Function) => {
    dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_LOADING });
    try {
        const resp = await axios().get('v2/health-pathway', withCredentials());
        dispatch({
            type: healthPathwayActionType.GET_HEALTH_PATHWAY_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_ERROR });
    }
};

export const getHealthPathwayById = (id: string) => async (dispatch: Function) => {
    dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_LOADING });
    try {
        const resp = await axios().get(`v2/health-pathway/${id}`, withCredentials());
        dispatch({
            type: healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_BY_ID_ERROR });
    }
};

export const getHealthPathwayArticleById = (id: string) => async (dispatch: Function) => {
    dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING });
    try {
        const resp = await axios().get(`v2/health-pathway/article/${id}`, withCredentials());
        dispatch({
            type: healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS,
            payload: resp.data,
        });
    } catch (error) {
        dispatch({ type: healthPathwayActionType.GET_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR });
    }
};

export const updateHealthPathwayArticleById = (id: string) => async (dispatch: Function) => {
    dispatch({ type: healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_LOADING });
    try {
        const resp = { data: articleByIdMockData.find(item => item.id === id) };
        // const resp = await axios().get('/v2/pathway', withCredentials());
        dispatch({
            type: healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_SUCCESS,
            payload: { ...resp.data, completed: true },
        });
    } catch (error) {
        dispatch({ type: healthPathwayActionType.UPDATE_HEALTH_PATHWAY_ARTICLE_BY_ID_ERROR });
    }
};
