import { IRoute } from 'lib/types';
import config from 'config';
import {
    SkillsOnboardingComponent,
    SkillsDashboardComponent,
    SkillDetailComponent,
} from './index';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const skillRoutes: IRoute[] = [
    {
        path: '/learning/skills/onboarding',
        roles: ['LEARNER'],
        component: SkillsOnboardingComponent,
        title: `${appTitle}: Skills Onboarding`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning/skills/map',
        roles: ['LEARNER'],
        component: SkillsDashboardComponent,
        title: `${appTitle}: Skills Center`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning/skills/vc',
        roles: ['LEARNER'],
        component: SkillsDashboardComponent,
        title: `${appTitle}: Skills Center`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/learning/skills/:skillId',
        roles: ['LEARNER'],
        component: SkillDetailComponent,
        title: `${appTitle}: Skills Detail`,
        authErrorPaths: authErrorPaths,
    }
];