import { skillsActionTypes, notificationActionTypes } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';
import mockSkillsMapData from 'assets/data/skills/skills.data.json';
import mockSelectedSkillDetailData from 'assets/data/skills/skills-details.data.json';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const getUserSkills = () => async (dispatch: Function) => {
    dispatch({ type: skillsActionTypes.GET_ALL_USER_SKILLS });
    try {
        await delay(2000);
        // const resp = await axios().get('some/url', withCredentials());
        dispatch({
            type: skillsActionTypes.GET_ALL_USER_SKILLS_SUCCESS,
            payload: mockSkillsMapData,
        });
    } catch (error) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load Skills data, please contact support' });
    }
};

export const getSelectedSkillDetail = (selectedSkillId: string) => async (dispatch: Function) => {
    dispatch({ type: skillsActionTypes.GET_SELECTED_SKILL });
    try {
        await delay(2000);
        // const resp = await axios().get(`some/url/${selectedSkillId}`, withCredentials());
        dispatch({
            type: skillsActionTypes.GET_SELECTED_SKILL_SUCCESS,
            payload: mockSelectedSkillDetailData,
        });
    } catch (error) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load Skills data, please contact support' });
    }
}