import { healthDashboardAllergyActionTypes } from 'redux/actionsTypes';
import produce from 'immer';

interface IAllergyObj {
    allergy: string;
    assetType: string;
    asset_id: string;
    lastReaction: any;
    userId: string;
    verificationAssetId: string;
    verificationCompany: string;
    verificationHash: string;
    verificationSignature: string;
    verificationStatus: string;
    verificationTimestamp: any;
    verificationType: string;
    verifier: string;
}

export interface IAllergyInfo {
    error: boolean;
    data: IAllergyObj[];
    loading: boolean;
}

interface IAllergyAction {
    type: string;
    payload: {
        data: IAllergyObj[]
    }
}

const initialStateData = {
    error: false,
    data: [],
    loading: false,
};

const reducer = (
    state: IAllergyInfo = initialStateData,
    action: IAllergyAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_LOADING:
                draft.error = false;
                draft.data = [];
                draft.loading = true;
                return draft;
            case healthDashboardAllergyActionTypes.GET_HEALTH_DASHBOARD_ALLERGY_SUCCESS:
                draft.error = false;
                draft.data = action.payload.data;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;