import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ProfileInfoRowComponent, ProfileInfoRowVaraint2Component } from 'components';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { toLocalDateString } from 'util/date.utils';
import {
    IProfileTrainingObject,
} from '../../../@types/profile-types';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { ProfileConstants } from 'app-constants';

const convertObjectToProfileRowVariant = (object: IProfileTrainingObject, selectedTheme: any) => {
    const header = object.name;
    const subHeader = object.organizationName || undefined;
    const addressLine = getSingleLineAddressFromObject(object);
    const bodyList = [];
    if (addressLine) {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'form_address_line'}>
                {addressLine}
            </Typography>
        );
    }
    return { header, subHeader, bodyList };
};

export const TrainingComponent = (props: any) => {
    const {
        data,
        selectedTheme,
        trainingLoading,
        handleModalOpen,
        canEdit,
        hideVerification = false
    } = props;
    const row = convertObjectToProfileRowVariant(data, selectedTheme);
    return (
        <Box className="flex-column full-width">
            <ProfileInfoRowVaraint2Component
                isLoading={trainingLoading}
                header={row.header}
                subHeader={row.subHeader}
                selectedTheme={selectedTheme}
                bodyDataList={row.bodyList}
                objectId={data.asset_id}
                onEditClicked={handleModalOpen}
                canEdit={
                    canEdit ? 'ACTIVE' : 'HIDDEN'
                }
                verificationData={convertProfileObjectToMaryVilleVerification(
                    data
                )}
                verificationDetail={{key: ProfileConstants.TRAINING_INFO, data: props}}
                hideVerification={hideVerification}
            />
            <Grid container direction="row">
                <Grid item xs={6} lg={6}>
                    <ProfileInfoRowComponent
                        isLoading={trainingLoading}
                        label="Start Date"
                        selectedTheme={selectedTheme}
                        value={
                            toLocalDateString(
                                data.startDate?.$date
                            ) || ''
                        }
                        canEdit={'HIDDEN'}
                        verificationHolderSize={2}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <ProfileInfoRowComponent
                        isLoading={trainingLoading}
                        label="End Date"
                        selectedTheme={selectedTheme}
                        value={
                            toLocalDateString(
                                data.endDate?.$date
                            ) || ''
                        }
                        canEdit={'HIDDEN'}
                        verificationHolderSize={2}
                    />
                </Grid>
            </Grid>
            {data.hours ? (
                <ProfileInfoRowComponent
                    isLoading={trainingLoading}
                    selectedTheme={selectedTheme}
                    label="Hours"
                    value={data.hours}
                    canEdit={'HIDDEN'}
                />
            ) : (
                <></>
            )}
        </Box>
    );
}
