import { Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Box, ThemeProvider, CssBaseline } from '@mui/material';
import { createBrowserHistory, History } from 'history';
import { RenderRoute } from './components/render-route';
import SideNavBar from './components/SideNavBar/SideNavBar';
import { baseTheme, lifeGraph, lifetrek, burstIQ } from './util/themes';
import { ThemeContext } from 'util/themes';
import { getUserRouteStatus } from 'util/getUserData';
import routes from './util/routes';
import store from './redux/store';
import { ConnectedRouter } from 'connected-react-router';
import { LicenseInfo } from '@mui/x-license-pro';
import { profileActionCreators } from 'redux/actionCreators';
import { AppNotificationComponent } from 'components';
import { Children, useEffect, useState } from 'react';
import { sideMenuConfig } from './util/navLinks';


export const history = createBrowserHistory() as History;

const updateDataGridProWithKey = () => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_KEY || '');
};

const themes : {[key : string] : any} = {
    'lifeGraph' : lifeGraph,
    'lifetrek' : lifetrek,
    'baseTheme' : baseTheme,
    'burstIQ': burstIQ
};

const AppContainer = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    dispatch(profileActionCreators.getProfilePersonalInfo());
    return (
        <>
            <SideNavBar sideMenuConfig={sideMenuConfig}>
                <Box component="div">
                    <Switch>
                        {Children.toArray(
                            routes.map((route) => <RenderRoute {...route} />)
                        )}
                        <Redirect exact to="/" />
                    </Switch>
                </Box>
            </SideNavBar>
        </>
    );
};

const LoginContainer = () => {
    return (
        <main>
            <Switch>
                {Children.toArray(
                    routes.map((route) => <RenderRoute {...route} />)
                )}
                <Redirect exact to="/" />
            </Switch>
        </main>
    );
};

export function App() {
    const [selectedTheme, setSelectedTheme] = useState(themes[window.localStorage.getItem('selectedTheme') || 'baseTheme']);
    const setCurrentTheme = (themeName:string) => {
        setSelectedTheme(themes[themeName]);
        window.localStorage.setItem('selectedTheme', themeName);
    }

    const status = getUserRouteStatus();
    updateDataGridProWithKey();
    return (
        <Router>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ThemeContext.Provider value={{selectedTheme, setCurrentTheme}}>
                        <ThemeProvider theme={selectedTheme}>
                            <AppNotificationComponent />
                            <CssBaseline />
                            {status === 'AUTHENTICATED' ? (
                                <AppContainer />
                            ) : (
                                <LoginContainer />
                            )}
                        </ThemeProvider>
                    </ThemeContext.Provider>
                </ConnectedRouter>
            </Provider>
        </Router>
    );
}

export default App;
