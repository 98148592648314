import { baseTheme } from 'util/themes';
import * as verificationUtil from 'util/verificationHelper';
import { IverificationStatus } from '../../@types/profile-types';

export const styleByStatus = {
    pending: {
        color: baseTheme.palette.warning.main,
        toolTip: 'Pending',
        icon: 'mdi:hexagon-slice-3',
    },
    unverified: {
        color: '#9AA0A6',
        toolTip: 'Unverified',
        icon: 'mdi:hexagon-outline',
    },
    verified: {
        toolTip: 'Verified',
        color: baseTheme.palette.success.main,
        icon: 'mdi:hexagon-slice-6',
    },
};

export const styles = {
    poweredByLogo: {
        height: '32px',
        width: '135px',
    },
    verificationTitle:(selectedTheme: any) => ({
        color: '#202124',
        fontSize: '20px',
        fontWeight: '600',
    }),
    labelTitle: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.overlines?.overline2.default,
        ...selectedTheme?.typography?.overlines?.overline2.regular,
        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]
    }),
    proofTitle: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.Components?.button?.default,
        ...selectedTheme?.typography?.Components?.button?.large,
        color: selectedTheme?.palette?.primary[700],
        cursor: 'pointer',
        textAlign: 'center',
    }),
    labelValue: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.default,
        ...selectedTheme?.typography?.body?.body1,
        ...selectedTheme?.typography?.body?.regular,
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
    }),
    verificationText: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.default,
        ...selectedTheme?.typography?.body?.body2,
        ...selectedTheme?.typography?.body?.regular,
        color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[700]
    }),
    trustStyle: (selectedTheme: any) => ({
        textAlign: 'center',
        mb: -1.25,
        ...selectedTheme?.typography?.body?.default,
        ...selectedTheme?.typography?.body?.body3,
        ...selectedTheme?.typography?.body?.bold,
        color: selectedTheme?.palette?.primary[600]
    }),
    proofMessage: {
        fontSize: '14px',
    },
};

const listItemForVerified = [
    {
        label: verificationUtil.VERIFICATION_VERIFIED_MESSAGE,
        valueAttribute: 'verificationMessage',
        labelStyle:(selectedTheme: any) => styles.verificationText(selectedTheme),
        valueStyle: () => {},
        type: 'verification',
    },
    {
        label: 'Verified By',
        valueAttribute: 'verificationCompany',
        labelStyle: (selectedTheme: any) =>  styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        type: 'verification',
    },
    {
        label: 'Why should I trust this?',
        labelStyle: (selectedTheme: any) => styles.trustStyle(selectedTheme),
        valueAttribute: 'whyTrust',
        valueStyle: () => {},
        type: 'verification',
    },
    {
        label: 'Verifier',
        type: 'verification',
        valueAttribute: 'verifier',
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
    },
    {
        label: 'QR Code',
        valueAttribute: 'qrCode',
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        type: 'verification',
    },
    {
        valueAttribute: 'verificationTimestamp',
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        label: 'When',
        type: 'verification',
    },
    {
        label: 'Asset Id',
        valueAttribute: 'verificationAssetId',
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        type: 'proof',
    },
    {
        valueAttribute: 'verificationHash',
        label: 'Hash',
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        type: 'proof',
    },
    {
        label: 'Show Proof',
        labelStyle: (selectedTheme: any) => styles.proofTitle(selectedTheme),
        valueAttribute: 'showProof',
        valueStyle: () => {},
        type: 'verification',
    },
    {
        label: 'Signature',
        valueAttribute: 'verificationSignature',
        valueStyle: (selectedTheme: any) => styles.labelValue(selectedTheme),
        labelStyle: (selectedTheme: any) => styles.labelTitle(selectedTheme),
        type: 'proof',
    },
];

const listItemForUnverified = [
    {
        label: verificationUtil.VERIFICATION_UNVERIFIED_MESSAGE,
        valueAttribute: 'unverified',
        labelStyle: (selectedTheme: any) => styles.verificationText(selectedTheme),
        valueStyle: () => {},
        type: 'verification',
    },
];

const listItemForPending = [
    {
        label: verificationUtil.VERIFICATION_PENDING_MESSAGE,
        valueAttribute: 'pending',
        labelStyle: (selectedTheme: any) => styles.verificationText(selectedTheme),
        valueStyle: () => {},
        type: 'verification',
    },
];

export const getListitemByStatus = (status: IverificationStatus) => {
    if (status === 'pending') {
        return listItemForPending;
    } else if (status === 'unverified') {
        return listItemForUnverified;
    } else if (status === 'verified') {
        return listItemForVerified;
    } else {
        return [];
    }
};
