import { Box, Typography, Paper } from '@mui/material';
import { ChipsComponent } from 'components';
import React, { useContext } from 'react';
import { ThemeContext } from 'util/themes';
import { IArticle, IInstanceData, IMetaData } from '../../../@types/pathway-types';
import { dataByStatus, styles } from './demonstration.helper';
import { Button } from 'lib/controls';
import { Articles } from '../Steps';

interface IDemonstrationComponent {
    sectionSelected: Function;
    instanceData: IInstanceData;
    metaData: IMetaData;
    data: any;
    isHealthMode: boolean;
}

const getDemonstrationChildCardContent = (card: any, instanceData: any, cardData: any, selectedTheme: any) => {
    const onClick = () => {
        console.log(instanceData?.status, 'button clicked');
    };
    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ ...styles.childCardHeader, ...card.headerStyle }}>
                {card.header}
            </Typography>
            {card.showHoldReason ? (
                <Typography
                    sx={{ ...styles.childCardSubheader, ...card.headerStyle }}
                >
                    {instanceData.holdReason}
                </Typography>
            ) : (
                <></>
            )}
            {card.showVariables ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {
                        <>
                            <Box sx={{ display: 'inline-flex', gap: 2 }}>
                                <Typography sx={styles.variableLabel}>
                                    Started
                                </Typography>
                                <Typography sx={styles.stepDescription}>
                                    {instanceData.startDate}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'inline-flex', gap: 2 }}>
                                <Typography sx={styles.variableLabel}>
                                    {card.variable2Label}
                                </Typography>
                                <Typography sx={styles.stepDescription}>
                                    {cardData[card.variable2Attribute]}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'inline-flex', gap: 2 }}>
                                <Typography sx={styles.variableLabel}>
                                    Evaluator
                                </Typography>
                                <Typography sx={styles.stepDescription}>
                                    {cardData.evaluatorName}
                                </Typography>
                            </Box>
                        </>
                    }
                </Box>
            ) : (
                <></>
            )}
            {card.footerText ? (
                <Typography sx={styles.childCardDescription}>
                    {card?.footerText}
                </Typography>
            ) : (
                <></>
            )}
            {card.buttonText ? (
                <Button
                    variant={'contained'}
                    sx={{
                        ...card.buttonStyle,
                        py: '0.25rem',
                        borderRadius: '4px'
                    }}
                    size={'medium'}
                    onClick={onClick}
                >
                    <Typography sx={{
                        ...selectedTheme?.typography?.Components?.button?.default,
                        ...selectedTheme?.typography?.Components?.button?.medium,
                        textTransform: 'none'
                    }}>
                        {card.buttonText}
                    </Typography>
                </Button>
            ) : (
                <></>
            )}
        </Box>
    );
};

function Demonstration(props: IDemonstrationComponent) {
    const { sectionSelected, data, isHealthMode, instanceData } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const skills = data?.skills?.map((x: any) => x.skillName);
    const stepDescription = data.description;
    const card =
        instanceData?.status ?
            dataByStatus[instanceData?.status] || dataByStatus.locked :
            dataByStatus.locked;

    return (
        <Box>
            {isHealthMode && !!data.articles.length ? (
                <Box style={{ width: '100%' }}>
                    {React.Children.toArray(
                        data?.articles.map((article: IArticle) => {
                            return (
                                <Articles
                                    {...article}
                                    metaData={{...article.metaData, title: card.header}}
                                    sectionSelected={sectionSelected}
                                />
                            );
                        })
                    )}
                </Box>
            ) : (
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                    <Paper elevation={1} sx={{ borderRadius: '12px', ...selectedTheme?.card?.default }}>
                        {getDemonstrationChildCardContent(card, instanceData, data, selectedTheme)}
                    </Paper>
                    {stepDescription ? (
                        <Typography sx={styles.stepDescription}>
                            {stepDescription}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {skills?.length > 0 ? (
                        <Box>
                            <Typography sx={{ ...styles.variableLabel, mb: 1 }}>
                                Skills:
                            </Typography>
                            <ChipsComponent
                                values={skills}
                                sx={{
                                    color: selectedTheme?.palette?.purple?.light,
                                    borderColor: selectedTheme?.palette?.purple?.light
                                }}
                            />
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default Demonstration;
