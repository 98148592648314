import axios from '../../util/axiosInstance';
import * as yup from 'yup';

const resetRequestUrl = '/auth/request-reset-password';
  
const schema = yup.object({
    email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Required'),
});

const initialValues = { email: '' };

const executeResetRequest = async (formValues: any): Promise<any> => {
    const values = { email: formValues.email };
    await axios()
        .post(resetRequestUrl, values)
        .then((resp: any) => {
            console.log('success', resp);
            return resp;
        })
        .catch((error: any) => {
            console.log('fail', error);
        });
};

export { executeResetRequest, initialValues, schema };
