import React, { useContext } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { DashboardApps } from '../dashboard/components/DashboardApps';
import { VerifiedSkillsCard } from './verified-skills-card/VerifiedSkillsCard';
import { ThemeContext } from 'util/themes';
import { apps } from './LearnerDashboardHelper';
import { LicenseAndCertificationsCardComponent } from 'pages/profile/license-certificates/licenseCertifications';
import { FormalEducationCardComponent } from 'pages/profile/formal-education/formalEducation';
import { WorkCardComponent } from 'pages/profile/work/work';

const styles = {
	headerImage: {
		background: 'url(/images/dashboard/dashboard-bg.svg) no-repeat',
		backgroundSize: 'contain',
		height: '20rem',
        position: "relative",
        top: "0px",
        width: "100%"
	}
};
export function LearnerDashboardComponent() {

    const { selectedTheme } = useContext(ThemeContext);
    const isInEditMode = false;

    return (
        <>
        <Box sx={styles.headerImage}>
            <Typography variant={'h1'} sx={{...selectedTheme?.typography?.h1, p : 5}}>Learning Center</Typography>
        </Box>
        <Container maxWidth={false} sx={{position: "relative", top: "-12rem"}}>
            <Box sx={{backgroundColor: 'white', ml:2, mr:2}}>
                <DashboardApps apps={apps}/>
                <VerifiedSkillsCard />
                <Grid display='flex' container spacing={2}>
                    <Grid item xs={4}>
                        <LicenseAndCertificationsCardComponent  
                            title={'Licenses & Certifications'}
                            canEdit={isInEditMode}
                            // itemsToShow={}
                            type={'PREVIEW'} 
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormalEducationCardComponent
                            title={'Formal Education'}
                            canEdit={isInEditMode}
                            type={'PREVIEW'}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WorkCardComponent
                            title={'Work'}
                            canEdit={isInEditMode}
                            type={'PREVIEW'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </>
    )
}