import { useMediaQuery, useTheme, Breakpoint } from '@mui/material';

export const useGetDivider = (dividerMap: any, defaultValue: number) => {
    const theme = useTheme();

    const screenSizes = ['xs', 'sm', 'md', 'lg'];
    const sizes = screenSizes.map(item=> {
        const size = useMediaQuery(theme.breakpoints.only(item as Breakpoint));
        if(size) {
            return dividerMap[item]
        }
    });

    return sizes.find((x) => x !== undefined) || defaultValue;
}