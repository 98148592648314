import {useState, useEffect, memo} from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { allergySelector } from 'redux/selectors';
import { HealthDashboardCard } from "../health-dashboard-card/HealthDashboardCard";
import { healthDashboardActionCreators } from 'redux/actionCreators';
import { AllergyForm } from './AllergyModal';

interface IAllergyCardComponent {
    cardData: {
        title: string;
        itemsToShow: any;
    }
}

const AllergyComponent = (props: IAllergyCardComponent) => {
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({
        allergy: '',
        lastReaction: '', 
        assetId: '',
        userId: ''
    });
    const { cardData } = props;
    let itemToShowArray = [...cardData.itemsToShow];
    const initialCardObj = {
        label: 'Last Major Reaction',
        value: '',
        subHeading: ''
    };
    
    useEffect(() => {
        dispatch(healthDashboardActionCreators.getHealthDashboardAllergyList());
    }, []);

    const formattedData = useSelector(allergySelector.selectAllergyData);
    const allergyLoading = useSelector(allergySelector.selectAllergyLoading);
    
    if(formattedData.length){
        formattedData.forEach((item: any) => {
            itemToShowArray.push(item);
        })
    }

    const handleModalOpen = (id: string) => {
        const filteredData = formattedData.filter((item: any) => {
            return item.assetId === id
        }) || [];
        if(filteredData.length){
            const { value, subHeading, assetId, userId } = filteredData[0];
            setSelectedData({
                'allergy': value,
                'lastReaction': subHeading !== '' ? moment(subHeading).format('YYYY-MM-DD') : '',
                assetId,
                userId
            });
        }
        setEdit(true);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const onAddIconClicked = () => {
        setSelectedData({
            allergy: '', 
            lastReaction: '',
            assetId: '',
            userId: ''
        });
        setEdit(false);
        setModalOpen(true);   
    }
    
    return (
        <>
            <HealthDashboardCard 
                title={cardData.title} 
                itemsToShow={itemToShowArray}
                isLoading={allergyLoading}
                initialCardObj={initialCardObj}
                canEdit={true}
                handleModalOpen={handleModalOpen}
                onAddIconClicked={onAddIconClicked}
            />
            {modalOpen ? (
                <AllergyForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    selectedData={selectedData}
                    isEdit={isEdit}
                />
                ) : (
            <></>
            )}
        </>
    )
}

export const AllergyCardComponent = memo(AllergyComponent);